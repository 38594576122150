import { get, post } from "../api_interceptor/httpClient";
import toggleLoaderAction from "../../../Redux/Actions/loader";
import {
  PAYOUT_CALCULATION,
  PAYOUT_MIGRATION,
  GET_CALCULATION_SUMMARY_LIST,
  PAYOUT_CALCULATION_DETAIL,
  PAYOUT_CALCULATION_DELETE,
  PAYOUT_CALCULATION_HISTORY,
  PAYOUT_CALCULATION_FREEZE,
  GET_LAST_CALC_DATE
} from "../api_interceptor/urls";

export const payout_calculation_function =
  (payload, successCallback, failureCallback) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await post(PAYOUT_CALCULATION, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };
export const payout_migration_function =
  (payload, successCallback, failureCallback) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await post(PAYOUT_MIGRATION, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };

export const payout_calculation_summary_list =
  (payload, successCallback, failureCallback) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await get(GET_CALCULATION_SUMMARY_LIST, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };

export const payout_calculation_detail =
  (successCallback, failureCallback) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await get(PAYOUT_CALCULATION_DETAIL);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };

export const delete_payout_calculation =
  (payload, successCallback, failureCallback) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await post(PAYOUT_CALCULATION_DELETE, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };

export const payout_calculation_history =
  (payload, successCallback, failureCallback) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await get(PAYOUT_CALCULATION_HISTORY, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };

export const freeze_payout_calculation =
  (payload, successCallback, failureCallback) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await post(PAYOUT_CALCULATION_FREEZE, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };
