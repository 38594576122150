import qs from "qs";
import * as types from "./ActionTypes";
import { post, get, put } from "../Helper/fetch";
import * as urls from "../../modules/payout/api_interceptor/urls";

export const lastCalculationDate = () => (dispatch) => {
  dispatch({ type: types.GET_PAYOUT_LASTCALCDATE_CALL });
  get({
    url: urls.GET_LAST_CALC_DATE,
    success: types.GET_PAYOUT_LASTCALCDATE_SUCCESS,
    failure: types.GET_PAYOUT_LASTCALCDATE_ERROR,
    dispatch,
    body: false
  });
};
