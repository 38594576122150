import React from "react";
import PropTypes from "prop-types";
import { Box, TextField, Typography } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { Field, ErrorMessage } from "formik";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#E6E4E4",
    color: theme.palette.common.black,
    height: "20px"
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const useStyles = makeStyles({
  table: {
    minWidth: 700
  },
  input: {
    textAlign: "right"
  }
});

const ItemTable = ({
  listData,
  setFieldValue,
  values,
  tabIndex,
  rateList,
  selected,
  setSelected,
  checkRateDisableFun,
  disabledFlag
}) => {
  const classes = useStyles();

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = listData.map((n) => n.ItemName);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, ItemName) => {
    const selectedIndex = selected.indexOf(ItemName);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, ItemName);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <div>
      <Box style={{ marginTop: "0px" }}>
        <TableContainer
          style={{
            borderTopLeftRadius: "22px",
            borderTopRightRadius: "22px",
            maxHeight: 600
          }}
          component={Paper}
        >
          <Table
            stickyHeader
            className={classes.table}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell align="left" padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={
                      selected.length > 0 && selected.length < listData.length
                    }
                    checked={
                      listData.length > 0 && selected.length === listData.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{ "aria-label": "select all desserts" }}
                  />
                </StyledTableCell>
                <StyledTableCell align="left">
                  <b>Item Name</b>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <b>Item Type</b>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <b>Rate Type</b>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <b>Rate</b>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <b>Profit %</b>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <b>Loss %</b>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listData.map((row, index) => {
                const isItemSelected = isSelected(row.ItemName);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    // onClick={() => {
                    //   setSelected(row.processor);
                    // }}
                    key={row.ItemName}
                  >
                    <StyledTableCell
                      onClick={(event) => handleClick(event, row.ItemName)}
                      align="left"
                      padding="checkbox"
                    >
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.ItemName}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <FormControl fullWidth>
                        <Checkbox
                          checked={
                            !!values.Groups[tabIndex].Items_List[index]
                              .IncludeID
                          }
                          onClick={() => {
                            const chk = values.Groups[tabIndex].Items_List[
                              index
                            ].IncludeID
                              ? null
                              : 1;
                            setFieldValue(
                              `Groups[${tabIndex}].Items_List[${index}].IncludeID`,
                              chk
                            );
                          }}
                          color="primary"
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                      </FormControl>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <FormControl fullWidth>
                        <Field
                          as={Select}
                          fullWidth
                          name={`Groups[${tabIndex}].Items_List[${index}].RateType_ID`}
                          variant="outlined"
                          displayEmpty
                          disabled={
                            !values.Groups[tabIndex].Items_List[index].IncludeID
                          }
                          style={{ minWidth: "125px" }}
                          placeholder="Rate Type"
                          value={
                            values.Groups[tabIndex].Items_List[index]
                              .RateType_ID
                          }
                          onChange={(e) => {
                            setFieldValue(
                              `Groups[${tabIndex}].Items_List[${index}].RateType_ID`,
                              e.target.value
                            );
                          }}
                        >
                          <MenuItem disabled value={null}>
                            Rate Type
                          </MenuItem>
                          {rateList.map((rates) => (
                            <MenuItem value={rates.RateType_ID}>
                              {rates.RateType}
                            </MenuItem>
                          ))}
                        </Field>
                      </FormControl>
                      <ErrorMessage
                        name={`Groups[${tabIndex}].Items_List[${index}].RateType_ID`}
                      >
                        {(msg) => (
                          <Typography
                            component="span"
                            className="error"
                            variant="colorError"
                          >
                            {msg}
                          </Typography>
                        )}
                      </ErrorMessage>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {/* <FormControl style={{ textAlign: "center" }} fullWidth> */}
                      <Field
                        as={TextField}
                        fullWidth
                        style={{ minWidth: "100px" }}
                        name={`Groups[${tabIndex}].Items_List[${index}].Rate`}
                        disabled={
                          checkRateDisableFun(
                            values.Groups[tabIndex].Items_List[index]
                              .RateType_ID
                          ) ||
                          !values.Groups[tabIndex].Items_List[index]
                            .IncludeID ||
                          !values.Groups[tabIndex].Items_List[index].RateType_ID
                        }
                        type="text"
                        InputProps={{
                          inputProps: {
                            min: 0,
                            style: {
                              textAlign: "center"
                            }
                          }
                        }}
                        value={values.Groups[tabIndex].Items_List[index].Rate}
                        onChange={(e) => {
                          if (/^\d+(\.\d+)*$/.test(e.target.value)) {
                            setFieldValue(
                              `Groups[${tabIndex}].Items_List[${index}].Rate`,
                              e.target.value
                            );
                          }
                        }}
                        variant="outlined"
                        placeholder="Rate"
                      />
                      <ErrorMessage
                        name={`Groups[${tabIndex}].Items_List[${index}].Rate`}
                      >
                        {(msg) => (
                          <Typography
                            component="span"
                            className="error"
                            variant="colorError"
                          >
                            {msg}
                          </Typography>
                        )}
                      </ErrorMessage>
                      {/* </FormControl> */}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <FormControl fullWidth>
                        <Field
                          as={TextField}
                          fullWidth
                          style={{ minInlineSize: "max-content" }}
                          name={`Groups[${tabIndex}].Items_List[${index}].Profit`}
                          disabled={
                            disabledFlag ||
                            !values.Groups[tabIndex].Items_List[index]
                              .IncludeID ||
                            !values.Groups[tabIndex].Items_List[index]
                              .RateType_ID
                          }
                          type="number"
                          InputProps={{
                            inputProps: { min: 0, max: 100 }
                          }}
                          value={
                            values.Groups[tabIndex].Items_List[index].Profit
                          }
                          onChange={(e) => {
                            setFieldValue(
                              `Groups[${tabIndex}].Items_List[${index}].Profit`,
                              e.target.value
                            );
                          }}
                          variant="outlined"
                          placeholder="Profit %"
                        />
                      </FormControl>
                      <ErrorMessage
                        name={`Groups[${tabIndex}].Items_List[${index}].Profit`}
                      >
                        {(msg) => (
                          <Typography
                            component="span"
                            className="error"
                            variant="colorError"
                          >
                            {msg}
                          </Typography>
                        )}
                      </ErrorMessage>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <FormControl fullWidth>
                        <Field
                          as={TextField}
                          fullWidth
                          style={{ minInlineSize: "max-content" }}
                          name={`Groups[${tabIndex}].Items_List[${index}].Loss`}
                          disabled={
                            disabledFlag ||
                            !values.Groups[tabIndex].Items_List[index]
                              .IncludeID ||
                            !values.Groups[tabIndex].Items_List[index]
                              .RateType_ID
                          }
                          type="number"
                          InputProps={{
                            inputProps: { min: 0, max: 100 }
                          }}
                          variant="outlined"
                          value={values.Groups[tabIndex].Items_List[index].Loss}
                          onChange={(e) => {
                            setFieldValue(
                              `Groups[${tabIndex}].Items_List[${index}].Loss`,
                              e.target.value
                            );
                          }}
                          placeholder="Loss %"
                        />
                      </FormControl>
                      <ErrorMessage
                        name={`Groups[${tabIndex}].Items_List[${index}].Loss`}
                      >
                        {(msg) => (
                          <Typography
                            component="span"
                            className="error"
                            variant="colorError"
                          >
                            {msg}
                          </Typography>
                        )}
                      </ErrorMessage>
                    </StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
};

ItemTable.propTypes = {
  listData: PropTypes.array,
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
  tabIndex: PropTypes.number,
  rateList: PropTypes.array,
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  checkRateDisableFun: PropTypes.func,
  disabledFlag: PropTypes.bool
};

ItemTable.defaultProps = {
  listData: [],
  setFieldValue: () => {},
  values: {},
  tabIndex: 0,
  rateList: [],
  selected: [],
  setSelected: () => {},
  checkRateDisableFun: () => {},
  disabledFlag: false
};

export default ItemTable;
