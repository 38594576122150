import "../../assets/css/CountryCode.scss";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import isEmpty from "lodash/isEmpty";
import { useSnackbar } from "notistack";
import styles from "assets/jss/material-dashboard-react/components/userForm";
import { ErrorMessage, Field, Form, Formik } from "formik";
import MenuItem from "@material-ui/core/MenuItem";
import Validations from "../../Utils/Validations";
import { userListApi, groupsTypeListApi } from "../../actions/TableData";
import {
  createNewGroupApiCall,
  updateGroupApiCall
} from "../../actions/Operations";
import { capitalizeFirstLetter } from "Utils/commonUtils";

const useStyles = makeStyles(styles);
const initialData = {
  groupName: "",
  groupStatus: "Active",
  groupType: "",
  userList: []
};

function AddEditGroup(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [userList, setUserList] = React.useState([]);
  const [groupTypeList, setGroupTypeList] = React.useState([]);
  const [initialValues, setInitialValues] = React.useState(initialData);
  const [initialSubmit, setInitialSubmit] = React.useState(false);
  const windowWidthSm = useMediaQuery(theme.breakpoints.up("sm"));

  const onFormSubmit = (values) => {
    const tempUserList = [];
    values.userList.map((user) => {
      tempUserList.push(user.UserID);
    });
    const updatedPayload = { ...values };
    updatedPayload.userList = [...tempUserList];

    if (isEmpty(props.details)) {
      props.createNewGroupApiCall(
        updatedPayload,
        () => {
          enqueueSnackbar("Group Created Successfully.", {
            variant: "success"
          });
          props.goBack();
        },
        (err) => {
          enqueueSnackbar(
            err.message ? err.message : "Failed to Create New Group.",
            {
              variant: "error"
            }
          );
        }
      );
    } else {
      props.updateGroupApiCall(
        { ...updatedPayload, groupId: props.details._id },
        () => {
          enqueueSnackbar("Group Updated Successfully.", {
            variant: "success"
          });
        },
        (err) => {
          enqueueSnackbar(
            err.message ? err.message : "Failed to Update Group.",
            {
              variant: "error"
            }
          );
        }
      );
    }
  };

  const callUserListApi = () => {
    props.userListApi(
      {
        ClientId: localStorage.getItem("clientId"),
        LoginEmail: localStorage.getItem("email")
      },
      (data) => {
        setUserList(data.Users_Data);
      }
    );
  };

  const callGroupTypeListApi = () => {
    props.groupsTypeListApi((data) => {
      setGroupTypeList(data.State_Data);
    });
  };

  React.useEffect(() => {
    callUserListApi();
    callGroupTypeListApi();
  }, []);

  React.useEffect(() => {
    if (
      props.addEditForm &&
      !isEmpty(props.details) &&
      !isEmpty(groupTypeList) &&
      !isEmpty(userList)
    ) {
      const { groupName, groupStatus, groupType, groupUser } = props.details;
      let groupTypeValue = null;
      if (groupType) {
        groupTypeValue = groupTypeList.find(
          (x) => x.groupTypeName === groupType
        );
      }
      const tempUserList = [];
      groupUser.map((userId) => {
        const x = userList.find((x) => x.UserID === userId);
        if (!isEmpty(x)) {
          tempUserList.push(x);
        }
      });
      setInitialValues({
        groupName,
        groupStatus,
        groupType: groupTypeValue
          ? groupTypeValue.groupTypeCode
          : groupTypeValue,
        userList: [...tempUserList]
      });
    }
  }, [groupTypeList, userList]);

  return (
    <>
      <Box className={classes.marginBox}>
        <Formik
          onSubmit={onFormSubmit}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={Validations.addEditGroups}
        >
          {({ errors, setFieldValue, values, touched, handleSubmit }) => (
            <Form>
              <Grid container className={classes.margin}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Field
                    as={TextField}
                    required
                    error={
                      initialSubmit && errors.groupName && touched.groupName
                    }
                    className={classes.halfInput}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      color: "secondary",
                      classes: {
                        root: classes.activeInputColor,
                        focused: "focused",
                        input: classes.resize
                      },
                      required: true
                    }}
                    label="Group Name"
                    name="groupName"
                    id="groupName"
                    style={{ height: "4.187em" }}
                  >
                    <ErrorMessage name="groupName">
                      {(msg) => (
                        <Typography
                          component="span"
                          color="error"
                          variant="body2"
                        >
                          {msg}
                        </Typography>
                      )}
                    </ErrorMessage>
                  </Field>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Field
                    as={TextField}
                    // error={
                    //   initialSubmit && errors.groupType && touched.groupType
                    // }
                    className={classes.halfInput}
                    select
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      color: "secondary",
                      classes: {
                        root: classes.activeInputColor,
                        focused: "focused",
                        input: classes.resize
                      }
                      // required: true,
                    }}
                    label="Group Type"
                    name="groupType"
                    id="groupType"
                    style={{ height: "4.187em" }}
                  >
                    {groupTypeList.map((type) => (
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value={type.groupTypeCode}
                        key={type._id}
                      >
                        {type.groupTypeName}
                      </MenuItem>
                    ))}
                  </Field>
                  <ErrorMessage name="groupType">
                    {(msg) => (
                      <Typography
                        component="span"
                        color="error"
                        variant="body2"
                      >
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>
              </Grid>
              <Grid container className={classes.margin}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Autocomplete
                    error={initialSubmit && errors.userList && touched.userList}
                    className={classes.halfInput}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      color: "secondary",
                      classes: {
                        root: classes.activeInputColor,
                        focused: "focused",
                        input: classes.resize
                      }
                    }}
                    name="userList"
                    id="userList"
                    style={{ height: "4.187em" }}
                    multiple
                    value={values.userList}
                    onChange={(_, value) => {
                      value && setFieldValue("userList", value);
                    }}
                    options={userList}
                    getOptionLabel={(option) =>
                      `${capitalizeFirstLetter(
                        option.FName
                      )} ${capitalizeFirstLetter(
                        option.MName
                      )} ${capitalizeFirstLetter(option.LName)}`
                    }
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Users List"
                        placeholder="Select Users"
                      />
                    )}
                  >
                    <ErrorMessage name="userList">
                      {(msg) => (
                        <Typography
                          component="span"
                          color="error"
                          variant="body2"
                        >
                          {msg}
                        </Typography>
                      )}
                    </ErrorMessage>
                  </Autocomplete>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Field
                    as={TextField}
                    required
                    select
                    error={
                      initialSubmit && errors.groupStatus && touched.groupStatus
                    }
                    className={classes.halfInput}
                    InputLabelProps={{
                      color: "secondary",
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        focused: "focused",
                        input: classes.resize
                      },
                      required: true
                    }}
                    label="Status"
                    name="groupStatus"
                    id="groupStatus"
                    style={{ height: "4.187em" }}
                  >
                    <MenuItem
                      className={classes.dialogBoxDropDown}
                      value="Active"
                    >
                      Active
                    </MenuItem>
                    <MenuItem
                      className={classes.dialogBoxDropDown}
                      value="InActive"
                    >
                      InActive
                    </MenuItem>
                    <ErrorMessage name="groupStatus">
                      {(msg) => (
                        <Typography
                          component="span"
                          color="error"
                          variant="body2"
                        >
                          {msg}
                        </Typography>
                      )}
                    </ErrorMessage>
                  </Field>
                </Grid>
              </Grid>

              <Grid container className={classes.margin}>
                <Grid item lg={9} md={9} sm={8} xs={0} />
                <Grid item lg={3} md={3} sm={4} xs={12} textAlign="right">
                  <Box className={classes.buttonBox} textAlign="right">
                    <Button
                      variant="contained"
                      className={classes.clearButton}
                      onClick={() => {
                        props.goBack();
                      }}
                    >
                      Back
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                      variant="contained"
                      className={classes.buttonStyle}
                      onClick={() => {
                        setInitialSubmit(true);
                        handleSubmit();
                      }}
                      style={{ alignItems: "right" }}
                    >
                      {isEmpty(props.details) ? "Submit" : "Update"}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
}

AddEditGroup.propTypes = {
  formData: PropTypes.object,
  userRole: PropTypes.object,
  roles: PropTypes.object,
  groupsTypeListApi: PropTypes.func,
  userListApi: PropTypes.func,
  goBack: PropTypes.func,
  createNewGroupApiCall: PropTypes.func,
  updateGroupApiCall: PropTypes.func,
  details: PropTypes.object,
  addEditForm: PropTypes.bool
};

const mapStateToProps = (app) => ({
  formData: app.users,
  userRole: app.userRole,
  roles: app.roles
});
export default connect(mapStateToProps, {
  userListApi,
  groupsTypeListApi,
  createNewGroupApiCall,
  updateGroupApiCall
})(AddEditGroup);
