import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions
} from "@material-ui/lab/Autocomplete";
import { getAgentByHierarchy } from "../../Redux/Actions/ProvideRegistration";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import { makeStyles } from "@material-ui/core/styles";
import isEmpty from "lodash/isEmpty";

const useStyles = makeStyles(styles);

function AgentSearchChip(props) {
  const classes = useStyles();
  const defaultProps = {
    options: props.tableData.agentList.data
  };
  let userid = localStorage.getItem("userId");

  return (
    <Autocomplete
      {...defaultProps}
      value={
        props.tableData.agentList &&
        !isEmpty(props.values.searchAgentId) &&
        props.tableData.agentList.data.find((op) =>
          op.User_Id == props.values.searchAgentId
            ? props.values.searchAgentId
            : userid &&
              isEmpty(props.values.searchAgentId) &&
              props.tableData.agentList.data.find((op) =>
                op.User_Id == userid ? userid : ""
              )
        )
      }
      name="Agent"
      id="Agent"
      label="Agent Name"
      className={classes.rootDialog}
      onChange={(e, value) => {
        if (value) {
          props.onChange(value.User_Id, "searchAgentId");
        } else {
          props.onChange("", "searchAgentId");
        }
      }}
      options={props.tableData.agentList.data?.sort((a, b) =>
        a.Name.localeCompare(b.Name)
      )}
      clearOnBlur
      clearOnEscape
      getOptionLabel={(option) => option.Name}
      getOptionValue={(option) => option.User_Id}
      renderInput={(params) => (
        <TextField
          {...params}
          style={{ fontSize: "82%" }}
          name="Agent"
          id="Agent"
          label="Agent Name"
        />
      )}
    />
  );
}
AgentSearchChip.propTypes = {
  tableData: PropTypes.object,
  values: PropTypes.object,
  getAgentByHierarchy: PropTypes.func,
  onChange: PropTypes.func
};

const mapStateToProps = (app) => ({
  formData: app.getProviderByFilterData,
  tableData: app.provideRegistration
});

export default connect(mapStateToProps, {
  getAgentByHierarchy
})(AgentSearchChip);
