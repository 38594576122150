import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import isEmpty from "lodash/isEmpty";
import FormControl from "@material-ui/core/FormControl";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);
const theme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      backgroundColor: "#2ba992",
      toolbar: {
        backgroundColor: "#3ab499"
      }
    }
  }
});

const FilterFields = ({
  searchValues,
  setSearchValues,
  processorList,
  handleSubmitSearch,
  handleClearSearch
}) => {
  const classes = useStyles();

  const onChange = (value, type) => {
    const allState = { ...searchValues };
    if (type == "fromMonth") {
      allState[type] = moment(value).startOf("month").format("YYYY-MM-DD");
    } else if (type == "toMonth") {
      allState[type] = moment(value).endOf("month").format("YYYY-MM-DD");
    } else {
      allState[type] = value;
    }
    setSearchValues({ ...allState });
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Grid container style={{ marginBottom: "1%" }}>
          <Grid item xs>
            <TextField
              className={classes.rootDialog}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused"
                }
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused"
                }
              }}
              SelectProps={{
                MenuProps: { disablePortal: false }
              }}
              label="Processor"
              value={searchValues.ProcessorID}
              onChange={(e) => onChange(e.target.value, "ProcessorID")}
              name="ProcessorID"
              id="ProcessorID"
              select
              fullWidth
            >
              {processorList.map((processor) => (
                <MenuItem
                  className={classes.dialogBoxDropDown}
                  value={processor.ProcessorID}
                >
                  {processor.ProcessorName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid spacing={2} container>
          <Grid item xs={6} lg={6}>
            <FormControl fullWidth>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  views={["month", "year"]}
                  id="fromMonth"
                  name="fromMonth"
                  maxDate={new Date()}
                  minDateMessage=""
                  invalidDateMessage=""
                  maxDateMessage=""
                  inputVariant="standard"
                  format="MM/yyyy"
                  placeholder="MM/YYYY"
                  label="Start Month"
                  value={moment(searchValues.fromMonth).format("MMM-YYYY")}
                  InputProps={{
                    classes: {
                      root: classes.activeInputColor,
                      input: classes.resize,
                      focused: "focused"
                    }
                  }}
                  onChange={(value) => {
                    onChange(value, "fromMonth");
                  }}
                  KeyboardButtonProps={{
                    size: "small"
                  }}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Grid>
          <Grid item xs={6} lg={6}>
            <FormControl fullWidth>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  views={["month", "year"]}
                  id="toMonth"
                  name="toMonth"
                  maxDate={new Date()}
                  minDate={searchValues.fromMonth}
                  minDateMessage=""
                  invalidDateMessage=""
                  maxDateMessage=""
                  label="End Month"
                  inputVariant="standard"
                  format="MM/yyyy"
                  placeholder="MM/YYYY"
                  InputProps={{
                    classes: {
                      root: classes.activeInputColor,
                      input: classes.resize,
                      focused: "focused"
                    }
                  }}
                  value={moment(searchValues.toMonth).format("MMM-YYYY")}
                  onChange={(value) => {
                    onChange(value, "toMonth");
                  }}
                  KeyboardButtonProps={{
                    size: "small"
                  }}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Grid>
          <Grid
            container
            className={classes.margin}
            style={{ justifyContent: "end", marginTop: "5%" }}
          >
            <Button
              cancel
              variant="contained"
              className={classes.clearButton}
              onClick={() => handleClearSearch()}
            >
              clear
            </Button>
            <Button
              variant="contained"
              className={classes.buttonStyle}
              onClick={() => handleSubmitSearch()}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
};

FilterFields.propTypes = {
  setSearchValues: PropTypes.func,
  searchValues: PropTypes.object,
  processorList: PropTypes.array,
  handleSubmitSearch: PropTypes.func,
  handleClearSearch: PropTypes.func
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(FilterFields);
