import React from "react";
import PropTypes from "prop-types";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import { Field, Form, Formik } from "formik";
import { ThemeProvider, createTheme, useTheme } from "@material-ui/core/styles";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Fade from "@material-ui/core/Fade";
import {
  Box,
  Button,
  Divider,
  Grid,
  InputLabel,
  Typography
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { commonStyle } from "../../assets/style/js/commonStyle";
import TodayIcon from "@material-ui/icons/Today";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import isEmpty from "lodash/isEmpty";

const ChangeStatusModal = ({
  open,
  onClose,
  updateStatusCall,
  selectedList
}) => {
  const oldTheme = useTheme();
  const cmClasses = commonStyle();
  const theme = createTheme({
    ...oldTheme,
    overrides: {
      MuiAutocomplete: {
        inputRoot: {
          padding: "0px"
        },
        input: {
          padding: "0px"
        }
      },
      MuiOutlinedInput: {
        root: {
          background: "#F9FAFF 0% 0% no-repeat",
          borderRadius: "6px",
          height: "44px"
        }
      }
    }
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
      aria-labelledby="sasdasd"
      aria-describedby="simpleasdasdasdan"
    >
      <Fade in={open}>
        <ThemeProvider theme={theme}>
          <Paper
            style={{
              width: "400px",
              padding: theme.spacing(2, 4, 3)
            }}
          >
            <Grid
              style={{
                justifyContent: "space-between"
              }}
              spacing={4}
              container
            >
              <Grid item>
                <Typography style={{ textDecorationLine: "underline" }}>
                  Change Payout Status
                </Typography>
              </Grid>
              <Grid item>
                <Typography>No of rows : {selectedList.length}</Typography>
              </Grid>
            </Grid>
            <Formik
              enableReinitialize
              // eslint-disable-next-line react/jsx-no-bind
              onSubmit={updateStatusCall}
              initialValues={{
                status: "P"
              }}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <Grid
                    spacing={2}
                    style={{
                      justifyContent: "space-between",
                      marginTop: "24px",
                      alignItems: "center"
                    }}
                    container
                  >
                    <Grid item>
                      <Typography>Status</Typography>
                    </Grid>
                    <Grid item>
                      <Field
                        as={TextField}
                        SelectProps={{
                          MenuProps: { disablePortal: false }
                        }}
                        name="status"
                        id="status"
                        placeholder="Status"
                        variant="outlined"
                        onChange={(e) => {
                          setFieldValue("status", e.target.value);
                        }}
                        select
                        style={{ minWidth: "280px" }}
                        fullWidth
                      >
                        <MenuItem value="A">Approved</MenuItem>
                        <MenuItem value="D">Declined</MenuItem>
                        <MenuItem value="I">In Review</MenuItem>
                        <MenuItem value="P">Pending</MenuItem>
                      </Field>
                    </Grid>
                  </Grid>

                  <Grid
                    spacing={2}
                    style={{
                      justifyContent: "end",
                      alignItems: "center",
                      marginTop: "2rem"
                    }}
                    container
                  >
                    <Grid item>
                      <Button
                        onClick={onClose}
                        style={{
                          background: theme.palette.primary.dark,
                          color: "white",
                          textTransform: "capitalize"
                        }}
                        variant="contained"
                      >
                        Close
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        color="primary"
                        type="submit"
                        // disabled={!values.profileId}
                        variant="contained"
                        contained
                        style={{
                          textTransform: "capitalize"
                        }}
                      >
                        Update
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Paper>
        </ThemeProvider>
      </Fade>
    </Modal>
  );
};

ChangeStatusModal.propTypes = {
  selectedList: PropTypes.array,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  updateStatusCall: PropTypes.func
};

ChangeStatusModal.defaultProps = {
  open: true,
  onClose: () => {},
  updateStatusCall: () => {}
};
export default ChangeStatusModal;
