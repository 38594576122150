const status = {
  newEntry: 1,
  sendForUnderwriting: 2,
  approve: 6,
  sendForBording: 7,
  manualReview: 8,
  underReview: 4,
  sendToMerchant: 10,
  decline: 5,
  appSubmitted: 3,
  live: 9,
};

export const showCheckList = (props, values) => {
  if (props.formData.providerDetailsById) {
    const preData = props.formData.providerDetailsById.Providers_Data[0];
    let show = true;
    if (props.mainData.roles.roleTab) {
      const filterSetUp = props.mainData.roles.roleTab.Roles_Data.Master_Permission.filter(
        key => key.catCode === 2
      );
      const filterPageMenu = filterSetUp.length > 0
        && filterSetUp[0].Page_Menu.filter(key => key.Page_Code === 1);
      const filterOption = filterPageMenu
        && filterPageMenu[0]?.Options?.filter(key => key?.value === 6);
      if (filterOption) {
        show = filterOption[0].permission;
      }
      if (
        preData.Current_Status === 1
        || preData.Current_Status === 10
        || preData.Current_Status === 5
      ) {
        show = false;
      }
    }
    if (
      preData.Current_Status === status.live
      || preData.Current_Status === status.decline
    ) {
      show = false;
    }
    if (!props.mainData.roles.roleTab) {
      show = false;
    }
    return show;
  }
};

export const changeStatusPermission = (props, values) => {
  if (props.formData.providerDetailsById) {
    const preData = props.formData.providerDetailsById.Providers_Data[0];
    let allowChangeStatus = true;
    if (props.mainData.roles.roleTab) {
      const filterSetUp = props.mainData.roles.roleTab.Roles_Data.Master_Permission.filter(
        key => key.catCode === 2
      );
      const filterPageMenu = filterSetUp.length > 0
        && filterSetUp[0].Page_Menu.filter(key => key.Page_Code === 1);
      const filterOption = filterPageMenu
        && filterPageMenu[0]?.Options?.filter(key => key?.value === 4);
      if (filterOption) {
        allowChangeStatus = !filterOption[0].permission;
      }
      if (
        preData.Current_Status === status.live
        || preData.Current_Status === status.decline
        || preData.Current_Status === status.sendToMerchant
      ) {
        allowChangeStatus = true;
      }
    }
    if (preData.Current_Status === status.newEntry) {
      allowChangeStatus = true;
    }
    if (!props.mainData.roles.roleTab) {
      allowChangeStatus = true;
    }
    return allowChangeStatus;
  }
};

export const checkFormDisabled = (props, values) => {
  if (props.formData.providerDetailsById) {
    const preData = props.formData.providerDetailsById.Providers_Data[0];
    let allowChangeStatus = false;
    if (!props.mainData.roles.roleTab) {
      if (
        preData.Current_Status === 3
        || preData.Current_Status === 9
        || preData.Current_Status === 5
        || preData.Current_Status === 4
        || preData.Current_Status === 6
        || preData.Current_Status === 7
        || preData.Current_Status === 8
      ) {
        allowChangeStatus = true;
      }
    }
    if (props.mainData.roles.roleTab) {
      const filterSetUp = props.mainData.roles.roleTab.Roles_Data.Master_Permission.filter(
        key => key.catCode === 2
      );
      const filterPageMenu = filterSetUp.length > 0
        && filterSetUp[0].Page_Menu.filter(key => key.Page_Code === 1);
      const filterOptionStatue = filterPageMenu
        && filterPageMenu[0]?.Options?.filter(key => key?.value === 4);
      if (filterOptionStatue) {
        allowChangeStatus = !filterOptionStatue[0].permission;
        if (filterOptionStatue[0].permission) {
          if (
            preData.Current_Status !== status.live
            || preData.Current_Status !== status.sendToMerchant
            || preData.Current_Status !== status.decline
          ) {
            allowChangeStatus = false;
          } else if (
            preData.Current_Status === status.live
            || preData.Current_Status === status.decline
            || preData.Current_Status === status.sendForUnderwriting
            || preData.Current_Status === status.appSubmitted
          ) {
            allowChangeStatus = true;
          }
        } else if (!filterOptionStatue[0].permission) {
          const filterOptionEditForm = filterPageMenu
        && filterPageMenu[0]?.Options?.filter(key => key?.value === 3);
          if (filterOptionEditForm) {
            allowChangeStatus = !filterOptionStatue[0].permission;
            if (preData.Current_Status === status.sendToMerchant) {
              allowChangeStatus = false;
            }
          }
        }
        if (preData.Current_Status === status.approve
          || preData.Current_Status === status.sendForBording
          || preData.Current_Status === status.decline) {
          allowChangeStatus = true;
        }
      }
    }
    return allowChangeStatus;
  }
};

export const disbaleAndHide = (props, values) => {
  let hide = false;
  if (props.formData.providerDetailsById) {
    const preData = props.formData.providerDetailsById.Providers_Data[0];
    if (preData.Current_Status === status.appSubmitted) {
      hide = true;
    }
  }
  return hide;
};

export const disableSaveDraftButton = (props, values) => {
  let disabled = true;
  if (props.formData.providerDetailsById) {
    const preData = props.formData.providerDetailsById.Providers_Data[0];
    if (!props.mainData.roles.roleTab) {
      if (
        preData.Current_Status === 3
        || preData.Current_Status === 9
        || preData.Current_Status === 5
        || preData.Current_Status === 4
        || preData.Current_Status === 6
        || preData.Current_Status === 7
        || preData.Current_Status === 8
      ) {
        disabled = true;
      }
    }
    if (props.mainData.roles.roleTab) {
      const filterSetUp = props.mainData.roles.roleTab.Roles_Data.Master_Permission.filter(
        key => key.catCode === 2
      );
      const filterPageMenu = filterSetUp.length > 0
        && filterSetUp[0].Page_Menu.filter(key => key.Page_Code === 1);
      const filterOptionStatue = filterPageMenu
        && filterPageMenu[0]?.Options?.filter(key => key?.value === 4);
      if (filterOptionStatue) {
        disabled = !filterOptionStatue[0].permission;
        if (filterOptionStatue[0].permission) {
          if (
            preData.Current_Status !== status.live
            || preData.Current_Status !== status.sendToMerchant
            || preData.Current_Status !== status.decline
          ) {
            disabled = false;
          } else if (
            preData.Current_Status === status.live
            || preData.Current_Status === status.decline
            || preData.Current_Status === status.sendForUnderwriting
            || preData.Current_Status === status.approve
            || preData.Current_Status === status.appSubmitted
          ) {
            disabled = true;
          }
        } else if (!filterOptionStatue[0].permission) {
          const filterOptionEditForm = filterPageMenu
        && filterPageMenu[0]?.Options?.filter(key => key?.value === 3);
          if (filterOptionEditForm) {
            disabled = !filterOptionStatue[0].permission;
            if (preData.Current_Status === status.sendToMerchant) {
              disabled = false;
            }
          }
        }
      }
      if (preData.Current_Status === status.approve
        || preData.Current_Status === status.sendForBording
        || preData.Current_Status === status.decline) {
        disabled = true;
      }
    }
  }
  if (props.formData.formMode !== "Edit") {
    return false;
  }
  return disabled;
};
