/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable radix */
/* eslint-disable no-loop-func */
/* eslint-disable no-restricted-syntax */
import React from "react";
// @material-ui/core components
import {
  alpha,
  makeStyles,
  withStyles,
  useTheme,
  lighten
} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Box from "@material-ui/core/Box";
// core components
import TextField from "@material-ui/core/TextField";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import MenuItem from "@material-ui/core/MenuItem";
import { Checkbox, Button } from "@material-ui/core";
import Card from "components/Card/Card";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EmailIcon from "@material-ui/icons/Email";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import { useSnackbar } from "notistack";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import PersonIcon from "@material-ui/icons/Person";
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import TablePagination from "@material-ui/core/TablePagination";
import DeleteIcon from "@material-ui/icons/Delete";
import isEmpty from "lodash/isEmpty";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import CoustomButton from "components/CustomButtons/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Table from "@material-ui/core/Table";
import Collapse from "@material-ui/core/Collapse";
import Search from "@material-ui/icons/Search";
import TableHead from "@material-ui/core/TableHead";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import FilterListIcon from "@material-ui/icons/FilterList";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import EquipmentInfoTree from "views/AddtionalFields/EquipmentInfo";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import tableStyle from "../../assets/jss/material-dashboard-react/components/reportTables";
import {
  setPricingTempleteEmpty,
  pricingTempleteCall,
  pricingTempleteCallInvite,
  getProviderDetailsById,
  getInviteEquipmentData
} from "../../Redux/Actions/ProvideRegistration";
import {
  getPricingTemplete,
  getApplicationType,
  getProccesorList
} from "../../actions/MerchantSignUp";
import {
  statusPreameter,
  statusPreameterKyc,
  statusApproveMpa,
  statusApproveKyc,
  mpaChecklistComment,
  kycChecklistAttechment,
  mpaChecklistAttechment,
  updatePricingTemplete,
  getUpdatePricingTemplate
} from "../../actions/Operations";

const useStyles = makeStyles(styles);
const StyledTreeItem = withStyles((theme) => ({
  iconContainer: {
    display: "none"
  },
  group: {
    marginLeft: "0px"
  }
}))((props) => <TreeItem {...props} />);

function createData(
  TemplateName,
  ProcessorType,
  AppType,
  Updated_Date,
  Created_By,
  Created_Date,
  status,
  TemplateID,
  editable
) {
  return {
    TemplateName,
    ProcessorType,
    AppType,
    Updated_Date,
    Created_By,
    Created_Date,
    status,
    TemplateID,
    editable
  };
}

function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.rootPagination}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Last</Typography>
        ) : (
          <Typography>First</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Previous</Typography>
        ) : (
          <Typography>Previous</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleFirstPageButtonClick}
        aria-label="first page"
        className={classes.bottonPage}
      >
        <Typography>{page + 1}</Typography>
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <Typography>Next</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <FirstPageIcon />
        ) : (
          <Typography>Last</Typography>
        )}
      </IconButton>
    </div>
  );
}
TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};
function descendingComparator(a, b, orderBy) {
  if (orderBy === "Created_date") {
    return new Date(b[orderBy]).valueOf() - new Date(a[orderBy]).valueOf();
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const headCells = [
  {
    id: "TemplateName",
    className: "TemplateName",
    numeric: false,
    disablePadding: true,
    label: "Template Name"
  },
  {
    id: "ProcessorType",
    className: "ProcessorType",
    numeric: false,
    disablePadding: true,
    label: "Processor Type"
  },
  {
    id: "AppType",
    className: "AppType",
    numeric: false,
    disablePadding: true,
    label: "Application Type"
  },
  {
    id: "Email",
    className: "Email",
    numeric: false,
    disablePadding: true,
    label: "Email"
  },
  {
    id: "Created_By",
    className: "Created_By",
    numeric: false,
    disablePadding: true,
    label: "Created By"
  },
  {
    id: "Created_date",
    className: "Created_date",
    numeric: true,
    disablePadding: false,
    label: "Created On"
  },
  {
    id: "Updated_Date",
    className: "Updated_Date",
    numeric: true,
    disablePadding: false,
    label: "Updated Date"
  },
  {
    id: "status",
    className: "status",
    numeric: true,
    disablePadding: false,
    label: "Status"
  }
];
function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHeader}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            className={classes.cellTextHeaderOffer}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};
const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    flex: "1 1 100%"
  }
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired
};

function BusinessForm(props) {
  const [data, setData] = React.useState(
    props.formData.pricingTemplete &&
      props?.formData?.pricingTemplete?.data &&
      props?.formData?.pricingTemplete?.data?.Pricing
  );
  const [isReset, setIsReset] = React.useState(false);
  const [formMode, setFormMode] = React.useState(false);
  const [showSelectedItem, setShowSelectedItem] = React.useState(false);
  const classes = useStyles();
  const [pricingData, setPricingData] = React.useState(
    props?.formData?.pricingTemplete && props?.formData?.pricingTemplete?.data
  );
  const [serviceListExpanded, setServiceListExpanded] = React.useState(false);
  const [equipmentListExpanded, setEquipmentListExpanded] =
    React.useState(false);
  const [equipmentData, setEquipmentData] = React.useState(
    props.formData.pricingTemplete &&
      props?.formData?.pricingTemplete?.data &&
      props?.formData?.pricingTemplete?.data?.Equipment
  );
  const [pricingTemplateList, setPricingTemplateList] = React.useState([]);
  const [proccssorList, setProccssorList] = React.useState([]);
  const [applicationTypeList, setApplicationTypeList] = React.useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [bla, setBla] = React.useState({
    processId:
      props?.formData?.providerDetailsById?.Providers_Data[0]?.ProcessorID,
    templateId:
      props?.formData?.providerDetailsById?.Providers_Data[0]?.TemplateID,
    applicationType:
      props.formData.providerDetailsById.Providers_Data[0].ApplicationID
  });
  const [showChangeTemplate, setShowChangeTemplate] = React.useState(false);
  const [selectedEquipmentCode, setSelectedEquipmentCode] = React.useState([]);
  const [seletctQuantity, setQuantity] = React.useState([]);

  React.useEffect(() => {
    if (
      data !== props.formData.pricingTemplete &&
      props?.formData?.pricingTemplete?.data?.Pricing &&
      props?.formData?.pricingTemplete?.data?.Equipment
    ) {
      const preValues = [...props?.formData?.pricingTemplete?.data?.Pricing];
      setData(preValues);
      setPricingData(props.formData.pricingTemplete.data);
      const newPricingData = [...props.formData.pricingTemplete.data.Equipment];
      newPricingData.forEach((key) => {
        const findIndexActiveCheckBox = key.equipmentItem.findIndex(
          (key) => key.activeCheckbox
        );
        key.equipmentItem.forEach((innerkey, index) => {
          if (key.successorEnable) {
            if (index === findIndexActiveCheckBox) {
              innerkey.disbaledCheckBox = true;
            } else {
              innerkey.disbaledCheckBox = true;
            }
          } else {
            innerkey.disbaledCheckBox = true;
          }
        });
      });
      setEquipmentData(props.formData.pricingTemplete.data.Equipment);
    }
  }, [props.formData.pricingTemplete]);

  React.useEffect(() => {
    if (bla && bla.applicationType && bla.processId && bla.templateId) {
      if (
        data !== props.formData.pricingTemplete &&
        props?.formData?.pricingTemplete?.data?.Pricing &&
        props.formData.Equipment &&
        props.formData.Equipment.data
      ) {
        const preValues = [...props?.formData?.pricingTemplete?.data?.Pricing];
        setData(preValues);
        setPricingData(props.formData.pricingTemplete.data);
        const newPricingData = [...props.formData.Equipment.data];
        newPricingData.forEach((key) => {
          const findIndexActiveCheckBox = key.equipmentItem.findIndex(
            (key) => key.activeCheckbox
          );
          key.equipmentItem.forEach((innerkey, index) => {
            if (key.successorEnable) {
              if (index === findIndexActiveCheckBox) {
                innerkey.disbaledCheckBox = true;
              } else {
                innerkey.disbaledCheckBox = true;
              }
            } else {
              innerkey.disbaledCheckBox = true;
            }
          });
        });
        setEquipmentData(props.formData.Equipment.data);
      }
    }
  }, [props.formData.Equipment]);

  React.useEffect(() => {
    props.getPricingTemplete(
      (data) => {
        if (data.status) {
          setPricingTemplateList(data.data);
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
      }
    );
    props.getApplicationType(
      (data) => {
        if (data.status) {
          setApplicationTypeList(data.data);
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
      }
    );
    props.getProccesorList(
      (data) => {
        if (data.status) {
          setProccssorList(data.data);
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
      }
    );
    if (props.formData.providerDetailsById) {
      const callPricingTempleteData = {
        providerId: props.formData.providerDetailsById.Providers_Data[0]._id
      };
      props.pricingTempleteCall(callPricingTempleteData);
    }
  }, [isReset]);

  const handleBack = () => {
    if (props.formData.providerDetailsById) {
      props.getProviderDetailsById(
        props.formData.providerDetailsById.Providers_Data[0]._id
      );
    }
    props.history.push(`/${props.mainData.app.layout}/user`);
  };

  const showIempMin = (data) => {
    const returnArray = [];
    const ticker = data.perItemTicker * 10000;
    const to = data.perItemTo * 10000;
    const from = data.perItemFrom * 10000;
    if (data.perItemTicker !== 0) {
      if (data.perItemTicker % 1 !== 0) {
        for (let i = from; i <= to; i += ticker) {
          if (i > 0) {
            const finalValue = i / 10000;
            returnArray.push(
              data.perItemTicker % 1 !== 0 ? finalValue.toFixed(4) : finalValue
            );
          } else {
            break;
          }
        }
      } else {
        for (let i = from; i <= to; i += ticker) {
          if (i > 0) {
            const finalValue = i / 10000;
            returnArray.push(
              data.perItemTicker % 1 !== 0 ? finalValue.toFixed(4) : finalValue
            );
          } else {
            break;
          }
        }
      }
    }
    if (ticker === 0 && to === 0 && from === 0) {
      returnArray.push("0");
    }
    if (
      returnArray.filter((key) => Number(key) === Number(data.perItemRate))
        .length === 0
    ) {
      returnArray.unshift("Custom");
    }
    return returnArray;
  };

  const handleFocus = (data) => {
    setTimeout(() => document.getElementById(data).focus(), 50);
  };

  React.useEffect(
    () => () => {
      props.setPricingTempleteEmpty();
    },
    []
  );

  const handleOnChangeProccess = (data, type) => {
    const allValue = { ...bla };
    allValue[type] = data;
    setIsReset(false);
    if (
      type === "templateId" &&
      allValue.processId !== "" &&
      allValue.applicationType !== ""
    ) {
      const dataRequest = {
        templateId: data,
        processorId: bla.processId
      };
      setFormMode(props.formData.formMode);
      props.pricingTempleteCallInvite(dataRequest);
      props.getInviteEquipmentData(dataRequest);
    }
    setEquipmentData(props?.mainData?.provideRegistration?.Equipment?.data);
    setBla(allValue);
    props.setPricingTempleteEmpty();
  };

  const onPricingChange = (value, type) => {
    const updatedPricingData = { ...pricingData };
    if (type === "Notes") {
      updatedPricingData.Notes = value;
    }
    if (type === "TermLength") {
      updatedPricingData.TermLength = value;
    }
    setPricingData({ ...updatedPricingData });
  };

  const onChange = (value, type, mainIndex, itemIndex) => {
    const updatedDate = [...data];
    if (type !== "check") {
      updatedDate[mainIndex].categoryItem[itemIndex].perItemRate = value;
    } else {
      updatedDate[mainIndex].categoryItem[itemIndex].perItemRate = 0;
      updatedDate[mainIndex].categoryItem[itemIndex].activeCheckbox = value;
    }
    if (type === "text") {
      handleFocus(updatedDate[mainIndex].categoryItem[itemIndex].itemName);
    }
    setData([...updatedDate]);
  };

  const handleReset = () => {
    props.setPricingTempleteEmpty();
    setShowChangeTemplate(false);
    setIsReset(true);
    setFormMode(false);
    setShowSelectedItem(false);
  };

  const handelValidation = () => {
    const validate = true;
    return validate;
  };

  const handleSave = () => {
    if (handelValidation()) {
      const dataRequest = {
        merchantId: props.formData.providerDetailsById.Providers_Data[0]._id,
        templateId:
          props.formData.providerDetailsById.Providers_Data[0].TemplateID,
        merchantTemplate: {
          ...pricingData,
          Pricing: data,
          Equipment: equipmentData
        }
      };
      props.updatePricingTemplete(
        dataRequest,
        (data) => {
          if (data.status) {
            enqueueSnackbar(`${data.message}`, {
              variant: "success"
            });
            setFormMode(false);
            setShowChangeTemplate(false);
          } else {
            enqueueSnackbar(`${data.message}`, {
              variant: "error"
            });
          }
        },
        (err) => {
          enqueueSnackbar(`${err.message}`, {
            variant: "error"
          });
        }
      );
    }
  };

  const profileTypeCheck =
    props.mainData.userRole.userList &&
    props.mainData.userRole.userList.Role_Permission_Data &&
    props.mainData.userRole.userList.Role_Permission_Data.Profile_Code.includes(
      "Merchant"
    );

  const providerStatusCheck =
    props.mainData.provideRegistration.providerDetailsById &&
    props.mainData.provideRegistration.providerDetailsById.Providers_Data[0]
      .Current_Status === 5;

  const handleSubmit = () => {
    const dataHandleSubmit = {
      merchantId: props.formData.providerDetailsById.Providers_Data[0]._id,
      templateId: bla.templateId,
      processorId: bla.processId
    };
    const mainData = {
      ...pricingData,
      Pricing: data,
      Equipment: equipmentData
    };
    props.getUpdatePricingTemplate(
      dataHandleSubmit,
      (data) => {
        if (data.status) {
          setFormMode(false);
          setShowSelectedItem(false);
          setShowChangeTemplate(false);
          enqueueSnackbar(`${data.message}`, {
            variant: "success"
          });
          setTimeout(() => {
            const dataRequest = {
              merchantId:
                props.formData.providerDetailsById.Providers_Data[0]._id,
              templateId: bla.templateId,
              merchantTemplate: mainData
            };
            props.updatePricingTemplete(dataRequest, {}, {});
          }, 1000);
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
      }
    );
  };

  const editForm = () => {
    setShowSelectedItem(true);
    setFormMode(props.formData.formMode);
    if (
      equipmentData !== props.mainData.provideRegistration.Equipment &&
      props.mainData.provideRegistration.Equipment
    ) {
      props.mainData.provideRegistration.Equipment.data.forEach((key) => {
        key.equipmentItem.forEach((innerkey) => {
          innerkey.selected = true;
          innerkey.equipmentType[0].selected = true;
        });
      });
      setEquipmentData(props.mainData.provideRegistration.Equipment.data);
    }
  };

  const name =
    props.formData.providerDetailsById &&
    props.formData.providerDetailsById.Providers_Data[0] &&
    props.formData.providerDetailsById.Providers_Data[0].Legal_Name;

  const selectedtemplateName =
    props.formData.providerDetailsById &&
    props.formData.providerDetailsById.Providers_Data &&
    props.formData.providerDetailsById.Providers_Data[0].pricingTemplateView
      ? props.formData.pricingTemplete &&
        props.formData.pricingTemplete.templateName
      : pricingTemplateList.filter((key) => key._id === bla.templateId)[0]
          ?.name;

  const templateNameonReset =
    props.formData.pricingTemplete &&
    props.formData.pricingTemplete.templateName;

  const activeTemplate =
    pricingTemplateList &&
    pricingTemplateList.filter((key) => key.status === "Active");

  return (
    <div style={{ paddingBottom: "40px" }}>
      <Card
        style={{ borderTopLeftRadius: "16px", borderTopRightRadius: "16px" }}
      >
        <Box
          style={{
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
            alignItems: "center",
            padding: "10px"
          }}
          className={classes.tittleColor}
          height={40}
        >
          <Grid
            style={{
              display: "flex",
              position: "relative",
              flexWrap: "nowrap",
              boxSizing: "border-box",
              flexDirection: "row",
              alignItems: "center"
            }}
            container
          >
            <Grid style={{ position: "absolute" }} item>
              <IconButton
                color="white"
                aria-label="Back"
                onClick={() => handleBack()}
                component="span"
                style={{ background: "white", padding: "6px" }}
              >
                <KeyboardBackspaceIcon fontSize="small" />
              </IconButton>
            </Grid>
            <Grid style={{ margin: "auto" }} item>
              <Typography
                style={{ margin: "auto" }}
                className={classes.tittleTextColor}
              >
                {`Pricing View(${
                  props.formData.providerDetailsById &&
                  props.formData.providerDetailsById.Providers_Data &&
                  props.formData.providerDetailsById.Providers_Data[0]
                    .Business_Name
                })`}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        {data &&
          data?.length > 0 &&
          !profileTypeCheck &&
          !providerStatusCheck && (
            <Box
              className={classes.buttonBoxPricing}
              style={{ marginBottom: "0px" }}
            >
              <Grid
                container
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                <Grid
                  item
                  sm={6}
                  md={6}
                  lg={6}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Template Name:
                  {!showChangeTemplate && (
                    <Grid
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "10px"
                      }}
                    >
                      <span style={{ fontWeight: "normal" }}>
                        {selectedtemplateName && !isReset
                          ? selectedtemplateName
                          : !templateNameonReset && isReset
                          ? selectedtemplateName
                          : templateNameonReset}
                      </span>
                    </Grid>
                  )}
                  {formMode && (
                    <>
                      {!profileTypeCheck &&
                        !providerStatusCheck &&
                        showChangeTemplate && (
                          <div
                            style={{
                              background: "#2ba9921a",
                              borderRadius: "5px",
                              minWidth: "35%",
                              marginLeft: "1rem"
                            }}
                          >
                            <TextField
                              className={classes.root}
                              variant="outlined"
                              size="small"
                              InputLabelProps={{
                                classes: {
                                  root: classes.activeInputLableColor,
                                  focused: "focused"
                                }
                              }}
                              InputProps={{
                                classes: {
                                  root: classes.activeInputColor,
                                  input: classes.resize,
                                  focused: "focused"
                                }
                              }}
                              label="Template"
                              value={bla.templateId}
                              onChange={(e) => {
                                handleOnChangeProccess(
                                  e.target.value,
                                  "templateId"
                                );
                              }}
                              name="pricingTemplate"
                              style={{ textAlign: "initial", width: "100%" }}
                              id="pricingTemplate"
                              select
                            >
                              {activeTemplate
                                .sort((a, b) =>
                                  a.name
                                    .toString()
                                    .localeCompare(b.name.toString())
                                )
                                .map((key) => (
                                  <MenuItem
                                    className={classes.dialogBoxDropDown}
                                    value={key._id}
                                  >
                                    {key.name}
                                  </MenuItem>
                                ))}
                            </TextField>
                          </div>
                        )}
                      <div
                        style={{
                          cursor: "pointer",
                          marginLeft: "1rem",
                          display: " flex",
                          alignItems: "center"
                        }}
                      >
                        {!showChangeTemplate && (
                          <EditIcon
                            onClick={() => setShowChangeTemplate(true)}
                          />
                        )}
                        {showChangeTemplate && (
                          <CloseIcon
                            onClick={() => setShowChangeTemplate(false)}
                          />
                        )}
                      </div>
                    </>
                  )}
                </Grid>

                <div style={{ justifyContent: "end" }}>
                  {props.formData.providerDetailsById &&
                    props.formData.providerDetailsById.Providers_Data &&
                    !props.formData.providerDetailsById.Providers_Data[0]
                      .pricingTemplateView && (
                      <div style={{ display: "flex", gap: "1rem" }}>
                        <Button
                          className={classes.resetButtonStyle}
                          variant="outlined"
                          fullWidth
                          color="#696969"
                          onClick={() => handleReset()}
                        >
                          Reset
                        </Button>
                        <Button
                          className={classes.buttonStyle}
                          variant="contained"
                          fullWidth
                          color="#696969"
                          onClick={() => handleSave()}
                        >
                          Save
                        </Button>
                      </div>
                    )}
                  {props.formData.providerDetailsById &&
                    props.formData.providerDetailsById.Providers_Data &&
                    props.formData.providerDetailsById.Providers_Data[0]
                      .pricingTemplateView && (
                      <div
                        style={{
                          display: "flex",
                          gap: "1rem",
                          marginRight: "40px"
                        }}
                      >
                        {!formMode ? (
                          <Button
                            className={classes.resetButtonStyle}
                            variant="outlined"
                            fullWidth
                            color="#696969"
                            onClick={() => editForm()}
                          >
                            Edit
                          </Button>
                        ) : (
                          <>
                            <Button
                              className={classes.resetButtonStyle}
                              variant="outlined"
                              fullWidth
                              color="#696969"
                              onClick={() => handleReset()}
                            >
                              Reset
                            </Button>

                            <Button
                              className={classes.buttonStyle}
                              variant="contained"
                              fullWidth
                              color="#696969"
                              onClick={() => handleSubmit()}
                            >
                              Submit
                            </Button>
                          </>
                        )}
                      </div>
                    )}
                </div>
              </Grid>
            </Box>
          )}
        {data && data?.length > 0 && (
          <Box className={classes.buttonBoxPricing}>
            <Grid container>
              <Grid
                item
                lg={3}
                md={3}
                sm={3}
                xs={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px"
                }}
              >
                <Typography style={{ fontWeight: "bold" }}>
                  Term Length (months)
                </Typography>
                <TextField
                  style={{
                    width: "70px",
                    padding: "0px 5px",
                    margin: "0px"
                  }}
                  rows={1}
                  disabled={!formMode}
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={pricingData?.TermLength}
                  onChange={(e) =>
                    onPricingChange(e.target.value, "TermLength")
                  }
                  InputLabelProps={{
                    classes: {
                      root: classes.activeInputLableColor,
                      focused: "focused"
                    }
                  }}
                  InputProps={{
                    classes: {
                      root: classes.activeInputColor,
                      input: classes.resize,
                      focused: "focused"
                    },
                    readOnly: !!(profileTypeCheck && !providerStatusCheck),
                    className:
                      profileTypeCheck && !providerStatusCheck
                        ? "Mui-disabled"
                        : ""
                  }}
                  name="TermLength"
                  id="TermLength"
                />
              </Grid>
              <Grid
                item
                lg={8}
                md={8}
                sm={8}
                xs={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                  marginLeft: "auto",
                  float: "right"
                }}
              >
                <Typography style={{ fontWeight: "bold" }}>
                  Description
                </Typography>
                <TextField
                  style={{
                    padding: "0px 5px",
                    margin: "0px"
                  }}
                  multiline
                  rows={2}
                  fullWidth
                  disabled={!formMode}
                  size="small"
                  variant="outlined"
                  value={pricingData?.Notes}
                  onChange={(e) => onPricingChange(e.target.value, "Notes")}
                  InputLabelProps={{
                    classes: {
                      root: classes.activeInputLableColor,
                      focused: "focused"
                    }
                  }}
                  InputProps={{
                    classes: {
                      root: classes.activeInputColor,
                      input: classes.resize,
                      focused: "focused"
                    },
                    readOnly: !!(profileTypeCheck && !providerStatusCheck),
                    className:
                      profileTypeCheck && !providerStatusCheck
                        ? "Mui-disabled"
                        : ""
                  }}
                  name="Notes"
                  id="Notes"
                />
              </Grid>
            </Grid>
          </Box>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center"
            // padding: "4%",
          }}
        >
          {!data.length && (
            <>
              <>
                <Grid
                  container
                  item
                  spacing={2}
                  style={{
                    margin: "15px"
                  }}
                >
                  <Typography style={{ fontWeight: "bold" }}>
                    {`Pricing Template: ${name}`}
                  </Typography>
                </Grid>
              </>
              <Grid container>
                <Grid item lg={4} sm={4} xs={12}>
                  <PersonIcon className={classes.inputBox} fontSize="small" />
                  <TextField
                    select
                    required
                    onChange={(e) => {
                      handleOnChangeProccess(e.target.value, "processId");
                    }}
                    value={bla.processId}
                    fullWidth
                    className={classes.root}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    label="Processor"
                    name="processor"
                    id="processor"
                  >
                    {proccssorList.map((key) => (
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value={key._id}
                      >
                        {key.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item lg={4} sm={4} xs={12}>
                  <PersonIcon className={classes.inputBox} fontSize="small" />
                  <TextField
                    select
                    required
                    fullWidth
                    disabled={bla.processId === ""}
                    className={classes.root}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    onChange={(e) => {
                      handleOnChangeProccess(e.target.value, "applicationType");
                    }}
                    value={bla.applicationType}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    label="Application Type,"
                    name="applicationType"
                    id="applicationType"
                  >
                    {applicationTypeList.map((key) => (
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value={key._id}
                      >
                        {key.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item lg={4} sm={4} xs={12}>
                  <EmailIcon className={classes.inputBox} fontSize="small" />
                  <TextField
                    select
                    required
                    fullWidth
                    className={classes.root}
                    disabled={
                      bla.processId === "" || bla.applicationType === ""
                    }
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    onChange={(e) => {
                      handleOnChangeProccess(e.target.value, "templateId");
                    }}
                    value={bla.templateId}
                    label="Pricing Template"
                    name="pricingTemplate"
                    id="pricingTemplate"
                  >
                    {activeTemplate
                      .sort((a, b) =>
                        a.name.toString().localeCompare(b.name.toString())
                      )
                      .map((key) => (
                        <MenuItem
                          className={classes.dialogBoxDropDown}
                          value={key._id}
                        >
                          {key.name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
              </Grid>
            </>
          )}
          {data && data.length > 0 && (
            <TreeView>
              <StyledTreeItem
                nodeId="1"
                label={
                  <div className={classes.lableTextColor}>
                    <span>Services and Fees</span>
                    {serviceListExpanded && <ExpandMoreIcon />}
                    {!serviceListExpanded && <ChevronRightIcon />}
                  </div>
                }
                onClick={() => setServiceListExpanded(!serviceListExpanded)}
              >
                {data &&
                  !formMode &&
                  data
                    .sort((a, b) => (a.categoryName > b.categoryName ? 1 : -1))
                    .map((key, mainIndex) => (
                      <>
                        <Box
                          style={{
                            alignItems: "center",
                            marginTop: "10px"
                          }}
                          key={Math.random()}
                          className={classes.tittleColor}
                          height={40}
                        >
                          <Typography
                            className={classes.tittleTextColor}
                            style={{ color: "white" }}
                          >
                            {key.categoryName}
                          </Typography>
                        </Box>
                        <Box className={classes.marginBox}>
                          <Grid container className={classes.margin}>
                            {key.categoryItem
                              .sort((a, b) =>
                                a.itemName > b.itemName ? 1 : -1
                              )
                              .map((item, index) => {
                                if (item.activeCheckbox) {
                                  return (
                                    <>
                                      <Grid
                                        key={Math.random()}
                                        item
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                          padding: "20px"
                                        }}
                                        lg={4}
                                        sm={6}
                                        xs={12}
                                      >
                                        <span
                                          style={{
                                            fontSize: "16px",
                                            padding: "1%",
                                            fontWeight: "bold",
                                            width: "70%",
                                            display: "flex",
                                            flexWrap: "wrap"
                                          }}
                                        >
                                          {item.itemName}:{" "}
                                        </span>
                                        <span
                                          style={{
                                            fontSize: "16px",
                                            padding: "1%",
                                            width: "30%",
                                            fontWeight: "normal",
                                            display: "flex"
                                          }}
                                        >
                                          ${item.perItemRate}
                                        </span>
                                      </Grid>
                                    </>
                                  );
                                }
                                return "";
                              })}
                          </Grid>
                        </Box>
                      </>
                    ))}
                {data &&
                  formMode &&
                  data
                    .sort((a, b) => (a.categoryName > b.categoryName ? 1 : -1))
                    .map((key, mainIndex) => (
                      <>
                        <Box
                          style={{
                            alignItems: "center",
                            marginTop: "10px"
                          }}
                          key={Math.random()}
                          className={classes.tittleColor}
                          height={40}
                        >
                          <Typography
                            className={classes.tittleTextColor}
                            style={{ color: "white" }}
                          >
                            {key.categoryName}
                          </Typography>
                        </Box>
                        <Box className={classes.marginBox}>
                          <Grid container className={classes.margin}>
                            {key.categoryItem
                              .sort((a, b) =>
                                a.itemName > b.itemName ? 1 : -1
                              )
                              .map((item, index) => (
                                <>
                                  <Grid
                                    key={Math.random()}
                                    item
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      padding: "20px"
                                    }}
                                    lg={6}
                                    sm={6}
                                    xs={12}
                                  >
                                    <div style={{ minWidth: "5%" }}>
                                      <Checkbox
                                        disabled={profileTypeCheck}
                                        checked={item.activeCheckbox}
                                        onChange={(e) =>
                                          onChange(
                                            e.target.checked,
                                            "check",
                                            mainIndex,
                                            index
                                          )
                                        }
                                        inputProps={{
                                          "aria-label": "select all desserts"
                                        }}
                                        color="primary"
                                      />
                                    </div>
                                    <span
                                      style={{
                                        fontSize: "16px",
                                        lineHeight: "20px",
                                        maxWidth: "25%",
                                        minWidth: "25%",
                                        padding: "1%"
                                      }}
                                    >
                                      {item.itemName}
                                    </span>
                                    {!profileTypeCheck && (
                                      <div
                                        style={{
                                          maxWidth: "25%",
                                          minWidth: "25%",
                                          padding: "1%"
                                        }}
                                      >
                                        <TextField
                                          className={classes.quaterInputPricing}
                                          key={Math.random()}
                                          disabled={!item.activeCheckbox}
                                          InputLabelProps={{
                                            classes: {
                                              root: classes.activeInputLableColor,
                                              focused: "focused"
                                            }
                                          }}
                                          InputProps={{
                                            classes: {
                                              root: classes.activeInputColor,
                                              input: classes.resizePrice,
                                              focused: "focused"
                                            }
                                          }}
                                          defaultValue="Custom"
                                          value={
                                            item.perItemTicker % 1 !== 0
                                              ? Number(item.perItemRate)
                                                  .toFixed(4)
                                                  .toString()
                                              : item.perItemRate
                                          }
                                          onChange={(e) =>
                                            onChange(
                                              e.target.value,
                                              item,
                                              mainIndex,
                                              index
                                            )
                                          }
                                          name={Math.random()
                                            .toString(36)
                                            .substr(2, 5)}
                                          id={Math.random()
                                            .toString(36)
                                            .substr(2, 5)}
                                          select
                                        >
                                          {showIempMin(
                                            item,
                                            index,
                                            key.categoryItem.length
                                          ).length > 0 &&
                                            showIempMin(
                                              item,
                                              index,
                                              key.categoryItem.length
                                            ).map((number) => (
                                              <MenuItem
                                                key={Math.random()}
                                                className={
                                                  classes.dialogBoxDropDown
                                                }
                                                value={
                                                  number === "Custom"
                                                    ? item.perItemTicker % 1 !==
                                                      0
                                                      ? Number(item.perItemRate)
                                                          .toFixed(4)
                                                          .toString()
                                                      : item.perItemRate
                                                    : number
                                                }
                                              >
                                                {number}
                                              </MenuItem>
                                            ))}
                                        </TextField>
                                      </div>
                                    )}
                                    <div
                                      style={{
                                        maxWidth: "25%",
                                        minWidth: "25%",
                                        padding: "1%",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center"
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: "16px",
                                          padding: "1%",
                                          textAlign: "end"
                                        }}
                                      >
                                        $
                                      </span>
                                      <TextField
                                        className={classes.quaterInputPricing}
                                        key={Math.random()}
                                        InputLabelProps={{
                                          classes: {
                                            root: classes.activeInputLableColor,
                                            focused: "focused"
                                          }
                                        }}
                                        InputProps={{
                                          classes: {
                                            root: classes.activeInputColor,
                                            input: classes.resizePrice,
                                            focused: "focused"
                                          }
                                        }}
                                        variant="outlined"
                                        style={{ backgroundColor: "#f9f7f7" }}
                                        value={item.perItemRate}
                                        disabled={
                                          !item.activeCheckbox ||
                                          profileTypeCheck
                                        }
                                        onChange={(e) =>
                                          onChange(
                                            e.target.value,
                                            "text",
                                            mainIndex,
                                            index
                                          )
                                        }
                                        name={item.itemName}
                                        id={item.itemName}
                                      />
                                      <span
                                        style={{
                                          fontSize: "16px",
                                          lineHeight: "20px",
                                          maxWidth: "25%",
                                          minWidth: "25%",
                                          padding: "1%"
                                        }}
                                      >
                                        {item.rateTypeSing !== "$"
                                          ? item.rateTypeSing
                                          : ""}
                                      </span>
                                    </div>
                                  </Grid>
                                </>
                              ))}
                          </Grid>
                        </Box>
                      </>
                    ))}
              </StyledTreeItem>
              {equipmentData && equipmentData.length > 0 && (
                <StyledTreeItem
                  nodeId="2"
                  label={
                    <div className={classes.lableTextColor}>
                      <span>Equipment Fees/Charges</span>
                      {equipmentListExpanded && <ExpandMoreIcon />}
                      {!equipmentListExpanded && <ChevronRightIcon />}
                    </div>
                  }
                  onClick={() =>
                    setEquipmentListExpanded(!equipmentListExpanded)
                  }
                >
                  <Box style={{ margin: "15px 30px" }}>
                    <EquipmentInfoTree
                      setEquipmentData={setEquipmentData}
                      equipmentData={equipmentData}
                      selectedEquipmentCode={selectedEquipmentCode}
                      seletctQuantity={seletctQuantity}
                      setSelectedEquipmentCode={(data) =>
                        setSelectedEquipmentCode(data)
                      }
                      formMode={formMode}
                      setQuantity={setQuantity}
                      showSelectedItem={showSelectedItem}
                    />
                  </Box>
                </StyledTreeItem>
              )}
            </TreeView>
          )}

          {props.formData.providerDetailsById &&
            props.formData.providerDetailsById.Providers_Data &&
            !props.formData.providerDetailsById.Providers_Data[0]
              .pricingTemplateView &&
            !profileTypeCheck &&
            !providerStatusCheck && (
              <Box className={classes.buttonBoxPricing}>
                <Grid
                  container
                  item
                  spacing={2}
                  style={{
                    textAlign: "center",
                    justifyContent: "inherit"
                  }}
                >
                  <Grid item sm={1} xs={1}>
                    <Button
                      variant="outlined"
                      fullWidth
                      color="#696969"
                      onClick={() => handleReset()}
                    >
                      Reset
                    </Button>
                  </Grid>
                  <Grid item sm={1} xs={1}>
                    <Button
                      className={classes.buttonStyle}
                      variant="contained"
                      fullWidth
                      color="#696969"
                      onClick={() => handleSave()}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            )}

          {props.formData.providerDetailsById &&
            props.formData.providerDetailsById.Providers_Data &&
            props.formData.providerDetailsById.Providers_Data[0]
              .pricingTemplateView &&
            !profileTypeCheck &&
            formMode && (
              <Box className={classes.buttonBoxPricing}>
                <Grid
                  container
                  item
                  spacing={2}
                  style={{
                    textAlign: "center",
                    justifyContent: "inherit",
                    marginRight: "40px"
                  }}
                >
                  <Grid item sm={1} xs={1}>
                    <Button
                      className={classes.resetButtonStyle}
                      variant="outlined"
                      fullWidth
                      color="#696969"
                      onClick={() => handleReset()}
                    >
                      Reset
                    </Button>
                  </Grid>
                  <Grid item sm={1} xs={1}>
                    <Button
                      className={classes.buttonStyle}
                      variant="contained"
                      fullWidth
                      color="#696969"
                      onClick={() => handleSubmit()}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            )}
        </div>
      </Card>
    </div>
  );
}

BusinessForm.propTypes = {
  formData: PropTypes.object,
  updatePricingTemplete: PropTypes.func,
  mainData: PropTypes.object,
  history: PropTypes.object,
  setPricingTempleteEmpty: PropTypes.func,
  pricingTempleteCall: PropTypes.func,
  getPricingTemplete: PropTypes.func,
  getApplicationType: PropTypes.func,
  getProccesorList: PropTypes.func,
  pricingTempleteCallInvite: PropTypes.func,
  getUpdatePricingTemplate: PropTypes.func,
  getProviderDetailsById: PropTypes.func,
  getInviteEquipmentData: PropTypes.func
};

const mapStateToProps = (app) => ({
  formData: app.provideRegistration,
  mainData: app
});

export default connect(mapStateToProps, {
  statusPreameter,
  statusPreameterKyc,
  statusApproveMpa,
  statusApproveKyc,
  mpaChecklistComment,
  kycChecklistAttechment,
  getProviderDetailsById,
  mpaChecklistAttechment,
  updatePricingTemplete,
  pricingTempleteCall,
  setPricingTempleteEmpty,
  getPricingTemplete,
  getApplicationType,
  getProccesorList,
  getUpdatePricingTemplate,
  pricingTempleteCallInvite,
  getInviteEquipmentData
})(BusinessForm);
