/* eslint-disable radix */
/* eslint-disable react/jsx-props-no-spreading */

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Grid, Box } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "components/CustomButtons/Button";
import ButtonOriginal from "@material-ui/core/Button";
import { useSnackbar } from "notistack";
import Card from "components/Card/Card";
import Checkbox from "@material-ui/core/Checkbox";
import { Typography, Divider } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import isEmpty from "lodash/isEmpty";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CancelIcon from "@material-ui/icons/Cancel";
import ClearIcon from "@material-ui/icons/Clear";
import ErrorIcon from "@material-ui/icons/Error";
import moment from "moment";
import {
  cptCodeDataCall,
  addProposalCall,
  setDefaultOfferValue,
} from "Redux/Actions/genrateOfferPatient";
import styled, { keyframes } from "styled-components";
import noDataImage from "../../assets/img/noDataImage.png";
import tableStyle from "../../assets/jss/material-dashboard-react/components/reportTables";
import tpoLavel0 from "../../assets/img/TPO_Level-0.jpeg";
import tpoLavel1 from "../../assets/img/TPO_Level-1.jpg";
import tpoLavel2 from "../../assets/img/TPO_Level-2.jpg";
import tpoLavel3 from "../../assets/img/TPO_Level-3.jpg";
import tpoLavel4 from "../../assets/img/TPO_Level-4.jpg";
import gif from "../../assets/img/success.gif";

const useStyles = makeStyles(tableStyle);

function createData(
  insurnaceProvider,
  offerId,
  patientResponsbility,
  cptCode,
  status,
  startDate,
  endDate,
  monthlyPay,
  id,
  offerNumber
) {
  return {
    insurnaceProvider,
    offerId,
    patientResponsbility,
    cptCode,
    status,
    startDate,
    endDate,
    monthlyPay,
    id,
    offerNumber,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  {
    id: "calories",
    numeric: true,
    disablePadding: true,
    label: "Offer ID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Insurance Carrier",
  },
  {
    id: "start",
    numeric: false,
    disablePadding: true,
    label: "Start Date",
  },
  {
    id: "end",
    numeric: false,
    disablePadding: true,
    label: "End Date",
  },
  {
    id: "fat",
    numeric: true,
    disablePadding: false,
    label: "Amount Due",
  },
  {
    id: "monthly",
    numeric: true,
    disablePadding: false,
    label: "Monthly Payments",
  },
  {
    id: "protein",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHeader}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            style={{ color: "white" }}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
            disabled={props.disableAction}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={Math.random()}
            className={classes.cellTextHeaderOffer}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  disableAction: PropTypes.bool,
};

function ProposalTable(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [preCptCodeData, setPreCptCodeData] = React.useState(
    props.tableData.cptCodeData
  );
  const [check, setCheck] = React.useState(false);
  const [selectedCount, setSelectedCount] = React.useState([]);
  const [addProposalModal, setaddProposalModal] = React.useState(false);
  const [validationModal, setValidationModal] = React.useState(false);
  const [otherServiceSelect, setOtherServiceSelect] = React.useState(false);
  const [submissionSuccess, setSubmissionSuccess] = React.useState(false);
  const [prePerposal, setPrePerposal] = React.useState(props.formData.addProposalData);
  const [errorMessage, setErrorMessage] = React.useState(false);
  const filter = createFilterOptions();
  const [values, handleInput] = React.useState({
    responsibilty: "",
    provider: "",
    insuranceProvider: "",
    otherservicename: "",
    service: "",
  });
  const [validationError, setValidationError] = React.useState({
    responsibilty: false,
    provider: false,
    insuranceProvider: false,
    service: false,
  });

  const onChange = (value, type) => {
    const allState = { ...values };
    const error = { ...validationError };
    allState[type] = value;
    error[type] = false;
    handleInput(allState);
    setValidationError(error);
  };

  const setFieldValue = (key, value) => {
    if (key === "service") {
      const allValidation = { ...validationError };
      allValidation.service = false;
      setValidationError(allValidation);
      handleInput(pre => {
        pre.service = value;
        return pre;
      });
    }
    if (key === "otherservicename") {
      const data = {};
      setSubmissionSuccess(data);
      const allValidation = { ...validationError };
      allValidation.service = false;
      setValidationError(allValidation);
      setOtherServiceSelect(true);
      handleInput(pre => {
        pre.otherservicename = value;
        return pre;
      });
    }
  };

  const checkValidation = () => {
    let checkValue = true;
    const value = { ...values };
    const allValidation = { ...validationError };
    Object.keys(validationError).forEach(v => {
      if (value[v] === "") {
        checkValue = false;
        allValidation[v] = true;
      }
    });
    setValidationError(allValidation);
    return checkValue;
  };

  // function handleInputChange(event, value) {
  //   const allState = { ...values };
  //   const allValidation = { ...validationError };
  //   allState.service = value;
  //   allValidation.service = false;
  //   handleInput(allState);
  //   setValidationError(allValidation);
  // }

  const onFormSubmit = () => {
    if (checkValidation()) {
      const patientIdValue = props.showOfferDEtails.data.Patient_id;

      let data = {
        patientId: props.showOfferDEtails.data.Patient_id,
        responsibilty: values.responsibilty,
        provider: values.provider,
        insuranceProvider: values.insuranceProvider,
        otherservicename: "",
      };

      if (patientIdValue === "") {
        data = {
          patientId: props.showOfferDEtails.data.Patient_id,
          patientName: props.showOfferDEtails.data.Patient_Name,
          ssn: props.showOfferDEtails.data.SSNFull,
          email: props.showOfferDEtails.data.Email,
          mobile: props.showOfferDEtails.data.Mobile_Number,
          dob: moment(props.showOfferDEtails.data.DOB).format("MM-DD-YYYY"),
          address1: props.showOfferDEtails.data.Address,
          address2: props.showOfferDEtails.data.Address2,
          city: props.showOfferDEtails.data.City,
          zip: props.showOfferDEtails.data.ZipCode,
          responsibilty: values.responsibilty,
          provider: values.provider,
          insuranceProvider: values.insuranceProvider,
          otherservicename: "",
        };
      }
      // if (values.service === "") {
      //   data.service = "other";
      //   data.otherservicename = values.otherservicename;
      // } else {
      //   data.service = props.tableData.cptCodeData.State_Data.filter(
      //     (key) => key.cptDescription === values.service
      //   )[0].cptCode;
      //   data.otherservicename = "";
      // }
      if (values.otherservicename !== "") {
        data.service = "other";
        data.otherservicename = values.otherservicename;
      } else {
        data.service = values.service;
        data.otherservicename = "";
      }
      props.addProposalCall(data);
      setaddProposalModal(false);
      handleResetValues(false);
      setValidationModal(true);
      setSubmissionSuccess(true);
    }
  };

  function blinkingEffect() {
    return keyframes`
      50% {
        opacity: 0;
      }
    `;
  }

  const AnimatedComponent = styled.div`
    animation: ${blinkingEffect} 2s linear infinite;
  `;

  React.useEffect(() => {
    setValidationModal(false);
    if (prePerposal !== props.formData.addProposalData) {
      setValidationModal(false);
      setPrePerposal(props.formData.addProposalData);
      if (
        props.formData.addProposalData
        && props.formData.addProposalData.status
      ) {
        setaddProposalModal(false);
        enqueueSnackbar(`${props.formData.addProposalData.message}`, {
          variant: "success",
        });
        handleCloseModel();
      } else if (
        props.formData.addProposalData
        && !props.formData.addProposalData.status
        && props.formData.addProposalData.errors
      ) {
        setErrorMessage(props.formData.addProposalData.errors);
        setSubmissionSuccess(false);
        setValidationModal(true);
      }
    }
  }, [props]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (offerId, pram) => {
    let value = selected;
    if (selected.length === 0) {
      value.push(offerId);
      setSelected(value);
      setCheck(pram);
    } else if (selected.length) {
      const checkAlreadyPresent = selected.filter(key => key === offerId);
      if (checkAlreadyPresent.length) {
        value = selected.filter(key => key !== offerId);
        setSelected(value);
        setCheck(pram);
      } else {
        value.push(offerId);
        setSelected(value);
        setCheck(pram);
      }
    }
  };

  const rows = [];
  props.showOfferDEtails.data.proposalList.forEach(key => {
    const insurnaceProvider = key.Insurance_Provider;
    const offerId = key.Offer_ID;
    const patientResponsbility = key.Patient_Responsibility;
    const startDate = key.start_Date;
    const endDate = key.end_Date;
    const monthlyPay = key.monthlyPay;
    const cptCode = key.CPT_Code;
    const status = key.Current_Status;
    const id = key._id;
    const offerNumber = key.offerNumber;
    rows.push(
      createData(
        insurnaceProvider,
        offerId,
        patientResponsbility,
        cptCode,
        status,
        startDate,
        endDate,
        monthlyPay,
        id,
        offerNumber
      )
    );
  });

  let totalAmount = 0;
  let countSelect = parseInt(0);
  if (selected.length > 0) {
    const value = [];
    rows.forEach(v => {
      if (selected.filter(key => key === v.offerId).length) {
        value.push(v.patientResponsbility);
        countSelect += parseInt(1);
      } else if (selected.filter(key => key === v.id).length) {
        value.push(v.patientResponsbility);
        countSelect += parseInt(1);
      }
    });
    totalAmount = value.reduce((acc, val) => acc + val, 0).toFixed(2);
  }
  // selected.length > 0 ? rows.filter(key => key.offerId === offerId);selected.reduce((acc, val) => acc + val, 0).toFixed(2) : 0; a.findIndex(t => (t.label === v.label && t.value === v.value)) === i)

  const handleGenrateOffer = value => {
    props.setOfferId(false);
    let totalAmountIn = 0;
    const valuePreposalId = [];
    const valueIn = [];
    rows.forEach(v => {
      if (value.filter(key => key === v.offerId).length) {
        valuePreposalId.push(v.id);
        valueIn.push(v.patientResponsbility);
      } else if (value.filter(key => key === v.id).length) {
        valuePreposalId.push(v.id);
        valueIn.push(v.patientResponsbility);
      }
    });
    totalAmountIn = valueIn.reduce((acc, val) => acc + val, 0).toFixed(2);
    props.setPropsalData(valuePreposalId);
    if (totalAmountIn > 0) {
      const data = {
        amount: totalAmountIn,
        terms: 24,
      };
      props.setLoadingFalse(true);
      setTimeout(() => {
        props.setLoadingFalse(false);
      }, 10000);
      props.getGenrateOfferData(data);
      props.setDefaultOfferValue(data);
    }
  };

  React.useEffect(() => {
    if (props.tableData.paymentDetailsData && props.tableData.createOffer) {
      const filterData = props.showOfferDEtails.data.proposalList.filter(
        key => key.Offer_ID === props.tableData.createOffer.data.OfferId
      );
      if (filterData.length > 0) {
        const backRow = {
          insurnaceProvider: filterData[0].Insurance_Provider,
          offerId: filterData[0].Offer_ID,
          patientResponsbility: filterData[0].Patient_Responsibility,
          startDate: filterData[0].start_Date,
          endDate: filterData[0].end_Date,
          monthlyPay: filterData[0].monthlyPay,
          cptCode: filterData[0].CPT_Code,
          status: filterData[0].Current_Status,
          id: filterData[0]._id,
        };
        handleClick(backRow.id, !check, true);
      }
      props.setOfferId(props.tableData.createOffer.data.OfferId);
    }
  }, []);

  React.useEffect(() => {
    if (preCptCodeData !== props.tableData.cptCodeData) {
      setPreCptCodeData(props.tableData.cptCodeData);
      if (props.tableData.cptCodeData) {
        setaddProposalModal(true);
      }
    }
  }, [props]);

  const handleGenrateOfferHyperLink = (value, offerId) => {
    props.setOfferId(offerId);
    const valuePreposalId = [];
    let totalAmountIn = 0;
    const valueIn = [];
    rows.forEach(v => {
      if (value.filter(key => key === v.offerId).length) {
        valuePreposalId.push(v.id);
        valueIn.push(v.patientResponsbility);
      } else if (value.filter(key => key === v.id).length) {
        valuePreposalId.push(v.id);
        valueIn.push(v.patientResponsbility);
      }
    });
    totalAmountIn = valueIn.reduce((acc, val) => acc + val, 0).toFixed(2);
    if (totalAmountIn > 0) {
      const data = {
        amount: totalAmountIn,
        terms: 24,
        offerId,
      };
      props.genrateOfferHyperLink(data);
      props.setDefaultOfferValue(data);
      props.setClickLink();
    }
  };

  const handleReset = () => {
    setSelected([]);
    props.handlePrePosalReset();
  };

  const generateOffers = offerId => {
    props.setOfferId(offerId);
    const checkBoxActive = [];
    const filterOfferId = rows.filter(key => key.offerId === offerId);
    filterOfferId.forEach(key => {
      checkBoxActive.push(key.id);
    });
    const valuePreposalId = [];
    rows.forEach(v => {
      if (checkBoxActive.filter(key => key === v.offerId).length) {
        valuePreposalId.push(v.id);
      } else if (checkBoxActive.filter(key => key === v.id).length) {
        valuePreposalId.push(v.id);
      }
    });
    props.setPropsalData(valuePreposalId);
    setSelected(checkBoxActive);
    handleGenrateOfferHyperLink(checkBoxActive, offerId);
  };

  const handleAddNew = () => {
    const data = {};
    setSubmissionSuccess(data);
    setOtherServiceSelect(true);
  };

  const handleResetValues = () => {
    handleInput({
      responsibilty: "",
      provider: "",
      insuranceProvider: "",
      otherservicename: "",
      service: "",
    });
  };

  const handleaddProposalModal = () => {
    setSubmissionSuccess(false);
    setValidationModal(false);
    props.cptCodeDataCall();
  };

  const handleCloseModel = () => {
    setValidationModal(false);
    setaddProposalModal(false);
    handleResetValues(false);
    const allState = { ...values };
    allState.responsibilty = "";
    allState.provider = "";
    allState.insuranceProvider = "";
    handleInput(allState);
    setSubmissionSuccess(false);
    const data = {
      commonText: "",
    };
    props.patientSearch(data);
    setFieldValue("service", "");
    setFieldValue("otherservicename", "");
  };

  const defaultProps = {
    options: props.tableData.cptCodeData.State_Data,
    // getOptionLabel: option => option.cptDescription,
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{ marginTop: "1%", marginBottom: "1%" }}
        ref={props.titleRef}
      >
        <Grid
          item
          lg={3}
          md={3}
          sm={3}
          xs={6}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // marginRight: "-2%",
          }}
        >
          <ButtonOriginal
            disabled={props.disableAction}
            color="primary"
            variant="contained"
            onClick={() => handleGenrateOffer(selected)}
            style={{
              padding: "12px 25px",
              fontSize: "17.5px",
              animation: "1s fadeAnimation",
            }}
          >
            <AnimatedComponent>
              {" "}
              <b>Generate Offer</b>
            </AnimatedComponent>
          </ButtonOriginal>
        </Grid>
        <Grid
          item
          lg={3}
          md={3}
          sm={3}
          xs={6}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ButtonOriginal variant="outlined" onClick={() => handleReset()}>
            Reset
          </ButtonOriginal>
        </Grid>
        <Grid
          item
          lg={2}
          md={2}
          sm={2}
          xs={3}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Selected Amount:
        </Grid>
        <Grid
          item
          lg={2}
          md={2}
          sm={2}
          xs={3}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            fontSize: "25px",
            fontStyle: "bold",
          }}
        >
          $
          {totalAmount}
          {" "}
          (
          {countSelect}
          )
        </Grid>
        <Grid
          item
          lg={2}
          md={2}
          sm={2}
          xs={6}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <Typography>
            New Dues ({props.showOfferDEtails.data.newDuesWithOffer}
            ):
          </Typography> */}

          <ButtonOriginal
            disabled={props.disableAction}
            variant="contained"
            style={{ marginLeft: "3%" }}
            color="primary"
            onClick={() => handleaddProposalModal()}
          >
            Add
          </ButtonOriginal>
        </Grid>
      </Grid>
      <Card
        style={{
          marginTop: "0px",
          // borderTop: "2px solid #0d1619",
        }}
      >
        <TableContainer style={{ maxHeight: "34rem" }}>
          <Table
            className={classes.patientTable}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              disableAction={props.disableAction}
            />
            <TableBody>
              {!isEmpty(rows) ? (
                rows.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow hover role="checkbox" key={Math.random()}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          onClick={() => props.disableAction
                            ? {}
                            : handleClick(row.id, !check)}
                          checked={
                            selected.filter(key => key === row.id).length > 0
                          }
                          inputProps={{ "aria-labelledby": labelId }}
                          disabled={props.disableAction}
                        />
                      </TableCell>
                      <TableCell
                        className={classes.patientNameColor}
                        style={{ cursor: "pointer" }}
                        align="left"
                        onClick={() => row.offerId ? generateOffers(row.offerId) : {}}
                      >
                        {row.offerNumber}
                      </TableCell>
                      <TableCell className={classes.cellText} align="left">
                        {row.insurnaceProvider}
                      </TableCell>
                      <TableCell className={classes.cellText} align="left">
                        {row.startDate}
                      </TableCell>
                      <TableCell className={classes.cellText} align="left">
                        {row.endDate}
                      </TableCell>
                      <TableCell className={classes.cellText} align="left">
                        {`$${row.patientResponsbility}`}
                      </TableCell>
                      <TableCell className={classes.cellText} align="left">
                        {row.monthlyPay}
                      </TableCell>
                      <TableCell className={classes.cellText} align="left">
                        {row.status}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell className="no-data" colSpan={headCells.length + 1}>
                    <img
                      src={noDataImage}
                      alt=""
                      className={classes.noDataImage}
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <Modal open={addProposalModal} onClose={() => handleCloseModel(false)}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card style={{ width: "70%", marginTop: "5%" }}>
            <Grid container className={classes.margin} style={{ margin: "2%" }}>
              <Grid item xs={11}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: "1%",
                    fontSize: "20px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {"Dues for: "}
                  <div
                    style={{
                      fontSize: "18px",
                      color: "#144b8e",
                      fontWeight: "bold",
                      marginTop: "1px",
                    }}
                  >
                    &nbsp;&nbsp;
                    {" "}
                    {props.showOfferDEtails.data.Patient_Name}
                    {" "}
                  </div>
                  <div
                    style={{
                      marginTop: "-1%",
                      marginLeft: "3%",
                    }}
                  >
                    {(props.showOfferDEtails.data.tpoLevel === "Level-0" && (
                      <img src={tpoLavel0} alt="" />
                    ))
                      || (props.showOfferDEtails.data.tpoLevel === "Level-1" && (
                        <img src={tpoLavel1} alt="" />
                      ))
                      || (props.showOfferDEtails.data.tpoLevel === "Level-2" && (
                        <img src={tpoLavel2} alt="" />
                      ))
                      || (props.showOfferDEtails.data.tpoLevel === "Level-3" && (
                        <img src={tpoLavel3} alt="" />
                      ))
                      || (props.showOfferDEtails.data.tpoLevel >= "Level-4" && (
                        <img src={tpoLavel4} alt="" />
                      ))}
                  </div>
                </div>
              </Grid>
              <Grid item xs={1} align="left">
                <ClearIcon
                  align="right"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleCloseModel(false)}
                />
              </Grid>
            </Grid>
            <Divider />
            <Grid
              container
              className={classes.margin}
              style={{ margin: "1% 2%" }}
            >
              <Grid item xs>
                <TextField
                  error={validationError.responsibilty}
                  required
                  style={{ width: "90%" }}
                  className={classes.root}
                  InputLabelProps={{
                    classes: {
                      root: classes.activeInputLableColor,
                      focused: "focused",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.activeInputColor,
                      input: classes.resize,
                      focused: "focused",
                    },
                  }}
                  label="Responsibilty ($)"
                  value={values.responsibilty}
                  onChange={e => onChange(e.target.value, "responsibilty")}
                  name="responsibilty"
                  id="responsibilty"
                />
              </Grid>
              <Grid item xs>
                <TextField
                  error={validationError.provider}
                  className={classes.root}
                  style={{ width: "90%" }}
                  required
                  InputLabelProps={{
                    classes: {
                      root: classes.activeInputLableColor,
                      focused: "focused",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.activeInputColor,
                      input: classes.resize,
                      focused: "focused",
                    },
                  }}
                  select
                  label="Provider"
                  value={values.provider}
                  onChange={e => onChange(e.target.value, "provider")}
                  name="provider"
                  id="provider"
                >
                  {props.userProvider.userProvider
                    && props.userProvider.userProvider.data.map(key => (
                      <MenuItem value={key.Porvider_Id}>
                        {key.ProviderData}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.margin}
              style={{ margin: "1% 2%" }}
            >
              <Grid item xs>
                <TextField
                  style={{ width: "90%" }}
                  error={validationError.insuranceProvider}
                  required
                  className={classes.root}
                  InputLabelProps={{
                    classes: {
                      root: classes.activeInputLableColor,
                      focused: "focused",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.activeInputColor,
                      input: classes.resize,
                      focused: "focused",
                    },
                  }}
                  label="Insurance Carrier"
                  value={values.insuranceProvider}
                  onChange={e => onChange(e.target.value, "insuranceProvider")}
                  name="insuranceProvider"
                  id="insuranceProvider"
                />
              </Grid>

              <Grid item xs>
                <Autocomplete
                  {...defaultProps}
                  value={
                    defaultProps.options
                      ? defaultProps.options?.find(
                        option => option.cptCode === values.service
                      ) || values.service
                      : ""
                  }
                  label="Service Description"
                  onChange={(event, newValue) => {
                    if (typeof newValue === "string") {
                      setFieldValue("service", newValue);
                      setFieldValue("otherservicename", newValue);
                    } else if (newValue && newValue.cptCode) {
                      setFieldValue("service", newValue.cptCode);
                      setFieldValue("otherservicename", "");
                    } else {
                      setFieldValue("service", newValue);
                      setFieldValue("otherservicename", newValue);
                    }
                  }}
                  selectOnFocus
                  clearOnBlur
                  size="small"
                  handleHomeEndKeys
                  id="service"
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    // Suggest the creation of a new value
                    if (params.inputValue !== "") {
                      filtered.unshift(params.inputValue);
                    }

                    return filtered;
                  }}
                  getOptionLabel={option => {
                    // Value selected with enter, right from the input
                    if (typeof option === "string") {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.cptDescription) {
                      return option.cptDescription;
                    }
                    // Regular option
                    return option.cptDescription;
                  }}
                  renderOption={option => typeof option === "string" ? (
                    <div>
                      <span style={{ fontWeight: 600 }}>
                        {`Add ${option}`}
                      </span>
                    </div>
                  ) : (
                    option.cptDescription
                  )}
                  freeSolo
                  renderInput={params => (
                    <TextField
                      style={{ width: "90%" }}
                      size="small"
                      {...params}
                      error={validationError.service}
                      label="Service Description"
                    />
                  )}
                />
              </Grid>

              {/* <Grid item xs>
                {!otherServiceSelect && (
                  <Autocomplete
                    {...defaultProps}
                    onInputChange={handleInputChange}
                    noOptionsText={
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        not available
                        <Button
                          color="secondary"
                          onClick={() => handleAddNew()}
                        >
                          Add New
                        </Button>
                      </Box>
                    }
                    onClose={(e, reason) => {
                      if (reason === "blur") {
                        handleAddNew();
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        className={classes.root}
                        style={{ width: "90%" }}
                        InputLabelProps={{
                          classes: {
                            root: classes.activeInputLableColor,
                            focused: "focused",
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: classes.activeInputColor,
                            input: classes.resize,
                            focused: "focused",
                          },
                        }}
                        {...params}
                        label="Service Description"
                      />
                    )}
                  />
                )}
                {otherServiceSelect && (
                  <TextField
                    style={{ width: "90%" }}
                    error={validationError.insuranceProvider}
                    required
                    className={classes.root}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused",
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <CancelIcon
                          onClick={() => setOtherServiceSelect(false)}
                        />
                      ),
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused",
                      },
                    }}
                    label="Add New Service"
                    value={values.otherservicename}
                    onChange={(e) =>
                      onChange(e.target.value, "otherservicename")
                    }
                    name="otherservicename"
                    id="otherservicename"
                  />
                )}
              </Grid> */}
            </Grid>
            <Grid
              container
              className={classes.margin}
              style={{ marginTop: "2%", marginBottom: "3%" }}
            >
              <Grid item xs align="center">
                <Box className={classes.buttonBox}>
                  <ButtonOriginal
                    variant="outlined"
                    style={{ height: "30px", marginRight: "1%" }}
                    // className={classes.clearButton}
                    size="large"
                    onClick={() => handleResetValues(false)}
                  >
                    Reset
                  </ButtonOriginal>
                  <Button
                    variant="contained"
                    className={classes.buttonStyle}
                    color="#696969"
                    size="large"
                    onClick={() => onFormSubmit()}
                  >
                    Create
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Card>
        </div>
      </Modal>
      <Modal open={validationModal} onClose={() => setValidationModal(false)}>
        <div className={classes.modalDiv}>
          <Card className={classes.modalCard}>
            <div className={classes.cardDiv} align="center">
              {!props.formData.addProposalData.status ? (
                <ErrorIcon style={{ fontSize: "75px", color: "#FF0000" }} />
              ) : (
                <img alt="" src={gif} width="50%" />
              )}
              <div className={classes.successText}>
                {props.formData.addProposalData.message}
              </div>
              <br />
              <Button
                variant="contained"
                className={classes.buttonStyle}
                color="#696969"
                size="sm"
                onClick={() => handleCloseModel()}
              >
                Ok
              </Button>
            </div>
          </Card>
        </div>
      </Modal>
    </>
  );
}

ProposalTable.propTypes = {
  showOfferDEtails: PropTypes.any,
  getGenrateOfferData: PropTypes.func,
  setDefaultOfferValue: PropTypes.func,
  setPropsalData: PropTypes.func,
  disableAction: PropTypes.bool,
  handlePrePosalReset: PropTypes.func,
  genrateOfferHyperLink: PropTypes.func,
  tableData: PropTypes.object,
  setOfferId: PropTypes.func,
  cptCodeDataCall: PropTypes.func,
  addProposalCall: PropTypes.func,
  patientSearch: PropTypes.func,
  // offerId: PropTypes.object,
  userProvider: PropTypes.object,
  formData: PropTypes.object,
  setLoadingFalse: PropTypes.func,
  setClickLink: PropTypes.func,
};

const mapStateToProps = app => ({
  tableData: app.genrateOfferPatient,
  formData: app.genrateOfferPatient,
});

export default connect(mapStateToProps, {
  cptCodeDataCall,
  addProposalCall,
  setDefaultOfferValue,
})(ProposalTable);
