
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "components/CustomButtons/Button";
import ButtonOriginal from "@material-ui/core/Button";
import Card from "components/Card/Card";
import Checkbox from "@material-ui/core/Checkbox";
import { Typography } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import isEmpty from "lodash/isEmpty";
import tableStyle from "../../assets/jss/material-dashboard-react/components/reportTables";
import noDataImage from "../../assets/img/noDataImage.png";

const useStyles = makeStyles(tableStyle);

function createData(
  insurnaceProvider,
  offerId,
  startDate,
  endDate,
  amountFonded,
  balanceAmount,
  monthlyAmount,
  offerNumber
) {
  return {
    insurnaceProvider,
    offerId,
    startDate,
    endDate,
    amountFonded,
    balanceAmount,
    monthlyAmount,
    offerNumber,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  {
    id: "calories",
    numeric: true,
    disablePadding: true,
    label: "Offer ID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Insurance Carrier",
  },
  {
    id: "start",
    numeric: false,
    disablePadding: true,
    label: "Start Date",
  },
  {
    id: "end",
    numeric: false,
    disablePadding: true,
    label: "End Date",
  },
  {
    id: "fat",
    numeric: true,
    disablePadding: false,
    label: "Amount Funded",
  },
  {
    id: "balance",
    numeric: true,
    disablePadding: false,
    label: "Balance Amount",
  },
  {
    id: "protein",
    numeric: true,
    disablePadding: false,
    label: "Monthly Payments",
  },
];

function EnhancedTableHead(props) {
  const { classes } = props;

  return (
    <TableHead className={classes.tableHeader} style={{ borderRadius: "2px" }}>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={Math.random()}
            className={classes.cellTextHeaderOffer}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
};

function ActivePlans(props) {
  const classes = useStyles();
  const [preActivePlanList, setPreActivePlanList] = React.useState(
    props.tableData.activePlanPatientList
  );
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [data, setData] = React.useState(false);
  const [selected, setSelected] = React.useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const rows = [];
  preActivePlanList.data.forEach(key => {
    const offerId = key.offerId;
    const insurnaceProvider = key.insuranceProvider;
    const startDate = key.offerStartDate;
    const endDate = key.offerEndDate;
    const amountFonded = key.amountfunded;
    const balanceAmount = key.balanceAmount;
    const monthlyAmount = key.monthlyAmount;
    const offerNumber = key.offerNumber;
    rows.push(
      createData(
        insurnaceProvider,
        offerId,
        startDate,
        endDate,
        amountFonded,
        balanceAmount,
        monthlyAmount,
        offerNumber
      )
    );
  });

  let totalAmount = 0;
  if (selected.length > 0) {
    const value = [];
    rows.forEach(v => {
      if (selected.filter(key => key === v.offerId).length) {
        value.push(v.patientResponsbility);
      } else if (selected.filter(key => key === v.id).length) {
        value.push(v.patientResponsbility);
      }
    });
    totalAmount = value.reduce((acc, val) => acc + val, 0).toFixed(2);
  }
  // selected.length > 0 ? rows.filter(key => key.offerId === offerId);selected.reduce((acc, val) => acc + val, 0).toFixed(2) : 0; a.findIndex(t => (t.label === v.label && t.value === v.value)) === i)
  const titleActivePlan = props.titleActivePlan;
  return (
    <>
      <Grid
        container
        style={{ marginTop: "1%", marginBottom: "1%" }}
        ref={titleActivePlan}
      >
        {/* <Grid
          item
          xs
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Typography>
            Ongoing Active Plans ({props.showOfferDEtails.data.activePlans}
            ):
          </Typography>
        </Grid> */}
        <Grid item xs />
        <Grid item xs />
        <Grid item xs />
        <Grid item xs />
      </Grid>
      <Card
        style={{
          marginTop: "0px",
          // borderTop: "2px solid #0d1619",
        }}
      >
        <TableContainer style={{ maxHeight: "34rem" }}>
          <Table
            className={classes.patientTable}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              disableAction={props.disableAction}
            />
            <TableBody>
              {!isEmpty(rows) ? (
                rows.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow hover role="checkbox" key={Math.random()}>
                      <TableCell
                        className={classes.patientNameColor}
                        style={{ cursor: "pointer" }}
                        align="left"
                      >
                        {row.offerNumber}
                      </TableCell>
                      <TableCell className={classes.cellText} align="left">
                        {row.insurnaceProvider}
                      </TableCell>
                      <TableCell className={classes.cellText} align="left">
                        {row.startDate}
                      </TableCell>
                      <TableCell className={classes.cellText} align="left">
                        {row.endDate}
                      </TableCell>
                      <TableCell className={classes.cellText} align="left">
                        {row.amountFonded}
                      </TableCell>
                      <TableCell className={classes.cellText} align="left">
                        {row.balanceAmount}
                      </TableCell>
                      <TableCell className={classes.cellText} align="left">
                        {row.monthlyAmount}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell className="no-data" colSpan={headCells.length + 1}>
                    <img src={noDataImage} alt="" className={classes.noDataImage} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
}

ActivePlans.propTypes = {
  showOfferDEtails: PropTypes.any,
  disableAction: PropTypes.bool,
  tableData: PropTypes.object,
};

export default ActivePlans;
