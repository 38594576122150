/* eslint-disable import/extensions */
import React from "react";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Menu from "@material-ui/core/Menu";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import {
  getUserDetailsById,
  changeUserFormMode
} from "../../Redux/Actions/UserRegistration";

const useStyles = makeStyles(styles);

function LongMenu(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (type) => {
    if (type === "edit") {
      props.changeUserFormMode("Edit");
      props.getUserDetailsById(props.rowId);
    }
    setAnchorEl(null);
  };

  const openStatusForm = () => {
    props.openForm();
    props.setStatusPid();
    handleClose();
  };

  const openPasswordForm = () => {
    props.openPasswordModel();
    props.setStatusPid();
    handleClose();
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {props.editable && (
          <MenuItem
            className={classes.dialogBoxDropDown}
            onClick={() => handleClose("edit")}
          >
            Edit
          </MenuItem>
        )}
        <MenuItem
          className={classes.dialogBoxDropDown}
          onClick={() => openStatusForm()}
        >
          Change Status
        </MenuItem>
        <MenuItem
          className={classes.dialogBoxDropDown}
          onClick={() => {
            props.setUserIdHierarchyTab(props.rowId);
            props.setValue(3);
          }}
        >
          View Herarchy
        </MenuItem>
      </Menu>
    </div>
  );
}

LongMenu.propTypes = {
  details: PropTypes.string,
  getUserDetailsById: PropTypes.func,
  changeUserFormMode: PropTypes.func,
  setUserIdHierarchyTab: PropTypes.func,
  openForm: PropTypes.func,
  setStatusPid: PropTypes.func,
  editable: PropTypes.bool,
  openPasswordModel: PropTypes.func,
  setValue: PropTypes.func,
  rowId: PropTypes.any
};

const mapStateToProps = (app) => ({
  formData: app.users
});

export default connect(mapStateToProps, {
  getUserDetailsById,
  changeUserFormMode
})(LongMenu);
