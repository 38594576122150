/* eslint-disable no-lone-blocks */
/* eslint-disable no-nested-ternary */
import React from "react";
import PropTypes from "prop-types";
import { alpha, makeStyles, withStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import IndeterminateCheckBoxOutlinedIcon from "@material-ui/icons/IndeterminateCheckBoxOutlined";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import {
  Checkbox,
  MenuItem,
  TextField,
  TableBody,
  TableCell,
} from "@material-ui/core";
import { NumericFormat } from "react-number-format";
import { useSnackbar } from "notistack";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import tableStyle from "../../assets/jss/material-dashboard-react/components/reportTables";

const useTableStyles = makeStyles(tableStyle);
const useStyles = makeStyles(styles);

function coustomNumber(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      isNumericString
    />
  );
}

const StyledTreeItem = withStyles(theme => ({
  iconContainer: {
    "& svg": {
      height: 24,
      width: 24,
    },
    height: 40,
    width: 40,
    display: "flex",
    alignItems: "center",
  },
  label: {
    fontSize: "1.2rem !important",
  },
  group: {
    marginLeft: 10,
    paddingLeft: 20,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}))(props => <TreeItem {...props} />);

const headCells = [
  {
    id: "checkbox",
    className: "checkbox",
    numeric: true,
    disablePadding: false,
    label: "",
  },
  {
    id: "equipmentName",
    className: "equipmentName",
    numeric: true,
    disablePadding: false,
    label: "",
  },
  {
    id: "name",
    className: "name",
    numeric: true,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "qty",
    className: "qty",
    numeric: false,
    disablePadding: true,
    label: "QTY",
  },
  {
    id: "unitPrice",
    className: "unitPrice",
    numeric: true,
    disablePadding: false,
    label: "Unit Price ($)",
  },
  {
    id: "totalPrice",
    className: "totalPrice",
    numeric: true,
    disablePadding: false,
    label: "Total Price ($)",
  },
];
function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align="left"
            style={{ padding: "2px 5px 0px 5px" }}
          >
            {headCell.label}
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  );
}

function PricingEquimentInfo(props) {
  const tableClasses = useTableStyles();
  const classes = useStyles();

  const showIempMin = data => {
    const returnArray = [];
    const ticker = data.qtyTicker;
    const to = data.qtyTo;
    const from = data.qtyFrom;
    if (data.qtyTicker !== 0) {
      if (data.qtyTicker % 1 !== 0) {
        for (let i = from; i <= to; i += ticker) {
          if (i > 0) {
            const finalValue = i;
            returnArray.push(
              data.qtyTicker % 1 !== 0 ? finalValue.toFixed(4) : finalValue
            );
          } else {
            break;
          }
        }
      } else {
        for (let i = from; i <= to; i += ticker) {
          if (i > 0) {
            const finalValue = i;
            returnArray.push(
              data.qtyTicker % 1 !== 0 ? finalValue.toFixed(4) : finalValue
            );
          } else {
            break;
          }
        }
      }
    }
    if (ticker === 0 && to === 0 && from === 0) {
      returnArray.push("0");
    }
    return returnArray;
  };

  const equimentOnchange = (value, index, innerIndex) => {
    const newCheckBoxValue = [...props.equipmentData];
    const indexOfQuimentType = newCheckBoxValue[index].equipmentItem[
      innerIndex
    ].equipmentType.findIndex(key => key.equipCode === value);
    newCheckBoxValue[index].equipmentItem[innerIndex].equipmentType.forEach(
      (item, typeIndex) => {
        if (typeIndex !== indexOfQuimentType) {
          newCheckBoxValue[index].equipmentItem[innerIndex].equipmentType[
            typeIndex
          ].selected = false;
        } else if (typeIndex === indexOfQuimentType) {
          newCheckBoxValue[index].equipmentItem[innerIndex].equipmentType[
            typeIndex
          ].selected = true;
        }
      }
    );
    props.setEquipmentData([...newCheckBoxValue]);
  };

  const quantityOnchange = (value, index, innerIndex) => {
    const newCheckBoxValue = [...props.equipmentData];
    const findSelectedType = newCheckBoxValue[index].equipmentItem[
      innerIndex
    ].equipmentType.findIndex(item => item.selected === true);
    newCheckBoxValue[index].equipmentItem[innerIndex].equipmentType[
      findSelectedType
    ].qty = value;
    if (newCheckBoxValue[index].successorEnable
      && newCheckBoxValue[index].equipmentItem.length - 1 > innerIndex
      && newCheckBoxValue[index].equipmentItem[innerIndex].equipmentType[
        findSelectedType].qtyTo === value) {
      newCheckBoxValue[index].equipmentItem[innerIndex + 1].disbaledCheckBox = true;
    } else if (newCheckBoxValue[index].successorEnable && newCheckBoxValue[index].equipmentItem.length - 1 > innerIndex
      && newCheckBoxValue[index].equipmentItem[innerIndex].equipmentType[
        findSelectedType].qtyTo !== value) {
      newCheckBoxValue[index].equipmentItem[innerIndex + 1].disbaledCheckBox = false;
      newCheckBoxValue[index].equipmentItem[innerIndex + 1].selected = false;
    }
    let totalPrice = "";
    if (
      newCheckBoxValue[index].equipmentItem[innerIndex].equipmentType[
        findSelectedType
      ].priceCalculation
    ) {
      totalPrice = parseFloat(newCheckBoxValue[index].equipmentItem[innerIndex].equipmentType[
        findSelectedType
      ].unitPrice.replace(/,/g, "")) * value;
    } else {
      totalPrice = newCheckBoxValue[index].equipmentItem[innerIndex].equipmentType[
        findSelectedType
      ].unitPrice;
    }
    newCheckBoxValue[index].equipmentItem[innerIndex].equipmentType[
      findSelectedType
    ].price = Number(totalPrice).toFixed(2);
    props.setEquipmentData([...newCheckBoxValue]);
  };

  const checkBoxOnChangeOnchange = (value, index, innerIndex) => {
    const newCheckBoxValue = [...props.equipmentData];
    newCheckBoxValue[index].equipmentItem[innerIndex].selected = value.checked;
    newCheckBoxValue[index].equipmentItem[
      innerIndex
    ].equipmentType[0].selected = true;
    props.setEquipmentData([...newCheckBoxValue]);
  };

  const onUnitPriceOnChange = (value, index, innerIndex) => {
    const newCheckBoxValue = [...props.equipmentData];
    const findSelectedType = newCheckBoxValue[index].equipmentItem[
      innerIndex
    ].equipmentType.findIndex(item => item.selected === true);
    newCheckBoxValue[index].equipmentItem[innerIndex].equipmentType[
      findSelectedType
    ].unitPrice = value;
    let totalPrice = "";
    if (
      newCheckBoxValue[index].equipmentItem[innerIndex].equipmentType[
        findSelectedType
      ].priceCalculation
    ) {
      totalPrice = parseFloat(newCheckBoxValue[index].equipmentItem[innerIndex].equipmentType[
        findSelectedType
      ].unitPrice.replace(/,/g, "")) * newCheckBoxValue[index].equipmentItem[innerIndex].equipmentType[
        findSelectedType
      ].qty;
    } else {
      totalPrice = newCheckBoxValue[index].equipmentItem[innerIndex].equipmentType[
        findSelectedType
      ].unitPrice;
    }
    newCheckBoxValue[index].equipmentItem[innerIndex].equipmentType[
      findSelectedType
    ].price = Number(totalPrice).toFixed(2);
    props.setEquipmentData([...newCheckBoxValue]);
  };

  return (
    <>
      <TreeView
        defaultCollapseIcon={<IndeterminateCheckBoxOutlinedIcon />}
        defaultExpandIcon={<AddBoxOutlinedIcon />}
        style={{ width: "100%" }}
      >
        {props?.equipmentData.map((data, index) => {
          if (data.activeCheckbox) {
            return (
              <StyledTreeItem
                nodeId={data?.categoryId}
                label={data?.categoryName}
              >
                <Table
                  className={tableClasses.table}
                  aria-labelledby="tableTitle"
                  aria-label="enhanced table"
                  style={{ marginBottom: "20px" }}
                >
                  <EnhancedTableHead classes={classes} />
                  <TableBody>
                    {data?.equipmentItem.map((item, innerIndex) => {
                      if (props.showSelectedItem) {
                        if (item.activeCheckbox) {
                          const findIndex = item.equipmentType.findIndex(type => item.selected && type.activeCheckbox && type.selected === true);
                          const unitPriceShow = findIndex >= 0 ? item.equipmentType[findIndex] : item.equipmentType[0];
                          return (
                            <TableRow>
                              {props.formMode && (
                                <TableCell
                                  align="left"
                                  style={{ padding: "4px", width: "5%" }}
                                >
                                  <Checkbox
                                    checked={item.selected}
                                    disabled={!item.disbaledCheckBox}
                                    onChange={e => checkBoxOnChangeOnchange(
                                      e.target,
                                      index,
                                      innerIndex
                                    )}
                                    inputProps={{
                                      "aria-label": "select all desserts",
                                    }}
                                    color="primary"
                                  />
                                </TableCell>
                              )}
                              {!props.formMode && (
                                <TableCell
                                  align="left"
                                  style={{ padding: "4px" }}
                                />
                              )}
                              <TableCell
                                align="left"
                                style={{
                                  padding: "4px 8px",
                                  fontWeight: "bold",
                                  width: "20%",
                                }}
                              >
                                {item?.equipmentName}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ padding: "4px", width: "25%" }}
                              >
                                {/* {props.formMode && ( */}
                                <TextField
                                  className={classes.quaterInputPricing}
                                  style={{
                                    width: "85%",
                                  }}
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.activeInputLableColor,
                                      focused: "focused",
                                    },
                                  }}
                                  InputProps={{
                                    classes: {
                                      root: classes.activeInputColor,
                                      input: classes.resizePrice,
                                      focused: "focused",
                                    },
                                  }}
                                  disabled={
                                    !item.activeCheckbox || !props.formMode || !item.selected
                                  }
                                  onChange={e => {
                                    equimentOnchange(
                                      e.target.value,
                                      index,
                                      innerIndex
                                    );
                                  }}
                                  value={
                                    unitPriceShow.equipCode
                                  }
                                  name="name"
                                  id="name"
                                  select
                                >
                                  {item.equipmentType.filter(typeActive => typeActive.activeCheckbox === true)
                                    .map(subItem => (
                                      <MenuItem
                                        value={subItem.equipCode}
                                        key={subItem.equipmentID}
                                      >
                                        {subItem.name}
                                      </MenuItem>
                                    ))}
                                </TextField>
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ padding: "4px", width: "10%" }}
                              >
                                {props.formMode && (
                                  <TextField
                                    className={classes.quaterInputPricing}
                                    style={{ width: "60%" }}
                                    InputLabelProps={{
                                      classes: {
                                        root: classes.activeInputLableColor,
                                        focused: "focused",
                                      },
                                    }}
                                    InputProps={{
                                      classes: {
                                        root: classes.activeInputColor,
                                        input: classes.resizePrice,
                                        focused: "focused",
                                      },
                                    }}
                                    disabled={!item.selected}
                                    onChange={e => {
                                      quantityOnchange(
                                        e.target.value,
                                        index,
                                        innerIndex
                                      );
                                    }}
                                    value={
                                      unitPriceShow.qty
                                    }
                                    name="qty"
                                    id="qty"
                                    select
                                  >
                                    {showIempMin(
                                      unitPriceShow
                                    ).map(number => (
                                      <MenuItem value={number} key={number}>
                                        {number}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                )}
                                {!props.formMode && (
                                  <TextField
                                    className={classes.quaterInputPricing}
                                    style={{ width: "60%" }}
                                    InputLabelProps={{
                                      classes: {
                                        root: classes.activeInputLableColor,
                                        focused: "focused",
                                      },
                                    }}
                                    InputProps={{
                                      classes: {
                                        root: classes.activeInputColor,
                                        input: classes.resizePrice,
                                        focused: "focused",
                                      },
                                    }}
                                    disabled={!props.formMode}
                                    value={
                                      unitPriceShow.qty
                                    }
                                    name="qty"
                                    id="qty"
                                  />
                                )}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ padding: "4px", width: "10%" }}
                              >
                                <TextField
                                  className={classes.quaterInputPricing}
                                  style={{
                                    width: "70%",
                                  }}
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.activeInputLableColor,
                                      focused: "focused",
                                    },
                                  }}
                                  InputProps={{
                                    classes: {
                                      root: classes.activeInputColor,
                                      input: classes.resizePrice,
                                      focused: "focused",
                                    },
                                    inputComponent: NumericFormat,
                                  }}
                                  value={unitPriceShow.unitPrice}
                                  name="unitPrice"
                                  id="unitPrice"
                                  onChange={e => onUnitPriceOnChange(
                                    e.target.value,
                                    index,
                                    innerIndex
                                  )}
                                />
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ padding: "4px", width: "10%" }}
                              >
                                <TextField
                                  className={classes.quaterInputPricing}
                                  style={{
                                    width: "70%",
                                  }}
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.activeInputLableColor,
                                      focused: "focused",
                                    },
                                  }}
                                  InputProps={{
                                    classes: {
                                      root: classes.activeInputColor,
                                      input: classes.resizePrice,
                                      focused: "focused",
                                    },
                                  }}
                                  disabled
                                  value={
                                    item.equipmentType.filter(
                                      typeNumber => typeNumber.activeCheckbox === true
                                        && typeNumber.selected === true
                                    ).length > 0
                                      ? item.equipmentType.filter(
                                        typeNumber => typeNumber.activeCheckbox
                                              === true
                                            && typeNumber.selected === true
                                      )[0].price
                                      : item.equipmentType.filter(
                                        typeNumber => typeNumber.activeCheckbox === true
                                      )[0].price
                                  }
                                  name="totalPrice"
                                  id="totalPrice"
                                />
                              </TableCell>
                              <TableCell>
                                {
                                  unitPriceShow.unitPrice === "" && (
                                    <span style={{
                                      color: "red",
                                      fontWeight: 600,
                                      fontStyle: "italic",
                                    }}>
                                      Invalid value
                                    </span>
                                  )
                                }
                                {
                                  parseFloat(unitPriceShow.Min_Unit_Price.replace(/,/g, "")) > parseFloat(unitPriceShow.unitPrice.replace(/,/g, "")) && (
                                    <span style={{
                                      color: "red",
                                      fontWeight: 600,
                                      fontStyle: "italic",
                                    }}>
                                      The value is too low
                                    </span>
                                  )
                                }
                                {
                                  parseFloat(unitPriceShow.Max_Unit_Price.replace(/,/g, "")) < parseFloat(unitPriceShow.unitPrice.replace(/,/g, "")) && (
                                    <span style={{
                                      color: "red",
                                      fontWeight: 600,
                                      fontStyle: "italic",
                                    }}>
                                      The value is too high
                                    </span>
                                  )
                                }
                              </TableCell>
                            </TableRow>
                          );
                          // }
                        }
                      } else if (item.activeCheckbox && item.selected) {
                        return (
                          <TableRow>
                            {props.formMode && (
                              <TableCell
                                align="left"
                                style={{ padding: "4px", width: "5%" }}
                              >
                                <Checkbox
                                  checked={item.selected}
                                  onChange={e => checkBoxOnChangeOnchange(
                                    e.target,
                                    index,
                                    innerIndex
                                  )}
                                  inputProps={{
                                    "aria-label": "select all desserts",
                                  }}
                                  color="primary"
                                />
                              </TableCell>
                            )}
                            {!props.formMode && (
                              <TableCell
                                align="left"
                                style={{ padding: "4px" }}
                              />
                            )}
                            <TableCell
                              align="left"
                              style={{
                                padding: "4px 8px",
                                fontWeight: "bold",
                                width: "20%",
                              }}
                            >
                              {item?.equipmentName}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ padding: "4px", width: "25%" }}
                            >
                              <TextField
                                className={classes.quaterInputPricing}
                                style={{
                                  width: "85%",
                                }}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.activeInputLableColor,
                                    focused: "focused",
                                  },
                                }}
                                InputProps={{
                                  classes: {
                                    root: classes.activeInputColor,
                                    input: classes.resizePrice,
                                    focused: "focused",
                                  },
                                }}
                                disabled={
                                  (!item.activeCheckbox || !props.formMode) || !item.selected
                                }
                                onChange={e => {
                                  equimentOnchange(
                                    e.target.value,
                                    index,
                                    innerIndex
                                  );
                                }}
                                value={
                                  item.equipmentType.filter(
                                    typeNumber => typeNumber.activeCheckbox === true
                                        && typeNumber.selected === true
                                  ).length > 0
                                    ? item.equipmentType.filter(
                                      typeNumber => typeNumber.activeCheckbox
                                              === true
                                            && typeNumber.selected === true
                                    )[0].equipCode
                                    : item.equipmentType.filter(
                                      typeNumber => typeNumber.activeCheckbox === true
                                    )[0].equipCode
                                }
                                name="name"
                                id="name"
                                select
                              >
                                {item.equipmentType
                                  .filter(i => i.activeCheckbox === true)
                                  .map(subItem => (
                                    <MenuItem
                                      value={subItem.equipCode}
                                      key={subItem.equipmentID}
                                    >
                                      {subItem.name}
                                    </MenuItem>
                                  ))}
                              </TextField>
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ padding: "4px", width: "10%" }}
                            >
                              {props.formMode && (
                                <TextField
                                  className={classes.quaterInputPricing}
                                  style={{ width: "60%" }}
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.activeInputLableColor,
                                      focused: "focused",
                                    },
                                  }}
                                  InputProps={{
                                    classes: {
                                      root: classes.activeInputColor,
                                      input: classes.resizePrice,
                                      focused: "focused",
                                    },
                                  }}
                                  disabled={!item.selected}
                                  onChange={e => {
                                    quantityOnchange(
                                      e.target.value,
                                      index,
                                      innerIndex
                                    );
                                  }}
                                  value={item.equipmentType.filter(
                                    typeNumber => typeNumber.activeCheckbox === true
                                        && typeNumber.selected === true
                                  ).length > 0
                                    ? item.equipmentType.filter(
                                      typeNumber => typeNumber.activeCheckbox
                                              === true
                                            && typeNumber.selected === true
                                    )[0].qty
                                    : item.equipmentType[0].qty}
                                  name="qty"
                                  id="qty"
                                  select
                                >
                                  {showIempMin(
                                    item.equipmentType.filter(typeActive => typeActive.activeCheckbox === true && typeActive.selected)[0]
                                  ).map(number => (
                                    <MenuItem value={number} key={number}>
                                      {number}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              )}
                              {!props.formMode && (
                                <TextField
                                  className={classes.quaterInputPricing}
                                  style={{ width: "60%" }}
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.activeInputLableColor,
                                      focused: "focused",
                                    },
                                  }}
                                  InputProps={{
                                    classes: {
                                      root: classes.activeInputColor,
                                      input: classes.resizePrice,
                                      focused: "focused",
                                    },
                                  }}
                                  disabled={!props.formMode}
                                  value={
                                    item.equipmentType.filter(
                                      typeNumber => typeNumber.activeCheckbox === true
                                          && typeNumber.selected === true
                                    ).length > 0
                                      ? item.equipmentType.filter(
                                        typeNumber => typeNumber.activeCheckbox
                                                === true
                                              && typeNumber.selected === true
                                      )[0].qty
                                      : item.equipmentType[0].qty
                                  }
                                  name="qty"
                                  id="qty"
                                />
                              )}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ padding: "4px", width: "15%" }}
                            >
                              <TextField
                                className={classes.quaterInputPricing}
                                style={{
                                  width: "70%",
                                }}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.activeInputLableColor,
                                    focused: "focused",
                                  },
                                }}
                                InputProps={{
                                  classes: {
                                    root: classes.activeInputColor,
                                    input: classes.resizePrice,
                                    focused: "focused",
                                  },
                                }}
                                disabled
                                value={
                                  item.equipmentType.filter(
                                    typeNumber => typeNumber.activeCheckbox === true
                                        && typeNumber.selected === true
                                  ).length > 0
                                    ? item.equipmentType.filter(
                                      typeNumber => typeNumber.activeCheckbox
                                              === true
                                            && typeNumber.selected === true
                                    )[0].unitPrice
                                    : item.equipmentType.filter(
                                      typeNumber => typeNumber.activeCheckbox === true
                                    )[0].unitPrice
                                }
                                name="unitPrice"
                                id="unitPrice"
                                type="number"
                              />
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ padding: "4px", width: "15%" }}
                            >
                              <TextField
                                className={classes.quaterInputPricing}
                                style={{
                                  width: "70%",
                                }}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.activeInputLableColor,
                                    focused: "focused",
                                  },
                                }}
                                InputProps={{
                                  classes: {
                                    root: classes.activeInputColor,
                                    input: classes.resizePrice,
                                    focused: "focused",
                                  },
                                }}
                                disabled
                                value={
                                  item.equipmentType.filter(
                                    typeNumber => typeNumber.activeCheckbox === true
                                        && typeNumber.selected === true
                                  ).length > 0
                                    ? item.equipmentType.filter(
                                      typeNumber => typeNumber.activeCheckbox
                                              === true
                                            && typeNumber.selected === true
                                    )[0].price
                                    : item.equipmentType.filter(
                                      typeNumber => typeNumber.activeCheckbox === true
                                    )[0].price
                                }
                                name="totalPrice"
                                id="totalPrice"
                              />
                            </TableCell>
                          </TableRow>
                        );
                      }
                    })}
                  </TableBody>
                </Table>
              </StyledTreeItem>
            );
          }
          return "";
        })}
      </TreeView>
    </>
  );
}

PricingEquimentInfo.propTypes = {
  equipmentData: PropTypes.any,
  setEquipmentData: PropTypes.func,
  showSelectedItem: PropTypes.any,
  formMode: PropTypes.bool,
};

export default PricingEquimentInfo;
