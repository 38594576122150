/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable import/extensions */
import React from "react";
import Box from "@material-ui/core/Box";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import InputLabel from "@material-ui/core/InputLabel";
// core components
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DateFnsUtils from "@date-io/date-fns";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useTheme } from "@material-ui/core/styles";
import { Typography, Divider } from "@material-ui/core";
import MenuBookRoundedIcon from "@material-ui/icons/MenuBookRounded";
import DateRangeRoundedIcon from "@material-ui/icons/DateRangeRounded";
import styles from "assets/jss/material-dashboard-react/components/businessForm.js";
import PersonIcon from "@material-ui/icons/Person";
import StayCurrentPortraitIcon from "@material-ui/icons/StayCurrentPortrait";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import MoreHorizRoundedIcon from "@material-ui/icons/MoreHorizRounded";
import LocationCityRoundedIcon from "@material-ui/icons/LocationCityRounded";
import NearMeRoundedIcon from "@material-ui/icons/NearMeRounded";
import RoomIcon from "@material-ui/icons/Room";
import Table from "@material-ui/core/Table";
import IconButton from "@material-ui/core/IconButton";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import TableCell from "@material-ui/core/TableCell";
import StarIcon from "@material-ui/icons/Star";
import AddIcon from "@material-ui/icons/Add";
import StrikethroughSIcon from "@material-ui/icons/StrikethroughS";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import Tooltip from "@material-ui/core/Tooltip";
import { locationCity, countryList } from "../../constant/locationConstant";

const useStyles = makeStyles(styles);
const phoneNumberRegEx =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const OwnerInformation = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const openDob = React.useRef(null);
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const [addressFileType, setAddressFileType] = React.useState("");
  const [photoIdType, setPhotoIdType] = React.useState("");

  const [stateInput, setStateInput] = React.useState(true);

  const handleSSNFocus = (e) => {
    e.preventDefault();
    e.target.select();
  };

  function formatPhoneNumber(v) {
    let r = v.replace(/\D/g, "");
    if (r.length > 11) {
      r = r.replace(/^(\d\d\d)(\d{3})(\d{0,4}).*/, "$1-$2-$3");
      return r;
    }
    if (r.length > 6) {
      r = r.replace(/^(\d\d\d)(\d{3})(\d{0,4}).*/, "$1-$2-$3");
    } else if (r.length > 3) {
      r = r.replace(/^(\d\d\d)(\d{0,3})/, "$1-$2");
    } else {
      r = r.replace(/^(\d*)/, "$1");
    }
    return r;
  }

  // function convertUTCDateToLocalDate(date) {
  //   return [
  //     String(101 + date.getMonth()).substring(1),
  //     String(100 + date.getDate()).substring(1),
  //     String(date.getFullYear())
  //   ].join("/");
  // }
  const form = (
    <div>
      <Grid container className={classes.margin}>
        <Grid item lg={4} sm={6} xs={12}>
          <PersonIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            required
            error={props.validationError.Owner_First_Name}
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            label="First Name"
            value={props.data.Owner_First_Name}
            onChange={(e) =>
              props.handleOwnerChange(e.target.value, "Owner_First_Name")
            }
            helperText={
              props.validationError.Owner_First_Name &&
              "Owner First Name is Required."
            }
            name="Owner_First_Name"
            id="Owner_First_Name"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <PersonIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            className={classes.root}
            error={props.validationError.Owner_Middle_Name}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            label="Middle Name"
            value={props.data.Owner_Middle_Name}
            onChange={(e) =>
              props.handleOwnerChange(e.target.value, "Owner_Middle_Name")
            }
            name="Owner_Middle_Name"
            id="Owner_Middle_Name"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <PersonIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            required
            className={classes.root}
            error={props.validationError.Owner_Last_Name}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              },
              required: true
            }}
            helperText={
              props.validationError.Owner_Last_Name &&
              "Owner Last Name is Required."
            }
            label="Last Name"
            value={props.data.Owner_Last_Name}
            onChange={(e) =>
              props.handleOwnerChange(e.target.value, "Owner_Last_Name")
            }
            name="Owner_Last_Name"
            id="Owner_Last_Name"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <MenuBookRoundedIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            required
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              },
              required: true
            }}
            helperText={
              props.validationError.Owner_Title && "Owner Title is Required."
            }
            label="Title"
            error={props.validationError.Owner_Title}
            value={props.data.Owner_Title}
            onChange={(e) =>
              props.handleOwnerChange(e.target.value, "Owner_Title")
            }
            name="Owner_Title"
            id="Owner_Title"
            select
          >
            <MenuItem className={classes.dialogBoxDropDown} value="Analyst">
              Analyst
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="CEO">
              CEO
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="COO">
              COO
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="CFO">
              CFO
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="CTO">
              CTO
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="Director">
              Director
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="Manager">
              Manager
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="Owner">
              Owner
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="Other">
              Other
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="Supervisor">
              Supervisor
            </MenuItem>
          </TextField>
        </Grid>

        <Grid item lg={4} sm={6} xs={12}>
          <MenuBookRoundedIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            required
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              },
              required: true
            }}
            error={props.validationError.Owner_Type}
            helperText={
              props.validationError.Owner_Type && "Owner Type is Required."
            }
            label="Type"
            value={props.data.Owner_Type}
            onChange={(e) =>
              props.handleOwnerChange(e.target.value, "Owner_Type")
            }
            name="Owner_Type"
            id="Owner_Type"
            select
          >
            <MenuItem
              className={classes.dialogBoxDropDown}
              value="Control Owner"
            >
              Control Owner
            </MenuItem>
            <MenuItem
              className={classes.dialogBoxDropDown}
              value="Partner Owner"
            >
              Partner Owner
            </MenuItem>
            <MenuItem
              className={classes.dialogBoxDropDown}
              value="Primary Owner"
            >
              Primary Owner
            </MenuItem>
          </TextField>
        </Grid>

        <Grid item lg={4} sm={6} xs={12}>
          <StrikethroughSIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            required
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              },
              required: true
            }}
            helperText={
              props.validationError.Owner_Ownership_Percentage &&
              "Owner Percentage(%) of Ownership is Required."
            }
            label="Percentage(%) of Ownership"
            error={props.validationError.Owner_Ownership_Percentage}
            value={props.data.Owner_Ownership_Percentage}
            onChange={(e) =>
              (/(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/.test(
                e.target.value
              ) ||
                e.target.value == "") &&
              props.handleOwnerChange(
                e.target.value,
                "Owner_Ownership_Percentage"
              )
            }
            name="Owner_Ownership_Percentage"
            id="Owner_Ownership_Percentage"
            type="text"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <DateRangeRoundedIcon className={classes.inputBox} fontSize="small" />
          <FormControl className={classes.root}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                clearable
                disabled={props.disabled}
                required
                inputVariant="standard"
                onChange={(e) => {
                  if (e === null) {
                    props.handleOwnerChange(e, "Owner_DOB");
                  } else if (e > new Date()) {
                    props.handleOwnerChange(e, "Owner_DOB");
                  } else {
                    props.handleOwnerChange(
                      moment(e).format("MM/DD/YYYY"),
                      "Owner_DOB"
                    );
                  }
                }}
                value={props.data.Owner_DOB ? props.data.Owner_DOB : null}
                helperText={
                  props.validationError.Owner_DOB &&
                  "Owner DOB is required with atleast 18 years of age."
                }
                error={props.validationError.Owner_DOB}
                label="Date Of Birth"
                id="birthday"
                name="birthday"
                style={{
                  "&.focused": {
                    color: theme.palette.primary.main,
                    fontSize: "14px",
                    fontStyle: "Helvetica, Arial,sans-serif"
                  }
                }}
                maxDate={moment().subtract(18, "years")._d}
                maxDateMessage="Age must be atleast 18 years."
                format="MM/dd/yyyy"
                placeholder="MM/DD/YYYY"
                keyboardIcon={<EventOutlinedIcon fontSize="small" />}
                KeyboardButtonProps={{
                  size: "small"
                }}
                InputProps={{
                  classes: {
                    root: classes.activeInputColor,
                    input: classes.resize,
                    focused: "focused"
                  },
                  endAdornment: (
                    <Tooltip title="cds_DateHelp" placement="top">
                      <IconButton edge="end" size="small">
                        <HelpOutlineOutlinedIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )
                }}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <MenuBookRoundedIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            required
            className={classes.root}
            error={props.validationError.Owner_Last4_SSN}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              },
              required: true
            }}
            inputProps={{
              autoComplete: "off",
              maxLength: 11
            }}
            label="Social Security Number (SSN)"
            // helperText="Enter last four digits of SSN"
            value={props.data.Owner_Last4_SSN}
            onChange={(e) =>
              props.handleOwnerChange(e.target.value, "Owner_Last4_SSN", e)
            }
            helperText={
              props.validationError.Owner_Last4_SSN && "Owner SSN is Required."
            }
            onFocus={(e) => handleSSNFocus(e)}
            name="Owner_Last4_SSN"
            id="Owner_Last4_SSN"
            type="text"
          />
        </Grid>

        <Grid item lg={4} sm={6} xs={12}>
          <HomeRoundedIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            required
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              },
              required: true
            }}
            label="Address 1"
            error={props.validationError.Owner_Street_Address}
            value={props.data.Owner_Street_Address}
            onChange={(e) =>
              props.handleOwnerChange(e.target.value, "Owner_Street_Address")
            }
            helperText={
              props.validationError.Owner_Street_Address &&
              "Owner Address 1 is Required."
            }
            name="Owner_Street_Address"
            id="Owner_Street_Address"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <HomeRoundedIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            label="Address 2"
            value={props.data.Owner_Street_Address2}
            error={props.validationError.Owner_Street_Address2}
            onChange={(e) =>
              props.handleOwnerChange(e.target.value, "Owner_Street_Address2")
            }
            name="Owner_Street_Address2"
            id="Owner_Street_Address2"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <AddIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            required
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              },
              required: true
            }}
            label="Country"
            value={props.data.Owner_Country_Code || ""}
            error={props.validationError.Owner_Country_Code}
            onChange={(e) =>
              props.handleOwnerChange(e.target.value, "Owner_Country_Code")
            }
            helperText={
              props.validationError.Owner_Country_Code &&
              "Owner Country Name is Required."
            }
            name="Owner_Country_Code"
            id="Owner_Country_Code"
            select
          >
            {props.formData.countryTypeList &&
              props.formData.countryTypeList.Country_Data.map((key) => (
                <MenuItem
                  className={classes.dialogBoxDropDown}
                  value={key.Country_Code}
                >
                  {key.Country_Name}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <NearMeRoundedIcon className={classes.inputBox} fontSize="small" />
          {props.data.Owner_Country_Code === "US" ? (
            <TextField
              disabled={props.disabled}
              required
              className={classes.root}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused"
                }
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused"
                },
                required: true
              }}
              label="State"
              error={props.validationError.Owner_State_Code}
              value={props.data.Owner_State_Code}
              onChange={(e) =>
                props.handleOwnerChange(e.target.value, "Owner_State_Code")
              }
              helperText={
                props.validationError.Owner_State_Code &&
                "Owner State Name is Required."
              }
              name="Owner_State_Code"
              id="Owner_State_Code"
              select={stateInput}
            >
              {stateInput &&
                locationCity.map((key) => (
                  <MenuItem
                    className={classes.dialogBoxDropDown}
                    value={key.value}
                  >
                    {key.name}
                  </MenuItem>
                ))}
            </TextField>
          ) : (
            <TextField
              disabled={props.disabled}
              className={classes.root}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused"
                }
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused"
                }
              }}
              label="State"
              error={props.validationError.Owner_State_Code}
              value={props.data.Owner_State_Code}
              onChange={(e) =>
                props.handleOwnerChange(e.target.value, "Owner_State_Code")
              }
              name="Owner_State_Code"
              id="Owner_State_Code"
            />
          )}
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <LocationCityRoundedIcon
            className={classes.inputBox}
            fontSize="small"
          />
          <TextField
            disabled={props.disabled}
            required
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              },
              required: true
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            helperText={
              props.validationError.Owner_City && "Owner City Name is Required."
            }
            label="City"
            error={props.validationError.Owner_City}
            value={props.data.Owner_City}
            onChange={(e) =>
              props.handleOwnerChange(e.target.value, "Owner_City")
            }
            name="Owner_City"
            id="Owner_City"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <RoomIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            required
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              },
              required: true
            }}
            label="Postal Code"
            error={props.validationError.Owner_Postal_Code}
            value={props.data.Owner_Postal_Code}
            onChange={(e) =>
              (/^[0-9]\d*$/g.test(e.target.value) || e.target.value == "") &&
              props.handleOwnerChange(e.target.value, "Owner_Postal_Code")
            }
            helperText={
              props.validationError.Owner_Postal_Code &&
              "Owner Postal Code is Required."
            }
            name="Owner_Postal_Code"
            id="Owner_Postal_Code"
            type="text"
          />
        </Grid>

        <Grid item lg={4} sm={6} xs={12}>
          <RoomIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            label="Postal Code Extension"
            value={props.data.Owner_Postal_Code_Ext}
            error={props.validationError.Owner_Postal_Code_Ext}
            helperText={
              props.validationError.Owner_Postal_Code_Ext &&
              "Owner Postal Code Extension should be max 4 digits."
            }
            onChange={(e) =>
              (/^[0-9]\d*$/g.test(e.target.value) || e.target.value === "") &&
              props.handleOwnerChange(e.target.value, "Owner_Postal_Code_Ext")
            }
            name="Owner_Postal_Code_Ext"
            id="Owner_Postal_Code_Ext"
            type="text"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <StayCurrentPortraitIcon
            className={classes.inputBox}
            fontSize="small"
          />
          <TextField
            disabled={props.disabled}
            required
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            label="Email Address"
            error={props.validationError.Owner_Email}
            value={props.data.Owner_Email}
            onChange={(e) =>
              props.handleOwnerChange(e.target.value, "Owner_Email")
            }
            helperText={
              props.validationError.Owner_Email && "Enter a Valid Email Address"
            }
            name="Owner_Email"
            id="Owner_Email"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <StayCurrentPortraitIcon
            className={classes.inputBox}
            fontSize="small"
          />
          <TextField
            disabled={props.disabled}
            required
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              inputProps: { min: 0, max: 10 },
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            label="Phone"
            error={props.validationError.Owner_Phone}
            value={formatPhoneNumber(props.data.Owner_Phone)}
            helperText={
              props.validationError.Owner_Phone && "Enter a Valid Phone Number"
            }
            onChange={(e) => {
              props.handleOwnerChange(
                formatPhoneNumber(e.target.value),
                "Owner_Phone"
              );
            }}
            name="Owner_Phone"
            id="Owner_Phone"
            type="text"
          />
        </Grid>
      </Grid>
      <>
        {(props.data.ownerAddressProofDetails.File_Name !== "" ||
          props.data.ownerPhotoIdDetails.File_Name !== "") && (
          <Grid item xs={12}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  <TableCell
                    className={classes.cellTextHeaderOffer}
                    align="center"
                  >
                    Document Type
                  </TableCell>
                  <TableCell
                    className={classes.cellTextHeaderOffer}
                    align="center"
                  >
                    Document Type Name
                  </TableCell>
                  <TableCell
                    className={classes.cellTextHeaderOffer}
                    align="center"
                  >
                    Name
                  </TableCell>
                  <TableCell
                    className={classes.cellTextHeaderOffer}
                    align="center"
                  >
                    File Type
                  </TableCell>
                  <TableCell
                    className={classes.cellTextHeaderOffer}
                    align="center"
                  >
                    Upload Time (UTC)
                  </TableCell>
                  {!props.disabled && (
                    <TableCell
                      className={classes.cellTextHeaderOffer}
                      align="center"
                    >
                      Action
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.data.ownerAddressProofDetails.File_Name !== "" && (
                  <TableRow>
                    <TableCell align="center">Address Proof</TableCell>
                    <TableCell align="center">
                      {props.data.ownerAddressProofDetails.Doc_Type}
                    </TableCell>
                    <TableCell align="center" disabled={props.disabled}>
                      <a
                        href={props.data.ownerAddressProofDetails.File_Path}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {props.data.ownerAddressProofDetails.File_Name}
                      </a>
                    </TableCell>
                    <TableCell align="center">
                      {props.data.ownerAddressProofDetails.File_Type}
                    </TableCell>
                    <TableCell align="center">
                      {moment(
                        props.data.ownerAddressProofDetails.Upload_Date_Time
                      )
                        .tz("UTC")
                        .format("MM/DD/YYYY hh:mm:ss A")}
                    </TableCell>
                    {(props.ProviderStatus === "New Entry" ||
                      props.ProviderStatus === "Sent to Merchant" ||
                      (props.kycCheckListFlag &&
                        props.editPermissionFlag &&
                        props.ProviderStatus !== "Live")) && (
                      <TableCell align="center">
                        <IconButton
                          onClick={() =>
                            props.handleDeleteUpload("ownerAddressProof")
                          }
                          disabled={props.disabled}
                          color="secondary"
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                )}

                {props.data.ownerPhotoIdDetails.File_Name !== "" && (
                  <TableRow>
                    <TableCell align="center">Photo ID</TableCell>
                    <TableCell align="center">
                      {props.data.ownerPhotoIdDetails.Doc_Type}
                    </TableCell>
                    <TableCell align="center">
                      <a
                        href={props.data.ownerPhotoIdDetails.File_Path}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {props.data.ownerPhotoIdDetails.File_Name}
                      </a>
                    </TableCell>
                    <TableCell align="center">
                      {props.data.ownerPhotoIdDetails.File_Type}
                    </TableCell>
                    <TableCell align="center">
                      {moment(props.data.ownerPhotoIdDetails.Upload_Date_Time)
                        .tz("UTC")
                        .format("MM/DD/YYYY hh:mm:ss A")}
                    </TableCell>
                    {(props.ProviderStatus === "New Entry" ||
                      props.ProviderStatus === "Sent to Merchant" ||
                      (props.kycCheckListFlag &&
                        props.editPermissionFlag &&
                        props.ProviderStatus !== "Live")) && (
                      <TableCell align="center">
                        <IconButton
                          onClick={() =>
                            props.handleDeleteUpload("ownerPhotoId")
                          }
                          disabled={props.disabled}
                          color="secondary"
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Grid>
        )}
        {props.data.ownerPhotoIdDetails.File_Name === "" && !props.disabled && (
          <Grid
            container
            style={{ flexDirection: windowWidth ? "row" : "column" }}
            className={classes.uploadDocumentBox}
          >
            <Grid item xs={7}>
              <Typography variant="subtitle1" style={{ marginLeft: "10px" }}>
                Photo ID (Driver License, Passport, Gov. issued ID) * (required)
                Maximum file size: 25MB (format: PDF, JPG, PNG)
              </Typography>
            </Grid>
            <Grid
              item
              xs={windowWidth ? 5 : 12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: windowWidth ? "row" : "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  width: "100%"
                }}
              >
                <TextField
                  className={classes.rootDocumentSelect}
                  disabled={props.disabled}
                  InputLabelProps={{
                    classes: {
                      root: classes.activeInputLableColor,
                      focused: "focused"
                    }
                  }}
                  InputProps={{
                    classes: {
                      root: classes.activeInputColor,
                      input: classes.resize,
                      focused: "focused"
                    },
                    required: true
                  }}
                  required
                  label="Document Type"
                  value={props.data.ownerPhotoIdDetails.Doc_Type}
                  // error={!photoIdType && photoIdType !== ""}
                  error={props.validationError.ownerPhotoId}
                  onChange={(e) => {
                    setPhotoIdType(true);
                    props.handleOwnerChange(e.target.value, "ownerPhotoIdType");
                  }}
                  name="Owner_Photo"
                  id="Owner_Photo"
                  select
                  helperText={
                    props.data.ownerPhotoIdDetails.Doc_Type === ""
                      ? "Select Document Type"
                      : ""
                  }
                  SelectProps={{
                    MenuProps: { disableScrollLock: true }
                  }}
                >
                  <MenuItem
                    className={classes.dialogBoxDropDown}
                    value="Driver License"
                  >
                    Driver License
                  </MenuItem>
                  <MenuItem
                    className={classes.dialogBoxDropDown}
                    value="Govt. issued ID"
                  >
                    Govt. Issued ID
                  </MenuItem>
                  <MenuItem
                    className={classes.dialogBoxDropDown}
                    value="Passport"
                  >
                    Passport
                  </MenuItem>
                </TextField>
                <label
                  htmlFor="upload-photoId"
                  style={{ margin: windowWidth ? "0px" : "10px" }}
                >
                  <input
                    style={{ display: "none" }}
                    id="upload-photoId"
                    accept="image/*, application/pdf"
                    name="upload-photoId"
                    required
                    disabled={props.disabled}
                    type={photoIdType ? "file" : "button"}
                    onClick={() => {
                      if (props.data.ownerPhotoIdDetails.Doc_Type) {
                        if (!photoIdType) {
                          setPhotoIdType(true);
                        }
                      } else {
                        setPhotoIdType(false);
                      }
                    }}
                    display="none"
                    onChange={(e) => {
                      if (props.data.ownerPhotoIdDetails.Doc_Type) {
                        props.handleUploadOnChange(
                          e.target.files[0],
                          "ownerPhotoId"
                        );
                      }
                    }}
                  />

                  <Button
                    component="span"
                    variant={!photoIdType ? "outlined" : "contained"}
                    disabled={!photoIdType || props.disabled}
                  >
                    Upload Photo ID
                  </Button>
                </label>
              </div>
            </Grid>
          </Grid>
        )}
      </>
      <Divider style={{ marginTop: "20px", marginBottom: "10px" }} />
    </div>
  );

  return (
    <div>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          padding: "20px",
          position: "relative"
        }}
        className={classes.ownerHeader}
      >
        <div
          style={{
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            alignContent: "center",
            textAlign: "center"
          }}
        >
          <div>
            <Typography className={classes.ownerTittle}>
              <b>{`Owner ${props.index + 1}`}</b>
            </Typography>
          </div>
          <div style={{ marginTop: "20px" }}>
            <Typography variant="caption">
              Provide the following information for each individual who owns,
              directly or indirectly, 25% or more of the equity interest of your
              business or acts as Controller
            </Typography>
          </div>
        </div>

        <div
          style={{
            position: "absolute",
            marginLeft: "89%",
            display: "flex"
          }}
        >
          {(props.kycCheckListFlag ||
            props.ProviderStatus === "New Entry" ||
            props.ProviderStatus === "Sent to Merchant") &&
            props.ProviderStatus !== "Live" &&
            (props.editPermissionFlag || props.createPermissionFlag) && (
              <Tooltip title="Add Owner" aria-label="add">
                <Button
                  className={classes.addIcon}
                  size="sm"
                  disabled={props.declinedApp}
                  aria-label="open drawer"
                  onClick={() => props.add()}
                >
                  <AddIcon />
                </Button>
              </Tooltip>
            )}
          {props.index !== 0 && (
            <Tooltip
              style={{ marginLeft: "10px" }}
              title="Remove"
              aria-label="Remove"
            >
              <Button
                className={classes.deleteIcon}
                aria-label="open drawer"
                disabled={props.declinedApp}
                onClick={() => props.remove()}
              >
                <DeleteOutlineRoundedIcon />
              </Button>
            </Tooltip>
          )}
        </div>
      </Box>
      <Box>{form}</Box>
    </div>
  );
};

OwnerInformation.propTypes = {
  index: PropTypes.any,
  data: PropTypes.object,
  add: PropTypes.func,
  remove: PropTypes.func,
  handleOwnerChange: PropTypes.func,
  validationError: PropTypes.object,
  formData: PropTypes.object,
  handleUploadOnChange: PropTypes.func,
  handleDeleteUpload: PropTypes.func,
  ProviderStatus: PropTypes.bool,
  kycCheckListFlag: PropTypes.bool,
  createPermissionFlag: PropTypes.bool,
  editPermissionFlag: PropTypes.bool,
  disabled: PropTypes.bool
};

const mapStateToProps = (app) => ({
  formData: app.provideRegistration
});
export default connect(mapStateToProps, null)(OwnerInformation);
