import React from "react";
import { createBrowserHistory } from "history";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { Router } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "./modules/payout/assets/style/index.scss";

// core components
// eslint-disable-next-line import/no-unresolved
import "./assets/css/material-dashboard-react.css?v=1.9.0";
import Admin from "./layouts/Admin";

const hist = createBrowserHistory();

let theme = createTheme({
  palette: {
    secondary: {
      main: "#00acc1",
      light: "#6aaac8", // graph color
      contrastText: "#29586e", // graph color
      dark: "#8ec8d1" // graph colot
    },
    tableColor: {
      header: "#964d4e",
      borderTop: "#0d1619",
      paggination: "#3e5715",
      clickable: "#481557",
      cellDiscription: "#6aaac8",
      cellFontColor: "#000000",
      tableHeaderTextColor: "#ffffff"
    },
    offerDetails: {
      offerDetailsHeaderCardColor1: "#81ca90",
      offerDetailsHeaderCardColor2: "#6ba376",
      optionPatientName: "#144b8e",
      activeTab: "#fafafa",
      activeTabBorderColor: "#bfbfbf",
      inActiveBorderColor: "#bfbfbf",
      inActiveTab: "#dcdcdc"
    },
    primary: {
      main: "#00acc1",
      light: "#bfbdbd",
      dark: "#696969", // gray color
      contrastText: "#fff" // white color
    },
    error: {
      main: "#ff4747",
      light: "#",
      dark: "#",
      contrastText: "#"
    },
    warning: {
      main: "#",
      light: "#",
      dark: "#",
      contrastText: "#"
    },
    info: {
      main: "#4da189",
      light: "#f5b058",
      dark: "#354dd4",
      contrastText: "#b8fcf9" // icon Box color
    },
    success: {
      main: "#228B22",
      light: "#",
      dark: "#",
      contrastText: "#144b8e"
    }
  },
  overrides: {
    MuiTypography: {
      body2: {
        fontSize: [16, "!important"]
      }
    }
  }
});

const AppView = (props) => {
  const [themeChange, setThemeChange] = React.useState(props.app.appTheme);
  const [defaultTheme, setDefaultTheme] = React.useState(
    props.app.defaultTheme
  );
  React.useEffect(() => {
    if (
      themeChange !== props.app.appTheme &&
      props.app.appTheme &&
      localStorage.getItem("token")
    ) {
      const themeObj = props.app.appTheme[0];
      delete themeObj.Theme_Name;
      delete themeObj._id;
      themeObj.error.main = "#ff4747";
      theme = createTheme({
        palette: themeObj,
        overrides: {
          MuiTypography: {
            subtitle1: {
              fontSize: [14, "!important"],
              fontStyle: "italic",
              textTransform: "none",
              color: "#a53789"
            },
            body1: {
              fontSize: [14, "!important"],
              lineHeight: 1.2
            },
            body2: {
              fontSize: [14, "!important"]
            },
            h1: {
              fontWeight: "bold",
              textTransform: "none",
              fontSize: [15, "!important"]
            }
          },
          MuiAutocomplete: {
            input: {
              fontSize: "14px",
              color: "#696969"
            }
          },

          MuiButton: {
            contained: {
              backgroundColor: themeObj.primary.main,
              height: "unset",
              color: themeObj.primary.contrastText,
              "&:hover": {
                backgroundColor: themeObj.primary.light,
                color: "#3c485"
              },
              textTransform: "capitalize",
              borderRadius: "3px",
              height: "32px"
            },
            outlined: { textTransform: "capitalize" }
          },
          MuiTableSortLabel: {
            root: {
              color: "white",
              "&:hover": {
                color: "white"
              }
            },

            active: {
              color: "white !important",
              "&:hover": {
                color: "white"
              }
            },
            iconDirectionDesc: {
              color: "white !important",
              "&:hover": {
                color: "white"
              }
            },
            iconDirectionAsc: {
              color: "white !important",
              "&:hover": {
                color: "white"
              }
            }
          }
        }
      });
      setThemeChange(props.app.appTheme);
    }
    if (
      defaultTheme !== props.app.defaultTheme &&
      props.app.defaultTheme.data
    ) {
      const themeObj = props.app.defaultTheme.data[0];
      delete themeObj.Theme_Name;
      const logoImage = themeObj.Logo_Image;
      delete themeObj.Logo_Image;
      delete themeObj._id;
      themeObj.error.main = "#ff4747";
      theme = createTheme({
        palette: themeObj,
        overrides: {
          MuiTypography: {
            body1: {
              fontSize: [14, "!important"],
              lineHeight: 1.2
            },
            subtitle1: {
              fontStyle: "italic",
              textTransform: "none",
              fontSize: [14, "!important"],
              color: "#a53789"
            },
            body2: {
              fontSize: [14, "!important"]
            },
            h1: {
              fontWeight: "bold",
              textTransform: "none",
              fontSize: [15, "!important"],
              color: themeObj.primary.dark
            }
          },
          MuiButton: {
            contained: {
              backgroundColor: themeObj.primary.main,
              height: "unset",
              color: themeObj.primary.contrastText,
              "&:hover": {
                backgroundColor: themeObj.primary.light,
                color: "#3c485"
              },
              textTransform: "capitalize",
              borderRadius: "3px"
            },
            outlined: { textTransform: "capitalize" }
          },
          MuiTableSortLabel: {
            root: {
              color: "white !important",
              "&:hover": {
                color: "white !important"
              }
            },
            active: {
              color: "white !important",
              "&:hover": {
                color: "white !important"
              }
            }
          }
        }
      });
      themeObj.Logo_Image = logoImage;
      setDefaultTheme(props.app.defaultTheme);
    }
  }, [props]);
  return (
    <MuiThemeProvider theme={theme}>
      <Router history={hist}>
        <Admin history={hist} />
      </Router>
    </MuiThemeProvider>
  );
};

AppView.propTypes = {
  app: PropTypes.object
};

const mapStateToProps = (app) => ({
  app: app.app
});

export default connect(mapStateToProps, null)(AppView);
