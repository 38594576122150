import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import WarningIcon from "@material-ui/icons/Warning";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Button from "@material-ui/core/Button";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import FilterFields from "./FilterFields";
import SearchFields from "./SearchFields";
import SearchedChips from "../../../../reports/components/SearchChips";
import tableStyle from "../../../components/CustomizeTable/style";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CustomTable from "../../../components/CustomizeTable/CustomTable";
import { TablePageData } from "../../../utils/constants";
import LongMenu from "./LongMenu";
import {
  payout_calculation_summary_list,
  delete_payout_calculation,
  freeze_payout_calculation
} from "../../../actions/PayoutCalculation.action";
import { calculationExportToCSV } from "../../../utils/common";
import CoustomButton from "../../../../../components/CustomButtons/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { fetchProcessorList } from "../../../actions/pricingSchedule.action";
import Grid from "@material-ui/core/Grid";

import {
  freezeSummaryPermission,
  viewSummaryPermission,
  deleteSummaryPermission,
  exportSummaryPermission
} from "../permission";
import LastCalculationDialog from "modules/payout/components/LastCalculationDialog/LastCalculationDialog";

const headCellsItems = [
  {
    id: "ProcessorID",
    label: "Processor",
    isSort: true,
    sortProperty: "ProcessorID",
    actionsCellStyle: "center"
  },
  {
    id: "ResidualMonth",
    label: "Residual Month",
    isSort: true,
    sortProperty: "ResidualMonth",
    actionsCellStyle: "center"
  },
  {
    id: "Type",
    label: "Calculation Type",
    isSort: true,
    sortProperty: "Type",
    actionsCellStyle: "center"
  },
  {
    id: "StartDate",
    label: "Start Date",
    isSort: true,
    sortProperty: "StartDate",
    actionsCellStyle: "center"
  },
  {
    id: "EndDate",
    label: "End Date",
    isSort: true,
    sortProperty: "EndDate",
    actionsCellStyle: "center"
  },
  {
    id: "Status",
    label: "Status",
    isSort: true,
    sortProperty: "Status",
    actionsCellStyle: "center"
  },
  {
    id: "No_Of_Merch",
    label: "Merchants",
    isSort: false,
    sortProperty: "No_Of_Merch",
    actionsCellStyle: "center"
  },
  {
    id: "PrevCalc",
    label: "Iteration",
    isSort: false,
    sortProperty: "PrevCalc",
    actionsCellStyle: "center"
  },
  {
    id: "action",
    label: "",
    isSort: false,
    sortProperty: "action",
    actionsCellStyle: "right"
  }
];

const labelName = {
  ProcessorID: "Processor",
  fromMonth: "Date From",
  toMonth: "Date To"
};
const CalculationSummary = ({
  payout_calculation_summary_list,
  delete_payout_calculation,
  freeze_payout_calculation,
  setShowHistoryTab,
  setSelectedRow,
  handleChange,
  fetchProcessorList,
  roles,
  users
}) => {
  const searchInitialData = {
    ProcessorID: "",
    fromMonth: "",
    toMonth: ""
  };

  const useStyles = makeStyles(tableStyle);
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = React.useState(1);
  const [initialCall, setInitialCall] = React.useState(true);
  const [order, setOrder] = React.useState("desc");
  const [pageDetails, setPageDetails] = React.useState({ ...TablePageData });
  const [orderBy, setOrderBy] = React.useState("ResidualMonth");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [processorList, setProcessorList] = React.useState([]);
  const [searchValues, setSearchValues] = React.useState({
    ...searchInitialData
  });
  const [chipValue, setChipValue] = React.useState({
    ...searchInitialData
  });
  const [calculationList, setCalculationList] = React.useState([]);
  const [searchBar, setSearchBar] = React.useState(false);
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
  const [freezeAlertOpen, setFreezeAlertOpen] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const [selectedID, setSelectedID] = React.useState(false);
  const [payoutMonth, setPayoutMonth] = React.useState(false);
  const [agentsList, setAgentsList] = React.useState([]);

  const handleSearchClose = () => {
    setSearchBar(false);
  };
  const handleSearchOpen = () => {
    setSearchBar(true);
  };
  const handleChangeRowsPerPage = (event) => {
    setPage(1);
    setRowsPerPage(event.target.value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleClearSearch = () => {
    setSearchValues({ ...searchInitialData });
    setChipValue({ ...searchInitialData });
    calculationSummaryListCall({
      ...searchInitialData,
      sortColumn: orderBy,
      sortOrder: order,
      recordPerPage: rowsPerPage,
      pageNo: page
    });
    handleSearchClose();
  };

  const handleSubmitSearch = () => {
    handleSearchClose();
    setPage(1);

    calculationSummaryListCall({
      ...searchValues
    });
    setChipValue({
      ...searchValues
    });
  };

  const getProcessorList = () => {
    fetchProcessorList(
      (data) => {
        if (data.status) {
          setProcessorList(data.data);
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
      }
    );
  };

  const calculationSummaryListCall = function (searchValues) {
    const payload = {
      ...searchValues,
      sortColumn: orderBy,
      sortOrder: order,
      recordPerPage: rowsPerPage.value === 0 ? "" : rowsPerPage,
      pageNo: page
    };
    payout_calculation_summary_list(
      payload,
      (res) => {
        if (res.status == "success") {
          setCalculationList(res);
          setPageDetails({
            ...pageDetails,
            lastPage: Math.ceil(res.totalCount / rowsPerPage),
            from: page === 1 ? 1 : (page - 1) * rowsPerPage + 1,
            to:
              page * rowsPerPage < res.totalCount
                ? page * rowsPerPage
                : res.totalCount,
            total: res.totalCount
          });
        }
      },
      (err) => {
        enqueueSnackbar("An error occurred.", {
          variant: "error"
        });
      }
    );
  };

  React.useState(() => {
    if (isEmpty(processorList)) {
      getProcessorList();
    }
  }, []);

  React.useEffect(() => {
    let filterData = { ...searchValues };
    if (users.payout.lastCalcDate && users.payout.lastCalcDate.data) {
      filterData.ProcessorID =
        searchValues.ProcessorID != ""
          ? searchValues.ProcessorID
          : users.payout.lastCalcDate.data[0].ProcessorID;
      setSearchValues({
        ...filterData
      });
      setChipValue({
        ...filterData
      });
      calculationSummaryListCall(filterData);
    }
  }, [orderBy, order, page, rowsPerPage, users.payout.lastCalcDate]);

  const exportedBy =
    users.users.userDetailsById &&
    users.users.userDetailsById?.Users_Data[0].Email;
  const exportedByFname =
    users.users.userDetailsById &&
    users.users.userDetailsById?.Users_Data[0].FName;
  const exportedByLname =
    users.users.userDetailsById &&
    users.users.userDetailsById?.Users_Data[0].LName;

  const findProcessorName = (id) => {
    if (!isEmpty(processorList)) {
      const name = processorList.find((x) => x.ProcessorID == id);
      return name ? name.ProcessorName : id;
    }
  };

  const processorName = React.useMemo(
    () => findProcessorName(searchValues.ProcessorID),
    [searchValues.ProcessorID, processorList]
  );

  const exportListData = () => {
    if (!isEmpty(calculationList.data)) {
      const mappedLogs = calculationList.data.map((row) => ({
        "Processor Name": row.ProcessorName,
        "Residual Month": row.ResidualMonth,
        "Calculation Type": row.Type,
        "Start Date": row.StartDate,
        "End Date": row.EndDate,
        Status: row.Status,
        Merchants: row.No_Of_Merch,
        "No Of Iteration": row.PrevCalc
      }));
      calculationExportToCSV(
        mappedLogs,
        "Calculation Summary List",
        chipValue,
        exportedBy,
        exportedByFname,
        exportedByLname,
        labelName,
        processorName
      );
    }
  };

  const handleDeleteChip = (chip) => {
    setPage(1);
    if (chipValue) {
      const temp = { ...searchValues };
      if (chip == "toMonth") {
        temp.toMonth = "";
        temp.fromMonth = "";
      }
      temp[chip] = "";
      setSearchValues({ ...temp });
      setChipValue({ ...temp });
      calculationSummaryListCall({ ...temp });
    }
  };

  const confirmDeleteRow = () => {
    const data = {
      calcId: selectedID
    };
    delete_payout_calculation(
      data,
      (res) => {
        if (res.status == "success") {
          enqueueSnackbar(`${res.message}`, {
            variant: "success"
          });
          calculationSummaryListCall();
          setErrorAlertOpen(false);
        } else if (res.status == "error") {
          setErrorMsg(res.message);
          setErrorAlertOpen(true);
        }
      },
      (err) => {
        setErrorMsg(err.message);
        setErrorAlertOpen(true);
      }
    );
    setSelectedID(false);
    setAlertOpen(false);
  };

  const DeleteRowByID = () => {
    const handleAlertClose = () => {
      setAlertOpen(false);
      setSelectedID(false);
    };

    return (
      <Dialog
        open={alertOpen}
        onClose={handleAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ textAlign: "center" }}>
          <WarningIcon
            style={{ color: "orange", width: "80px", height: "80px" }}
          />
          <DialogContentText id="alert-dialog-description">
            Do you want to delete the payout calculation for :{" "}
            <p style={{ margin: "0px", fontWeight: "bold" }}>{payoutMonth}</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button onClick={handleAlertClose} variant="contained">
            No
          </Button>
          <Button
            onClick={() => confirmDeleteRow()}
            color="primary"
            variant="contained"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const ErrorDialog = () => {
    const isAlertClose = () => {
      setErrorAlertOpen(false);
      setErrorMsg(false);
    };

    return (
      <Dialog
        open={errorAlertOpen}
        onClose={isAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ textAlign: "center" }}>
          <ErrorOutlineIcon
            style={{ color: "orange", width: "100px", height: "100px" }}
          />
          <DialogContentText id="alert-dialog-description">
            {errorMsg
              ? errorMsg
              : "This selected Month/Year calculation is already freezed by Admin. You can't recalculate it. Please contact to Administrator."}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button onClick={isAlertClose} color="primary" variant="contained">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const showHistory = (id) => {
    if (history.location.pathname.includes("CalculationSummary")) {
      history.push(`CalculationHistory?calcId=${id}`);
    } else {
      setShowHistoryTab(true);
      setSelectedRow(id);
      handleChange("", 2);
    }
  };

  const freezeRowFunction = () => {
    const data = {
      calcId: selectedID
    };
    freeze_payout_calculation(
      data,
      (res) => {
        if (res.status == "success") {
          enqueueSnackbar(`${res.message}`, {
            variant: "success"
          });
          setErrorAlertOpen(false);
          calculationSummaryListCall();
        } else if (res.status == "error") {
          setErrorMsg(res.message);
          setErrorAlertOpen(true);
        }
      },
      (err) => {
        setErrorMsg(err.message);
        setErrorAlertOpen(true);
      }
    );
    setSelectedID(false);
    setFreezeAlertOpen(false);
  };

  const FreezeRowAlert = () => {
    const handleAlertClose = () => {
      setFreezeAlertOpen(false);
      setSelectedID(false);
    };

    return (
      <Dialog
        open={freezeAlertOpen}
        onClose={handleAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ textAlign: "center" }}>
          <WarningIcon
            style={{ color: "orange", width: "80px", height: "80px" }}
          />
          <DialogContentText id="alert-dialog-description">
            Do you want to freeze the payout calculation for :{" "}
            <p style={{ margin: "0px", fontWeight: "bold" }}>{payoutMonth}</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button onClick={handleAlertClose} variant="contained">
            No
          </Button>
          <Button
            onClick={() => freezeRowFunction()}
            color="primary"
            variant="contained"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <TableContainer
      className={classes.TableContainer}
      component={Paper}
      style={{ borderRadius: "unset" }}
    >
      {windowWidth && (
        <Grid
          container
          className={classes.searchContainer}
          style={{
            flexWrap: "nowrap",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <Grid item className={classes.margin}>
            <LastCalculationDialog />
          </Grid>
          <Grid item className={classes.margin}>
            <div className={classes.searchWrapper}>
              <SearchFields
                searchBar={searchBar}
                handleSearchClose={handleSearchClose}
                handleSearchOpen={handleSearchOpen}
                Fields={() => {
                  return (
                    <FilterFields
                      handleSearchClose={() => handleSearchClose()}
                      searchValues={searchValues}
                      setSearchValues={setSearchValues}
                      processorList={processorList}
                      handleClearSearch={handleClearSearch}
                      handleSubmitSearch={handleSubmitSearch}
                    />
                  );
                }}
              />
              {roles &&
                exportSummaryPermission(roles.Roles_Data.Master_Permission) && (
                  <CoustomButton
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={exportListData}
                    variant="contained"
                    className={classes.ExportButtonStyle}
                  >
                    Export
                  </CoustomButton>
                )}
            </div>
          </Grid>
        </Grid>
      )}
      {!windowWidth && (
        <Grid container className={classes.searchContainer}>
          <Grid item style={{ flexWrap: "nowrap" }}>
            <LastCalculationDialog />
          </Grid>

          <Grid item className={classes.expirtItemGrid}>
            <SearchFields
              searchBar={searchBar}
              handleSearchClose={handleSearchClose}
              handleSearchOpen={handleSearchOpen}
              Fields={() => {
                return (
                  <FilterFields
                    handleSearchClose={() => handleSearchClose()}
                    searchValues={searchValues}
                    setSearchValues={setSearchValues}
                    processorList={processorList}
                    handleClearSearch={handleClearSearch}
                    handleSubmitSearch={handleSubmitSearch}
                  />
                );
              }}
            />
            {roles &&
              exportSummaryPermission(roles.Roles_Data.Master_Permission) && (
                <CoustomButton
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={exportListData}
                  variant="contained"
                  color="primary"
                  className={classes.buttonStyle}
                >
                  Export
                </CoustomButton>
              )}
          </Grid>
        </Grid>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          className={classes.clearButton}
          onClick={() => history.goBack()}
        >
          Back
        </Button>
        <SearchedChips
          handleDeleteChip={handleDeleteChip}
          searchValues={chipValue}
          labelName={labelName}
          processorName={processorName}
        />
      </div>
      <ErrorDialog />
      <DeleteRowByID />
      <FreezeRowAlert />
      <CustomTable
        page={page}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        data={!isEmpty(calculationList) && calculationList.data}
        pageDetails={pageDetails}
        headCells={headCellsItems}
        isCallInitialization={initialCall}
        selected={[]}
        setSelected={() => {}}
        isSelection={false}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        totalCount={!isEmpty(calculationList) && calculationList.totalCount}
      >
        {!isEmpty(calculationList) &&
          calculationList.data.map((row) => {
            return (
              <TableRow className={classes.cellHeadSign} key={row.ID}>
                <TableCell className={classes.cellText} align="center">
                  {row.ProcessorName}
                </TableCell>
                <TableCell className={classes.cellText} align="center">
                  {row.ResidualMonth}
                </TableCell>
                <TableCell className={classes.cellText} align="center">
                  {row.Type}
                </TableCell>
                <TableCell className={classes.cellText} align="center">
                  {row.StartDate}
                </TableCell>
                <TableCell className={classes.cellText} align="center">
                  {row.EndDate}
                </TableCell>
                <TableCell className={classes.cellText} align="center">
                  {row.Status}
                </TableCell>
                <TableCell className={classes.cellText} align="center">
                  {row.No_Of_Merch}
                </TableCell>
                <TableCell className={classes.cellText} align="center">
                  {row.PrevCalc}
                </TableCell>
                <TableCell className={classes.cellText} align="right">
                  <LongMenu
                    report={
                      !history.location.pathname.includes("PayoutCalculation")
                    }
                    deleteRow={() => {
                      if (row.Is_Calc_Freezed == 0) {
                        setSelectedID(row.ID);
                        setPayoutMonth(row.ResidualMonth);
                        setAlertOpen(true);
                      } else {
                        setErrorAlertOpen(true);
                      }
                    }}
                    showHistory={() => showHistory(row.ID)}
                    freezeSummary={() => {
                      setSelectedID(row.ID);
                      setPayoutMonth(row.ResidualMonth);
                      setFreezeAlertOpen(true);
                    }}
                    roles={roles}
                    calcFreeze={row.Is_Calc_Freezed}
                  />
                </TableCell>
              </TableRow>
            );
          })}
      </CustomTable>
    </TableContainer>
  );
};
CalculationSummary.propTypes = {
  payout_calculation_summary_list: PropTypes.func,
  delete_payout_calculation: PropTypes.func,
  setShowHistoryTab: PropTypes.func,
  setSelectedRow: PropTypes.func,
  handleChange: PropTypes.func,
  fetchProcessorList: PropTypes.func,
  freeze_payout_calculation: PropTypes.func,
  users: PropTypes.object
};

CalculationSummary.defaultProps = {
  payout_calculation_summary_list: () => {},
  delete_payout_calculation: () => {},
  freeze_payout_calculation: () => {},
  setShowHistoryTab: () => {},
  setSelectedRow: () => {},
  handleChange: () => {},
  fetchProcessorList: () => {}
};

const mapStateToProps = (app) => ({
  roles: app.roles.roleTab,
  users: app
});

export default connect(mapStateToProps, {
  payout_calculation_summary_list,
  delete_payout_calculation,
  fetchProcessorList,
  freeze_payout_calculation
})(CalculationSummary);
