import { get, post } from "../api_interceptor/httpClient";
import toggleLoaderAction from "../../../Redux/Actions/loader";
import {
  GET_ADJUSTMENT_LIST,
  CREATE_ADJUSTMENT,
  ADJUSTMENT_NAME_LIST
} from "../api_interceptor/urls";

export const getAdjustmentList =
  (payload, successCallback, failureCallback) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await get(GET_ADJUSTMENT_LIST, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };

export const getAdjustmentDetailsByID =
  (payload, successCallback, failureCallback) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await get(ADJUSTMENT_NAME_LIST, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };

export const getAdjustmentDetails =
  (successCallback, failureCallback, search) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    let finalUrl = ADJUSTMENT_NAME_LIST;
    if (search) {
      finalUrl += "?search=1";
    }
    try {
      const data = await get(finalUrl);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };

export const createAdjustment =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await post(CREATE_ADJUSTMENT, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };
