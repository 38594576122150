import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import { connect } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { Button, Grid, InputLabel, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MenuItem from "@material-ui/core/MenuItem";
import DateFnsUtils from "@date-io/date-fns";
import { Field, Form, Formik, ErrorMessage } from "formik";
import {
  createTheme,
  ThemeProvider,
  makeStyles
} from "@material-ui/core/styles";
import isEmpty from "lodash/isEmpty";
import Checkbox from "@material-ui/core/Checkbox";
import styles from "assets/jss/material-dashboard-react/components/userForm";
import { valuesIn } from "lodash";
import { getQueryParams } from "../../utils/common";
import { fetchAgentList } from "../../actions/agentPayout.action";
import { fetchProcessorList } from "../../actions/pricingSchedule.action";
import {
  getAdjustmentDetails,
  getAdjustmentDetailsByID,
  createAdjustment
} from "../../actions/adjustment.actions";
import {
  fetchMappedMerchantsListAll,
  fetchMappedMerchantsList
} from "../../actions/merchantMapping.action";
import Validations from "../../utils/validations";
import CoustomButton from "../../../../components/CustomButtons/Button";
import tableStyle from "../../components/CustomizeTable/style";

const theme = createTheme({
  primary: {
    main: "#2ba992"
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        background: "#F9FAFF 0% 0% no-repeat",
        borderRadius: "6px",
        height: "100%"
      },
      input: {
        padding: "14px 14px",
        "&.Mui-disabled": {
          color: "rgba(0, 0, 0, 0.26) !important",
          boxShadow: "none !important",
          backgroundColor: "#f1f1f199 !important"
        }
      }
    },
    MuiFormControl: {
      root: {
        // background: "#F9FAFF 0% 0% no-repeat",
        borderRadius: "6px"
        // maxWidth: "220px",
      }
    },
    MuiPickersToolbar: {
      backgroundColor: "#2ba992",
      toolbar: {
        backgroundColor: "#3ab499"
      }
    }
  }
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={2} pb={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const useStyles = makeStyles(styles);
const useBtnStyles = makeStyles(tableStyle);

const AdjustmentAddEdit = ({
  fetchProcessorList,
  getAdjustmentDetails,
  createAdjustment,
  fetchAgentList,
  fetchMappedMerchantsList,
  fetchMappedMerchantsListAll,
  getAdjustmentDetailsByID
}) => {
  const initialAdjustmentDetails = {
    ProcessorID: getQueryParams("processorId") || "",
    Adjustment_Name: "",
    ProcessorName: "",
    MID: "",
    Description: "",
    Amount: "",
    List_ID: "",
    ProfileID: getQueryParams("mid") || "",
    DBAName: "",
    StartMonth: "",
    Is_Calc_Freezed: 0,
    StartYear: "",
    StartDate: getQueryParams("fromMonth") || null,
    EndDate: getQueryParams("toMonth") || null,
    EndMonth: "",
    EndYear: "",
    Forever: false,
    Adj_ID: "",
    Status: "1"
  };
  const ADJUSTMENT_ID = getQueryParams("adjId");
  const PROCESSOR_ID = getQueryParams("processorId");
  const PAY_MID = getQueryParams("mid");
  const EditFlag = ADJUSTMENT_ID;
  const Add_Adjustment_Flag = PROCESSOR_ID && PAY_MID;
  const history = useHistory();
  const classes = useStyles();
  const btnClasses = useBtnStyles();
  const { enqueueSnackbar } = useSnackbar();
  const formikRef = React.useRef(null);
  const [initialValues, setInitialValues] = React.useState({
    ...initialAdjustmentDetails
  });
  const [dbaNameList, setDbaNameList] = React.useState([]);
  const [lastCalculationDate, setLastCalculationDate] = React.useState("");
  const [adjustmentNameList, setAdjustmentNameList] = React.useState([]);
  const [processorList, setProcessorList] = React.useState([]);
  const [selectedAdjustment, setSelectedAdjustment] = React.useState(
    EditFlag ? ADJUSTMENT_ID : ""
  );
  const [selectedAdjustmentValue, setSelectedAdjustmentValue] =
    React.useState(false);
  const [agentsList, setAgentsList] = React.useState([]);
  const [merchantList, setMerchantList] = React.useState([]);

  const getAllAdjustmentNameList = () => {
    getAdjustmentDetails(
      (data) => {
        if (data.status == "success") {
          setAdjustmentNameList(data.data);
        }
      },
      (err) => {}
    );
  };

  const getProcessorList = () => {
    fetchProcessorList(
      (data) => {
        if (data.status == "success") {
          setProcessorList(data.data);
        }
      },
      (err) => {}
    );
  };

  const getFieldsDetails = () => {
    let payload = "";
    if (EditFlag) {
      payload = {
        Adj_ID: selectedAdjustment
      };
      getAdjustmentDetailsByID(
        payload,
        (data) => {
          if (data.status == "success") {
            callForDbaNameList(data.editData[0].ProfileID, data);
            setInitialValues(data.editData[0]);
            setLastCalculationDate(data.lastCalculation);
          }
        },
        (err) => {}
      );
    }
  };

  const addEditAdjustmentForm = (values) => {
    const payload = {
      ProcessorID: values.ProcessorID,
      Adjustment_Name: values.Adjustment_Name,
      List_ID: values.List_ID,
      Description: values.Description,
      Amount: values.Amount,
      ProfileID: values.ProfileID,
      MID: values.MID,
      StartDate: moment(values.StartDate).format("YYYY-MM-DD"),
      EndDate: values.Forever
        ? ""
        : moment(values.EndDate).format("YYYY-MM-DD"),
      StartMonth: 1 + moment(values.StartDate).month(),
      StartYear: moment(values.StartDate).year(),
      EndMonth: values.Forever ? "" : 1 + moment(values.EndDate).month(),
      EndYear: values.Forever ? "" : moment(values.EndDate).year(),
      Forever: values.Forever ? 1 : 0,
      Adj_ID: EditFlag ? values.Adj_ID : null,
      Status: values.Status,
      CreatedBy: isEmpty(EditFlag) ? localStorage.getItem("userId") : "",
      UpdatedBy: localStorage.getItem("userId")
    };
    createAdjustment(
      payload,
      (data) => {
        if (data.status && data.status !== "error") {
          enqueueSnackbar(`${data.message}`, {
            variant: "success"
          });
          history.push("/admin/Adjustment");
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
      }
    );
  };

  const getAgentList = (agentName = "") => {
    fetchAgentList(
      (data) => {
        if (data.status || data.status === "success") {
          if (
            (data.message === "No record found" && !isEmpty(agentsList)) ||
            isEmpty(data.data)
          ) {
            setAgentsList([
              {
                UserID: "919863231596669598",
                AgentName: "No record found"
              }
            ]);
          }
          if (data.data) {
            setAgentsList(() => [...data.data]);
          }
        }
      },
      (err) => {},
      { search: agentName }
    );
  };

  const getPayoutList = function () {
    fetchMappedMerchantsListAll(
      (records) => {
        if (records.status == "success") {
          setMerchantList(records.data);
        }
      },
      () => {}
    );
  };

  React.useEffect(() => {
    getProcessorList();
    getAllAdjustmentNameList();
    if (isEmpty(agentsList)) {
      getAgentList();
    }
    getPayoutList();
    getFieldsDetails();
    if (initialAdjustmentDetails.ProfileID !== "") {
      callForDbaNameList(initialAdjustmentDetails.ProfileID);
    }
  }, []);

  const handleBack = () => {
    history.goBack();
  };

  const callForDbaNameList = (e) => {
    fetchMappedMerchantsList(
      { userId: e },
      (records) => {
        if (records.status === "success") {
          setDbaNameList(
            records.data.sort((a, b) => a.DBAName.localeCompare(b.DBAName))
          );
        } else {
        }
      },
      () => {
        enqueueSnackbar("API Request Failed", {
          variant: "error"
        });
      }
    );
  };

  const defaultProps = {
    options: dbaNameList && dbaNameList
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Box className="agent-payout-container">
          <Box mb={4} className="agent-payout-addNewProfile">
            <Grid className="custflex-dc" container>
              <Formik
                enableReinitialize
                innerRef={(r) => {
                  formikRef.current = r;
                }}
                onSubmit={addEditAdjustmentForm}
                initialValues={{ ...initialValues, EditFlag }}
                validationSchema={Validations.addNewAdjustment}
              >
                {({ values, setFieldValue, resetForm, errors, touched }) => (
                  <>
                    <Form>
                      <Grid
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginBottom: "40px"
                        }}
                        container
                        item
                      >
                        <Grid item>
                          <Typography variant="h5">
                            <b>
                              {EditFlag
                                ? `Adjustment - (${initialValues.Adjustment_Name})`
                                : "New Adjustment"}
                            </b>
                          </Typography>
                        </Grid>
                        {EditFlag && (
                          <Grid item>
                            <Typography>
                              Last Calculation Date:{" "}
                              <span style={{ color: "#0093c9" }}>
                                {lastCalculationDate}
                              </span>
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                      <Grid
                        spacing={4}
                        container
                        className="custflex-dr-aic"
                        item
                      >
                        {/* adddd code */}
                        <Grid spacing={4} container item>
                          <Grid xs={12} sm={12} md={6} lg={6} container item>
                            <Field
                              as={TextField}
                              disabled={values.Is_Calc_Freezed === 1}
                              required
                              error={
                                touched.List_ID &&
                                errors.List_ID &&
                                !selectedAdjustmentValue
                              }
                              className={classes.halfInputAdjustment}
                              InputLabelProps={{
                                classes: {
                                  root: classes.activeInputLableColor,
                                  focused: "focused"
                                }
                              }}
                              InputProps={{
                                color: "secondary",
                                classes: {
                                  root: classes.activeInputColor,
                                  input: classes.resize,
                                  focused: "focused"
                                }
                              }}
                              label="Adjustment Name"
                              name="List_ID"
                              id="List_ID"
                              select
                              onChange={(e) => {
                                setSelectedAdjustmentValue(e.target.value);
                                setFieldValue("List_ID", e.target.value);
                              }}
                              helperText={
                                touched.List_ID &&
                                errors.List_ID &&
                                !selectedAdjustmentValue
                                  ? errors.List_ID
                                  : ""
                              }
                            >
                              <MenuItem value={-1}>Others</MenuItem>
                              {adjustmentNameList &&
                                adjustmentNameList
                                  .sort((a, b) =>
                                    a.Adjustment_Name.localeCompare(
                                      b.Adjustment_Name
                                    )
                                  )
                                  .map((key) => (
                                    <MenuItem
                                      value={key.List_ID}
                                      key={key.List_ID}
                                    >
                                      {key.Adjustment_Name}
                                    </MenuItem>
                                  ))}
                            </Field>
                          </Grid>

                          {values.List_ID === -1 && (
                            <Grid xs={12} sm={12} md={6} lg={6} container item>
                              <Field
                                as={TextField}
                                required
                                // error={
                                //   touched.Adj_ID &&
                                //   errors.Adj_ID &&
                                //   selectedAdjustmentValue
                                // }
                                className={classes.halfInputAdjustment}
                                disabled={values.Is_Calc_Freezed === 1}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.activeInputLableColor,
                                    focused: "focused"
                                  }
                                }}
                                InputProps={{
                                  color: "secondary",
                                  classes: {
                                    root: classes.activeInputColor,
                                    input: classes.resize,
                                    focused: "focused"
                                  }
                                }}
                                onChange={(e) => {
                                  setFieldValue(
                                    "Adjustment_Name",
                                    e.target.value
                                  );
                                }}
                                label="Other Adjustment Name"
                                name="Adjustment_Name"
                                id="Adjustment_Name"
                                // helperText={
                                //   touched.Adj_ID &&
                                //   errors.Adj_ID &&
                                //   selectedAdjustmentValue
                                //     ? errors.Adj_ID
                                //     : ""
                                // }
                              />
                            </Grid>
                          )}
                        </Grid>

                        <Grid xs={12} sm={12} md={6} lg={6} container item>
                          <Field
                            as={TextField}
                            required
                            error={touched.ProcessorID && errors.ProcessorID}
                            className={classes.halfInputAdjustment}
                            disabled={
                              values.Is_Calc_Freezed === 1 ||
                              Add_Adjustment_Flag
                            }
                            InputLabelProps={{
                              classes: {
                                root: classes.activeInputLableColor,
                                focused: "focused"
                              }
                            }}
                            InputProps={{
                              color: "secondary",
                              classes: {
                                root: classes.activeInputColor,
                                input: classes.resize,
                                focused: "focused"
                              }
                            }}
                            onChange={(e) => {
                              setFieldValue("ProcessorID", e.target.value);
                            }}
                            label="Processor"
                            name="ProcessorID"
                            id="ProcessorID"
                            select
                            helperText={
                              touched.ProcessorID && errors.ProcessorID
                                ? errors.ProcessorID
                                : ""
                            }
                          >
                            <MenuItem disabled value="">
                              Select Processor
                            </MenuItem>
                            {processorList.map((processor) => (
                              <MenuItem value={processor.ProcessorID}>
                                {processor.ProcessorName}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                        <Grid xs={12} sm={12} md={6} lg={6} container item>
                          <Field
                            as={TextField}
                            required
                            error={touched.ProfileID && errors.ProfileID}
                            className={classes.halfInputAdjustment}
                            disabled={
                              values.Is_Calc_Freezed === 1 ||
                              Add_Adjustment_Flag
                            }
                            InputLabelProps={{
                              classes: {
                                root: classes.activeInputLableColor,
                                focused: "focused"
                              }
                            }}
                            InputProps={{
                              color: "secondary",
                              classes: {
                                root: classes.activeInputColor,
                                input: classes.resize,
                                focused: "focused"
                              }
                            }}
                            onChange={(e) => {
                              callForDbaNameList(e.target.value);
                              setFieldValue("ProfileID", e.target.value);
                            }}
                            label="Agent Profile"
                            name="ProfileID"
                            id="ProfileID"
                            helperText={
                              touched.ProfileID && errors.ProfileID
                                ? errors.ProfileID
                                : ""
                            }
                            select
                          >
                            <MenuItem disabled value="">
                              Select Agent Name
                            </MenuItem>
                            {agentsList
                              .sort((a, b) =>
                                a.AgentName.localeCompare(b.AgentName)
                              )
                              .map((val) => (
                                <MenuItem value={val.UserID}>
                                  {val.AgentName}
                                </MenuItem>
                              ))}
                          </Field>
                        </Grid>

                        <Grid xs={12} sm={12} md={6} lg={6} container item>
                          <Autocomplete
                            {...defaultProps}
                            className={classes.halfInputAdjustment}
                            name="MID"
                            id="MID"
                            defaultValue={dbaNameList && "4445045262524"}
                            style={{
                              fontSize: "14px",
                              "&.focused": {
                                // color: theme.palette.primary.main,
                                fontSize: "14px",
                                fontStyle: "Helvetica, Arial,sans-serif"
                              }
                            }}
                            value={
                              dbaNameList &&
                              dbaNameList.find((op) =>
                                op.DBAName === values.MID ? dbaNameList.MID : ""
                              )
                            }
                            inputValue={
                              dbaNameList &&
                              dbaNameList.find((op) => op.MID === values.MID)
                                ? dbaNameList.find(
                                    (op) => op.MID === values.MID
                                  ).DBAName
                                : ""
                            }
                            fullWidth
                            onChange={(e, value) => {
                              if (value) {
                                setFieldValue("MID", value.MID);
                              } else {
                                setFieldValue("MID", "");
                              }
                            }}
                            clearOnBlur
                            clearOnEscape
                            options={
                              dbaNameList &&
                              dbaNameList?.sort((a, b) =>
                                a.DBAName.localeCompare(b.DBAName)
                              )
                            }
                            getOptionLabel={(option) => option.DBAName}
                            getOptionValue={(option) => option.MID}
                            renderInput={(params) => (
                              <TextField
                                InputLabelProps={{
                                  classes: {
                                    root: classes.activeInputLableColor,
                                    focused: "focused"
                                  }
                                }}
                                InputProps={{
                                  color: "secondary",
                                  classes: {
                                    root: classes.activeInputColor,
                                    input: classes.resize,
                                    focused: "focused"
                                  }
                                }}
                                name="MID"
                                id="MID"
                                {...params}
                                label="DBA Name or MID"
                              />
                            )}
                          />
                        </Grid>
                        <Grid xs={12} sm={12} md={6} lg={6} container item>
                          <Field
                            as={TextField}
                            required
                            error={touched.Amount && errors.Amount}
                            className={classes.halfInputAdjustment}
                            disabled={values.Is_Calc_Freezed === 1}
                            InputLabelProps={{
                              classes: {
                                root: classes.activeInputLableColor,
                                focused: "focused"
                              }
                            }}
                            InputProps={{
                              color: "secondary",
                              classes: {
                                root: classes.activeInputColor,
                                input: classes.resize,
                                focused: "focused"
                              }
                            }}
                            label="Amount"
                            name="Amount"
                            id="Amount"
                            helperText={
                              touched.Amount && errors.Amount
                                ? errors.Amount
                                : ""
                            }
                          />
                        </Grid>
                        <Grid
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          className="custflex-dr-aic"
                          container
                          item
                        >
                          <Grid
                            xs={4}
                            sm={4}
                            md={4}
                            lg={4}
                            className="custflex-dr-aic"
                            container
                            item
                          >
                            <Grid
                              className="fieldWithLabel"
                              xs={4}
                              style={{
                                justifyContent: "start"
                              }}
                              item
                            >
                              <InputLabel id="label-StartDate">
                                From
                                <span className="spn-required">*</span>
                              </InputLabel>
                            </Grid>
                            <Grid xs={8} item>
                              <FormControl fullWidth>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <Field
                                    as={KeyboardDatePicker}
                                    fullWidth
                                    views={["month", "year"]}
                                    disabled={values.Is_Calc_Freezed === 1}
                                    id="StartDate"
                                    name="StartDate"
                                    labelId="label-StartDate"
                                    error={
                                      errors.StartDate && touched.StartDate
                                    }
                                    minDateMessage=""
                                    invalidDateMessage=""
                                    maxDateMessage=""
                                    inputVariant="standard"
                                    format="MM/yyyy"
                                    placeholder="MM/YYYY"
                                    onChange={(value) => {
                                      setFieldValue("StartDate", value);
                                    }}
                                    KeyboardButtonProps={{
                                      size: "small"
                                    }}
                                  />
                                </MuiPickersUtilsProvider>
                              </FormControl>
                            </Grid>
                          </Grid>
                          {!values.Forever && (
                            <Grid
                              xs={4}
                              sm={4}
                              md={4}
                              lg={4}
                              className="custflex-dr-aic"
                              container
                              item
                            >
                              <Grid className="fieldWithLabel" xs={4} item>
                                <InputLabel id="label-EndDate">
                                  To
                                  <span className="spn-required">*</span>
                                </InputLabel>
                              </Grid>
                              <Grid xs={8} item>
                                <FormControl fullWidth>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Field
                                      as={KeyboardDatePicker}
                                      fullWidth
                                      disabled={values.Forever}
                                      views={["month", "year"]}
                                      id="EndDate"
                                      name="EndDate"
                                      labelId="label-EndDate"
                                      error={errors.EndDate && touched.EndDate}
                                      minDate={values.StartDate}
                                      minDateMessage=""
                                      invalidDateMessage=""
                                      maxDateMessage=""
                                      inputVariant="standard"
                                      format="MM/yyyy"
                                      placeholder="MM/YYYY"
                                      onChange={(value) => {
                                        setFieldValue("EndDate", value);
                                      }}
                                      KeyboardButtonProps={{
                                        size: "small"
                                      }}
                                    />
                                  </MuiPickersUtilsProvider>
                                </FormControl>
                              </Grid>
                            </Grid>
                          )}
                          <Grid
                            xs={4}
                            sm={4}
                            md={4}
                            lg={4}
                            className="custflex-dr-aic"
                            container
                            item
                          >
                            <Grid xs={2} item>
                              <Checkbox
                                color="primary"
                                checked={values.Forever}
                                style={{
                                  color: "#2ba992"
                                }}
                                // disabled={values.EndDate}
                                onChange={(event) => {
                                  setFieldValue(
                                    "Forever",
                                    event.target.checked
                                  );
                                }}
                              />
                            </Grid>
                            <Grid xs={4} item style={{ marginLeft: "8px" }}>
                              <InputLabel id="label-EndMonth">
                                Forever
                                <span className="spn-required">*</span>
                              </InputLabel>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          className="custflex-dr-aic"
                          container
                          item
                        >
                          <Grid
                            xs={6}
                            sm={6}
                            md={6}
                            lg={6}
                            className="custflex-dr-aic"
                            container
                            item
                          >
                            <Grid
                              xs={6}
                              sm={6}
                              md={6}
                              lg={6}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "16px"
                              }}
                            >
                              <Grid xs={4} item>
                                <InputLabel>
                                  Active
                                  <span className="spn-required">*</span>
                                </InputLabel>
                              </Grid>
                              <Grid xs={2} item>
                                <Checkbox
                                  disabled={values.Is_Calc_Freezed == 1}
                                  color="primary"
                                  checked={values.Status == 1}
                                  error={errors.Status}
                                  style={{
                                    color: "#2ba992"
                                  }}
                                  onChange={(event) => {
                                    setFieldValue("Status", 1);
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              xs={6}
                              sm={6}
                              md={6}
                              lg={6}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "16px"
                              }}
                            >
                              <Grid xs={4} item>
                                <InputLabel>
                                  Inctive
                                  <span className="spn-required">*</span>
                                </InputLabel>
                              </Grid>
                              <Grid xs={2} item>
                                <Checkbox
                                  color="primary"
                                  style={{
                                    color: "#2ba992"
                                  }}
                                  disabled={values.Is_Calc_Freezed == 1}
                                  checked={values.Status == 0}
                                  error={errors.Status}
                                  onChange={(event) => {
                                    setFieldValue("Status", 0);
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        {/* psss adjjj */}
                        <Grid
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          className="custflex-dr-aic"
                          container
                          item
                        >
                          <Grid
                            className="fieldWithLabel"
                            xs={12}
                            lg={12}
                            item
                            style={{
                              justifyContent: "start"
                            }}
                          >
                            <InputLabel
                              id="label-Description"
                              style={{
                                marginBottom: "14px"
                              }}
                            >
                              Description
                              {/* <span className="spn-required">*</span> */}
                            </InputLabel>
                          </Grid>
                          <Grid xs={12} lg={12} item>
                            <FormControl fullWidth>
                              <Field
                                as={TextField}
                                fullWidth
                                variant="outlined"
                                disabled={values.Is_Calc_Freezed === 1}
                                name="Description"
                                error={
                                  errors.Description && touched.Description
                                }
                                rows={3}
                                multiline
                                labelId="label-Description"
                                placeholder="Description"
                              />
                            </FormControl>
                            <ErrorMessage name="Description">
                              {(msg) => (
                                <Typography
                                  component="span"
                                  className="errorAbs"
                                  variant="colorError"
                                >
                                  {msg}
                                </Typography>
                              )}
                            </ErrorMessage>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        container
                        item
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          justifyContent: "center",
                          margin: "1rem"
                        }}
                        spacing={3}
                      >
                        <Box mr={2}>
                          <Button
                            className={btnClasses.clearButton}
                            onClick={handleBack}
                            variant="contained"
                          >
                            Back
                          </Button>
                        </Box>
                        <Box mr={2}>
                          <Button
                            onClick={() => {
                              resetForm();
                            }}
                            variant="contained"
                            className={btnClasses.clearButton}
                          >
                            Reset
                          </Button>
                        </Box>
                        <Box>
                          <Button
                            type="submit"
                            className={btnClasses.ExportButtonStyle}
                            variant="contained"
                          >
                            {EditFlag ? "Submit" : "Create"}
                          </Button>
                        </Box>
                      </Grid>
                    </Form>
                  </>
                )}
              </Formik>
            </Grid>
          </Box>
        </Box>
      </ThemeProvider>
    </div>
  );
};

AdjustmentAddEdit.propTypes = {
  getAdjustmentDetails: PropTypes.func,
  getAdjustmentDetailsByID: PropTypes.func,
  fetchProcessorList: PropTypes.func,
  createAdjustment: PropTypes.func,
  fetchMappedMerchantsListAll: PropTypes.func,
  fetchMappedMerchantsList: PropTypes.func,
  fetchAgentList: PropTypes.func
};

AdjustmentAddEdit.defaultProps = {
  getAdjustmentDetails: () => {},
  getAdjustmentDetailsByID: () => {},
  fetchProcessorList: () => {},
  createAdjustment: () => {},
  fetchAgentList: () => {},
  fetchMappedMerchantsList: () => {},
  fetchMappedMerchantsListAll: () => {}
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
  getAdjustmentDetails,
  fetchProcessorList,
  createAdjustment,
  fetchAgentList,
  fetchMappedMerchantsListAll,
  fetchMappedMerchantsList,
  getAdjustmentDetailsByID
})(AdjustmentAddEdit);
