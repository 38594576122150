/* eslint-disable import/extensions */
import React from "react";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Menu from "@material-ui/core/Menu";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import { useParams, useHistory } from "react-router-dom";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import styles from "assets/jss/material-dashboard-react/components/businessForm";

const useStyles = makeStyles(styles);

function LongMenu(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    props.setValues((pre) => {
      pre.status = props.details.Current_Status;
      return pre;
    });
  };

  const handleClose = (type) => {
    if (type === "edit") {
      history.push(`/admin/PricingTemplate/edit/${props.details._id}`);
    }
    if (type === "cloneTemplate") {
      history.push(`/admin/PricingTemplate/cloneTemplate/${props.details._id}`);
    }
    setAnchorEl(null);
  };

  // const openStatusForm = () => {
  //   handleClose();
  // };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        style={{ padding: "0px" }}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          className={classes.dialogBoxDropDown}
          onClick={() => handleClose("edit")}
        >
          Edit
        </MenuItem>
        <MenuItem
          className={classes.dialogBoxDropDown}
          onClick={() => handleClose("cloneTemplate")}
        >
          Clone
        </MenuItem>
        {/* <MenuItem
          className={classes.dialogBoxDropDown}
          onClick={() => openStatusForm()}
        >
          Change Status
        </MenuItem> */}
      </Menu>
    </div>
  );
}

LongMenu.propTypes = {
  details: PropTypes.object,
  setValues: PropTypes.func,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(LongMenu);
