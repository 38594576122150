import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { Field, Form, Formik } from "formik";
import TableContainer from "@material-ui/core/TableContainer";
import Style from "./style";
import "./cart.css";

const useStyles = makeStyles(Style);

function MobilePosCheckout(props) {
  const classes = useStyles();

  React.useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://apps.coinsuites.com/widget/coinsuites.js?v4";
    script.async = true;

    document.body.appendChild(script);

    // return () => {
    //   document.body.removeChild(script);
    // };
  }, []);

  return (
    <>
      <TableContainer
        style={{
          width: "500px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
          marginTop: "10%",
        }}
        className={classes.TableContainer}
        component={Paper}
      >
        <Paper style={{ marginTop: "2%", padding: "40px" }}>
          <Formik onSubmit={() => {}} enableReinitialize initialValues={{}}>
            {() => (
              <Form>
                <Grid
                  spacing={4}
                  container
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignContent: "center",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid xs={10} item>
                    <Field
                      as={TextField}
                      fullWidth
                      size="small"
                      variant="outlined"
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,

                          focused: "focused",
                        },
                      }}
                      label="InvoiceNumber"
                      name="invoicNumber"
                      id="invoicNumber"
                    />
                  </Grid>
                  <Grid xs={10} item>
                    <Field
                      as={TextField}
                      fullWidth
                      select
                      variant="outlined"
                      size="small"
                      value={"USD"}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,

                          focused: "focused",
                        },
                      }}
                      label="Select Currency"
                      name="currency"
                      id="currency"
                    >
                      <MenuItem value="USD">USD</MenuItem>
                      <MenuItem value="CAD">CAD</MenuItem>
                    </Field>
                  </Grid>
                  <Grid xs={10} item>
                    <Field
                      as={TextField}
                      fullWidth
                      type="number"
                      size="small"
                      variant="outlined"
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,

                          focused: "focused",
                        },
                      }}
                      label="Amount"
                      name="amount"
                      id="amount"
                    />
                  </Grid>
                </Grid>
                <Grid alignItems="center" justify="flex-center" container>
                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignContent: "center",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "30px",
                    }}
                    item
                    xs={12}
                  >
                    <div
                      coinsuites="Paywidget"
                      coinsuites_id="4a007183-4737-4aaf-9617-e60515f31f3e"
                      amount="36.00"
                      currency="USD"
                      ref_id="SB83347"
                      className="paydevpaybtn"
                    />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </TableContainer>
    </>
  );
}

MobilePosCheckout.propTypes = {};

const mapStateToProps = (app) => ({});
export default connect(mapStateToProps, null)(MobilePosCheckout);
