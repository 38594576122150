const BASE_URL = process.env.REACT_APP_PAYOUT_API_BASE_PATH;
// pricing schedule
export const GET_PRICING_SCHEDULE_LIST = `${BASE_URL}/pricing-schedules`;
export const GET_PRICING_SCHEDULE_INFO = `${BASE_URL}/pricing-sechedules-items`;
export const GET_PAYOUT_PROCESSOR_LIST = `${BASE_URL}/processor-list`;
export const GET_PAYOUT_RATE_TYPE_LIST = `${BASE_URL}/rate-list`;
// agent payout api list
export const GET_AGENT_LIST = `${BASE_URL}/agent-list`;
export const GET_REP_CODE_LIST = `${BASE_URL}/repcode-list`;
export const CREATE_NEW_AGENT_PAYOUT = `${BASE_URL}/agent-profile-add`;
export const GET_AGENT_PAYOUT_MULTISPLIT_LIST = `${BASE_URL}/profile-multisplit-list`;
export const CLONE_AGENT_PROFILE = `${BASE_URL}/clone-profile-setup`;
export const AGENT_PROFILE_VERSIONS_LIST = `${BASE_URL}/profile-versions-list`;
export const UPDATE_AGENT_PS = `${BASE_URL}/update-profile-ps`;

// share and payee and split
export const GET_SHARE_TYPES_LIST = `${BASE_URL}/sharetypes-list`;
export const GET_SPLIT_PAYEE_LIST = `${BASE_URL}/payee-list`;
export const GET_SPLIT_PAYER_LIST = `${BASE_URL}/payer-list`;
export const CREATE_AGENT_MULTI_SPLIT = `${BASE_URL}/profile-multisplit-add`;
export const REMOVE_AGENT_MULTI_SPLIT = `${BASE_URL}/profile-multisplit-delete`;
// merchant mapping
export const CHANGE_PAYOUT_STATUS = `${BASE_URL}/agent-profile-status`;
export const GET_MERCHANT_MAPPING_LIST = `${BASE_URL}/merchant-profiles-list`;
export const GET_PROFILE_REPCODE_LIST = `${BASE_URL}/profile-rapcode-list`;
export const GET_MERCHANTS_BY_NAME = `${BASE_URL}/find-merchant-name`;
export const UPDATE_MAPPING_STATUS = `${BASE_URL}/mapping-status-update`;
export const GET_MERCHANT_PROFILES_HISTORY = `${BASE_URL}/merchant-profiles-history`;
// adjustment
export const GET_ADJUSTMENT_LIST = `${BASE_URL}/adjustment`;
export const CREATE_ADJUSTMENT = `${BASE_URL}/save-adjustment`;
export const ADJUSTMENT_NAME_LIST = `${BASE_URL}/adjustment-name-list`;
// payout calculation
export const PAYOUT_MIGRATION = `${BASE_URL}/payout-uml-migration`;
export const GET_PAYOUT_LIST = `${BASE_URL}/payout-list`;
export const GET_PAYOUT_DETAILS_LIST = `${BASE_URL}/payout-details`;
export const PAYOUT_CALCULATION = `${BASE_URL}/payout-calculation`;
export const GET_CALCULATION_SUMMARY_LIST = `${BASE_URL}/payout-calculation-summary`;
export const PAYOUT_CALCULATION_DETAIL = `${BASE_URL}/payout-last-calculation-details`;
export const PAYOUT_CALCULATION_DELETE = `${BASE_URL}/delete-payout-calculation`;
export const PAYOUT_CALCULATION_HISTORY = `${BASE_URL}/payout-calculation-history`;
export const PAYOUT_CALCULATION_FREEZE = `${BASE_URL}/freeze-calculation`;
// payout approval
export const GET_PAYOUT_APPROVAL_LIST = `${BASE_URL}/payout-approval`;
export const UPDATE_PAYOUT_APPROVAL_STATUS = `${BASE_URL}/update-payout-status`;

// merchant history
export const GET_MERCHANT_PROFILE_HISTORY = `${BASE_URL}/merchant-profiles-history`;
// last calculation date
export const GET_LAST_CALC_DATE = `${BASE_URL}/procs-calc-date`;
