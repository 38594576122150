/* eslint-disable import/extensions */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-react/components/businessForm";

const useStyles = makeStyles(styles);

function SimpleDialog(props) {
  const [values, setValue] = React.useState(props.searchValue);
  const classes = useStyles();
  const handleClear = () => {
    setValue({
      name: "",
      status: ""
    });
    props.searchChipData({
      name: "",
      status: ""
    });
    props.getPricingListCall({
      name: "",
      status: ""
    });
    props.close();
  };

  const onChange = (value, type) => {
    const allState = { ...values };
    allState[type] = value;
    setValue(allState);
  };

  const onFormSubmit = () => {
    props.getPricingListCall(values);
    props.searchChipData(values);
    props.close();
  };

  return (
    <>
      <Grid container className={classes.margin} style={{ marginBottom: "5%" }}>
        <Grid item xs>
          <TextField
            className={classes.rootDialog}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                focused: "focused",
                input: classes.resize
              }
            }}
            label="Template Name"
            value={values.name}
            onChange={(e) => onChange(e.target.value, "name")}
            name="name"
            id="name"
            style={{ fontSize: "82%" }}
          />
        </Grid>
      </Grid>

      <Grid container className={classes.margin} style={{ marginBottom: "5%" }}>
        <Grid item xs>
          <TextField
            className={classes.rootDialog}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            placeholder="Select Status"
            label="Status"
            value={values.status}
            onChange={(e) => onChange(e.target.value, "status")}
            name="status"
            id="status"
            select
            style={{ fontSize: "82%" }}
          >
            <MenuItem className={classes.dialogBoxDropDown} value="Active">
              Active
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="Inactive">
              In-Active
            </MenuItem>
          </TextField>
        </Grid>
      </Grid>

      <Grid
        container
        className={classes.margin}
        style={{ justifyContent: "end" }}
      >
        <Button
          cancel
          variant="contained"
          className={classes.resetButtonStyle}
          disabled={values.name === "" && values.status === ""}
          onClick={() => handleClear()}
        >
          clear
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.buttonStyle}
          disabled={values.name === "" && values.status === ""}
          onClick={() => onFormSubmit()}
        >
          Search
        </Button>
      </Grid>
    </>
  );
}

SimpleDialog.propTypes = {
  close: PropTypes.func,
  getPricingListCall: PropTypes.func,
  searchValue: PropTypes.object,
  searchChipData: PropTypes.func
};

const mapStateToProps = (app) => ({
  tableData: app.provideRegistration
});

export default connect(mapStateToProps, {})(SimpleDialog);
