import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";


export const exportToCSVPricing = (mappedLogs, fileName, chipValue,exportedBy,exportedByFname,exportedByLname,labelName) => {
  var result = [];
  for (var id in labelName) {
    let some = labelName[id];
    let another = chipValue[id];
    if (another != "" && another != undefined) {
      result.push(`${some} : ${another}`);
    }
  }
  var myCurrentDate = new Date();	
  var date = myCurrentDate.getDate()  + '/' + (myCurrentDate.getMonth()+1) + '/' + myCurrentDate.getFullYear() +' '+ myCurrentDate.getHours()+':'+ myCurrentDate.getMinutes()+':'+ myCurrentDate.getSeconds();
  const newCurrentDate = date;
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const ws = XLSX.utils.json_to_sheet(mappedLogs,{ origin: 'A6'});
  XLSX.utils.sheet_add_aoa(ws, [[`Module:`]], { origin: "A1" });
  XLSX.utils.sheet_add_aoa(ws, [[`${fileName}`]], { origin: "B1" });
  XLSX.utils.sheet_add_aoa(ws, [[`Exported on:`]], { origin: "A2" });
  XLSX.utils.sheet_add_aoa(ws, [[`${newCurrentDate}`]], { origin: "B2" });
  XLSX.utils.sheet_add_aoa(ws, [[`Exported by:`]], { origin: "A3" });
  XLSX.utils.sheet_add_aoa(ws, [[`${exportedByFname} ${exportedByLname} <${exportedBy}>`]], { origin: "B3" });
  XLSX.utils.sheet_add_aoa(ws, [[`Filters:`]], { origin: "A4" });
  XLSX.utils.sheet_add_aoa(ws, [[`${result}`]], { origin: "B4" });
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

export const exportToCSV = (mappedLogs, fileName, chipValue,exportedBy,exportedByFname,exportedByLname,labelName) => {
  var result = [];
  for (var id in labelName) {
    let some = labelName[id];
    let another = chipValue[id];
    if (another != "" && another != undefined) {
      result.push(`${some} : ${another}`);
    }
  }
  var myCurrentDate = new Date();	
  var date = myCurrentDate.getDate()  + '/' + (myCurrentDate.getMonth()+1) + '/' + myCurrentDate.getFullYear() +' '+ myCurrentDate.getHours()+':'+ myCurrentDate.getMinutes()+':'+ myCurrentDate.getSeconds();
  const newCurrentDate = date;
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const ws = XLSX.utils.json_to_sheet(mappedLogs,{ origin: 'A6'});
  XLSX.utils.sheet_add_aoa(ws, [[`Module:`]], { origin: "A1" });
  XLSX.utils.sheet_add_aoa(ws, [[`${fileName}`]], { origin: "B1" });
  XLSX.utils.sheet_add_aoa(ws, [[`Exported on:`]], { origin: "A2" });
  XLSX.utils.sheet_add_aoa(ws, [[`${newCurrentDate}`]], { origin: "B2" });
  XLSX.utils.sheet_add_aoa(ws, [[`Exported by:`]], { origin: "A3" });
  XLSX.utils.sheet_add_aoa(ws, [[`${exportedByFname} ${exportedByLname} <${exportedBy}>`]], { origin: "B3" });
  XLSX.utils.sheet_add_aoa(ws, [[`Filters:`]], { origin: "A4" });
  XLSX.utils.sheet_add_aoa(ws, [[`${result}`]], { origin: "B4" });
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

export const exportToCSVWithPayout = (mappedLogs, fileName, chipValue,exportedBy,exportedByFname,exportedByLname,labelName) => {
  var result = [];
  for (var id in labelName) {
    let some = labelName[id];
    let another = chipValue[id];
    if (another != "" && another != undefined) {
      result.push(`${some} : ${another}`);
    }
  }
  var myCurrentDate = new Date();	
  var date = myCurrentDate.getDate()  + '/' + (myCurrentDate.getMonth()+1) + '/' + myCurrentDate.getFullYear() +' '+ myCurrentDate.getHours()+':'+ myCurrentDate.getMinutes()+':'+ myCurrentDate.getSeconds();
  const newCurrentDate = date;
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const ws = XLSX.utils.json_to_sheet(mappedLogs,{ origin: 'A6'});
  XLSX.utils.sheet_add_aoa(ws, [[`Module:`]], { origin: "A1" });
  XLSX.utils.sheet_add_aoa(ws, [[`${fileName}`]], { origin: "B1" });
  XLSX.utils.sheet_add_aoa(ws, [[`Exported on:`]], { origin: "A2" });
  XLSX.utils.sheet_add_aoa(ws, [[`${newCurrentDate}`]], { origin: "B2" });
  XLSX.utils.sheet_add_aoa(ws, [[`Exported by:`]], { origin: "A3" });
  XLSX.utils.sheet_add_aoa(ws, [[`${exportedByFname} ${exportedByLname} <${exportedBy}>`]], { origin: "B3" });
  XLSX.utils.sheet_add_aoa(ws, [[`Filters:`]], { origin: "A4" });
  XLSX.utils.sheet_add_aoa(ws, [[`${result}`]], { origin: "B4" });
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

export const calculationExportToCSV = (mappedLogs, fileName, chipValue,exportedBy,exportedByFname,exportedByLname,labelName,processorName) => {
  var result = [];
  for (var id in labelName) {
    let some = labelName[id];
    let another = chipValue[id];
    if (another != "" && another != undefined) {
      result.push(`${some} : ${processorName}`);
    }
  }
  var myCurrentDate = new Date();	
  var date = myCurrentDate.getDate()  + '/' + (myCurrentDate.getMonth()+1) + '/' + myCurrentDate.getFullYear() +' '+ myCurrentDate.getHours()+':'+ myCurrentDate.getMinutes()+':'+ myCurrentDate.getSeconds();
  const newCurrentDate = date;
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const ws = XLSX.utils.json_to_sheet(mappedLogs,{ origin: 'A6'});
  XLSX.utils.sheet_add_aoa(ws, [[`Module:`]], { origin: "A1" });
  XLSX.utils.sheet_add_aoa(ws, [[`${fileName}`]], { origin: "B1" });
  XLSX.utils.sheet_add_aoa(ws, [[`Exported on:`]], { origin: "A2" });
  XLSX.utils.sheet_add_aoa(ws, [[`${newCurrentDate}`]], { origin: "B2" });
  XLSX.utils.sheet_add_aoa(ws, [[`Exported by:`]], { origin: "A3" });
  XLSX.utils.sheet_add_aoa(ws, [[`${exportedByFname} ${exportedByLname} <${exportedBy}>`]], { origin: "B3" });
  XLSX.utils.sheet_add_aoa(ws, [[`Filters:`]], { origin: "A4" });
  XLSX.utils.sheet_add_aoa(ws, [[`${result}`]], { origin: "B4" });
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getQueryParams = function (data) {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get(data);
};

export const handleClickTable = function (
  event,
  name,
  processor,
  selected,
  selectedProcessorList,
  setSelected
) {
  const selectedIndex = selected.indexOf(name);
  const processorIndex = selectedProcessorList.indexOf(processor);
  let newSelected = [];
  let processorArr = [];
  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selected, name);
    processorArr = processorArr.concat(selectedProcessorList, processor);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selected.slice(1));
    processorArr = processorArr.concat(selectedProcessorList.slice(1));
  } else if (selectedIndex === selected.length - 1) {
    newSelected = newSelected.concat(selected.slice(0, -1));
    processorArr = processorArr.concat(selectedProcessorList.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
      selected.slice(0, selectedIndex),
      selected.slice(selectedIndex + 1)
    );
    processorArr = processorArr.concat(
      selectedProcessorList.slice(0, processorIndex),
      selectedProcessorList.slice(processorIndex + 1)
    );
  }
  setSelected(newSelected, processorArr);
};

export const accountingNumberformat = (
  number,
  maxdecimal = 2,
  mindecimal = 2
) => {
  const intNumber = typeof number === "number" ? number : parseFloat(number);
  const result = new Intl.NumberFormat("en", {
    maximumFractionDigits: maxdecimal,
    minimumFractionDigits: mindecimal
  }).format(intNumber);
  return result;
};

export const handleRowDataCheck = function (
  event,
  name,
  agent,
  selected,
  agentsList,
  setSelected
) {
  const selectedIndex = selected.indexOf(name);
  const agentIndex = agentsList.indexOf(agent);
  let newSelected = [];
  let agentsArr = [];
  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selected, name);
    agentsArr = agentsArr.concat(agentsList, agent);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selected.slice(1));
    agentsArr = agentsArr.concat(agentsList.slice(1));
  } else if (selectedIndex === selected.length - 1) {
    newSelected = newSelected.concat(selected.slice(0, -1));
    agentsArr = agentsArr.concat(agentsList.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
      selected.slice(0, selectedIndex),
      selected.slice(selectedIndex + 1)
    );
    agentsArr = agentsArr.concat(
      agentsList.slice(0, agentIndex),
      agentsList.slice(agentIndex + 1)
    );
  }
  setSelected(newSelected, agentsArr);
};
