import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { useSnackbar } from "notistack";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CustomTable from "../../components/CustomizeTable/CustomTable";
import CoustomButton from "../../../../components/CustomButtons/Button";
import tableStyle from "../../components/CustomizeTable/style";
import { importExportToCSV, accountingNumberformat } from "../../utils/common";

import {
  getImportLogsList,
  getImportLogsModuleList,
  fetchImportLogDownloadFile
} from "../../actions/payout.action";
import { TablePageData } from "../../utils/constants";
import SearchComponent from "../../components/SearchFields";
import SearchedChips from "../../components/SearchChips";
import LongMenu from "./LongMenu";
import FilterFields from "./filterFields";
import { fetchProcessorList } from "../../../payout/actions/pricingSchedule.action";

const headCellsItems = [
  {
    id: "Log_ID",
    label: "ID",
    isSort: true,
    sortProperty: "Log_ID",
    actionsCellStyle: "left"
  },
  {
    id: "Module_Name",
    label: "Module",
    isSort: true,
    sortProperty: "Module_Name",
    actionsCellStyle: "center"
  },
  {
    id: "Import_Type",
    label: "Import Type",
    isSort: true,
    sortProperty: "Import_Type",
    actionsCellStyle: "center"
  },
  {
    id: "File_Name",
    label: "File Name",
    isSort: true,
    sortProperty: "File_Name",
    actionsCellStyle: "center"
  },

  {
    id: "Uploaded_By",
    label: "Uploaded By",
    isSort: true,
    sortProperty: "Uploaded_By",
    actionsCellStyle: "center"
  },
  {
    id: "Upload_Time",
    label: "Uploaded Time",
    isSort: true,
    sortProperty: "Upload_Time",
    actionsCellStyle: "center"
  },
  {
    id: "Total_Records",
    label: "Total Records",
    isSort: true,
    sortProperty: "Total_Records",
    actionsCellStyle: "center"
  },
  {
    id: "Success_Records",
    label: "Success Records",
    isSort: true,
    sortProperty: "Success_Records",
    actionsCellStyle: "center"
  },
  {
    id: "Status",
    label: "Status",
    isSort: true,
    sortProperty: "Status",
    actionsCellStyle: "center"
  },
  {
    id: "action",
    label: "",
    isSort: true,
    sortProperty: "action",
    actionsCellStyle: "right"
  }
];

const getLastMonth = () => {
  let makeDate = new Date();
  makeDate.setMonth(makeDate.getMonth());

  const month = makeDate.getMonth();
  const day = makeDate.getDate();
  const year = makeDate.getFullYear();
  makeDate = `${year}/${month}/${day}`;
  return makeDate;
};

const labelName = {
  fromMonth: "From Month",
  toMonth: "To Month",
  moduleID: "Module",
  importType: "Type",
  status: "Status",
  fileName: "File Name"
};

const searchInitialData = {
  fromMonth: "",
  toMonth: "",
  moduleID: "",
  importType: "",
  status: "",
  fileName: ""
};

function ImportLogReport({
  getImportLogsList,
  getImportLogsModuleList,
  fetchImportLogDownloadFile,
  users,
  fetchProcessorList
}) {
  const useStyles = makeStyles(tableStyle);
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const dataParameter = "RowNumber";
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = React.useState(1);
  const [initialCall, setInitialCall] = React.useState(true);
  const [pageDetails, setPageDetails] = React.useState({ ...TablePageData });
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("Upload_Time");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [importLogList, setImportLogList] = React.useState([]);
  const [importLogModuleList, setImportLogModuleList] = React.useState([]);
  const [searchValues, setSearchValues] = React.useState({
    ...searchInitialData
  });
  const [chipValue, setChipValue] = React.useState({ ...searchInitialData });
  const [searchBar, setSearchBar] = React.useState(false);
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const [processorList, setProcessorList] = React.useState([]);
  const [calculationList, setCalculationList] = React.useState([]);

  const downloadImportedData = (res) => {
    const content = get(res, "data", []);
    if (!isEmpty(content)) {
      const mappedData = content.map((row) => ({
        UserType: row.userType,
        "First Name": row.firstName,
        "Middle Name": row.middleName,
        "Last Name": row.lastName,
        Email: row.emailID,
        "Address line 1": row.addressLine1,
        "Address line 2": row.addressLine2,
        City: row.city,
        State: row.state,
        Country: row.country,
        Zip: row.zip,
        "Mobile country code": row.mobileCountryCode,
        "Mobile No": row.mobile,
        "Alternate No": row.alternatePhone,
        "Phone extension": row.phoneExtension,
        "Reports to mail Id": row.reportsToMailId,
        Role: row.role,
        Timezone: row.timezone,
        "Profile visibility": row.profileVisibility,
        Message: row.message
      }));
      importExportToCSV(
        mappedData,
        "Downloaded File Log Details",
        chipValue,
        exportedBy,
        exportedByFname,
        exportedByLname,
        labelName
      );
    }
  };

  const handleSearchOpen = () => {
    setSearchBar(true);
  };

  const handleSearchClose = () => {
    setSearchBar(false);
  };

  const handleSubmitSearch = () => {
    setChipValue(searchValues);
    handleSearchClose();
  };

  const handleClearSearch = () => {
    setSearchValues({ ...searchInitialData });
    setChipValue({ ...searchInitialData });
    handleSearchClose();
  };

  const handleDeleteChip = (chip) => {
    if (chipValue) {
      let temp = { ...searchValues };
      if (chip === "toMonth") {
        // temp.toMonth = getLastMonth();
        // temp.fromMonth = getLastMonth();
        temp.toMonth = "";
        temp.fromMonth = "";
      } else {
        temp[chip] = "";
      }

      setSearchValues({ ...temp });
      setChipValue({ ...temp });
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(1);
    setRowsPerPage(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const exportedBy =
    users.users.userDetailsById &&
    users.users.userDetailsById?.Users_Data[0].Email;
  const exportedByFname =
    users.users.userDetailsById &&
    users.users.userDetailsById?.Users_Data[0].FName;
  const exportedByLname =
    users.users.userDetailsById &&
    users.users.userDetailsById?.Users_Data[0].LName;

  const exportListData = () => {
    const filterData = {
      sortColumn: orderBy,
      sortOrder: order,
      recordPerPage: "",
      pageNo: 1,
      ...searchValues
    };
    getImportLogsList(
      { ...filterData },
      (records) => {
        if (records.status === "success") {
          const content = get(records, "data", []);
          if (!isEmpty(content)) {
            const mappedLogs = content.map((row) => ({
              "Log ID": row.Log_ID,
              Module: row.Module_Name,
              "Import Type": row.Import_Type,
              "File Name": row.File_Name,
              Status: row.Status.type,
              "Uploaded By": row.Uploaded_By,
              "Upload Time": row.Upload_Time,
              "Total Records": row.Total_Records,
              "Success Records": row.Success_Records
            }));
            importExportToCSV(
              mappedLogs,
              "Import Logs",
              chipValue,
              exportedBy,
              exportedByFname,
              exportedByLname,
              labelName,
              moduleName
            );
          }
        } else {
          enqueueSnackbar(records.message, {
            variant: "error"
          });
        }
      },
      () => {
        enqueueSnackbar("API Request Failed", {
          variant: "error"
        });
      }
    );
  };

  const getPayoutList = function () {
    const filterData = {
      sortColumn: orderBy,
      sortOrder: order,
      recordPerPage: rowsPerPage.value === 0 ? "" : rowsPerPage,
      pageNo: page,
      ...searchValues
    };

    getImportLogsList(
      { ...filterData },
      (records) => {
        if (records.status === "success") {
          const content = get(records, "data", []);
          const { totalCount } = records;
          setImportLogList(content);
          setPageDetails({
            ...pageDetails,
            lastPage: Math.ceil(totalCount / rowsPerPage),
            from: page === 1 ? 1 : (page - 1) * rowsPerPage + 1,
            to:
              page * rowsPerPage < totalCount ? page * rowsPerPage : totalCount,
            total: totalCount
          });
        } else {
          enqueueSnackbar(records.message, {
            variant: "error"
          });
        }
      },
      () => {
        enqueueSnackbar("API Request Failed", {
          variant: "error"
        });
      }
    );
  };

  const getModuleNameList = () => {
    getImportLogsModuleList(
      (res) => {
        if (res.status === "success") {
          setImportLogModuleList(res.data);
        }
      },
      (err) => {
        enqueueSnackbar("Module name list api request failed", {
          variant: "error"
        });
      }
    );
  };

  const downloadFile = (logId, moduleID) => {
    let data = {
      logId: logId,
      moduleID: moduleID
    };
    fetchImportLogDownloadFile(
      data,
      (res) => {
        if (res.status === true) {
          downloadImportedData(res);
        } else {
          enqueueSnackbar("Download Request Failed", {
            variant: "error"
          });
        }
      },
      (err) => {
        enqueueSnackbar("Download Request Failed", {
          variant: "error"
        });
      }
    );
  };

  const getProcessorList = () => {
    fetchProcessorList(
      (data) => {
        if (data.status) {
          setProcessorList(data.data);
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
      }
    );
  };

  const findProcessorName = (id) => {
    if (!isEmpty(importLogModuleList)) {
      const name = importLogModuleList.find((x) => x.Module_ID == id);
      return name ? name.Module_Name : id;
    }
  };

  const moduleName = React.useMemo(
    () => findProcessorName(searchValues.moduleID),
    [searchValues.moduleID, importLogModuleList]
  );

  React.useEffect(() => {
    getPayoutList();
  }, [orderBy, order, page, rowsPerPage]);

  React.useEffect(() => {
    getModuleNameList();
  }, []);

  React.useEffect(() => {
    if (page === 1) {
      getPayoutList();
    }
    setPage(1);
  }, [chipValue]);

  return (
    <>
      <TableContainer className={classes.TableContainer} component={Paper}>
        {windowWidth && (
          <Grid
            container
            className={classes.searchContainer}
            style={{ flexWrap: "nowrap", justifyContent: "end" }}
          >
            <Grid item className={classes.margin}></Grid>
            <Grid item className={classes.margin}>
              <div className={classes.searchWrapper}>
                <SearchComponent
                  searchBar={searchBar}
                  handleSearchClose={handleSearchClose}
                  handleSearchOpen={handleSearchOpen}
                  Fields={() => {
                    return (
                      <FilterFields
                        handleSearchClose={() => handleSearchClose()}
                        setSearchValues={setSearchValues}
                        searchValues={searchValues}
                        handleSubmitSearch={handleSubmitSearch}
                        handleClearSearch={handleClearSearch}
                        importLogModuleList={importLogModuleList}
                      />
                    );
                  }}
                />
                {/* <ImportButton
                  fileImportFun={(data, res, err) =>
                    userImportFile(data, res, err)
                  }
                /> */}

                <CoustomButton
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={exportListData}
                  variant="contained"
                  className={classes.ExportButtonStyle}
                >
                  Export
                </CoustomButton>
              </div>
            </Grid>
          </Grid>
        )}
        {!windowWidth && (
          <Grid container className={classes.searchContainer}>
            <Grid item className={classes.expirtItemGrid}>
              <SearchComponent
                searchBar={searchBar}
                handleSearchClose={handleSearchClose}
                handleSearchOpen={handleSearchOpen}
                Fields={() => {
                  return (
                    <FilterFields
                      handleSearchClose={() => handleSearchClose()}
                      setSearchValues={setSearchValues}
                      searchValues={searchValues}
                      handleSubmitSearch={handleSubmitSearch}
                      handleClearSearch={handleClearSearch}
                      importLogModuleList={importLogModuleList}
                    />
                  );
                }}
              />
              {/* <ImportButton
                fileImportFun={(data, res, err) =>
                  userImportFile(data, res, err)
                }
              /> */}

              <CoustomButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={exportListData}
                variant="contained"
                color="primary"
                className={classes.buttonStyle}
              >
                Export
              </CoustomButton>
            </Grid>
          </Grid>
        )}
        <SearchedChips
          handleDeleteChip={handleDeleteChip}
          searchValues={chipValue}
          labelName={labelName}
          moduleName={moduleName}
        />
        <CustomTable
          page={page}
          order={order}
          orderBy={orderBy}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          data={importLogList}
          headCells={headCellsItems}
          dataParameter={dataParameter}
          isCallInitialization={initialCall}
          selected={[]}
          setSelected={() => {}}
          isSelection={false}
          rowsPerPage={rowsPerPage}
          pageDetails={pageDetails}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        >
          {importLogList.map((row) => {
            return (
              <TableRow key={row.RowNumber} className={classes.cellHeadSign}>
                <TableCell className={classes.cellText} align="left">
                  {row.Log_ID}
                </TableCell>
                <TableCell className={classes.cellText} align="center">
                  {row.Module_Name}
                </TableCell>
                <TableCell align="center" className={classes.cellText}>
                  {row.Import_Type}
                </TableCell>
                <TableCell align="center" className={classes.cellText}>
                  {row.File_Name}
                </TableCell>
                <TableCell align="center" className={classes.cellText}>
                  {row.Uploaded_By}
                </TableCell>
                <TableCell align="center" className={classes.cellText}>
                  {row.Upload_Time}
                </TableCell>
                <TableCell align="center" className={classes.cellText}>
                  {row.Total_Records}
                </TableCell>
                <TableCell align="center" className={classes.cellText}>
                  {row.Success_Records}
                </TableCell>
                <TableCell align="center" className={classes.cellText}>
                  {row.Status}
                </TableCell>
                <TableCell align="right" className={classes.cellText}>
                  <LongMenu
                    downloadFile={() => downloadFile(row.Log_ID, row.Module_ID)}
                    name={row.Module_Name}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </CustomTable>
      </TableContainer>
    </>
  );
}
ImportLogReport.propTypes = {
  getImportLogsList: PropTypes.func,
  getImportLogsModuleList: PropTypes.func,
  fetchImportLogDownloadFile: PropTypes.func,
  // userImportFile: PropTypes.func
  users: PropTypes.object,
  fetchProcessorList: PropTypes.func
};

ImportLogReport.defaultProps = {
  getImportLogsList: () => {},
  getImportLogsModuleList: () => {},
  fetchImportLogDownloadFile: () => {},
  fetchProcessorList: () => {}
  // userImportFile: () => {}
};

const mapStateToProps = (app) => ({
  users: app
});

export default connect(mapStateToProps, {
  getImportLogsList,
  getImportLogsModuleList,
  fetchImportLogDownloadFile,
  fetchProcessorList
  // userImportFile
})(ImportLogReport);
