/* eslint-disable camelcase */
// eslint-disable-next-line no-unused-vars
import * as yup from "yup";
// eslint-disable-next-line no-unused-vars
import { array, mixed, object, string, date, number, boolean } from "yup";
let patternFiveDigit = /^\d+(\.\d{1,5})?$/;
export default {
  addAgentMultiSplit: object({
    pricingScheduleId: string("Please enter a valid name."),
    profileIDRec: object("Please select a valid profile").required(
      "Agent repCode is a required."
    ),
    profileIDPayer: object("Please select a valid profile").required(
      "Paid by is a required."
    ),
    shareTypeID: string("Please enter a valid name.").required(
      "Share Type is a required."
    ),
    splitPer: string("Please enter a valid percentage.")
  }),

  addEditPricingSchedule: object({
    PS_Name: string("Please enter a valid name.")
      .required("Name is a required field.")
      .trim(),
    ProcessorID: string("Please select a valid processor.")
      .required("Processor is a required field.")
      .trim(),
    St_Date: date("Please enter a valid start date.")
      .typeError("Enter a valid Date")
      .nullable()
      .required("Start month is a required field.")
      .transform((curr, orig) => (orig === "" ? null : curr))
      .when("EditFlag", {
        is: false,
        then: yup
          .date("Please enter a valid start date.")
          .typeError("Enter a valid Date")
          .nullable()
          .required("Start month is a required field.")
          .transform((curr, orig) => (orig === "" ? null : curr))
          .test(
            "St_Date",
            "Only present and future month is allowed.",
            (value) => {
              let newDate = new Date();
              newDate = new Date(newDate.setDate(2));
              newDate = newDate.setHours(0, 0, 0, 0);
              const x = new Date(value).setDate(2);
              return x >= newDate;
            }
          )
          .test("St_Date", "Max 2099 year is allowed", (value) => {
            let newDate = new Date("12/30/2099");
            newDate = newDate.setHours(0, 0, 0, 0);
            return new Date(value).getTime() <= newDate;
          })
      }),
    Description: string("Please enter a valid description.").nullable()
      .max(500, "Description Length Must be equal or less than 500 characters.")
      .trim(),
    Split_Type: string("Please enter a select split type.").trim(),
    Loss_Percent: mixed().when("Split_Type", {
      is: "P",
      then: yup
        .number()
        .required("Loss % is a required field.")
        .typeError("Loss % must be a number")
        .min(0, "Loss % must in between 0 to 100")
        .max(100, "Loss % must in between 0 to 100")
    }),

    Profit_Percent: mixed().when("Split_Type", {
      is: "P",
      then: yup
        .number()
        .required("Profit % is a required field.")
        .typeError("Profit % must be a number")
        .min(0, "Profit % must in between 0 to 100")
        .max(100, "Profit % must in between 0 to 100")
    }),
    multiLoss: number()
      .typeError("Loss % must be a number")
      .min(0, "Loss % must in between 0 to 100")
      .max(100, "Loss % must in between 0 to 100"),
    multiProfit: number()
      .typeError("Profit % must be a number")
      .min(0, "Profit % must in between 0 to 100")
      .max(100, "Profit % must in between 0 to 100"),
    rate: number()
      .typeError("Rate % must be a number")
      .min(0, "Rate % must be greater then 0"),
    Groups: array().when("Split_Type", {
      is: "I",
      then: array().of(
        object({
          Items_List: array().of(
            object({
              IncludeID: number().nullable(),
              RateType_ID: number()
                .nullable()
                .when("IncludeID", {
                  is: (IncludeID) => !!IncludeID,
                  then: number()
                    .nullable()
                    .required("Please select a rate type")
                }),
              Rate: number()
                .nullable()
                .when(["IncludeID", "RateType_ID"], {
                  is: (IncludeID, RateType_ID) =>
                    RateType_ID !== 1 &&
                    RateType_ID !== 2 &&
                    RateType_ID !== 8 &&
                    RateType_ID &&
                    IncludeID,
                  then: yup
                    .number("Rate must be a number")
                    .positive()
                    .test(
                      "is-decimal",
                      "The amount should be a decimal with maximum 5 digits",
                      (val) => {
                        if (val != undefined) {
                          return patternFiveDigit.test(val);
                        }
                        return true;
                      }
                    )
                    .nullable()
                    .required("Rate is a required field.")
                    .min(0, "Rate % must be greater then 0")
                    .max(999999, "Rate % must be less then 999999")
                }),
              Profit: number("Profit % must be a number")
                .nullable()
                .when(["IncludeID", "RateType_ID"], {
                  is: (IncludeID, RateType_ID) => RateType_ID && IncludeID,
                  then: yup
                    .number("Profit % must be a number")
                    .positive()
                    .nullable()
                    .required("Profit % is a required field.")
                    .min(0, "Profit % must in between 0 to 100")
                    .max(100, "Profit % must in between 0 to 100")
                }),
              Loss: number("Loss % must be a number")
                .nullable()
                .when(["IncludeID", "RateType_ID"], {
                  is: (IncludeID, RateType_ID) => RateType_ID && IncludeID,
                  then: yup
                    .number("Loss % must be a number")
                    .positive()
                    .nullable()
                    .required("Loss % is a required field.")
                    .min(0, "Loss % must in between 0 to 100")
                    .max(100, "Loss % must in between 0 to 100")
                })
            })
          )
        })
      ),
      otherwise: array().of(
        object({
          Items_List: array().of(
            object({
              IncludeID: number().nullable(),
              RateType_ID: number()
                .nullable()
                .when("IncludeID", {
                  is: (IncludeID) => !!IncludeID,
                  then: number()
                    .nullable()
                    .required("Please select a rate type")
                }),
              Rate: number()
                .nullable()
                .when(["IncludeID", "RateType_ID"], {
                  is: (IncludeID, RateType_ID) =>
                    RateType_ID !== 1 &&
                    RateType_ID !== 2 &&
                    RateType_ID !== 8 &&
                    RateType_ID &&
                    IncludeID,
                  then: yup
                    .number("Rate must be a number")
                    .nullable()
                    .required("Rate is a required field.")
                    .min(0, "Rate % must be greater then 0")
                }),
              Profit: number("Profit % must be a number")
                .nullable()
                .when(["IncludeID", "RateType_ID"], {
                  is: (IncludeID, RateType_ID) => RateType_ID && IncludeID,
                  then: yup
                    .number("Profit % must be a number")
                    .nullable()
                    .min(0, "Profit % must in between 0 to 100")
                    .max(100, "Profit % must in between 0 to 100")
                }),
              Loss: number("Loss % must be a number")
                .nullable()
                .when(["IncludeID", "RateType_ID"], {
                  is: (IncludeID, RateType_ID) => RateType_ID && IncludeID,
                  then: yup
                    .number("Loss % must be a number")
                    .nullable()
                    .min(0, "Loss % must in between 0 to 100")
                    .max(100, "Loss % must in between 0 to 100")
                })
            })
          )
        })
      )
    })
  }),
  addNewAdjustment: object({
    ProcessorID: string("Please select processor").required(
      "Processor is a required."
    ),
    ProfileID: string("Please select agent profile").required(
      "Agent profile is required."
    ),
    Amount: string("Please enter a valid amount.").required(
      "Amount is required."
    ),
    StartDate: string("Please enter a valid start date.")
      .nullable()
      .required("Start date is required."),
    EndDate: string("Please enter a valid end date.")
      .nullable()
      .required("End date is required."),
    Description: string("Please enter a valid description.").nullable()
      .max(500, "Description Length Must be equal or less than 500 characters.")
      .trim()
  })
};
