import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import qs from "qs";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { useSnackbar } from "notistack";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from "@material-ui/core/Button";
import CustomTable from "../../components/CustomizeTable/CustomTable";
import CoustomButton from "../../../../components/CustomButtons/Button";
import tableStyle from "../../components/CustomizeTable/style";
import { exportToCSV } from "../../utils/common";
import {
  fetchPayoutDetailsListNew,
  fetchProcessorList,
  fetchAgentList,
  fetchRepCodeList
} from "../../actions/payout.action";
import { TablePageData } from "../../utils/constants";
import SearchComponent from "../../components/SearchFields";
import SearchedChips from "../../components/SearchChips";
import {
  getQueryParams,
  accountingNumberformat,
  getDataBlankFormat
} from "../../utils/common";

import FilterFields from "./filterFields";
import LastCalculationDialog from "modules/payout/components/LastCalculationDialog/LastCalculationDialog";

const headCellsItems = [
  {
    id: "Processor",
    label: "Processor",
    isSort: true,
    sortProperty: "Processor",
    actionsCellStyle: "center"
  },
  {
    id: "ResidualMonth",
    label: "Residual Month",
    isSort: true,
    sortProperty: "ResidualMonth",
    actionsCellStyle: "left"
  },
  {
    id: "PayoutMonth",
    label: "Payout Month",
    isSort: true,
    sortProperty: "PayoutMonth",
    actionsCellStyle: "center"
  },
  {
    id: "Merchant",
    label: "Merchant",
    isSort: true,
    sortProperty: "DBAName",
    actionsCellStyle: "center"
  },
  // {
  //   id: "DBAName",
  //   label: "DBA Name",
  //   isSort: true,
  //   sortProperty: "DBAName",
  //   actionsCellStyle: "center"
  // },
  {
    id: "AgentName",
    label: "Agent",
    isSort: true,
    sortProperty: "AgentName",
    actionsCellStyle: "center"
  },
  // {
  //   id: "RepCode",
  //   label: "Rep Code",
  //   isSort: true,
  //   sortProperty: "RepCode",
  //   actionsCellStyle: "center"
  // },
  // {
  //   id: "FeeCategory",
  //   label: "Fee Category",
  //   isSort: true,
  //   sortProperty: "FeeCategory",
  //   actionsCellStyle: "center"
  // },
  // {
  //   id: "ItemName",
  //   label: "Item Name",
  //   isSort: true,
  //   sortProperty: "ItemName",
  //   actionsCellStyle: "center"
  // },
  {
    id: "Count",
    label: "Count",
    isSort: true,
    sortProperty: "Count",
    actionsCellStyle: "center"
  },
  // {
  //   id: "TxnCount",
  //   label: "Transactions",
  //   isSort: true,
  //   sortProperty: "TxnCount",
  // },
  {
    id: "Volume",
    label: "Volume ($)",
    isSort: true,
    sortProperty: "Volume",
    actionsCellStyle: "center"
  },
  {
    id: "Income",
    label: "Income ($)",
    isSort: true,
    sortProperty: "Income",
    actionsCellStyle: "center"
  },
  {
    id: "Expense",
    label: "Expense ($)",
    isSort: true,
    sortProperty: "Expense",
    actionsCellStyle: "center"
  },
  {
    id: "GrossProfit",
    label: "Gross Profit ($)",
    isSort: true,
    sortProperty: "GrossProfit",
    actionsCellStyle: "center"
  },
  // {
  //   id: "BuyRate",
  //   label: "Buy Rate",
  //   isSort: true,
  //   sortProperty: "BuyRate",
  // },
  // {
  //   id: "RateType",
  //   label: "Rate Type",
  //   isSort: true,
  //   sortProperty: "RateType",
  // },
  {
    id: "PayouttoOthers",
    label: "Payout to Others ($)",
    isSort: true,
    sortProperty: "PayouttoOthers",
    actionsCellStyle: "center"
  },
  {
    id: "Payout",
    label: "Payout ($)",
    isSort: true,
    sortProperty: "Payout",
    actionsCellStyle: "right"
  },
  {
    id: "Adjustments",
    label: "Adjustments ($)",
    isSort: true,
    sortProperty: "Adjustments",
    actionsCellStyle: "center"
  },
  {
    id: "NetPayout",
    label: "Net Payout",
    isSort: true,
    sortProperty: "NetPayout"
  }
];

const getLastMonth = () => {
  let makeDate = new Date();
  makeDate.setMonth(makeDate.getMonth());

  const month = makeDate.getMonth();
  const day = makeDate.getDate();
  const year = makeDate.getFullYear();
  makeDate = `${year}/${month}/${day}`;
  return makeDate;
};

const labelName = {
  fromMonth: "From Month",
  toMonth: "To Month",
  searchBy: "Searched By",
  processor: "Processor",
  agentId: "Agent",
  repCode: "Rep Code",
  dbaName: "DBA Name"
};

function PayoutSummaryReport({
  fetchPayoutDetailsListNew,
  fetchProcessorList,
  fetchAgentList,
  fetchRepCodeList,
  users
}) {
  const useStyles = makeStyles(tableStyle);
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const dataParameter = "RowNumber";
  const searchInitialData = {
    fromMonth: getQueryParams("fromMonth") || "",
    toMonth: getQueryParams("toMonth") || "",
    searchBy: getQueryParams("searchBy") || "ResidualMonth",
    processor: getQueryParams("processor") || "",
    agentId: getQueryParams("agentId") || "",
    repCode: getQueryParams("repCode") || "",
    dbaName: ""
  };
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = React.useState(1);
  const [initialCall, setInitialCall] = React.useState(true);
  const [pageDetails, setPageDetails] = React.useState({ ...TablePageData });
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("ResidualMonth");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchValues, setSearchValues] = React.useState({
    ...searchInitialData
  });
  const [payoutList, setPayoutList] = React.useState([]);
  const [searchBar, setSearchBar] = React.useState(false);
  const [agentsList, setAgentsList] = React.useState([]);
  const [chipValue, setChipValue] = React.useState({
    ...searchInitialData
  });
  const [processorList, setProcessorList] = React.useState([]);
  const [repCodeList, setRepCodeList] = React.useState([]);
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));

  const findAgentName = (id) => {
    if (!isEmpty(agentsList)) {
      const name = agentsList.find((x) => x.UserID == id);
      return name ? name.AgentName.split("[")[0] : id;
    }
  };

  const findProcessorName = (id) => {
    if (!isEmpty(processorList)) {
      const name = processorList.find((x) => x.ProcessorID == id);
      return name ? name.ProcessorShortName : id;
    }
  };

  const agentName = React.useMemo(
    () => findAgentName(searchValues.agentId),
    [searchValues.agentId, agentsList]
  );
  const processorName = React.useMemo(
    () => findProcessorName(searchValues.processor),
    [searchValues.processor, processorList]
  );

  const handleSearchOpen = () => {
    setSearchBar(true);
  };

  const handleSearchClose = () => {
    setSearchBar(false);
  };

  const handleSubmitSearch = () => {
    setChipValue(searchValues);
    handleSearchClose();
    setPage(1);
    getPayoutList({
      ...searchValues
    });
  };

  const handleClearSearch = () => {
    setSearchValues({ ...searchInitialData });
    setChipValue({ ...searchInitialData });
    handleSearchClose();
    setPage(1);
  };

  const getProcessorList = () => {
    fetchProcessorList(
      (data) => {
        if (data.status) {
          setProcessorList(data.data);
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
      }
    );
  };

  const getAgentList = (agentNaame = "") => {
    fetchAgentList(
      (data) => {
        if (data.status) {
          if (
            (data.message === "No record found" && !isEmpty(agentsList)) ||
            isEmpty(data.data)
          ) {
            setAgentsList([
              {
                UserID: 919863231596669598,
                AgentName: "No record found"
              }
            ]);
          }
          if (data.data) {
            setAgentsList(() => [...data.data]);
          }
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
      },
      { search: agentNaame }
    );
  };

  const getRepCodeList = () => {
    fetchRepCodeList(
      (data) => {
        if (data.status) {
          setRepCodeList(data.data);
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
      },
      { userId: searchValues.agentId || "" }
    );
  };

  const handleDeleteChip = (chip) => {
    if (chipValue) {
      const temp = { ...searchValues };
      if (chip === "toMonth") {
        temp.toMonth = "";
        temp.fromMonth = "";
      } else {
        temp[chip] = "";
      }
      setSearchValues({ ...temp });
      setChipValue({ ...temp });
      setPage(1);
      getPayoutList({
        ...temp
      });
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(1);
    setRowsPerPage(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const viewPayoutDetails = (details, type) => {
    if (type && type === "adjustment") {
      if (details.Adjustment !== "0.00") {
        const url = `/admin/AdjustmentDetails?agentId=${
          details.AgentID
        }&fromMonth=${
          moment(details.ResidualMonth).format("YYYY-MM-DD") || ""
        }&toMonth=${
          moment(details.ResidualMonth).format("YYYY-MM-DD") || ""
        }&processorId=${searchValues.processor}&repCode=${
          searchValues.repCode
        }&userId=${localStorage.getItem("userId")}`;
        history.push(url);
        localStorage.setItem("AdjustmentDetails", url);
      }
    } else {
      const url = `/admin/IncomeExpenseDetail?payoutId=${
        details.RowNumber || ""
      }&agentId=${details.AgentID}&repCode=${details.RepCode}&processor=${
        searchValues.processor
      }&searchBy=ResidualMonth&fromMonth=${
        moment(details.ResidualMonth).format("YYYY-MM-DD") || ""
      }&toMonth=${moment(details.ResidualMonth).format("YYYY-MM-DD") || ""}`;
      history.push(url);
    }
  };

  const exportedBy =
    users.users.userDetailsById &&
    users.users.userDetailsById?.Users_Data[0].Email;
  const exportedByFname =
    users.users.userDetailsById &&
    users.users.userDetailsById?.Users_Data[0].FName;
  const exportedByLname =
    users.users.userDetailsById &&
    users.users.userDetailsById?.Users_Data[0].LName;
  const exportListData = () => {
    const filterData = {
      sortColumn: orderBy,
      sortOrder: order,
      recordPerPage: "",
      pageNo: 1,
      ...searchValues
    };
    filterData.userId = localStorage.getItem("userId") || "";
    fetchPayoutDetailsListNew(
      { ...filterData },
      (records) => {
        if (records.status === "success") {
          const content = get(records, "data", []);
          if (!isEmpty(content)) {
            const mappedLogs = content.map((row) => ({
              Processor: row.Processor,
              "Residual Month": row.ResidualMonth,
              "Payout Month": row.PayoutMonth,
              MID: row.MID,
              "DBA Name": row.DBAName,
              "Agent Name": row.AgentName,
              "Rep Code": row.RepCode,
              // "Fee Category": row.FeeCategory,
              // "Item Name": row.ItemName,
              Count: row.Count,
              Volume: accountingNumberformat(row.Volume),
              Income: row.Income,
              Expense: row.Expense,
              "Gross Profit": row.GrossProfit,
              // "Split %": row.Split,
              // "Buy Rate": row.BuyRate,
              // "Rate Type": row.RateType,
              "Payout to Others": row.PayouttoOthers,
              Payout: row.Payout,
              Adjustments: row.Adjustment,
              "Net Payout": row.NetPayout
            }));
            exportToCSV(
              mappedLogs,
              "Payout Details",
              chipValue,
              exportedBy,
              exportedByFname,
              exportedByLname,
              labelName
            );
          }
        } else {
          enqueueSnackbar(records.message, {
            variant: "error"
          });
        }
      },
      () => {
        enqueueSnackbar("API Request Failed", {
          variant: "error"
        });
      }
    );
  };

  const getPayoutList = function (searchValues) {
    let filterData = {
      ...searchValues,
      sortColumn: orderBy,
      sortOrder: order,
      recordPerPage: rowsPerPage.value === 0 ? "" : rowsPerPage,
      pageNo: page,
      userId: localStorage.getItem("userId")
    };
    fetchPayoutDetailsListNew(
      { ...filterData },
      (records) => {
        if (records.status === "success") {
          const content = get(records, "data", []);
          const { totalCount } = records;
          setPayoutList(content);
          setPageDetails({
            ...pageDetails,
            lastPage: Math.ceil(totalCount / rowsPerPage),
            from: page === 1 ? 1 : (page - 1) * rowsPerPage + 1,
            to:
              page * rowsPerPage < totalCount ? page * rowsPerPage : totalCount,
            total: totalCount
          });
        } else {
          enqueueSnackbar(records.message, {
            variant: "error"
          });
        }
      },
      () => {
        enqueueSnackbar("API Request Failed", {
          variant: "error"
        });
      }
    );
  };

  React.useEffect(() => {
    if (users.payout.lastCalcDate && users.payout.lastCalcDate.data) {
      const serarcValuesFromLocal = {
        fromMonth:
          searchValues.fromMonth != ""
            ? searchValues.fromMonth
            : moment(users.payout.lastCalcDate.data[0].LastCalcMonthYear)
                .startOf("month")
                .format("YYYY-MM-DD"),
        toMonth:
          searchValues.toMonth != ""
            ? searchValues.toMonth
            : moment(users.payout.lastCalcDate.data[0].LastCalcMonthYear)
                .endOf("month")
                .format("YYYY-MM-DD"),
        processor:
          searchValues.processor != ""
            ? searchValues.processor
            : users.payout.lastCalcDate.data[0].ProcessorID
      };
      setChipValue({
        ...searchValues,
        ...serarcValuesFromLocal
      });
      setSearchValues({
        ...searchValues,
        ...serarcValuesFromLocal
      });
      getPayoutList({
        ...searchValues,
        ...serarcValuesFromLocal
      });
    }
  }, [orderBy, order, page, rowsPerPage]);

  React.useEffect(() => {
    if (isEmpty(processorList)) {
      getProcessorList();
    }
    if (isEmpty(agentsList)) {
      getAgentList();
    }
  }, []);

  React.useEffect(() => {
    if (users.payout.lastCalcDate && users.payout.lastCalcDate.data) {
      if (localStorage.getItem("PayoutDetails")) {
        const latestNew = qs.parse(localStorage.getItem("PayoutDetails"));
        const serarcValuesFromLocal = {
          repCode: latestNew.repCode,
          agentId: getQueryParams("agentId"),
          dbaName: latestNew.dbaName,
          processor: latestNew.processor.replace("\n", "").trim(),
          fromMonth: moment(latestNew.fromMonth)
            .startOf("month")
            .format("YYYY-MM-DD"),
          toMonth: moment(latestNew.toMonth)
            .endOf("month")
            .format("YYYY-MM-DD"),
          searchBy: latestNew.searchBy.replace("\n", "").trim()
        };
        setChipValue(serarcValuesFromLocal);
        setSearchValues(serarcValuesFromLocal);
        getPayoutList(serarcValuesFromLocal);
      } else {
        const serarcValuesFromLocal = {
          repCode: searchValues.repCode,
          agentId: searchValues.agentId,
          dbaName: searchValues.dbaName,
          fromMonth: moment(users.payout.lastCalcDate.data[0].LastCalcMonthYear)
            .startOf("month")
            .format("YYYY-MM-DD"),
          processor: users.payout.lastCalcDate.data[0].ProcessorID,
          toMonth: moment(users.payout.lastCalcDate.data[0].LastCalcMonthYear)
            .endOf("month")
            .format("YYYY-MM-DD")
        };
        setChipValue({
          ...searchValues,
          ...serarcValuesFromLocal
        });
        setSearchValues({
          ...searchValues,
          ...serarcValuesFromLocal
        });
        getPayoutList({
          ...searchValues,
          ...serarcValuesFromLocal
        });
      }
    }
  }, [users.payout.lastCalcDate]);

  React.useEffect(() => {
    if (searchValues.agentId) {
      getRepCodeList();
    }
  }, [searchValues.agentId]);

  return (
    <>
      <TableContainer className={classes.TableContainer} component={Paper}>
        {windowWidth && (
          <Grid
            container
            className={classes.searchContainer}
            style={{ flexWrap: "nowrap", justifyContent: "end" }}
          >
            <Grid
              item
              className={classes.margin}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start"
              }}
            >
              <div style={{ padding: "0 8px" }}>
                <LastCalculationDialog />
              </div>
            </Grid>
            <Grid item className={classes.margin}>
              <div className={classes.searchWrapper}>
                <SearchComponent
                  searchBar={searchBar}
                  handleSearchClose={handleSearchClose}
                  handleSearchOpen={handleSearchOpen}
                  Fields={() => (
                    <FilterFields
                      handleSearchClose={() => handleSearchClose()}
                      setSearchValues={setSearchValues}
                      searchValues={searchValues}
                      agentsList={agentsList}
                      processorList={processorList}
                      repCodeList={repCodeList}
                      getAgentList={getAgentList}
                      handleSubmitSearch={handleSubmitSearch}
                      handleClearSearch={handleClearSearch}
                    />
                  )}
                />
                <CoustomButton
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={exportListData}
                  variant="contained"
                  className={classes.ExportButtonStyle}
                >
                  Export
                </CoustomButton>
              </div>
            </Grid>
          </Grid>
        )}
        {!windowWidth && (
          <Grid container className={classes.searchContainer}>
            <Grid
              item
              className={classes.margin}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start"
              }}
            >
              <div style={{ padding: "10px" }}>
                <LastCalculationDialog />
              </div>
            </Grid>
            <Grid item className={classes.expirtItemGrid}>
              <SearchComponent
                searchBar={searchBar}
                handleSearchClose={handleSearchClose}
                handleSearchOpen={handleSearchOpen}
                Fields={() => (
                  <FilterFields
                    handleSearchClose={() => handleSearchClose()}
                    setSearchValues={setSearchValues}
                    searchValues={searchValues}
                    agentsList={agentsList}
                    processorList={processorList}
                    repCodeList={repCodeList}
                    getAgentList={getAgentList}
                    handleSubmitSearch={handleSubmitSearch}
                    handleClearSearch={handleClearSearch}
                  />
                )}
              />
              <CoustomButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={exportListData}
                variant="contained"
                color="primary"
                className={classes.buttonStyle}
              >
                Export
              </CoustomButton>
            </Grid>
          </Grid>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingLeft: "10px"
          }}
        >
          <Grid item style={{ flexWrap: "nowrap" }}>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              className={classes.clearButton}
              onClick={() => history.goBack()}
            >
              Back
            </Button>
          </Grid>
          <SearchedChips
            handleDeleteChip={handleDeleteChip}
            searchValues={chipValue}
            labelName={labelName}
            agentName={agentName}
            processorName={processorName}
          />
        </div>
        <CustomTable
          page={page}
          order={order}
          orderBy={orderBy}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          data={payoutList}
          headCells={headCellsItems}
          dataParameter={dataParameter}
          isCallInitialization={initialCall}
          selected={[]}
          setSelected={() => {}}
          isSelection={false}
          rowsPerPage={rowsPerPage}
          pageDetails={pageDetails}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        >
          {payoutList.map((row) => (
            <TableRow key={row.RowNumber} className={classes.cellHeadSign}>
              <TableCell
                align="center"
                className={classes.cellText}
                onClick={() => viewPayoutDetails(row)}
              >
                {row.Processor}
              </TableCell>
              <TableCell
                className={classes.cellText}
                align="left"
                onClick={() => viewPayoutDetails(row)}
              >
                {row.ShortResidualMonth}
              </TableCell>
              <TableCell
                className={classes.cellText}
                align="center"
                onClick={() => viewPayoutDetails(row)}
              >
                {row.ShortPayoutMonth}
              </TableCell>
              <TableCell
                align="center"
                className={classes.cellText}
                onClick={() => viewPayoutDetails(row)}
              >
                {row.MID}
                <br />
                <span style={{ color: "#0093c9" }}>
                  {getDataBlankFormat(row.DBAName)}
                </span>
              </TableCell>
              <TableCell
                align="center"
                className={classes.cellText}
                onClick={() => viewPayoutDetails(row)}
              >
                {row.AgentName}
                <br />
                <span style={{ color: "#0093c9" }}>
                  {getDataBlankFormat(row.RepCode)}
                </span>
              </TableCell>
              <TableCell
                align="center"
                className={classes.cellText}
                onClick={() => viewPayoutDetails(row)}
              >
                {row.Count}
              </TableCell>

              {/* <TableCell
                  align="center"
                  className={classes.cellText}
                >
                  {row.TxnCount}
                </TableCell> */}
              <TableCell
                align="center"
                className={classes.cellText}
                onClick={() => viewPayoutDetails(row)}
              >
                {accountingNumberformat(row.Volume)}
              </TableCell>
              <TableCell
                align="center"
                className={classes.cellText}
                onClick={() => viewPayoutDetails(row)}
              >
                {accountingNumberformat(row.Income)}
              </TableCell>
              <TableCell
                align="center"
                className={classes.cellText}
                onClick={() => viewPayoutDetails(row)}
              >
                {accountingNumberformat(row.Expense)}
              </TableCell>

              <TableCell
                align="center"
                className={classes.cellText}
                onClick={() => viewPayoutDetails(row)}
              >
                {row.GrossProfit.toString().includes("-") ? (
                  <span style={{ color: "red" }}>
                    ({row.GrossProfit.toString().replace("-", "")})
                  </span>
                ) : (
                  row.GrossProfit
                )}
              </TableCell>

              {/* <TableCell
                  align="center"
                  className={classes.cellText}
                  style={{}}
                >
                  {accountingNumberformat(row.Split)}
                </TableCell> */}
              {/* <TableCell
                  align="center"
                  className={classes.cellText}
                >
                  {row.BuyRate}
                </TableCell> */}
              <TableCell
                align="center"
                className={classes.cellText}
                onClick={() => viewPayoutDetails(row)}
              >
                {accountingNumberformat(row.PayouttoOthers)}
              </TableCell>
              <TableCell
                align="center"
                className={classes.cellText}
                onClick={() => viewPayoutDetails(row)}
              >
                {row.Payout.toString().includes("-") ? (
                  <span style={{ color: "red" }}>
                    ({row.Payout.toString().replace("-", "")})
                  </span>
                ) : (
                  row.Payout
                )}
              </TableCell>
              <TableCell
                align="center"
                className={classes.cellText}
                onClick={() => viewPayoutDetails(row, "adjustment")}
              >
                <span style={{ color: "#0093c9" }}>{row.Adjustment}</span>
              </TableCell>
              <TableCell
                align="center"
                className={classes.cellText}
                onClick={() => viewPayoutDetails(row)}
              >
                {row.NetPayout.toString().includes("-") ? (
                  <span style={{ color: "red" }}>
                    ({row.NetPayout.toString().replace("-", "")})
                  </span>
                ) : (
                  row.NetPayout
                )}
              </TableCell>
            </TableRow>
          ))}
        </CustomTable>
      </TableContainer>
    </>
  );
}
PayoutSummaryReport.propTypes = {
  fetchPayoutDetailsListNew: PropTypes.func,
  fetchProcessorList: PropTypes.func,
  fetchAgentList: PropTypes.func,
  fetchRepCodeList: PropTypes.func,
  users: PropTypes.object
};

PayoutSummaryReport.defaultProps = {
  fetchPayoutDetailsListNew: () => {},
  fetchProcessorList: () => {},
  fetchAgentList: () => {},
  fetchRepCodeList: () => {}
};

const mapStateToProps = (app) => ({
  users: app
});

export default connect(mapStateToProps, {
  fetchPayoutDetailsListNew,
  fetchProcessorList,
  fetchAgentList,
  fetchRepCodeList
})(PayoutSummaryReport);
