import React from "react";
import PropTypes from "prop-types";
import { Box, Button, Grid, InputLabel, Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import DateFnsUtils from "@date-io/date-fns";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Autocomplete from "@material-ui/lab/Autocomplete";
import TodayIcon from "@material-ui/icons/Today";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import FormControl from "@material-ui/core/FormControl";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { Field, Form, Formik } from "formik";
import { commonStyle } from "../../assets/style/js/commonStyle";
import tableStyle from "../../components/CustomizeTable/style";
import {
  createTheme,
  ThemeProvider,
  makeStyles
} from "@material-ui/core/styles";

const theme = createTheme({
  primary: {
    main: "#2ba992"
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#2ba992 !important"
      }
    },
    MuiAutocomplete: {
      inputRoot: {
        padding: "0px !important"
      }
    },
    MuiOutlinedInput: {
      input: {
        padding: "11px 8px !important"
      }
    }
  }
});
const useBtnStyles = makeStyles(tableStyle);
const AddNewProfile = ({
  selectedUser,
  agentsList,
  processorList,
  // repCodeList,
  setSelectDetails,
  psList,
  selectDetails,
  setAddPayout,
  onSubmit
}) => {
  const cmClasses = commonStyle();
  const btnClasses = useBtnStyles();

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Box className="agent-payout-addNewProfile">
          <Grid className="custflex-dc" container>
            <Grid item>
              <Typography className="error-test">
                Processor and RepCode Combination Should be unique
              </Typography>
            </Grid>
            <Formik
              enableReinitialize
              // eslint-disable-next-line react/jsx-no-bind
              onSubmit={onSubmit}
              initialValues={{
                forever: true,
                processorId: {},
                repCode: "",
                pricingScheduleId: {},
                dateFrom: null,
                dateTo: null
              }}
            >
              {({ values, setFieldValue, resetForm }) => (
                <>
                  <Form>
                    <Grid
                      spacing={6}
                      container
                      className="custflex-dr-aic"
                      item
                    >
                      <Grid
                        xs={12}
                        sm={12}
                        md={6}
                        lg={4}
                        className="custflex-dr-aic"
                        container
                        item
                        style={{ padding: "16px" }}
                      >
                        <Grid className="custflex-jc-nowrap" xs={4} item>
                          <InputLabel id="label-Processor">
                            Processor
                            <span className="spn-required">*</span>
                          </InputLabel>
                        </Grid>
                        <Grid xs={8} item>
                          <FormControl
                            fullWidth
                            className={cmClasses.borderInput}
                          >
                            <Field
                              as={Autocomplete}
                              options={processorList}
                              fullWidth
                              name="processorId"
                              classes={{ root: "select-min-width" }}
                              labelId="label-Processor"
                              // variant="outlined"
                              onChange={(_, value, action) => {
                                if (action === "clear") {
                                  setFieldValue("processorId", {});
                                  setSelectDetails({
                                    ...selectDetails,
                                    processor: {}
                                  });
                                } else if (value) {
                                  setFieldValue("processorId", value);
                                  setSelectDetails({
                                    ...selectDetails,
                                    processor: value
                                  });
                                }
                              }}
                              getOptionLabel={(option) => option.ProcessorName}
                              placeholder="Processor"
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  // label="Combo box"
                                  variant="outlined"
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        sm={12}
                        md={6}
                        lg={4}
                        className="custflex-dr-aic"
                        container
                        item
                      >
                        <Grid className="custflex-jc-nowrap" xs={4} item>
                          <InputLabel id="label-RepCode">
                            RepCode
                            <span className="spn-required">*</span>
                          </InputLabel>
                        </Grid>
                        <Grid xs={8} item>
                          <FormControl
                            fullWidth
                            className={cmClasses.borderInput}
                          >
                            <Field
                              fullWidth
                              as={TextField}
                              name="repCode"
                              labelId="label-RepCode"
                              variant="outlined"
                              placeholder="RepCode"
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        sm={12}
                        md={6}
                        lg={4}
                        className="custflex-dr-aic"
                        container
                        item
                        style={{ padding: "16px" }}
                      >
                        <Grid className="custflex-jc-nowrap" xs={4} item>
                          <InputLabel id="label-Pricing-Schedule">
                            Pricing Schedule
                            <span className="spn-required">*</span>
                          </InputLabel>
                        </Grid>
                        <Grid xs={8} item>
                          <FormControl
                            fullWidth
                            className={cmClasses.borderInput}
                          >
                            <Field
                              fullWidth
                              as={Autocomplete}
                              options={psList}
                              disabled={isEmpty(values.processorId)}
                              name="pricingScheduleId"
                              onChange={(_, value, action) => {
                                if (action === "clear") {
                                  setFieldValue("pricingScheduleId", {});
                                } else if (value) {
                                  setFieldValue("pricingScheduleId", value);
                                }
                              }}
                              labelId="label-Pricing-Schedule"
                              variant="outlined"
                              placeholder="Pricing Schedule"
                              getOptionLabel={(option) => option.PS_Name || ""}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  // label="Combo box"
                                  variant="outlined"
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        sm={12}
                        md={6}
                        lg={4}
                        className="custflex-dr-aic"
                        container
                        item
                        style={{ padding: "16px" }}
                      >
                        <Grid className="custflex-jc-nowrap" xs={4} item>
                          <InputLabel id="label-dateFrom">
                            Start From
                            <span className="spn-required">*</span>
                          </InputLabel>
                        </Grid>
                        <Grid xs={8} item>
                          <FormControl
                            fullWidth
                            className={cmClasses.borderInput}
                          >
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <DatePicker
                                fullWidth
                                id="date-picker-dialog"
                                labelId="label-dateFrom"
                                inputVariant="outlined"
                                name="dateFrom"
                                maxDate={
                                  !values.forever
                                    ? values.dateTo
                                    : new Date(2099, 12, 25, 23, 59, 59)
                                }
                                allowKeyboardControl={false}
                                format="MM/yyyy"
                                placeholder="MM/YYYY"
                                views={["month", "year"]}
                                minDateMessage=""
                                invalidDateMessage=""
                                maxDateMessage="From Date cannot be greater than to date"
                                defaultValue={null}
                                value={
                                  values.dateFrom
                                    ? moment(values.dateFrom).format(
                                        "MM-DD-YYYY"
                                      )
                                    : null
                                }
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton onClick={() => {}}>
                                        <TodayIcon
                                          style={{
                                            color: "rgba(0, 0, 0, 0.54)"
                                          }}
                                        />
                                      </IconButton>
                                    </InputAdornment>
                                  )
                                }}
                                onChange={(value) => {
                                  let dateObj = value;
                                  if (dateObj && dateObj.getMonth()) {
                                    const month = dateObj.getMonth() + 1; // months from 1-12
                                    const day = dateObj.getDate();
                                    const year = dateObj.getFullYear();
                                    dateObj = `${year}-${month}-${day}`;
                                  }
                                  setFieldValue("dateFrom", dateObj);
                                }}
                                KeyboardButtonProps={{
                                  "aria-label": "start form"
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </FormControl>
                        </Grid>
                      </Grid>

                      <Grid
                        xs={12}
                        sm={12}
                        md={6}
                        lg={4}
                        className="custflex-dr-aic"
                        container
                        item
                        style={{ padding: "16px" }}
                      >
                        <Grid className="custflex-jc-nowrap" xs={2} item>
                          <InputLabel id="label-To">
                            To
                            <span className="spn-required">*</span>
                          </InputLabel>
                        </Grid>
                        {values.forever && (
                          <Grid xs={4} item>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={values.forever}
                                  onChange={() => {
                                    setFieldValue("forever", !values.forever);
                                  }}
                                  name="forever"
                                  color="primary"
                                />
                              }
                              label="Forever"
                            />
                          </Grid>
                        )}
                        {!values.forever ? (
                          <Grid style={{ display: "flex" }} xs={10} item>
                            <FormControl
                              fullWidth
                              style={{ marginRight: "20px" }}
                              className={cmClasses.borderInput}
                            >
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                  fullWidth
                                  id="date-picker-dialog"
                                  inputVariant="outlined"
                                  name="dateTo"
                                  minDate={
                                    !values.forever
                                      ? values.dateFrom ||
                                        new Date(2020, 4, 25, 23, 59, 59)
                                      : new Date(2020, 4, 25, 23, 59, 59)
                                  }
                                  allowKeyboardControl={false}
                                  format="MM/yyyy"
                                  placeholder="MM/YYYY"
                                  views={["month", "year"]}
                                  minDateMessage="From Date cannot be greater than to date"
                                  invalidDateMessage=""
                                  maxDateMessage=""
                                  defaultValue={null}
                                  value={
                                    values.dateTo
                                      ? moment(values.dateTo).format(
                                          "MM-DD-YYYY"
                                        )
                                      : null
                                  }
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton onClick={() => {}}>
                                          <TodayIcon
                                            style={{
                                              color: "rgba(0, 0, 0, 0.54)"
                                            }}
                                          />
                                        </IconButton>
                                      </InputAdornment>
                                    )
                                  }}
                                  onChange={(value) => {
                                    let dateObj = value;
                                    if (dateObj && dateObj.getMonth()) {
                                      const month = dateObj.getMonth() + 1; // months from 1-12
                                      const day = dateObj.getDate();
                                      const year = dateObj.getFullYear();
                                      dateObj = `${year}-${month}-${day}`;
                                    }
                                    setFieldValue("dateTo", dateObj);
                                  }}
                                  KeyboardButtonProps={{
                                    "aria-label": "start to"
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            </FormControl>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={values.forever}
                                  onChange={() => {
                                    setFieldValue("forever", !values.forever);
                                  }}
                                  name="forever"
                                  color="primary"
                                />
                              }
                              label="Forever"
                            />
                          </Grid>
                        ) : (
                          <Grid xs={6}></Grid>
                        )}
                      </Grid>
                      <Grid
                        xs={12}
                        sm={12}
                        md={6}
                        lg={4}
                        spacing={2}
                        style={{
                          display: "flex !important",
                          justifyContent: "flex-end",
                          alignContent: "center",
                          alignItems: "center",
                          padding: "16px"
                        }}
                        container
                        item
                      >
                        <Grid item>
                          <Button
                            onClick={() => {
                              resetForm(resetForm);
                              setAddPayout(false);
                            }}
                            variant="outlined"
                            className={btnClasses.clearButton}
                          >
                            Cancel
                          </Button>
                        </Grid>

                        <Grid item>
                          <Button
                            type="submit"
                            className={btnClasses.ExportButtonStyle}
                            variant="contained"
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Form>
                </>
              )}
            </Formik>
          </Grid>
        </Box>
      </ThemeProvider>
    </div>
  );
};
AddNewProfile.propTypes = {
  selectedUser: PropTypes.object,
  agentsList: PropTypes.array,
  processorList: PropTypes.array,
  // repCodeList: PropTypes.array,
  setSelectDetails: PropTypes.object,
  psList: PropTypes.array,
  selectDetails: PropTypes.object,
  setAddPayout: PropTypes.func,
  onSubmit: PropTypes.func
};

export default AddNewProfile;
