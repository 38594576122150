import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export const exportToCSV = (
  mappedLogs,
  fileName,
  chipValue,
  exportedBy,
  exportedByFname,
  exportedByLname,
  labelName,
  
) => {
  var result = [];
  for (var id in labelName) {
    let some = labelName[id];
    let another = chipValue[id];
    if (another != "" && another != undefined) {
      result.push(`${some} : ${another}`);
    }
  }

  var myCurrentDate = new Date();
  var date =
    myCurrentDate.getDate() +
    "/" +
    (myCurrentDate.getMonth() + 1) +
    "/" +
    myCurrentDate.getFullYear() +
    " " +
    myCurrentDate.getHours() +
    ":" +
    myCurrentDate.getMinutes() +
    ":" +
    myCurrentDate.getSeconds();
  const newCurrentDate = date;
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const ws = XLSX.utils.json_to_sheet(mappedLogs, { origin: "A6" });
  XLSX.utils.sheet_add_aoa(ws, [[`Module:`]], { origin: "A1" });
  XLSX.utils.sheet_add_aoa(ws, [[`${fileName}`]], { origin: "B1" });
  XLSX.utils.sheet_add_aoa(ws, [[`Exported on:`]], { origin: "A2" });
  XLSX.utils.sheet_add_aoa(ws, [[`${newCurrentDate}`]], { origin: "B2" });
  XLSX.utils.sheet_add_aoa(ws, [[`Exported by:`]], { origin: "A3" });
  XLSX.utils.sheet_add_aoa(
    ws,
    [[`${exportedByFname} ${exportedByLname} <${exportedBy}>`]],
    { origin: "B3" }
  );
  XLSX.utils.sheet_add_aoa(ws, [[`Filters:`]], { origin: "A4" });
  XLSX.utils.sheet_add_aoa(ws, [[`${result}`]], { origin: "B4" });
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};
export const importExportToCSV = (
  mappedLogs,
  fileName,
  chipValue,
  exportedBy,
  exportedByFname,
  exportedByLname,
  labelName,
  moduleName
) => {
  var result = [];
  for (var id in labelName) {
    let some = labelName[id];
    let another = chipValue[id];
    if (another != "" && another != undefined) {
      result.push(`${some}: ${moduleName}`);
    }
  }
  var results = [];
  for (var id in moduleName) {
    let some = moduleName[id];
    let another = chipValue[id];
    if (another != "" && another != undefined) {
      results.push(`${some} : ${another}`);
    }
  }
 console.log("results",results)
  var myCurrentDate = new Date();
  var date =
    myCurrentDate.getDate() +
    "/" +
    (myCurrentDate.getMonth() + 1) +
    "/" +
    myCurrentDate.getFullYear() +
    " " +
    myCurrentDate.getHours() +
    ":" +
    myCurrentDate.getMinutes() +
    ":" +
    myCurrentDate.getSeconds();
  const newCurrentDate = date;
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const ws = XLSX.utils.json_to_sheet(mappedLogs, { origin: "A6" });
  XLSX.utils.sheet_add_aoa(ws, [[`Module:`]], { origin: "A1" });
  XLSX.utils.sheet_add_aoa(ws, [[`${fileName}`]], { origin: "B1" });
  XLSX.utils.sheet_add_aoa(ws, [[`Exported on:`]], { origin: "A2" });
  XLSX.utils.sheet_add_aoa(ws, [[`${newCurrentDate}`]], { origin: "B2" });
  XLSX.utils.sheet_add_aoa(ws, [[`Exported by:`]], { origin: "A3" });
  XLSX.utils.sheet_add_aoa(
    ws,
    [[`${exportedByFname} ${exportedByLname} <${exportedBy}>`]],
    { origin: "B3" }
  );
  XLSX.utils.sheet_add_aoa(ws, [[`Filters:`]], { origin: "A4" });
  XLSX.utils.sheet_add_aoa(ws, [[`${result}`]], { origin: "B4" });
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getQueryParams = function (data) {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get(data);
};

export const accountingNumberformat = (
  number,
  maxdecimal = 2,
  mindecimal = 2
) => {
  const intNumber = typeof number === "number" ? number : parseFloat(number);
  const result = new Intl.NumberFormat("en", {
    maximumFractionDigits: maxdecimal,
    minimumFractionDigits: mindecimal
  }).format(intNumber);
  return result;
};

export const getDataBlankFormat = function (data) {
  let returnData = "";
  if (data) {
    returnData = `(${data})`;
  }
  return returnData;
};
