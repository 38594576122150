import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0
  },
  chip: {
    margin: theme.spacing(0.5)
  }
}));

function ChipsArray({
  searchValues,
  labelName,
  handleDeleteChip,
  agentName,
  processorName,
  adjustment_Name,
  merchant_Name
}) {
  const classes = useStyles();
  const location = useLocation();

  const checkURL = (val) => {
    if (location.pathname.indexOf(val) > -1) {
      return true;
    } else return false;
  };
  return (
    <Paper elevation={0} component="ul" className={classes.root}>
      {Object.entries(searchValues).map((data) => {
        let icon;
        if (!data[1]) {
          return;
        }
        if (data[0] === "Adj_ID") {
          return (
            <li key={data.key}>
              <Chip
                icon={icon}
                label={`${labelName[data[0]]} : ${adjustment_Name}`}
                onDelete={() => handleDeleteChip(data[0])}
                className={classes.chip}
              />
            </li>
          );
        }

        if (data[0] === "ProcessorID" || data[0] === "processor") {
          return (
            <li key={data.key}>
              <Chip
                icon={icon}
                label={`${labelName[data[0]]} : ${processorName}`}
                onDelete={() => handleDeleteChip(data[0])}
                className={classes.chip}
              />
            </li>
          );
        }

        if (data[0] === "ProfileID") {
          return (
            <li key={data.key}>
              <Chip
                icon={icon}
                label={`${labelName[data[0]]} : ${agentName}`}
                onDelete={() => handleDeleteChip(data[0])}
                className={classes.chip}
              />
            </li>
          );
        }

        if (data[0] === "MID") {
          return (
            <li key={data.key}>
              <Chip
                icon={icon}
                label={`${labelName[data[0]]} : ${merchant_Name}`}
                onDelete={() => handleDeleteChip(data[0])}
                className={classes.chip}
              />
            </li>
          );
        }
        if (data[0] === "Status") {
          return (
            <li key={data.key}>
              <Chip
                icon={icon}
                label={`${labelName[data[0]]} : ${
                  data[1] == 1 ? "Active" : "Inactive"
                }`}
                onDelete={() => handleDeleteChip(data[0])}
                className={classes.chip}
              />
            </li>
          );
        }
        if (data[0] === "status" && checkURL("PayoutApproval")) {
          return (
            <li key={data.key}>
              <Chip
                icon={icon}
                label={`${labelName[data[0]]} : ${
                  data[1] == 1
                    ? "Pending"
                    : data[1] == 2
                    ? "Under Review"
                    : data[1] == 3
                    ? "Approved"
                    : "Declined"
                }`}
                onDelete={() => handleDeleteChip(data[0])}
                className={classes.chip}
              />
            </li>
          );
        }

        if (data[0] === "fromMonth" || data[0] === "FromMonth") {
          return (
            <li key={data.key}>
              <Chip
                icon={icon}
                label={`${labelName[data[0]]} : ${moment(data[1]).format(
                  "MMM-YYYY"
                )}`}
                onDelete={() => handleDeleteChip(data[0])}
                className={classes.chip}
              />
            </li>
          );
        }
        if (data[0] === "toMonth" || data[0] === "ToMonth") {
          return (
            <li key={data.key}>
              <Chip
                icon={icon}
                label={`${labelName[data[0]]} : ${moment(data[1]).format(
                  "MMM-YYYY"
                )}`}
                onDelete={() => handleDeleteChip(data[0])}
                className={classes.chip}
              />
            </li>
          );
        }
        return (
          <li key={data.key}>
            <Chip
              icon={icon}
              label={`${labelName[data[0]]} : ${data[1]}`}
              onDelete={() => handleDeleteChip(data[0])}
              className={classes.chip}
            />
          </li>
        );
      })}
    </Paper>
  );
}

ChipsArray.propTypes = {
  searchValues: PropTypes.object,
  labelName: PropTypes.object,
  handleDeleteChip: PropTypes.func,
  agentName: PropTypes.any,
  processorName: PropTypes.any,
  adjustment_Name: PropTypes.any,
  merchant_Name: PropTypes.any
};
export default ChipsArray;
