import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import isEmpty from "lodash/isEmpty";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import FormControl from "@material-ui/core/FormControl";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import tableStyle from "assets/jss/material-dashboard-react/components/reportTables";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import Modal from "@material-ui/core/Modal";
import { Box } from "@material-ui/core";
import Card from "components/Card/Card";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  createTheme,
  ThemeProvider,
  makeStyles,
  withStyles,
  useTheme
} from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles(styles);
const useTableStyle = makeStyles(tableStyle);
function SearchFields({
  agentsList,
  processorList,
  repCodeList,
  searchValues,
  setSearchValues
}) {
  const classes = useStyles();
  const tableClasses = useTableStyle();
  const theme = useTheme();
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const windowWidthSm = useMediaQuery(theme.breakpoints.up("sm"));
  const [showModal, setShowModal] = React.useState(false);
  const [isProcessorSelected, setIsProcessorSelected] = React.useState(false);

  const onChange = (value, type) => {
    const allState = { ...searchValues };
    allState[type] = value;
    setSearchValues({ ...allState });
  };

  const isModalOpen = () => {
    setShowModal(true);
  };
  const defaultProps = {
    options: agentsList && agentsList
  };
  const repCodeListProps = {
    options: repCodeList && repCodeList
  };

  return (
    <>
      <Grid className={classes.margin}>
        <Button variant="outlined" onClick={isModalOpen}>
          Modify Search
        </Button>
      </Grid>
      <Modal
        align="center"
        open={showModal}
        onClose={() => setShowModal(false)}
      >
        <Card
          className={
            windowWidthSm ? tableClasses.modalRoot : tableClasses.modalRootXs
          }
        >
          <div className={tableClasses.statusModalHeader}>
            <div className={tableClasses.statusHeadingText}>
              Search Criteria
            </div>
            <ClearIcon
              style={{ color: "white" }}
              onClick={() => setShowModal(false)}
            />
          </div>
          <Grid container className={classes.margin} style={{ padding: "2%" }}>
            <Grid
              container
              className={classes.margin}
              style={{ marginBottom: "1%" }}
            >
              <Grid item xs>
                <TextField
                  className={classes.rootDialog}
                  InputLabelProps={{
                    classes: {
                      root: classes.activeInputLableColor,
                      focused: "focused"
                    }
                  }}
                  InputProps={{
                    classes: {
                      root: classes.activeInputColor,
                      input: classes.resize,
                      focused: "focused"
                    }
                  }}
                  SelectProps={{
                    MenuProps: { disablePortal: false }
                  }}
                  label="Processor"
                  value={searchValues.processorId}
                  onChange={(e) => {
                    onChange(e.target.value, "processorId");
                    setIsProcessorSelected(true);
                  }}
                  name="processorId"
                  id="processorId"
                  select
                  fullWidth
                >
                  {processorList.map((processor) => (
                    <MenuItem
                      className={classes.dialogBoxDropDown}
                      value={processor.ProcessorID}
                    >
                      {processor.ProcessorName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.margin}
              style={{ marginBottom: "1%" }}
            >
              <Grid item xs>
                <Autocomplete
                  {...defaultProps}
                  name="agentName"
                  id="agentName"
                  style={{
                    fontSize: "14px",
                    "&.focused": {
                      // color: theme.palette.primary.main,
                      fontSize: "14px",
                      fontStyle: "Helvetica, Arial,sans-serif"
                    }
                  }}
                  value={
                    agentsList &&
                    agentsList.find((op) =>
                      op.UserID == searchValues.agentName
                        ? searchValues.agentName
                        : ""
                    )
                  }
                  fullWidth
                  onChange={(e, value) => {
                    if (value) {
                      onChange(value.UserID, "agentName");
                    } else {
                      onChange("", "agentName");
                    }
                  }}
                  clearOnBlur
                  clearOnEscape
                  options={
                    agentsList &&
                    agentsList?.sort((a, b) =>
                      a.AgentName.localeCompare(b.AgentName)
                    )
                  }
                  getOptionLabel={(option) => option.AgentName}
                  getOptionValue={(option) => option.UserID}
                  renderInput={(params) => (
                    <TextField
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      required
                      name="agentName"
                      id="agentName"
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        },
                        required: true
                      }}
                      {...params}
                      label="Agent Name"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.margin}
              style={{ marginBottom: "1%" }}
            >
              <Grid style={{ width: "100%" }} item xs={12}>
                <Autocomplete
                  {...repCodeListProps}
                  name="ProfileID"
                  id="ProfileID"
                  style={{
                    fontSize: "14px",
                    "&.focused": {
                      // color: theme.palette.primary.main,
                      fontSize: "14px",
                      fontStyle: "Helvetica, Arial,sans-serif"
                    }
                  }}
                  value={
                    repCodeList &&
                    repCodeList.find((op) =>
                      op.ProfileID == searchValues.ProfileID
                        ? searchValues.ProfileID
                        : ""
                    )
                  }
                  disabled={!isProcessorSelected}
                  clearOnBlur
                  clearOnEscape
                  fullWidth
                  options={
                    repCodeList &&
                    repCodeList?.sort((a, b) =>
                      a.RepCode.localeCompare(b.RepCode)
                    )
                  }
                  getOptionLabel={(option) => option.RepCode}
                  renderOption={(option) => option.RepCode}
                  onChange={(e, value) => {
                    if (value) {
                      onChange(value.ProfileID, "ProfileID");
                    } else {
                      onChange("", "ProfileID");
                    }
                  }}
                  getOptionValue={(option) => option.ProfileID}
                  renderInput={(params) => (
                    <TextField
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      required
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        },
                        required: true
                      }}
                      {...params}
                      label="RepCode"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={12}
                container
                item
                style={{
                  display: "flex",
                  flexWrap: "nowrap",
                  justifyContent: "flex-end",
                  margin: "1rem"
                }}
                spacing={3}
              >
                <Box mr={2}>
                  <Button
                    variant="outlined"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </Button>
                </Box>
                <Box>
                  <Button
                    type="submit"
                    variant="contained"
                    className={classes.buttonStyle}
                  >
                    Search
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Modal>
    </>
  );
}

SearchFields.propTypes = {
  agentsList: PropTypes.array,
  processorList: PropTypes.array,
  repCodeList: PropTypes.array,
  searchValues: PropTypes.object,
  setSearchValues: PropTypes.func
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(SearchFields);
