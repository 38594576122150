import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import isEmpty from "lodash/isEmpty";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

import MenuItem from "@material-ui/core/MenuItem";
import styles from "../../../../assets/jss/material-dashboard-react/components/businessForm";

const useStyles = makeStyles(styles);

function SimpleDialog({
  setSearchValues,
  handleSubmitSearch,
  handleClearSearch,
  searchValues,
  agentsList,
  processorList,
  repCodeList,
  getAgentList,
  allmerchantList
}) {
  const classes = useStyles();
  const onChange = (value, type) => {
    const allState = { ...searchValues };
    allState[type] = value;
    setSearchValues(allState);
  };

  const onFormSubmit = () => {
    const allState = { ...searchValues };
    handleSubmitSearch(allState);
  };

  const merchantProps = {
    options: allmerchantList && allmerchantList
  };

  return (
    <>
      <Grid container className={classes.margin} style={{ marginBottom: "3%" }}>
        <Grid item xs>
          <TextField
            className={classes.rootDialog}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            SelectProps={{
              MenuProps: { disablePortal: false }
            }}
            label="Processor"
            value={searchValues.processorId}
            onChange={(e) => onChange(e.target.value, "processorId")}
            name="processorId"
            id="processorId"
            select
            fullWidth
          >
            {processorList.map((processor) => (
              <MenuItem
                className={classes.dialogBoxDropDown}
                value={processor.ProcessorID}
              >
                {processor.ProcessorName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Grid container className={classes.margin} style={{ marginBottom: "3%" }}>
        <Grid item xs>
          <Autocomplete
            name="userId"
            id="userId"
            style={{
              fontSize: "14px",
              "&.focused": {
                // color: theme.palette.primary.main,
                fontSize: "14px",
                fontStyle: "Helvetica, Arial,sans-serif"
              }
            }}
            label="Type(s) of goods or services sold"
            value={
              agentsList &&
              agentsList.find((option) =>
                option.UserID === searchValues.userId ? searchValues.userId : ""
              )
            }
            autoHighlight
            fullWidth
            onChange={(e, value) => {
              if (value) {
                onChange(value.UserID, "userId");
              } else {
                onChange("", "userId");
              }
            }}
            selectOnFocus
            options={agentsList || []}
            getOptionLabel={(option) => option.AgentName || ""}
            getOptionValue={(option) => option.UserID || ""}
            renderInput={(params) => (
              <TextField
                InputLabelProps={{
                  classes: {
                    root: classes.activeInputLableColor,
                    focused: "focused"
                  }
                }}
                name="agentName"
                id="agentName"
                required
                InputProps={{
                  classes: {
                    root: classes.activeInputColor,
                    input: classes.resize,
                    focused: "focused"
                  },
                  required: true
                }}
                {...params}
                label="Agent Name"
              />
            )}
            renderOption={(option) => (
              <>
                {option.AgentName && (
                  <span
                    className={classes.dialogBoxDropDown}
                    style={{
                      width: "100%",
                      margin: "-5px -16px",
                      padding: "4px 16px"
                    }}
                  >
                    {option?.AgentName}
                  </span>
                )}
              </>
            )}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.margin} style={{ marginBottom: "3%" }}>
        <Grid item xs>
          <TextField
            className={classes.rootDialog}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            SelectProps={{
              MenuProps: { disablePortal: false }
            }}
            label="Agent Profile (Repcode)"
            value={searchValues.profileId}
            onChange={(e) => onChange(e.target.value, "profileId")}
            name="profileId"
            id="profileId"
            select
            fullWidth
          >
            {repCodeList.map((repCode) => (
              <MenuItem
                className={classes.dialogBoxDropDown}
                value={repCode.ProfileID}
              >
                {repCode.RepCode}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Grid container className={classes.margin} style={{ marginBottom: "3%" }}>
        <Grid style={{ width: "100%" }} item xs={12}>
          <TextField
            key="searchDbaMID"
            className={classes.rootDialog}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            label="DBA Name or MID"
            value={searchValues.searchDbaMID}
            onChange={(e) => onChange(e.target.value, "searchDbaMID")}
            name="searchDbaMID"
            style={{ width: "100%" }}
            fullWidth
            id="searchDbaMID"
            autoFocus={searchValues.searchDbaMID != "" && true}
          />
        </Grid>
      </Grid>
      {/* <Grid container className={classes.margin} style={{ marginBottom: "3%" }}>
        <Grid style={{ width: "100%" }} item xs={12}>
          <TextField
            className={classes.rootDialog}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            SelectProps={{
              MenuProps: { disablePortal: false }
            }}
            label="Status"
            value={searchValues.status}
            onChange={(e) => onChange(e.target.value, "status")}
            name="status"
            style={{ width: "100%" }}
            fullWidth
            id="status"
            select
          >
            <MenuItem className={classes.dialogBoxDropDown} value={1}>
              Mapped
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value={0}>
              Un-Mapped
            </MenuItem>
          </TextField>
        </Grid>
      </Grid> */}

      <Grid
        container
        className={classes.margin}
        style={{ justifyContent: "end", marginTop: "5%" }}
      >
        <Button
          variant="contained"
          className={classes.clearButton}
          disabled={
            searchValues.Current_Status === "" &&
            searchValues.Role === "" &&
            searchValues.Name === ""
          }
          onClick={() => handleClearSearch()}
        >
          clear
        </Button>
        <Button
          variant="contained"
          className={classes.buttonStyle}
          disabled={
            searchValues.Current_Status === "" &&
            searchValues.Role === "" &&
            searchValues.Name === ""
          }
          onClick={() => onFormSubmit()}
        >
          Search
        </Button>
      </Grid>
    </>
  );
}

SimpleDialog.propTypes = {
  setSearchValues: PropTypes.func,
  allmerchantList: PropTypes.array,
  searchValues: PropTypes.object,
  agentsList: PropTypes.array,
  processorList: PropTypes.array,
  getAgentList: PropTypes.array,
  repCodeList: PropTypes.array,
  handleSubmitSearch: PropTypes.func,
  handleClearSearch: PropTypes.func,
  allmerchantList: PropTypes.array
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(SimpleDialog);
