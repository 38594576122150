import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import qs from "qs";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { useSnackbar } from "notistack";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from "@material-ui/core/Button";
import CustomTable from "../../components/CustomizeTable/CustomTable";
import CoustomButton from "../../../../components/CustomButtons/Button";
import tableStyle from "../../components/CustomizeTable/style";
import { exportToCSV } from "../../utils/common";
import {
  fetchAdjustmentDetailsList,
  fetchProcessorList,
  fetchAgentList,
  fetchRepCodeList
} from "../../actions/payout.action";
import { getAdjustmentDetails } from "../../../payout/actions/adjustment.actions";
import { TablePageData } from "../../utils/constants";
import SearchComponent from "../../components/SearchFields";
import SearchedChips from "../../components/SearchChips";
import {
  getQueryParams,
  accountingNumberformat,
  getDataBlankFormat
} from "../../utils/common";

import FilterFields from "./filterFields";
import LastCalculationDialog from "modules/payout/components/LastCalculationDialog/LastCalculationDialog";

const headCellsItems = [
  {
    id: "StartDate",
    label: "Month",
    isSort: true,
    sortProperty: "StartDate",
    actionsCellStyle: "center"
  },
  {
    id: "ProcessorName",
    label: "Processor",
    isSort: true,
    sortProperty: "ProcessorName",
    actionsCellStyle: "center"
  },
  {
    id: "MID",
    label: "Merchant",
    isSort: true,
    sortProperty: "MID",
    actionsCellStyle: "center"
  },
  {
    id: "Agent_Name",
    label: "Agent Name",
    isSort: true,
    sortProperty: "Agent_Name",
    actionsCellStyle: "center"
  },
  {
    id: "Agent_Name",
    label: "Profile",
    isSort: true,
    sortProperty: "Agent_Name",
    actionsCellStyle: "center"
  },
  {
    id: "Adjustment_Name",
    label: "Adjustment Item",
    isSort: true,
    sortProperty: "Adjustment_Name",
    actionsCellStyle: "center"
  },
  {
    id: "Amount",
    label: "Amount ($)",
    isSort: true,
    sortProperty: "Amount",
    actionsCellStyle: "center"
  }
];

const labelName = {
  fromMonth: "From Month",
  toMonth: "To Month",
  searchBy: "Searched By",
  processorId: "Processor",
  userId: "User",
  agentId: "Agent",
  repCode: "Rep Code",
  dbaName: "DBA Name",
  adjustmentName: "Adjustment Name"
};

const searchInitialData = {
  adjustmentName: "",
  agentId: getQueryParams("agentId") || "",
  dbaName: "",
  fromMonth: getQueryParams("fromMonth") || "",
  processorId: getQueryParams("processorId") || "1",
  toMonth: getQueryParams("toMonth") || "",
  userId: "",
  repCode: ""
};

function PayoutSummaryReport({
  fetchAdjustmentDetailsList,
  fetchProcessorList,
  fetchAgentList,
  fetchRepCodeList,
  getAdjustmentDetails,
  users
}) {
  const useStyles = makeStyles(tableStyle);
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const dataParameter = "RowNumber";
  const { enqueueSnackbar } = useSnackbar();
  const [lastCalculationDate, setLastCalculationDate] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [initialCall, setInitialCall] = React.useState(true);
  const [pageDetails, setPageDetails] = React.useState({ ...TablePageData });
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("StartDate");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchValues, setSearchValues] = React.useState({
    ...searchInitialData
  });
  const [adjustmentNameList, setAdjustmentNameList] = React.useState([]);
  const [payoutList, setPayoutList] = React.useState([]);
  const [searchBar, setSearchBar] = React.useState(false);
  const [agentsList, setAgentsList] = React.useState([]);
  const [chipValue, setChipValue] = React.useState({
    ...searchInitialData
  });
  const [processorList, setProcessorList] = React.useState([]);
  const [repCodeList, setRepCodeList] = React.useState([]);
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));

  const findAgentName = (id) => {
    if (!isEmpty(agentsList)) {
      const name = agentsList.find((x) => x.UserID == id);
      return name ? name.AgentName.split("[")[0] : id;
    }
  };

  const findProcessorName = (id) => {
    if (!isEmpty(processorList)) {
      const name = processorList.find((x) => x.ProcessorID == id);
      return name ? name.ProcessorShortName : id;
    }
  };

  const agentName = React.useMemo(
    () => findAgentName(searchValues.agentId),
    [searchValues.agentId, agentsList]
  );
  const processorName = React.useMemo(
    () => findProcessorName(searchValues.processorId),
    [searchValues.processorId, processorList]
  );

  const handleSearchOpen = () => {
    setSearchBar(true);
  };

  const handleSearchClose = () => {
    setSearchBar(false);
  };

  const handleSubmitSearch = () => {
    handleSearchClose();
    setPage(1);
    getPayoutList({
      ...searchValues
    });
    setChipValue(searchValues);
  };

  const handleClearSearch = () => {
    setSearchValues({ ...searchInitialData });
    const url = `/admin/AdjustmentDetails?agentId=${
      searchInitialData.agentId
    }&fromMonth=${
      moment(searchInitialData.fromMonth).format("YYYY-MM-DD") || ""
    }&toMonth=${
      moment(searchInitialData.toMonth).format("YYYY-MM-DD") || ""
    }&processorId=${searchInitialData.processorId}&userId=${
      searchInitialData.userId
    }&dbaName=${searchInitialData.dbaName}&adjustmentName=${
      searchInitialData.adjustmentName
    }`;
    history.push(url);
    localStorage.setItem("AdjustmentDetails", url);
    setChipValue({ ...searchInitialData });
    handleSearchClose();
    setPage(1);
  };

  const getProcessorList = () => {
    fetchProcessorList(
      (data) => {
        if (data.status) {
          setProcessorList(data.data);
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
      }
    );
  };
  const getAgentList = (agentNaame = "") => {
    fetchAgentList(
      (data) => {
        if (data.status) {
          if (
            (data.message === "No record found" && !isEmpty(agentsList)) ||
            isEmpty(data.data)
          ) {
            setAgentsList([
              {
                UserID: 919863231596669598,
                AgentName: "No record found"
              }
            ]);
          }
          if (data.data) {
            setAgentsList(() => [...data.data]);
          }
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
      },
      { search: agentNaame }
    );
  };

  const getRepCodeList = () => {
    fetchRepCodeList(
      (data) => {
        if (data.status) {
          setRepCodeList(data.data);
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
      },
      { userId: searchValues.agentId || "" }
    );
  };

  const getAllAdjustmentNameList = () => {
    getAdjustmentDetails(
      { ...searchValues },
      (records) => {
        if (records.status === "success") {
          setAdjustmentNameList(records.data);
        } else {
          enqueueSnackbar(records.message, {
            variant: "error"
          });
        }
      },
      () => {
        enqueueSnackbar("API Request Failed", {
          variant: "error"
        });
      }
    );
  };

  const handleDeleteChip = (chip) => {
    setPage(1);
    let url = "";
    if (chipValue) {
      const temp = { ...searchValues };
      if (chip === "toMonth") {
        temp.toMonth = "";
        temp.fromMonth = "";
      } else {
        temp[chip] = "";
      }
      setSearchValues({ ...temp });
      setChipValue({ ...temp });
      getPayoutList({
        ...temp
      });
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(1);
    setRowsPerPage(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const viewPayoutDetails = (details, type) => {
    if (type && type === "adjustment") {
      const url = `/admin/AdjustmentDetails?agentId=${
        details.agentId
      }&fromMonth=${
        moment(details.fromMonth).format("YYYY-MM-DD") || ""
      }&toMonth=${
        moment(details.toMonth).format("YYYY-MM-DD") || ""
      }&processorId=${details.processorId}&repCode=${details.repCode}&userId=${
        details.userId
      }`;
      history.push(url);
      localStorage.setItem("AdjustmentDetails", url);
    } else {
      const url = `/admin/IncomeExpenseDetail?payoutId=${
        details.RowNumber || ""
      }&searchBy=${searchValues.searchBy}&fromMonth=${
        moment(details.ResidualMonth).format("YYYY-MM-DD") || ""
      }&toMonth=${moment(details.ResidualMonth).format("YYYY-MM-DD") || ""}`;
      history.push(url);
    }
  };
  const exportedBy =
    users.users.userDetailsById &&
    users.users.userDetailsById?.Users_Data[0].Email;
  const exportedByFname =
    users.users.userDetailsById &&
    users.users.userDetailsById?.Users_Data[0].FName;
  const exportedByLname =
    users.users.userDetailsById &&
    users.users.userDetailsById?.Users_Data[0].LName;

  const exportListData = () => {
    const filterData = {
      sortColumn: orderBy,
      sortOrder: order,
      recordPerPage: "",
      pageNo: 1,
      ...searchValues
    };
    filterData.userId = localStorage.getItem("userId") || "";
    fetchAdjustmentDetailsList(
      { ...filterData },
      (records) => {
        if (records.status === "success") {
          const content = get(records, "data", []);
          if (!isEmpty(content)) {
            const mappedLogs = content.map((row) => ({
              Month: row.StartDate,
              ProcessorName: row.ProcessorName,
              Merchant: row.MID,
              "Agent Name": row.Agent_Name,
              Profile: row.RepCode,
              "Adjustment Item": row.Adjustment_Name,
              Amount: row.Amount
            }));
            exportToCSV(
              mappedLogs,
              "Payout Details",
              chipValue,
              exportedBy,
              exportedByFname,
              exportedByLname,
              labelName
            );
          }
        } else {
          enqueueSnackbar(records.message, {
            variant: "error"
          });
        }
      },
      () => {
        enqueueSnackbar("API Request Failed", {
          variant: "error"
        });
      }
    );
  };

  const getPayoutList = function (searchValues) {
    let filterData = {
      ...searchValues,
      sortColumn: orderBy,
      sortOrder: order,
      recordPerPage: rowsPerPage.value === 0 ? "" : rowsPerPage,
      pageNo: page,
      userId: localStorage.getItem("userId")
    };
    fetchAdjustmentDetailsList(
      { ...filterData },
      (records) => {
        if (records.status === "success") {
          setLastCalculationDate(records.lastCalculation);
          const content = get(records, "data", []);
          const { totalCount } = records;
          setPayoutList(content);
          setPageDetails({
            ...pageDetails,
            lastPage: Math.ceil(totalCount / rowsPerPage),
            from: page === 1 ? 1 : (page - 1) * rowsPerPage + 1,
            to:
              page * rowsPerPage < totalCount ? page * rowsPerPage : totalCount,
            total: totalCount
          });
        } else {
          enqueueSnackbar(records.message, {
            variant: "error"
          });
        }
      },
      () => {
        enqueueSnackbar("API Request Failed", {
          variant: "error"
        });
      }
    );
  };

  React.useEffect(() => {
    if (isEmpty(processorList)) {
      getProcessorList();
    }
    if (isEmpty(agentsList)) {
      getAgentList();
    }

    if (isEmpty(adjustmentNameList)) {
      getAllAdjustmentNameList();
    }
  }, []);

  React.useEffect(() => {
    if (users.payout.lastCalcDate && users.payout.lastCalcDate.data) {
      const serarcValuesFromLocal = {
        repCode:
          searchValues.repCode != ""
            ? searchValues.repCode
            : getQueryParams("repCode"),
        agentId:
          searchValues.agentId != ""
            ? searchValues.agentId
            : getQueryParams("agentId"),
        fromMonth:
          searchValues.fromMonth != ""
            ? searchValues.fromMonth
            : moment(users.payout.lastCalcDate.data[0].LastCalcMonthYear)
                .startOf("month")
                .format("YYYY-MM-DD"),
        toMonth:
          searchValues.toMonth != ""
            ? searchValues.toMonth
            : moment(users.payout.lastCalcDate.data[0].LastCalcMonthYear)
                .endOf("month")
                .format("YYYY-MM-DD"),
        processorId:
          searchValues.processorId != ""
            ? searchValues.processorId
            : users.payout.lastCalcDate.data[0].ProcessorID,
        dbaName: searchValues.dbaName,
        adjustmentName: searchValues.adjustmentName
      };
      setChipValue(serarcValuesFromLocal);
      setSearchValues(serarcValuesFromLocal);
      getPayoutList({
        ...serarcValuesFromLocal
      });
    }
  }, [orderBy, order, page, rowsPerPage]);

  React.useEffect(() => {
    if (users.payout.lastCalcDate && users.payout.lastCalcDate.data) {
      if (localStorage.getItem("AdjustmentDetails")) {
        const latestNew = qs.parse(localStorage.getItem("AdjustmentDetails"));
        const serarcValuesFromLocal = {
          repCode: latestNew.repCode,
          adjustmentName: latestNew.adjustmentName,
          agentId: getQueryParams("agentId"),
          dbaName: latestNew.dbaName,
          fromMonth: latestNew.fromMonth,
          processorId: latestNew.processorId,
          toMonth: latestNew.toMonth,
          userId: latestNew.userId
        };
        setChipValue(serarcValuesFromLocal);
        setSearchValues(serarcValuesFromLocal);
        getPayoutList(serarcValuesFromLocal);
      } else {
        const serarcValuesFromLocal = {
          repCode: getQueryParams("repCode") || "",
          adjustmentName: "",
          agentId: getQueryParams("agentId") || "",
          dbaName: "",
          fromMonth: moment(users.payout.lastCalcDate.data[0].LastCalcMonthYear)
            .startOf("month")
            .format("YYYY-MM-DD"),
          processorId:
            getQueryParams("processorId") ||
            users.payout.lastCalcDate.data[0].ProcessorID,
          toMonth: moment(users.payout.lastCalcDate.data[0].LastCalcMonthYear)
            .endOf("month")
            .format("YYYY-MM-DD"),
          userId: localStorage.getItem("userId")
        };
        setChipValue(serarcValuesFromLocal);
        setSearchValues(serarcValuesFromLocal);
        getPayoutList(serarcValuesFromLocal);
      }
    }
  }, [users.payout.lastCalcDate]);

  React.useEffect(() => {
    if (searchValues.agentId) {
      getRepCodeList();
    }
  }, [searchValues.agentId]);

  return (
    <>
      <TableContainer className={classes.TableContainer} component={Paper}>
        {windowWidth && (
          <Grid
            container
            className={classes.searchContainer}
            style={{ flexWrap: "nowrap", justifyContent: "space-between" }}
          >
            <Grid
              item
              className={classes.margin}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start"
              }}
            >
              <div style={{ padding: "10px" }}>
                <LastCalculationDialog />
              </div>
            </Grid>
            <Grid item className={classes.margin}>
              <div className={classes.searchWrapper}>
                <SearchComponent
                  searchBar={searchBar}
                  handleSearchClose={handleSearchClose}
                  handleSearchOpen={handleSearchOpen}
                  Fields={() => (
                    <FilterFields
                      handleSearchClose={() => handleSearchClose()}
                      setSearchValues={setSearchValues}
                      searchValues={searchValues}
                      agentsList={agentsList}
                      processorList={processorList}
                      repCodeList={repCodeList}
                      getAgentList={getAgentList}
                      handleSubmitSearch={handleSubmitSearch}
                      handleClearSearch={handleClearSearch}
                      adjustmentNameList={adjustmentNameList}
                    />
                  )}
                />
                <CoustomButton
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={exportListData}
                  variant="contained"
                  className={classes.ExportButtonStyle}
                >
                  Export
                </CoustomButton>
              </div>
            </Grid>
          </Grid>
        )}
        {!windowWidth && (
          <Grid container className={classes.searchContainer}>
            <Grid
              item
              className={classes.margin}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start"
              }}
            >
              <div style={{ padding: "10px" }}>
                <LastCalculationDialog />
              </div>
            </Grid>
            <Grid item className={classes.expirtItemGrid}>
              <SearchComponent
                searchBar={searchBar}
                handleSearchClose={handleSearchClose}
                handleSearchOpen={handleSearchOpen}
                Fields={() => (
                  <FilterFields
                    handleSearchClose={() => handleSearchClose()}
                    setSearchValues={setSearchValues}
                    searchValues={searchValues}
                    agentsList={agentsList}
                    processorList={processorList}
                    repCodeList={repCodeList}
                    getAgentList={getAgentList}
                    handleSubmitSearch={handleSubmitSearch}
                    handleClearSearch={handleClearSearch}
                  />
                )}
              />
              <CoustomButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={exportListData}
                variant="contained"
                color="primary"
                className={classes.buttonStyle}
              >
                Export
              </CoustomButton>
            </Grid>
          </Grid>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingLeft: "10px"
          }}
        >
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            className={classes.clearButton}
            onClick={() => history.goBack()}
          >
            Back
          </Button>
          <SearchedChips
            handleDeleteChip={handleDeleteChip}
            searchValues={chipValue}
            labelName={labelName}
            agentName={agentName}
            processorName={processorName}
          />
        </div>
        <CustomTable
          page={page}
          order={order}
          orderBy={orderBy}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          data={payoutList}
          headCells={headCellsItems}
          dataParameter={dataParameter}
          isCallInitialization={initialCall}
          selected={[]}
          setSelected={() => {}}
          isSelection={false}
          rowsPerPage={rowsPerPage}
          pageDetails={pageDetails}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        >
          {payoutList.map((row) => (
            <TableRow key={row.RowNumber} className={classes.cellHeadSign}>
              <TableCell
                align="center"
                className={classes.cellText}
                // onClick={() => viewPayoutDetails(row)}
                // onClick={() => handleAddEdit("edit", row)}
              >
                {row.StartDate}
              </TableCell>
              <TableCell
                align="center"
                className={classes.cellText}
                // onClick={() => viewPayoutDetails(row)}
                // onClick={() => handleAddEdit("edit", row)}
              >
                {row.ProcessorName}
              </TableCell>
              <TableCell
                align="center"
                className={classes.cellText}
                // onClick={() => viewPayoutDetails(row)}
                // onClick={() => handleAddEdit("edit", row)}
              >
                {row.MID}
                <br />
                <span style={{ color: "#0093c9" }}>
                  {getDataBlankFormat(row.DBAName)}
                </span>
              </TableCell>
              <TableCell
                align="center"
                className={classes.cellText}
                // onClick={() => viewPayoutDetails(row)}
                // onClick={() => handleAddEdit("edit", row)}
              >
                {row.Agent_Name}
              </TableCell>
              <TableCell
                align="center"
                className={classes.cellText}
                // onClick={() => viewPayoutDetails(row)}
                // onClick={() => handleAddEdit("edit", row)}
              >
                {row.RepCode}
              </TableCell>
              <TableCell
                align="center"
                className={classes.cellText}
                // onClick={() => viewPayoutDetails(row)}
                // onClick={() => handleAddEdit("edit", row)}
              >
                {row.Adjustment_Name}
              </TableCell>
              <TableCell
                align="center"
                className={classes.cellText}
                // onClick={() => viewPayoutDetails(row)}
                // onClick={() => handleAddEdit("edit", row)}
              >
                {row?.Amount?.toString().includes("-") ? (
                  <span style={{ color: "red" }}>
                    ({row?.Amount?.toString().replace("-", "")})
                  </span>
                ) : (
                  row.Amount
                )}
              </TableCell>
            </TableRow>
          ))}
        </CustomTable>
      </TableContainer>
    </>
  );
}
PayoutSummaryReport.propTypes = {
  fetchAdjustmentDetailsList: PropTypes.func,
  fetchProcessorList: PropTypes.func,
  fetchAgentList: PropTypes.func,
  fetchRepCodeList: PropTypes.func,
  getAdjustmentDetails: PropTypes.func,
  users: PropTypes.object
};

PayoutSummaryReport.defaultProps = {
  fetchAdjustmentDetailsList: () => {},
  fetchProcessorList: () => {},
  fetchAgentList: () => {},
  fetchRepCodeList: () => {},
  getAdjustmentDetails: () => {},
  users: () => {}
};

const mapStateToProps = (app) => ({
  users: app
});

export default connect(mapStateToProps, {
  fetchAdjustmentDetailsList,
  fetchProcessorList,
  fetchAgentList,
  fetchRepCodeList,
  getAdjustmentDetails
})(PayoutSummaryReport);
