import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import { connect } from "react-redux";
import Radio from "@material-ui/core/Radio";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { Button, Grid, InputLabel, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import DateFnsUtils from "@date-io/date-fns";
import Select from "@material-ui/core/Select";
import { Field, Form, Formik, ErrorMessage } from "formik";
import {
  createTheme,
  ThemeProvider,
  makeStyles,
  withStyles
} from "@material-ui/core/styles";
import isEmpty from "lodash/isEmpty";
import styles from "assets/jss/material-dashboard-react/components/userForm";
import AppBar from "@material-ui/core/AppBar";
import SwipeableViews from "react-swipeable-views";
import PayoutCalculation from "./payout_calculation/payout_calculation";
import CalculationSummary from "./calculation_summary/calculation_summary";
import CalculationHistory from "./CalculationHistory";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      style={{ overflowX: "hidden" }}
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ padding: "0px" }} p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const TabStyle = withStyles((theme) => ({
  root: {
    "&$selected": {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium
    }
  },
  tab: {
    padding: "0.5rem",
    fontFamily: "Open Sans",
    fontSize: "2rem",
    backgroundColor: "grey",
    color: "black",
    "&:hover": {
      backgroundColor: "red",
      color: "white",
      opacity: 1
    }
  },
  selected: {}
}))((props) => <Tab {...props} />);

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  };
}

const useStyles = makeStyles((theme) => ({
  ...styles,
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%"
  },
  indicator: {
    color: theme.palette.primary.main
  }
}));

const PayoutManager = () => {
  const classes = useStyles();
  const history = useHistory();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    if (newValue === 1) {
      history.push("CalculationSummary");
    }
    setValue(newValue);
  };

  return (
    <>
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            style={{ width: "100%", minWidth: "fit-content" }}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <TabStyle
              classes={{ selected: "#2ba992" }}
              style={{ maxWidth: "fit-content", textTransform: "none" }}
              label="Payout Calculation"
              {...a11yProps(0)}
            />
            <TabStyle
              style={{ maxWidth: "fit-content", textTransform: "none" }}
              label="Calculation Summary"
              {...a11yProps(1)}
            />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <PayoutCalculation />
        </TabPanel>
      </div>
    </>
  );
};

PayoutManager.propTypes = {};

PayoutManager.defaultProps = {};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(PayoutManager);
