import { get, post } from "../api_interceptor/httpClient";
import toggleLoaderAction from "../../../Redux/Actions/loader";
import {
  GET_PAYOUT_LIST,
  GET_AGENT_LIST,
  CREATE_NEW_AGENT_PAYOUT,
  GET_REP_CODE_LIST,
  GET_AGENT_PAYOUT_MULTISPLIT_LIST,
  GET_SHARE_TYPES_LIST,
  GET_SPLIT_PAYEE_LIST,
  GET_SPLIT_PAYER_LIST,
  CREATE_AGENT_MULTI_SPLIT,
  REMOVE_AGENT_MULTI_SPLIT,
  CHANGE_PAYOUT_STATUS,
  CLONE_AGENT_PROFILE,
  AGENT_PROFILE_VERSIONS_LIST,
  UPDATE_AGENT_PS
} from "../api_interceptor/urls";

export const fetchPayoutList =
  (payload, successCallback, failureCallback) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await get(GET_PAYOUT_LIST, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };

export const fetchAgentList =
  (successCallback, failureCallback, payload) => async () => {
    const userId = localStorage.getItem("userId") || "";
    try {
      const data = await get(GET_AGENT_LIST, { userId, ...payload });

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const fetchRepCodeList =
  (successCallback, failureCallback, payload) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await get(GET_REP_CODE_LIST, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };

export const fetctAgentPayoutList =
  (successCallback, failureCallback, payload) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await get(GET_AGENT_PAYOUT_MULTISPLIT_LIST, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };

export const addNewAgentPayout =
  (payload, successCallback, failureCallback) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await post(CREATE_NEW_AGENT_PAYOUT, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };

export const fetctShareTypeList =
  (successCallback, failureCallback) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await get(GET_SHARE_TYPES_LIST);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };

export const fetctAgentPayeeList =
  (successCallback, failureCallback, payload) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await get(GET_SPLIT_PAYEE_LIST, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };

export const fetctAgentPayerList =
  (successCallback, failureCallback, payload) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await get(GET_SPLIT_PAYER_LIST, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };

export const addNewAgentMultiSplit =
  (payload, successCallback, failureCallback) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await post(CREATE_AGENT_MULTI_SPLIT, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };

export const deleteAgentMultiSplit =
  (successCallback, failureCallback, payload) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await get(REMOVE_AGENT_MULTI_SPLIT, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };

export const changeAgentPayoutSplitStatus =
  (successCallback, failureCallback, payload) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await get(CHANGE_PAYOUT_STATUS, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };

export const cloneAgentProfile =
  (payload, successCallback, failureCallback) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await post(CLONE_AGENT_PROFILE, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };

export const getAgentProfileVersionList =
  (payload, successCallback, failureCallback) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await get(AGENT_PROFILE_VERSIONS_LIST, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };

export const updateAgentPS =
  (payload, successCallback, failureCallback) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await post(UPDATE_AGENT_PS, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };
