/* eslint-disable no-inner-declarations */
/* eslint-disable no-tabs */
/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useRef, useEffect } from "react";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import PropTypes from "prop-types";

// import exportCss from '../../assets/css/amchart/export';
// eslint-disable-next-line camelcase
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import "../../views/Dashboard/dashboard.css";

am4core.useTheme(am4themes_animated);

function ActivePatient(props) {
  useEffect(() => {
    if (document.getElementById("chartRef") !== null) {
      const chart = am4core.create("chartRef", am4charts.XYChart);
      chart.logo.disabled = true;
      chart.width = am4core.percent(100);
      chart.height = am4core.percent(100);
      const title = chart.titles.create();
      title.text = "Patients";

      title.fontSize = 13;
      title.fontFamily = "Calibri";
      title.paddingBottom = 10;
      title.align = "left";
      title.fontWeight = "bold";

      if (
        props.dashboardData.activePatientData &&
        props.dashboardData.activePatientData.data
      ) {
        // Add data
        chart.data = props.dashboardData.activePatientData.data;
      }

      // Create axes
      const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "monthYear";
      categoryAxis.renderer.minGridDistance = 0;
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.grid.template.disabled = true;
      categoryAxis.renderer.labels.template.rotation = 315;
      categoryAxis.renderer.labels.template.verticalCenter = "middle";
      categoryAxis.renderer.labels.template.fontSize = am4core.percent(70);
      categoryAxis.renderer.labels.template.url = "/admin/activePatient";
      categoryAxis.renderer.labels.template.urlTarget = "_self";

      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.renderer.grid.template.location = 0;
      valueAxis.renderer.grid.template.disabled = true;
      valueAxis.renderer.labels.template.fontSize = am4core.percent(70);

      // Create series
      function createSeries(field, name, color) {
        const series = chart.series.push(new am4charts.ColumnSeries());
        series.name = name;
        series.fill = color;
        series.stroke = color;
        series.dataFields.valueY = field;
        series.dataFields.categoryX = "monthYear";
        series.sequencedInterpolation = true;
        series.stacked = true;
        series.columns.template.width = am4core.percent(40);
        series.dummyData = {
          description: name
        };
        // series.columns.template.tooltipText = "[bold,font-size:11px]{name} :[/]\n-----------------\n[font-size:11px]{categoryX}: {valueY}";

        series.columns.template.tooltipHTML = `<table style="font-size:10px">
		   <tr>
		   <center style="font-size:10px"><strong>{name}</strong></center>
		   <hr />
		 </tr>
		
		   <tr>
			 <th align="left">{categoryX}:</th>
			 <td>{valueY}</td>
		   </tr>
		  	   
		   </table>`;

        const labelBullet = series.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.text = "{valueY}";
        labelBullet.locationY = 0.5;
        labelBullet.label.color = am4core.color("white");
        labelBullet.label.fontSize = am4core.percent(70);
        labelBullet.label.hideOversized = true;

        return series;
      }

      createSeries("activePatient", "Regular Patient", "#6aaac8");
      createSeries("default", "Accounts in Default", "#94d18e");
      createSeries("Movetocollection", "Moved To Collection", "#cae6cc");
      createSeries("Closed", "Closed", "#e6ce93");
      chart.legend = new am4charts.Legend();
      chart.legend.position = "right";
      chart.legend.width = am4core.percent(22);
      chart.legend.useDefaultMarker = true;
      chart.legend.fontSize = 10;
      /*-----------------*/
      chart.legend.labels.template.maxWidth = 10;
      chart.legend.labels.template.truncate = true;
      chart.legend.labels.template.wrap = true;
      // chart.legend.itemContainers.template.tooltipText = "{dataContext.dummyData.description}";
      chart.legend.itemContainers.backgroundColor = am4core.color("#ffffff");
      chart.legend.itemContainers.template.tooltipHTML = `<table style="font-size:10px">
		   <tr>
		   <center style="font-size:10px">{dataContext.dummyData.description}</center>
			 </tr>   </table>`;

      /*--------------------*/

      const marker = chart.legend.markers.template.children.getIndex(0);
      marker.cornerRadius(12, 12, 12, 12);
      marker.strokeWidth = 0;
      marker.strokeOpacity = 0;

      const markerTemplate = chart.legend.markers.template;
      markerTemplate.width = 10;
      markerTemplate.height = 10;
    }
  }, [props]);
  return (
    <>
      <div id="chartRef" style={{ width: "100%", height: "20rem" }} />
    </>
  );
}

ActivePatient.propTypes = {
  dashboardData: PropTypes.object
};

export default ActivePatient;
