import "../../assets/css/CountryCode.scss";
import React from "react";

import { Select } from "@material-ui/core";
import Autocomplete, {
  createFilterOptions
} from "@material-ui/lab/Autocomplete";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
// import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Radio from "@material-ui/core/Radio";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Tooltip from "@material-ui/core/Tooltip";
import MenuItem from "@material-ui/core/MenuItem";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { useSnackbar } from "notistack";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { Divider } from "@material-ui/core";
import styles from "assets/jss/material-dashboard-react/components/userForm";
import isEmpty from "lodash/isEmpty";
// import useMediaQuery from "@material-ui/core/useMediaQuery";
// import closeImage from "assets/img/Close_Image.png";
// import gif from "../../assets/img/success.gif";
// import Card from "components/Card/Card";
import { capitalizeFirstLetter } from "Utils/commonUtils";
import MultipleSelect from "../../components/GroupsMultipleSelect/Index";
import Validation from "../../Utils/Validations";
import { userListApi } from "../../actions/TableData";
import { locationCity } from "../../constant/locationConstant";
import {
  getCountryTypeList,
  getProfileTypeList,
  getRoleList,
  changeUserFormMode,
  getTimeZoneList,
  getUserList,
  getUserListProvider,
  getGroupListData,
  getProviderRoleList
} from "../../Redux/Actions/UserRegistration";
import {
  updateUserApiCall,
  createNewUserApiCall,
  autoGeneratedRepCode
} from "../../actions/Operations";
import {
  getAgentByHierarchy,
  getMerchantByHierarchy
} from "../../Redux/Actions/ProvideRegistration";

const useStyles = makeStyles(styles);

const GreenRadio = withStyles((theme) => ({
  root: {
    "&$checked": {
      color: theme.palette.primary.main
    }
  },
  checked: {}
}))((props) => <Radio color="default" {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  };
}

// const useStyles = makeStyles(theme => ({
//   root: {
//     backgroundColor: theme.palette.background.paper,
//     width: '100%',
//   },
// }));

function UserManager(props) {
  const classes = useStyles();
  let userid = localStorage.getItem("userId");
  const filter = createFilterOptions();
  const { enqueueSnackbar } = useSnackbar();
  const formikRef = React.useRef(null);
  const [filterDataRole, setFilterDataRole] = React.useState(false);
  const [userList, setUserList] = React.useState([]);
  const [dataRole, setDataRole] = React.useState(false);
  const [preUserListData, setPreUserListData] = React.useState(
    props.formData.groupListData
  );
  const [initialValues, setInitialValues] = React.useState({
    Profile_Code: "",
    First_Name: "",
    Middle_Name: "",
    Last_Name: "",
    Email: "",
    Password: "Admin@1234",
    Role_ID: "",
    Address1: "",
    Address2: "",
    Extension: "",
    City: "",
    Mobile: "",
    Phone_No: "",
    Country_Code: "US",
    State_Code: "",
    Zip: "",
    Time_Zone_Code: "",
    Accessible_Provider: [props.userRole.userList.ClientID],
    reportsTo: "",
    profileVisibility: "1",
    confirmPassword: "Admin@1234",
    groupId: [],
    repoCode: "",
    CreateByClientId: localStorage.getItem("userId"),
    ClientID: props.userRole.userList.ClientID,
    Created_By: localStorage.getItem("userId"),
    dialCode: 1
  });

  const longText = (
    <ul>
      <li>Uppercase Letters</li>
      <li>Lowercase Letters</li>
      <li>Numbers</li>
      <li>Special Character</li>
    </ul>
  );

  const callUserListApi = () => {
    props.userListApi(
      {
        ClientId: localStorage.getItem("clientId"),
        LoginEmail: localStorage.getItem("email"),
        formType: "reportsTo"
      },
      (data) => {
        setUserList(data.Users_Data);
      }
    );
  };

  React.useEffect(() => {
    const data = {
      userID: userid
    };
    // if (!props.formData.agentList && !props.formData.merchantList) {
    props.getAgentByHierarchy(data);
    props.getMerchantByHierarchy(data);
    // }
  }, []);

  const autoGeneratedRepCodeCall = () => {
    props.autoGeneratedRepCode(
      (data) => {
        if (data.status) {
          formikRef.current.setFieldValue("repoCode", data.data.newRepoCode);
        }
      },
      (err) => {
        enqueueSnackbar("Failed to auto generate.", {
          variant: "error"
        });
      }
    );
  };

  const handleVisability = (event) => {
    if (event.target.value === "3") {
      formikRef.current.setFieldValue("profileVisibility", "3");
      props.getGroupListData();
    } else {
      formikRef.current.setFieldValue("profileVisibility", event.target.value);
    }
  };

  const handleUserType = (e) => {
    const valueRole = props.roles.providerroleList.Roles_Data.filter(
      (v) => v.Profile_Code === e
    );
    setFilterDataRole(valueRole);
    formikRef.current.setFieldValue("Profile_Code", e);
    formikRef.current.setFieldValue("Role_ID", "");
  };

  const handleBack = () => {
    props.changeUserFormMode("init");
  };

  if (
    !dataRole &&
    props.roles.providerroleList &&
    props.roles.providerroleList.Roles_Data &&
    props.formData.userDetailsById
  ) {
    const preData = props.formData.userDetailsById.Users_Data[0];
    const valueRole = props.roles.providerroleList.Roles_Data.filter(
      (v) => v.Profile_Code === preData.Profile_Code
    );

    const updatedData = {
      Profile_Code: preData.Profile_Code,
      First_Name: preData.FName,
      Middle_Name: preData.MName,
      Last_Name: preData.LName,
      Email: preData.Email,
      Password: "",
      Role_ID: preData.RoleID,
      Address1: preData.Address1,
      Address2: preData.Address2,
      City: preData.City,
      Mobile: preData.Mobile ? formatPhoneNumber(preData?.Mobile) : "",
      Phone_No: preData.Phone ? formatPhoneNumber(preData?.Phone) : "",
      Country_Code: preData.Country_Code,
      State_Code: preData.State_Code,
      Extension: preData.Extension || "",
      Zip: preData.Zip,
      Time_Zone_Code: preData.TimeZone_Code,
      Accessible_Provider: preData.Accessible_Provider,
      reportsTo: preData.reportsTo,
      profileVisibility: preData.profileVisibility.toString(),
      confirmPassword: "",
      groupId: preData.groupId,
      repoCode: preData.repoCode || "",
      ClientID: preData.ClientID,
      dialCode: preData.dialCode,
      UserID: preData.UserID
    };

    setInitialValues({ ...updatedData });
    setFilterDataRole(valueRole);
    setDataRole(true);
  }

  function formatPhoneNumber(v) {
    let r = v.replace(/\D/g, "");
    if (r.length > 11) {
      r = r.replace(/^(\d\d\d)(\d{3})(\d{0,4}).*/, "$1-$2-$3");
      return r;
    }
    if (r.length > 6) {
      r = r.replace(/^(\d\d\d)(\d{3})(\d{0,4}).*/, "$1-$2-$3");
    } else if (r.length > 3) {
      r = r.replace(/^(\d\d\d)(\d{0,3})/, "$1-$2");
    } else {
      r = r.replace(/^(\d*)/, "$1");
    }
    return r;
  }

  const onFormSubmit = (values) => {
    if (props.formData.formMode === "Create") {
      props.createNewUserApiCall(
        values,
        (data) => {
          if (data.status) {
            enqueueSnackbar(data.message, {
              variant: "success"
            });
            handleBack();
          } else {
            enqueueSnackbar(data.message, {
              variant: "error"
            });
          }
        },
        (err) => {
          enqueueSnackbar(err.message, {
            variant: "error"
          });
        }
      );
    }
    if (props.formData.formMode === "Edit") {
      const finalValues = { ...values };
      props.updateUserApiCall(
        finalValues,
        (data) => {
          enqueueSnackbar(data.message, {
            variant: "success"
          });
          handleBack();
        },
        (err) => {
          enqueueSnackbar(err.message, {
            variant: "error"
          });
        }
      );
    }
  };

  const profileCode =
    props?.userRole?.userList &&
    props?.userRole?.userList?.Role_Permission_Data &&
    props?.userRole?.userList?.Role_Permission_Data?.Profile_Code;

  React.useEffect(() => {
    if (!props.formData.profileTypeList && profileCode !== false) {
      props.getProfileTypeList(profileCode);
    }
  }, [profileCode]);

  React.useEffect(() => {
    callUserListApi();
    if (!props.formData.countryTypeList) {
      props.getCountryTypeList();
    }
    if (!props.formData.roleList) {
      props.getRoleList();
    }
    if (!props.formData.timeZoneList) {
      props.getTimeZoneList();
    }
    if (!props.formData.providerroleList) {
      props.getProviderRoleList();
    }
    if (!props.formData.userProvider) {
      const data = {
        clientId: props.userRole.userList.ClientID
      };
      props.getUserListProvider(data);
    }
  }, []);

  React.useEffect(() => {
    if (preUserListData !== props.formData.groupListData) {
      setPreUserListData(props.formData.groupListData);
    }
  }, [props]);

  const agentLists =
    props.formData.agentList &&
    props.formData.agentList.data &&
    props.formData.agentList.data;

  const defaultProps = {
    options: agentLists && agentLists
  };

  return (
    <>
      <Box className={classes.marginBox}>
        <Formik
          innerRef={(p) => {
            formikRef.current = p;
          }}
          onSubmit={onFormSubmit}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={
            props.formData.formMode === "Create"
              ? Validation.createUser
              : Validation.updateUser
          }
        >
          {({ errors, setFieldValue, values, touched, handleSubmit }) => (
            <Form>
              <Grid container className={classes.margin}>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Field
                    as={TextField}
                    required
                    error={touched.Profile_Code && errors.Profile_Code}
                    className={classes.halfInput}
                    onChange={(e) => handleUserType(e.target.value)}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      color: "secondary",
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    // disabled={props.formData.formMode !== "Create"}
                    label="Choose User Type"
                    name="Profile_Code"
                    id="Profile_Code"
                    select
                    style={{ height: "5.187em" }}
                    helperText={
                      touched.Profile_Code && errors.Profile_Code
                        ? errors.Profile_Code
                        : ""
                    }
                  >
                    {props.formData.profileTypeList &&
                      props.formData?.profileTypeList?.Profile_Data.map(
                        (key) => (
                          <MenuItem
                            className={classes.dialogBoxDropDown}
                            value={key.Profile_Code}
                            key={key.Profile_Code}
                          >
                            {key.ProfileName}
                          </MenuItem>
                        )
                      )}
                  </Field>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Field
                    as={TextField}
                    required
                    error={errors.repoCode && touched.repoCode}
                    className={classes.halfInput}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button
                            style={{ marginBottom: "5px" }}
                            size="small"
                            disabled={props.formData.formMode !== "Create"}
                            aria-label="toggle password visibility"
                            onClick={() => autoGeneratedRepCodeCall()}
                          >
                            Auto Generate
                          </Button>
                          <Divider
                            variant="middle"
                            style={{ height: 18, margin: 4 }}
                            orientation="vertical"
                          />
                          <Tooltip
                            style={{ marginBottom: "5px" }}
                            title="Can be Entered manually Or You can auto-Generated"
                            classes={{
                              tooltip: classes.customTooltip
                            }}
                          >
                            <ErrorOutlineIcon />
                          </Tooltip>
                        </InputAdornment>
                      ),
                      color: "secondary",
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    disabled={props.formData.formMode !== "Create"}
                    label="User Code"
                    onChange={(e) => {
                      setFieldValue("repoCode", e.target.value.trim());
                    }}
                    name="repoCode"
                    id="repoCode"
                    style={{ height: "5.187em" }}
                    helperText={
                      errors.repoCode && touched.repoCode ? errors.repoCode : ""
                    }
                  />
                </Grid>
                <Grid item xs />
              </Grid>
              <Grid container className={classes.margin}>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Field
                    as={TextField}
                    required
                    error={errors.First_Name && touched.First_Name}
                    className={classes.halfInput}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      color: "secondary",
                      classes: {
                        root: classes.activeInputColor,
                        focused: "focused",
                        input: classes.resize
                      },
                      required: true
                    }}
                    label="First Name"
                    name="First_Name"
                    helperText={
                      errors.First_Name && touched.First_Name
                        ? errors.First_Name
                        : ""
                    }
                    id="First_Name"
                    style={{ height: "5.187em" }}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Field
                    as={TextField}
                    error={errors.Middle_Name && touched.Middle_Name}
                    className={classes.halfInput}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      color: "secondary",
                      classes: {
                        root: classes.activeInputColor,
                        focused: "focused",
                        input: classes.resize
                      },
                      required: true
                    }}
                    label="Middle Name"
                    name="Middle_Name"
                    id="Middle_Name"
                    style={{ height: "5.187em" }}
                    helperText={
                      errors.Middle_Name && touched.Middle_Name
                        ? touched.Middle_Name
                        : ""
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Field
                    as={TextField}
                    required
                    error={errors.Last_Name && touched.Last_Name}
                    className={classes.halfInput}
                    InputLabelProps={{
                      color: "secondary",
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        focused: "focused",
                        input: classes.resize
                      },
                      required: true
                    }}
                    label="Last Name"
                    name="Last_Name"
                    id="Last_Name"
                    style={{ height: "5.187em" }}
                    helperText={
                      errors.Last_Name && touched.Last_Name
                        ? errors.Last_Name
                        : ""
                    }
                  />
                </Grid>
              </Grid>
              <Grid container className={classes.margin}>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Field
                    as={TextField}
                    required
                    error={errors.Email && touched.Email}
                    className={classes.halfInput}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      color: "secondary",
                      classes: {
                        root: classes.activeInputColor,
                        focused: "focused",
                        input: classes.resize
                      },
                      required: true
                    }}
                    disabled={props.formData.formMode !== "Create"}
                    label="Email"
                    name="Email"
                    id="Email"
                    style={{ height: "5.187em" }}
                    helperText={
                      errors.Email && touched.Email ? errors.Email : ""
                    }
                    w
                  />
                </Grid>
                {/* <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Field
                    as={TextField}
                    required
                    autoComplete="new-password"
                    error={errors.Password && touched.Password}
                    className={classes.halfInput}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused",
                      },
                    }}
                    InputProps={{
                      color: "secondary",
                      endAdornment: (
                        <Tooltip
                          title={longText}
                          classes={{
                            tooltip: classes.customTooltip,
                          }}
                        >
                          <ErrorOutlineIcon />
                        </Tooltip>
                      ),
                      classes: {
                        root: classes.activeInputColor,
                        focused: "focused",
                        input: classes.resize,
                      },
                      required: true,
                    }}
                    label="Password"
                    disabled={props.formData.formMode !== "Create"}
                    name="Password"
                    type="password"
                    id="Password"
                    style={{ height: "5.187em" }}
                    helperText={
                      errors.Password && touched.Password ? errors.Password : ""
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Field
                    as={TextField}
                    className={classes.halfInput}
                    error={errors.confirmPassword && touched.confirmPassword}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused",
                      },
                    }}
                    InputProps={{
                      color: "secondary",
                      classes: {
                        root: classes.activeInputColor,
                        focused: "focused",
                        input: classes.resize,
                      },
                      required: true,
                    }}
                    label="Confirm Password"
                    disabled={props.formData.formMode !== "Create"}
                    name="confirmPassword"
                    type="password"
                    id="confirmPassword"
                    style={{ height: "5.187em" }}
                    helperText={
                      errors.confirmPassword && touched.confirmPassword
                        ? errors.confirmPassword
                        : ""
                    }
                  />
                </Grid> */}
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Field
                    as={TextField}
                    required
                    error={errors.Role_ID && touched.Role_ID}
                    className={classes.halfInput}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      color: "secondary",
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    label="Role"
                    disabled={values.Profile_Code === ""}
                    name="Role_ID"
                    id="Role_ID"
                    select
                    helperText={
                      errors.Role_ID && touched.Role_ID ? errors.Role_ID : ""
                    }
                    style={{ height: "5.187em" }}
                  >
                    {!filterDataRole &&
                      props.roles.providerroleList &&
                      props.roles.providerroleList.Roles_Data &&
                      props.roles.providerroleList.Roles_Data.map((key) => (
                        <MenuItem
                          className={classes.dialogBoxDropDown}
                          value={key._id}
                        >
                          {key.RoleName}
                        </MenuItem>
                      ))}
                    {filterDataRole &&
                      filterDataRole
                        .sort((a, b) => (a.RoleName > b.RoleName ? 1 : -1))
                        .map((key) => (
                          <MenuItem
                            className={classes.dialogBoxDropDown}
                            value={key._id}
                          >
                            {key.RoleName}
                          </MenuItem>
                        ))}
                  </Field>
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Autocomplete
                    {...defaultProps}
                    className={classes.halfInput}
                    name="reportsTo"
                    id="reportsTo"
                    label="Reports To"
                    value={
                      values?.reportsTo &&
                      agentLists?.find((option) =>
                        option?.User_Id === values?.reportsTo
                          ? values?.reportsTo
                          : ""
                      )
                    }
                    options={
                      agentLists &&
                      agentLists.sort((a, b) => a.Name.localeCompare(b.Name))
                    }
                    getOptionLabel={(option) => option.Name}
                    renderOption={(option) => (
                      <>
                        {option.Name && option.Email && (
                          <span
                            className={classes.dialogBoxDropDown}
                            style={{
                              width: "100%",
                              margin: "-5px -16px",
                              padding: "4px 16px"
                            }}
                          >
                            {option.Name} [{option.Email}]
                          </span>
                        )}
                      </>
                    )}
                    onChange={(event, value) => {
                      if (value && value.User_Id) {
                        setFieldValue("reportsTo", value.User_Id);
                      }
                    }}
                    renderInput={(params) => (
                      <Field
                        component={TextField}
                        {...params}
                        label="Reports to"
                        required
                        error={errors.reportsTo && touched.reportsTo}
                        style={{ height: "5.187em" }}
                        helperText={
                          errors.reportsTo && touched.reportsTo
                            ? errors.reportsTo
                            : ""
                        }
                        InputLabelProps={{
                          classes: {
                            root: classes.activeInputLableColor,
                            focused: "focused"
                          }
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Field
                    as={TextField}
                    className={classes.halfInput}
                    required
                    error={errors.Address1 && touched.Address1}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      color: "secondary",
                      classes: {
                        root: classes.activeInputColor,
                        focused: "focused",
                        input: classes.resize
                      },
                      required: true
                    }}
                    label="Address Line 1"
                    name="Address1"
                    id="Address1"
                    style={{ height: "5.187em" }}
                    helperText={
                      errors.Address1 && touched.Address1 ? errors.Address1 : ""
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Field
                    as={TextField}
                    error={errors.Address2 && touched.Address2}
                    className={classes.halfInput}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      color: "secondary",
                      classes: {
                        root: classes.activeInputColor,
                        focused: "focused",
                        input: classes.resize
                      },
                      required: true
                    }}
                    label="Address Line 2"
                    name="Address2"
                    id="Address2"
                    style={{ height: "5.187em" }}
                    helperText={
                      errors.Address2 && touched.Address2 ? errors.Address2 : ""
                    }
                  />
                </Grid>

                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Field
                    as={TextField}
                    error={errors.City && touched.City}
                    required
                    className={classes.halfInput}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      color: "secondary",
                      classes: {
                        root: classes.activeInputColor,
                        focused: "focused",
                        input: classes.resize
                      },
                      required: true
                    }}
                    label="City"
                    name="City"
                    id="City"
                    style={{ height: "5.187em" }}
                    helperText={errors.City && touched.City ? errors.City : ""}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Field
                    as={TextField}
                    required
                    label="Mobile"
                    name="Mobile"
                    id="Mobile"
                    style={{ height: "5.187em" }}
                    error={errors.Mobile && touched.Mobile}
                    className={classes.halfInput}
                    helperText={
                      errors.Mobile && touched.Mobile ? errors.Mobile : ""
                    }
                    onChange={(e) => {
                      setFieldValue(
                        "Mobile",
                        formatPhoneNumber(e.target.value)
                      );
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      color: "secondary",
                      classes: {
                        root: classes.activeInputColor,
                        focused: "focused",
                        input: classes.resize
                      },
                      required: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <Select
                            id="country-menu"
                            value={values.dialCode}
                            onChange={(event) => {
                              setFieldValue("dialCode", event.target.value);
                            }}
                            classes={{ root: "country-list" }}
                            className="no-underline"
                            inputProps={{
                              "aria-label": "Without label"
                            }}
                          >
                            {props.formData.countryTypeList &&
                              props.formData.countryTypeList.Country_Data.map(
                                (country) => (
                                  <MenuItem
                                    value={country.Dial_Code}
                                    option={
                                      props.formData.countryTypeList
                                        ?.Country_Data
                                    }
                                    key={country.Country_Code}
                                    className="country"
                                  >
                                    <span
                                      className={`country-flag ${country.Country_Code.toLowerCase().substring(
                                        0,
                                        2
                                      )}`}
                                    />
                                    &nbsp;
                                    <span className="dial-code">{`+${country.Dial_Code}`}</span>
                                    &nbsp;
                                    <span className="country-name">
                                      ({country.Country_Name})
                                    </span>
                                  </MenuItem>
                                )
                              )}
                          </Select>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Field
                    as={TextField}
                    className={classes.halfInput}
                    style={{ width: "50%", height: "5.187em" }}
                    error={errors.Phone_No && touched.Phone_No}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    label="Alternative number"
                    name="Phone_No"
                    id="Phone_No"
                    onChange={(e) => {
                      setFieldValue(
                        "Phone_No",
                        formatPhoneNumber(e.target.value)
                      );
                    }}
                    InputProps={{
                      color: "secondary",
                      classes: {
                        root: classes.activeInputColor,
                        focused: "focused",
                        input: classes.resize
                      },
                      required: true
                    }}
                    helperText={
                      errors.Phone_No && touched.Phone_No
                        ? errors.Phone_No
                        : " "
                    }
                  />
                  <Field
                    as={TextField}
                    className={classes.halfInput}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    error={errors.Extension && touched.Extension}
                    helperText={
                      errors.Extension && touched.Extension
                        ? errors.Extension
                        : ""
                    }
                    label="Extension"
                    name="Extension"
                    id="Extension"
                    style={{ height: "5.187em", width: "35%" }}
                    InputProps={{
                      color: "secondary",
                      classes: {
                        root: classes.activeInputColor,
                        focused: "focused",
                        input: classes.resize
                      }
                    }}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Field
                    as={TextField}
                    required
                    error={errors.Country_Code && touched.Country_Code}
                    className={classes.halfInput}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      color: "secondary",
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    label="Country"
                    name="Country_Code"
                    id="Country_Code"
                    select
                    style={{ height: "5.187em" }}
                    helperText={
                      errors.Country_Code && touched.Country_Code
                        ? errors.Country_Code
                        : ""
                    }
                  >
                    {props.formData.countryTypeList &&
                      props.formData.countryTypeList.Country_Data.map((key) => (
                        <MenuItem
                          className={classes.dialogBoxDropDown}
                          value={key.Country_Code}
                        >
                          {key.Country_Name}
                        </MenuItem>
                      ))}
                  </Field>
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  {values.Country_Code === "US" ? (
                    <Field
                      as={TextField}
                      required
                      error={errors.State_Code && touched.State_Code}
                      className={classes.halfInput}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        color: "secondary",
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      label="State"
                      name="State_Code"
                      id="State_Code"
                      select
                      style={{ height: "5.187em" }}
                      helperText={
                        errors.State_Code && touched.State_Code
                          ? errors.State_Code
                          : " "
                      }
                    >
                      {locationCity.map((key) => (
                        <MenuItem
                          className={classes.dialogBoxDropDown}
                          value={key.value}
                        >
                          {key.name}
                        </MenuItem>
                      ))}
                    </Field>
                  ) : (
                    <Field
                      as={TextField}
                      required
                      error={errors.State_Code && touched.State_Code}
                      className={classes.halfInput}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        color: "secondary",
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      label="State"
                      name="State_Code"
                      id="State_Code"
                      style={{ height: "5.187em" }}
                      helperText={
                        errors.State_Code && touched.State_Code
                          ? errors.State_Code
                          : " "
                      }
                    />
                  )}
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Field
                    as={TextField}
                    className={classes.halfInput}
                    required
                    error={errors.Zip && touched.Zip}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      color: "secondary",
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    label="ZIP"
                    name="Zip"
                    type="number"
                    id="Zip"
                    style={{ height: "5.187em" }}
                    helperText={errors.Zip && touched.Zip ? errors.Zip : ""}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Field
                    as={TextField}
                    required
                    error={errors.Time_Zone_Code && touched.Time_Zone_Code}
                    className={classes.halfInput}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      color: "secondary",
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    label="TimeZone"
                    name="Time_Zone_Code"
                    id="Time_Zone_Code"
                    select
                    style={{ height: "5.187em" }}
                    helperText={
                      errors.Time_Zone_Code && touched.Time_Zone_Code
                        ? errors.Time_Zone_Code
                        : ""
                    }
                  >
                    <MenuItem
                      className={classes.dialogBoxDropDown}
                      value="option"
                    >
                      Choose your timezone
                    </MenuItem>
                    {props.formData.timeZoneList &&
                      props.formData.timeZoneList.Timezone_Data.map((key) => (
                        <MenuItem
                          className={classes.dialogBoxDropDown}
                          value={key.Timezone_Code}
                        >
                          {key.Timezone_Name}
                        </MenuItem>
                      ))}
                  </Field>
                </Grid>

                <Grid item xs />
              </Grid>
              <Divider style={{ marginTop: "20px" }} />
              <Grid container className={classes.margin}>
                <Grid item xs>
                  <Box height={40} className={classes.radioBox}>
                    <div
                      style={{ marginRight: "2%" }}
                      className={classes.radioBoxRoot}
                    >
                      <Typography className={classes.textColorRadioCard}>
                        Profile Visibility :
                      </Typography>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <GreenRadio
                        className={classes.checkBox}
                        checked={values.profileVisibility === "1"}
                        onChange={handleVisability}
                        value="1"
                        name="radio-button-demo"
                        inputProps={{ "aria-label": "1" }}
                        style={{ marginRight: "10px" }}
                      />
                      <Typography className={classes.textColorRadioCard}>
                        Own
                      </Typography>
                    </div>
                    <div
                      style={{ marginRight: "2%" }}
                      className={classes.radioBoxRoot}
                    >
                      <GreenRadio
                        checked={values.profileVisibility === "2"}
                        onChange={handleVisability}
                        className={classes.checkBox}
                        value="2"
                        style={{ marginRight: "10px" }}
                        inputProps={{ "aria-label": "2" }}
                      />
                      <Typography className={classes.textColorRadioCard}>
                        Own and Persons Directly reporting to me
                      </Typography>
                    </div>
                    <div
                      style={{ marginRight: "2%" }}
                      className={classes.radioBoxRoot}
                    >
                      <GreenRadio
                        checked={values.profileVisibility === "3"}
                        onChange={handleVisability}
                        className={classes.checkBox}
                        value="3"
                        style={{ marginRight: "10px" }}
                        inputProps={{ "aria-label": "3" }}
                      />
                      <Typography className={classes.textColorRadioCard}>
                        Groups
                      </Typography>
                    </div>
                  </Box>
                  <div style={{ textAlign: "center", textAlignLast: "center" }}>
                    {isEmpty(values.profileVisibility) ? (
                      <Typography
                        component="span"
                        className="error"
                        variant="body2"
                      >
                        {errors.profileVisibility}
                      </Typography>
                    ) : isEmpty(values.groupId) &&
                      values.profileVisibility === "3" ? (
                      <Typography
                        component="span"
                        className="error"
                        variant="body2"
                      >
                        Please select at least 1 group to continue.
                      </Typography>
                    ) : (
                      ""
                    )}
                  </div>
                </Grid>
              </Grid>
              {values.profileVisibility === "3" && (
                <Grid container className={classes.margin}>
                  <Grid item xs textAlign="right" sx={{ border: 1 }}>
                    <MultipleSelect
                      {...props}
                      onChange={(event) => {
                        setFieldValue("groupId", event);
                      }}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid container className={classes.margin}>
                <Grid item lg={9} md={9} sm={8} xs={0} />
                <Grid item lg={3} md={3} sm={4} xs={12} textAlign="right">
                  <Box className={classes.buttonBox} textAlign="right">
                    <Button
                      variant="contained"
                      className={classes.clearButton}
                      onClick={() => handleBack()}
                    >
                      Back
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                      variant="contained"
                      disabled={
                        values.profileVisibility === "3" &&
                        isEmpty(values.groupId)
                      }
                      className={classes.buttonStyle}
                      onClick={() => {
                        handleSubmit();
                      }}
                      style={{ alignItems: "right" }}
                    >
                      {props.formData.formMode === "Create"
                        ? "Submit"
                        : "Update"}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
}

UserManager.propTypes = {
  formData: PropTypes.object,
  getCountryTypeList: PropTypes.func,
  getProfileTypeList: PropTypes.func,
  getRoleList: PropTypes.func,
  getTimeZoneList: PropTypes.func,
  changeUserFormMode: PropTypes.func,
  getUserList: PropTypes.func,
  getProviderRoleList: PropTypes.func,
  userRole: PropTypes.object,
  getUserListProvider: PropTypes.func,
  roles: PropTypes.object,
  userListApi: PropTypes.func,
  getGroupListData: PropTypes.func,
  autoGeneratedRepCode: PropTypes.func,
  updateUserApiCall: PropTypes.func,
  createNewUserApiCall: PropTypes.func,
  getAgentByHierarchy: PropTypes.func,
  getMerchantByHierarchy: PropTypes.func
};

const mapStateToProps = (app) => ({
  formData: app.users,
  userRole: app.userRole,
  roles: app.roles
});
export default connect(mapStateToProps, {
  updateUserApiCall,
  createNewUserApiCall,
  getCountryTypeList,
  getProfileTypeList,
  getRoleList,
  autoGeneratedRepCode,
  userListApi,
  changeUserFormMode,
  getTimeZoneList,
  getUserList,
  getUserListProvider,
  getProviderRoleList,
  getGroupListData,
  getAgentByHierarchy,
  getMerchantByHierarchy
})(UserManager);
