export const TablePageData = {
  lastPage: 1,
  from: 0,
  to: 5,
  total: 0,
  perPageOptions: [
    1,
    5,
    10,
    25,
    {
      label: "All",
      value: 0,
    },
  ],
};
