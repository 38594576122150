/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Card from "components/Card/Card";
import "../Payment/card.css";
import { Grid, Typography } from "@material-ui/core";
import "react-credit-cards/es/styles-compiled.css";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import {
  getAllActivePlanList,
  getOpenFundingAmount,
  getPendingOfferAmount,
  getPaymentStatus,
  getOfferAccountStatus,
  getOriginalLoanTerm,
  getRemainingFundingGraph,
  resetPaymentList
} from "Redux/Actions/PatientDashboard";
import Link from "@material-ui/core/Link";
import isEmpty from "lodash/isEmpty";
import { patientPaymentDetailsCall } from "Redux/Actions/genrateOfferPatient";
import Ripple from "components/RippleEffect/Ripple";
import Button from "components/CustomButtons/Button";
import styles from "./Dashboard.style";
import visaCardImg from "../../assets/img/visa.png";
import masterCardImg from "../../assets/img/masterCard.png";

const useStyles = makeStyles(styles);

const PatientPaymentDashboard = (props) => {
  const classes = useStyles();
  const [prePaymentDetails, setPrePaymentsDetails] = React.useState(
    props.patientDetails.paymentDetailsData
  );

  const handleBackToDashboard = () => {
    props.resetPaymentList();
    if (props.patientDetails.createOffer) {
      props.history.push("/admin/offerpayment");
    } else {
      props.history.push("/patient/Dashboard");
    }
  };

  React.useEffect(() => {
    if (
      prePaymentDetails !== props.patientDetails.paymentDetailsData &&
      !props.patientDetails.printOfferData
    ) {
      setPrePaymentsDetails(props.patientDetails.paymentDetailsData);
      if (props.patientDetails.paymentDetailsData) {
        props.state();
        props.history.push("/tpo/payments");
        props.resetPaymentList();
      }
    }
  }, [props]);

  const handlePayNowNewMethod = () => {
    let data;
    if (props.patientDetails.createOffer) {
      const amount =
        props.patientDetails.createOffer.data.payType === 1
          ? Number(
              props.patientDetails.genrateOfferData.data[0].Discounted[0].finalPayAmount
                .toString()
                .replace("$", "")
            )
          : Number(
              props.patientDetails.genrateOfferData.data[0].Monthly[0].downPayment
                .toString()
                .replace("$", "")
            );
      data = {
        patientId: props.tableData.activePlanPatientList.data[0].patientId,
        offerId: props.patientDetails.createOffer.data.OfferId,
        amount,
        paymentId: ""
      };
    } else {
      data = {
        patientId: props.tableData.activePlanPatientList.data[0].patientId,
        offerId: props.tableData.activePlanPatientList.data[0].offerId,
        amount: 0,
        paymentId: ""
      };
    }
    props.patientPaymentDetailsCall(data);
  };

  const handlePayNow = (paymentId) => {
    let data;
    if (props.patientDetails.createOffer) {
      const amount =
        props.patientDetails.createOffer &&
        props.patientDetails.createOffer.data.payType === 1
          ? Number(
              props.patientDetails.genrateOfferData.data[0].Discounted[0].finalPayAmount
                .toString()
                .replace("$", "")
            )
          : Number(
              props.patientDetails.genrateOfferData.data[0].Monthly[0].downPayment
                .toString()
                .replace("$", "")
            );
      if (paymentId) {
        data = {
          patientId: props.patientDetails.selectPatient.data.Patient_id,
          offerId: props.patientDetails.createOffer.data.OfferId,
          amount,
          paymentId
        };
      } else {
        data = {
          patientId: props.tableData.activePlanPatientList.data[0].patientId,
          offerId: props.tableData.activePlanPatientList.data[0].offerId,
          amount,
          paymentId: ""
        };
      }
    } else {
      const amount = 0;
      if (paymentId) {
        data = {
          patientId: props.tableData.activePlanPatientList.data[0].patientId,
          offerId: props.tableData.activePlanPatientList.data[0].offerId,
          amount,
          paymentId
        };
      } else {
        data = {
          patientId: props.tableData.activePlanPatientList.data[0].patientId,
          offerId: props.tableData.activePlanPatientList.data[0].offerId,
          amount,
          paymentId: ""
        };
      }
    }
    props.patientPaymentDetailsCall(data);
  };

  const addCardClassName = (key) => {
    setTimeout(() => {}, 6000);
  };

  const fullAddress =
    props.tableData.offetDetailsPatient.data.length > 0
      ? `${props.tableData.offetDetailsPatient.data[0].address1}, ${props.tableData.offetDetailsPatient.data[0].address2}, ${props.tableData.offetDetailsPatient.data[0].city}, ${props.tableData.offetDetailsPatient.data[0].state}, ${props.tableData.offetDetailsPatient.data[0].zipCode}`
      : props.patientDetails.selectPatient.data.Address;

  return (
    <>
      <Card style={{ overflow: "hidden" }}>
        <Grid
          container
          style={{
            margin: "5%",
            marginBottom: "10px",
            display: "flex",
            flexDirection: "row"
          }}
        >
          <Grid
            item
            xs={8}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Typography style={{ fontSize: "20px" }}>
              Your Payment Options
            </Typography>
            <Typography style={{ fontSize: "15px" }}>
              An overview of your payment methods and settings
            </Typography>
          </Grid>
          <Grid item xs={1}>
            {props.patientDetails.data && (
              <Button onClick={() => handlePayNowNewMethod()}>
                Pay Now By New Method
              </Button>
            )}
          </Grid>
          <Grid item xs={2} align="right">
            <Button
              variant="contained"
              className={classes.clearButton}
              onClick={() => handleBackToDashboard()}
            >
              {`${
                props.patientDetails.createOffer ? "Back" : "Back to Dashboard"
              }`}
            </Button>
          </Grid>
        </Grid>
        {!props.patientDetails.data && (
          <Card style={{ margin: "2%", padding: "10px", width: "94%" }}>
            <Grid container style={{ margin: "1%" }}>
              <Grid item xs style={{ display: "flex", flexDirection: "row" }}>
                <Typography style={{ fontSize: "20px" }}>
                  Your Current Monthly payment Setup of Loan:
                </Typography>
                <Typography
                  style={{
                    fontSize: "22px",
                    fontStyle: "bold",
                    color: "#3a57a6"
                  }}
                >
                  {props.tableData.offetDetailsPatient.data &&
                    (props.tableData.offetDetailsPatient.data.length > 0
                      ? props.tableData.offetDetailsPatient.data[0].offerId
                      : props.patientDetails.createOffer.data.OfferId)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container style={{ margin: "1%" }}>
              <Grid
                item
                xs={12}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Typography style={{ fontSize: "14px" }}>
                  <div style={{ fontSize: "14px", fontWeight: "bold" }}>
                    ADDRESS
                  </div>
                  {"\n"}
                  {props.tableData.offetDetailsPatient.data.length > 0
                    ? fullAddress
                    : props.patientDetails.selectPatient.data.Address}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Typography style={{ fontSize: "14px" }}>
                  <div style={{ fontSize: "14px", fontWeight: "bold" }}>
                    Nickname
                  </div>
                  {"\n"}
                  {props.tableData.offetDetailsPatient.data.length > 0
                    ? props.tableData.offetDetailsPatient.data[0].nickName
                    : ""}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Typography style={{ fontSize: "14px", width: "100%" }}>
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      marginBottom: "1%"
                    }}
                  >
                    Payment Method
                  </div>
                  {"\n"}
                  {props.tableData.offetDetailsPatient.data[0].cardType ===
                  "visa" ? (
                    <img
                      src={visaCardImg}
                      alt=""
                      style={{ width: "32px", marginRight: "7px" }}
                    />
                  ) : (
                    <img
                      src={masterCardImg}
                      alt=""
                      style={{ width: "32px", marginRight: "7px" }}
                    />
                  )}
                  {`${
                    props.tableData.offetDetailsPatient.data.length > 0
                      ? props.tableData.offetDetailsPatient.data[0].cardType
                      : ""
                  } ending in ${
                    props.tableData.offetDetailsPatient.data.length > 0
                      ? props.tableData.offetDetailsPatient.data[0]
                          .last4CardNumber
                      : ""
                  }`}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        )}
        {!props.patientDetails.data && (
          <Grid
            container
            style={{
              margin: "5%",
              marginBottom: "10px",
              marginTop: "10px",
              display: "flex",
              flexDirection: "row"
            }}
          >
            <Grid item xs={9} />
            <Grid item xs={3}>
              <Button
                style={{ textTransform: "none" }}
                onClick={() => handlePayNowNewMethod()}
              >
                Setup new Payment Method
              </Button>
            </Grid>
          </Grid>
        )}
        {!isEmpty(props.tableData.paymentList.data[0].card) && (
          <Card style={{ margin: "2%", padding: "10px", width: "94%" }}>
            <Grid container>
              <Grid
                item
                xs={5}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Typography style={{ fontSize: "20px", color: "#3a56a6" }}>
                  Your saved card details:
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} style={{ padding: "1%" }}>
                {props.tableData.paymentList &&
                  props.tableData.paymentList.data[0].card.map((key) => (
                    <Accordion style={{ margin: "1%" }}>
                      <AccordionSummary
                        style={{
                          minHeight: "0px",
                          paddingTop: "0px",
                          backgroundColor: "#f3f3f3",
                          margin: "0px"
                        }}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        onClick={() => addCardClassName(key)}
                        id="panel1a-header"
                      >
                        <Grid item xs={6} style={{ display: "flex" }}>
                          {key.cardType === "visa" ? (
                            <img
                              src={visaCardImg}
                              alt=""
                              style={{ width: "32px", marginRight: "7px" }}
                            />
                          ) : (
                            <img
                              src={masterCardImg}
                              alt=""
                              style={{ width: "32px", marginRight: "7px" }}
                            />
                          )}
                          <Typography className={classes.heading}>
                            {`${key.cardType} ending in ${key.last4CardNumber}`}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={5}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center"
                          }}
                        >
                          <Typography
                            style={{ fontSize: "16px", color: "#3a56a6" }}
                          >
                            Setup Date:{" "}
                          </Typography>
                          <Typography className={classes.heading}>
                            {`${key.setupDate}`}
                          </Typography>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails style={{ flexDirection: "column" }}>
                        <Grid container style={{ margin: "1%" }}>
                          <Grid
                            item
                            xs={4}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column"
                              }}
                            >
                              <Typography
                                style={{
                                  fontSize: "15px",
                                  color: "#3a56a6",
                                  fontWeight: "bold"
                                }}
                              >
                                Name on card
                              </Typography>
                              <Typography
                                align="left"
                                style={{ fontSize: "14px" }}
                              >
                                {key.firstName}
                              </Typography>
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column"
                              }}
                            >
                              <Typography
                                style={{ fontSize: "20px", color: "#3a56a6" }}
                              >
                                Billing Details
                              </Typography>
                              <Typography
                                align="left"
                                style={{ fontSize: "14px" }}
                              >
                                {key.firstName}
                              </Typography>
                              <Typography
                                align="left"
                                style={{ fontSize: "14px" }}
                              >
                                {key.address1}
                                {", "}
                                {key.address2}
                              </Typography>
                              <Typography
                                align="left"
                                style={{ fontSize: "14px" }}
                              >
                                {key.city}
                                {", "}
                                {key.state}
                                {", "}
                                {key.zipCode}
                              </Typography>
                            </div>
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              style={{ height: "35%", marginTop: "35%" }}
                              onClick={() => handlePayNow(key.paymentId)}
                            >
                              {`${
                                props.patientDetails.createOffer
                                  ? "Pay Now"
                                  : "Schedule"
                              }`}
                            </Button>
                          </Grid>
                        </Grid>
                        {/* <Grid container style={{ margin: "1%" }}>
                      <Grid item xs style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                        <Button onClick={() => handlePayNow(key.paymentId)}>{`${props.patientDetails.createOffer ? "Pay Now" : "Schedule"}`}</Button>
                      </Grid>
                    </Grid> */}
                      </AccordionDetails>
                    </Accordion>
                  ))}
              </Grid>
            </Grid>
          </Card>
        )}
        {!isEmpty(props.tableData.paymentList.data[0].bank) && (
          <Card style={{ margin: "2%", padding: "10px", width: "94%" }}>
            <Grid container>
              <Grid
                item
                xs={5}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Typography style={{ fontSize: "20px", color: "#3a56a6" }}>
                  Your saved bank details:
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} style={{ padding: "1%" }}>
                {props.tableData.paymentList &&
                  props.tableData.paymentList.data[0].bank.map((key) => (
                    <Accordion style={{ margin: "1%" }}>
                      <AccordionSummary
                        style={{
                          minHeight: "0px",
                          paddingTop: "0px",
                          backgroundColor: "#f3f3f3",
                          margin: "0px"
                        }}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        onClick={() => addCardClassName(key)}
                        id="panel1a-header"
                      >
                        <Grid item xs={6} style={{ display: "flex" }}>
                          {/* {key.cardType === "visa" ? (
                          <img
                            src={visaCardImg}
                            alt=""
                            style={{ width: "32px", marginRight: "7px" }}
                          />
                        ) : (
                          <img
                            src={masterCardImg}
                            alt=""
                            style={{ width: "32px", marginRight: "7px" }}
                          />
                        )} */}
                          <AccountBalanceIcon
                            style={{ width: "32px", marginRight: "7px" }}
                          />
                          <Typography className={classes.heading}>
                            {`Account number ending in ${key.last4CardNumber}`}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={5}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center"
                          }}
                        >
                          <Typography
                            style={{ fontSize: "16px", color: "#3a56a6" }}
                          >
                            Setup Date:{" "}
                          </Typography>
                          <Typography className={classes.heading}>
                            {`${key.setupDate}`}
                          </Typography>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails style={{ flexDirection: "column" }}>
                        <Grid container style={{ margin: "1%" }}>
                          <Grid
                            item
                            xs={4}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column"
                              }}
                            >
                              <Typography
                                style={{
                                  fontSize: "15px",
                                  color: "#3a56a6",
                                  fontWeight: "bold"
                                }}
                              >
                                Name on card
                              </Typography>
                              <Typography
                                align="left"
                                style={{ fontSize: "14px" }}
                              >
                                {key.firstName}
                              </Typography>
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column"
                              }}
                            >
                              <Typography
                                style={{ fontSize: "20px", color: "#3a56a6" }}
                              >
                                Billing Details
                              </Typography>
                              <Typography
                                align="left"
                                style={{ fontSize: "14px" }}
                              >
                                {key.firstName}
                              </Typography>
                              <Typography
                                align="left"
                                style={{ fontSize: "14px" }}
                              >
                                {key.address1}
                                {", "}
                                {key.address2}
                              </Typography>
                              <Typography
                                align="left"
                                style={{ fontSize: "14px" }}
                              >
                                {key.city}
                                {", "}
                                {key.state}
                                {", "}
                                {key.zipCode}
                              </Typography>
                            </div>
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              style={{ height: "35%", marginTop: "35%" }}
                              onClick={() => handlePayNow(key.paymentId)}
                            >
                              {`${
                                props.patientDetails.createOffer
                                  ? "Pay Now"
                                  : "Schedule"
                              }`}
                            </Button>
                          </Grid>
                        </Grid>
                        {/* <Grid container style={{ margin: "1%" }}>
                      <Grid item xs style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                        <Button onClick={() => handlePayNow(key.paymentId)}>{`${props.patientDetails.createOffer ? "Pay Now" : "Schedule"}`}</Button>
                      </Grid>
                    </Grid> */}
                      </AccordionDetails>
                    </Accordion>
                  ))}
              </Grid>
            </Grid>
          </Card>
        )}
      </Card>
    </>
  );
};

PatientPaymentDashboard.propTypes = {
  tableData: PropTypes.object,
  history: PropTypes.object,
  patientDetails: PropTypes.object,
  patientPaymentDetailsCall: PropTypes.func,
  state: PropTypes.func,
  resetPaymentList: PropTypes.func
};

const mapStateToProps = (app) => ({
  tableData: app.patientDashboard,
  patientDetails: app.genrateOfferPatient,
  userDetails: app.userRole
});

export default connect(mapStateToProps, {
  getAllActivePlanList,
  getOpenFundingAmount,
  getPendingOfferAmount,
  getPaymentStatus,
  getOfferAccountStatus,
  getOriginalLoanTerm,
  getRemainingFundingGraph,
  patientPaymentDetailsCall,
  resetPaymentList
})(PatientPaymentDashboard);
