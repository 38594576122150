/* eslint-disable camelcase */
/* eslint-disable no-restricted-syntax */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { makeStyles, useTheme, lighten } from "@material-ui/core/styles";
import CardHeader from "@material-ui/core/CardHeader";
import ClearIcon from "@material-ui/icons/Clear";
import TablePagination from "@material-ui/core/TablePagination";
import isEmpty from "lodash/isEmpty";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import CoustomButton from "components/CustomButtons/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TextField from "@material-ui/core/TextField";
import Collapse from "@material-ui/core/Collapse";
import Card from "components/Card/Card";
import Search from "@material-ui/icons/Search";
import TableHead from "@material-ui/core/TableHead";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import ChipInput from "material-ui-chip-input";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import AddIcon from "@material-ui/icons/Add";
import Modal from "@material-ui/core/Modal";
import noDataImage from "../../assets/img/noDataImage.png";
import SimpleDialog from "../../components/DialogBox/Roles";
import LongMenu from "../../components/Menu/Role";
import { exportToCSV } from "../../Utils/commonUtils";
import { getRoleExport } from "../../actions/Operations";
import {
  rolePermisstionRequest,
  changeRoleFormMode,
  getRoleByFilterData,
  roleStatusUpdate,
  setRoleSearchData
} from "../../Redux/Actions/RoleRegistration";
import tableStyle from "../../assets/jss/material-dashboard-react/components/reportTables";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import { getUserDetailsById } from "../../Redux/Actions/UserRegistration";
const useStyles = makeStyles(tableStyle);
const useFormStyles = makeStyles(styles);

function createData(
  Role_Name,
  Profile_Type,
  Landing_Page,
  Creation_Date,
  Update_Date,
  status,
  Id,
  editable,
  Associated_Users,
  profileCode
) {
  return {
    Role_Name,
    Profile_Type,
    Landing_Page,
    Creation_Date,
    Update_Date,
    status,
    Id,
    editable,
    Associated_Users,
    profileCode
  };
}

function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.rootPagination}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Last</Typography>
        ) : (
          <Typography>First</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Previous</Typography>
        ) : (
          <Typography>Previous</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleFirstPageButtonClick}
        aria-label="first page"
        className={classes.bottonPage}
      >
        <Typography>{page + 1}</Typography>
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <Typography>Next</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <FirstPageIcon />
        ) : (
          <Typography>Last</Typography>
        )}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

const headCells = [
  {
    id: "RoleName",
    numeric: false,
    disablePadding: true,
    label: "Role Name"
  },
  {
    id: "Profile_Name",
    numeric: false,
    disablePadding: true,
    label: "User Type"
  },
  {
    id: "Landing_Page",
    numeric: false,
    disablePadding: false,
    label: "Landing Page"
  },
  {
    id: "Created_Date",
    numeric: false,
    disablePadding: true,
    label: "Creation Date"
  },
  {
    id: "Updated_Date",
    numeric: false,
    disablePadding: false,
    label: "Update Date"
  },
  {
    id: "Status",
    numeric: true,
    disablePadding: false,
    label: "Status"
  }
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHeader}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            className={classes.cellTextHeaderOffer}
            align="center"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}

        <TableCell />
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired
};

function RoleDataList(props) {
  const classes = useStyles();
  const formclasses = useFormStyles();
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("Updated_Date");
  const theme = useTheme();
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const windowWidthSm = useMediaQuery(theme.breakpoints.up("sm"));
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [preRoleData, setPreRoleData] = React.useState(
    props.tableData.roleList
  );
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [statusDetails, setStatusPid] = React.useState({
    id: "",
    name: "",
    status: "",
    associated: ""
  });
  const [values, setValues] = React.useState({
    status: "",
    id: ""
  });
  const [searchVlaues, setSearchValues] = React.useState(
    props.tableData.searchDetails
  );
  const [openModel, setOpenModel] = React.useState(false);
  const [openassociateModel, setOpenassociateModel] = React.useState(false);
  const [chipValue, setChipValue] = React.useState(["Status: All"]);
  const [open, setOpen] = React.useState(false);
  const [rolePermission, setRolePermission] = React.useState(
    props.tableData.roleDetailsById
  );
  const [temp, setTemp] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState();
  const [preUpdateStatus, setPreUpdateStatus] = React.useState(
    props.tableData.updateRoleStatus
  );

  const [validationError, setVlidationError] = React.useState({
    Password: true,
    ConfirmPassword: true
  });

  const providerregisterurl = () => {
    props.changeRoleFormMode("Create");
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };
  const exportedBy =
    props.mainData.users.userDetailsById &&
    props.mainData.users.userDetailsById?.Users_Data[0].Email;
  const exportedByFname =
    props.mainData.users.userDetailsById &&
    props.mainData.users.userDetailsById?.Users_Data[0].FName;
  const exportedByLname =
    props.mainData.users.userDetailsById &&
    props.mainData.users.userDetailsById?.Users_Data[0].LName;
  const exportListData = () => {
    const filterData = { ...props.tableData.searchDetails };
    filterData.recordPerPage = props.tableData.roleList.totCount;
    filterData.pageNo = 1;
    props.getRoleExport(filterData, (data) => {
      if (!isEmpty(data.Roles_Data)) {
        const mappedLogs = data.Roles_Data.map((row) => ({
          "Role Name": row.RoleName,
          "Profile Type": row.Profile_Name,
          "Landing Page": row.Landing_Page,
          "Creation Date": moment(row.Created_Date).format("MM/DD/YYYY"),
          "Update Date": moment(row.Updated_Date).format("MM/DD/YYYY"),
          Status: row.Status
        }));
        exportToCSV(
          mappedLogs,
          "Roles List",
          chipValue,
          exportedBy,
          exportedByFname,
          exportedByLname
        );
      }
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    const requestData = { ...props.tableData.searchDetails };
    requestData.sortColumn = property;
    requestData.sortOrder = isAsc ? "desc" : "asc";
    props.getRoleByFilterData(requestData);
    props.setRoleSearchData(requestData);
  };

  const saveStatus = () => {
    const statusReequest = {
      rid: values.id,
      status: values.status
    };
    props.roleStatusUpdate(statusReequest);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    const requestData = { ...props.tableData.searchDetails };
    requestData.recordPerPage = parseInt(event.target.value, 10);
    requestData.pageNo = 1;
    props.getRoleByFilterData(requestData);
    props.setRoleSearchData(requestData);
  };

  React.useEffect(() => {
    if (rolePermission !== props.tableData.roleDetailsById) {
      if (props.tableData.roleDetailsById.status) {
        setRolePermission(props.tableData.roleDetailsById);
        props.changeRoleFormMode("ManageRole");
      }
    }
  }, [props]);

  React.useEffect(() => {
    if (preRoleData !== props.tableData.roleList) {
      setPreRoleData(props.tableData.roleList);
    }
    if (preUpdateStatus !== props.tableData.updateRoleStatus) {
      props.getRoleByFilterData(props.tableData.searchDetails);
      setPreUpdateStatus(props.tableData.updateRoleStatus);
    }
    if (props.tableData.searchDetails.pageNo !== page) {
      setPage(props.tableData.searchDetails.pageNo);
    }
    if (props.tableData.searchDetails.sortOrder !== order) {
      setOrder(props.tableData.searchDetails.sortOrder);
    }
    if (props.tableData.searchDetails.sortColumn !== orderBy) {
      setOrderBy(props.tableData.searchDetails.sortColumn);
    }
  }, [props]);

  React.useEffect(() => {
    let userid = localStorage.getItem("userId");
    props.getRoleByFilterData(props.tableData.searchDetails);
    getProviderByFilterData(props.tableData.searchDetails);
    props.getUserDetailsById(userid);
  }, []);

  const getProviderByFilterData = (value) => {
    props.setRoleSearchData(value);
    setSearchValues(value);
    const chipArray = [];
    for (const property in value) {
      if (value[property] !== "") {
        if (property === "Name" || property === "Role" || property === "All") {
          chipArray.push(
            `${property === "Current_Status" ? "Status" : property}: ${
              value[property]
            }`
          );
        }
      }
    }
    setChipValue(chipArray);
  };

  const handleNameRedirect = (value) => {
    const data = {
      role_id: value
    };
    props.rolePermisstionRequest(data);
  };

  const onChange = (value, type) => {
    const allState = { ...values };
    const error = { ...validationError };
    error[type] = false;
    allState[type] = value;
    allState.id = statusDetails.id;
    setValues(allState);
    setVlidationError(error);
  };

  const handleDeleteChip = (chip) => {
    if (chipValue.length) {
      setChipValue(chipValue.filter((v) => v !== chip));
      const requestData = { ...props.tableData.searchDetails };
      const fillterPreChip = chipValue.filter((v) => v !== chip);
      const filterRemoveChip = chipValue.filter((v) => v === chip);
      if (fillterPreChip.length !== 0) {
        fillterPreChip.forEach((v) => {
          const splitSearchName = v.split(":");
          if (splitSearchName[0] === "Name") {
            requestData.Name = splitSearchName[1].trim();
          }
          if (splitSearchName[0] === "Role") {
            requestData.Role = splitSearchName[1];
          }
        });
      }
      if (filterRemoveChip.length !== 0) {
        filterRemoveChip.forEach((v) => {
          const splitSearchName = v.split(":");
          if (splitSearchName[0] === "Name") {
            requestData.Name = "";
          }
          if (splitSearchName[0] === "Role") {
            requestData.Role = "";
          }
        });
      }
      props.getRoleByFilterData(requestData);
      props.setRoleSearchData(requestData);
    } else {
      const requestData = { ...props.tableData.searchDetails };
      requestData.Name = "";
      requestData.Role = "";
      props.getRoleByFilterData(requestData);
      props.setRoleSearchData(requestData);
      setChipValue(chipValue);
    }
    if (chipValue.length === 0) {
      const requestData = { ...props.tableData.searchDetails };
      requestData.Name = "";
      requestData.Role = "";
      props.getRoleByFilterData(requestData);
      props.setRoleSearchData(requestData);
      setChipValue([]);
    }
    setTemp(temp);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    const requestData = { ...props.tableData.searchDetails };
    requestData.pageNo = newPage + 1;
    props.getRoleByFilterData(requestData);
    props.setRoleSearchData(requestData);
  };

  const rows = [];
  if (preRoleData) {
    preRoleData.Roles_Data.forEach((v, i) => {
      const Id = v._id;
      const Role_Name = v.RoleName;
      const Profile_Type = v.Profile_Name;
      const Landing_Page = v.Landing_Page;
      const Creation_Date = v.Created_Date;
      const Update_Date = v.Updated_Date;
      const status = v.Status;
      const editable = v.RoleEdit;
      const associated = v.Associated_Users;
      const profileCode = v.Profile_Code;
      rows.push(
        createData(
          Role_Name,
          Profile_Type,
          Landing_Page,
          Creation_Date,
          Update_Date,
          status,
          Id,
          editable,
          associated,
          profileCode
        )
      );
    });
  }

  return (
    <>
      <Modal
        open={openModel}
        onClose={() => setOpenModel(false)}
        align="center"
      >
        <Card
          className={windowWidthSm ? classes.modalRoot : classes.modalRootXs}
        >
          <div className={classes.statusModalHeader}>
            <div className={classes.statusHeadingText}>Change Role Status</div>
            <ClearIcon
              style={{ color: "white", cursor: "pointer" }}
              onClick={() => setOpenModel(false)}
            />
          </div>
          <Grid
            container
            className={classes.margin}
            style={{
              flexDirection: "column",
              paddingLeft: "4%",
              alignItems: "flex-start"
            }}
          >
            <Grid item style={{ display: "flex", flexDirection: "row" }}>
              <Typography variant="h1" style={{ padding: "10px" }}>
                Role:
              </Typography>
              {"    "}
              <Typography
                component="p"
                variant="body1"
                style={{ padding: "10px" }}
              >
                {statusDetails.name}
              </Typography>
            </Grid>
            <Grid
              item
              style={{ display: "flex", flexDirection: "row", width: "80%" }}
            >
              <Typography variant="h1" style={{ padding: "10px" }}>
                Status:
              </Typography>
              <TextField
                className={formclasses.root}
                style={{ textAlign: "start" }}
                InputLabelProps={{
                  classes: {
                    root: formclasses.activeInputLableColor,
                    focused: "focused"
                  }
                }}
                InputProps={{
                  classes: {
                    root: formclasses.activeInputColor,
                    input: formclasses.resize,
                    focused: "focused"
                  }
                }}
                value={values.status}
                onChange={(e) => onChange(e.target.value, "status")}
                name="status"
                id="status"
                select
              >
                <MenuItem
                  className={formclasses.dialogBoxDropDown}
                  value="Active"
                >
                  Active
                </MenuItem>
                <MenuItem
                  className={formclasses.dialogBoxDropDown}
                  value="Inactive"
                >
                  Inactive
                </MenuItem>
              </TextField>
            </Grid>
          </Grid>

          <Grid
            container
            className={classes.marginTop}
            style={{
              paddingTop: "10px",
              paddingRight: "20px",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center"
            }}
          >
            <CoustomButton
              onClick={() => saveStatus()}
              align="center"
              variant="contained"
            >
              Update
            </CoustomButton>
          </Grid>
        </Card>
      </Modal>
      <Modal
        open={openassociateModel}
        onClose={() => setOpenassociateModel(false)}
        align="center"
      >
        <div className={classes.modalDiv} align="center">
          <Card
            className={windowWidthSm ? classes.modalRoot : classes.modalRootXs}
          >
            <div
              className={classes.statusModalHeader}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "98%"
              }}
            >
              <CardHeader
                className={classes.statusHeadingText}
                subheader={
                  <Typography style={{ color: "white" }}>
                    Associated Users
                  </Typography>
                }
                align="center"
              />
              <ClearIcon
                style={{ color: "white", cursor: "pointer" }}
                onClick={() => setOpenassociateModel(false)}
              />
            </div>
            <Grid
              container
              style={{ padding: "15px" }}
              className={classes.margin}
            >
              <Grid item xs>
                <div className={classes.demo}>
                  <span style={{ fontWeight: "bold" }}>Role: </span>
                  {`${statusDetails.name}`}
                </div>
              </Grid>
            </Grid>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                marginBottom: "1rem",
                marginLeft: "1rem"
              }}
            >
              <span style={{ fontWeight: "bold" }}>
                Associated Users (Count: {statusDetails.associated.length}):{" "}
              </span>
            </div>
            <Grid container className={classes.dialogScrollBar}>
              <Grid item xs>
                <div className={classes.demo}>
                  {statusDetails.associated && statusDetails.associated[0] ? (
                    <List dense={dense}>
                      {statusDetails.associated.map((key) => (
                        <ListItem xs={12} style={{ padding: "unset" }}>
                          <ListItemText
                            primary={key}
                            style={{ textAlign: "start" }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <div className={classes.demo}>
                      <span>None</span>
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
            <Grid
              item
              sm={12}
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                margin: "8px 1rem"
              }}
            >
              <Button
                cancel
                className={classes.clearButton}
                aria-controls="simple-menu"
                onClick={() => setOpenassociateModel(false)}
                align="center"
                style={{ textTransform: "capitalize" }}
              >
                Close
              </Button>
            </Grid>
          </Card>
        </div>
      </Modal>
      {windowWidth && (
        <Grid container className={classes.searchContainer}>
          <Grid item className={classes.margin}>
            <div className={classes.searchWrapper}>
              <ClickAwayListener
                mouseEvent="onMouseDown"
                touchEvent="onTouchStart"
                onClickAway={() => handleClose()}
              >
                <Box style={{ width: "35%" }}>
                  <ChipInput
                    value={chipValue.length > 0 ? chipValue : []}
                    onClick={() => handleClickOpen()}
                    style={{ display: "flex" }}
                    classes={{ chipContainer: classes.chipContainer }}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    label="Search"
                    onDelete={(chip, index) => handleDeleteChip(chip, index)}
                  />
                  <Collapse
                    in={open}
                    timeout="auto"
                    unmountOnExit
                    className={classes.overLayBox}
                  >
                    <SimpleDialog
                      close={() => handleClose()}
                      searchValue={searchVlaues}
                      seacrhChipData={(data) => getProviderByFilterData(data)}
                    />
                  </Collapse>
                </Box>
              </ClickAwayListener>
              <CoustomButton
                color="white"
                aria-label="edit"
                justIcon
                round
                onClick={() => {}}
              >
                <Search />
              </CoustomButton>
              <CoustomButton
                variant="contained"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={exportListData}
                className={classes.ExportButtonStyle}
              >
                Export
              </CoustomButton>
              <CoustomButton
                variant="contained"
                onClick={providerregisterurl}
                aria-controls="simple-menu"
                className={classes.ExportButtonStyle}
              >
                <AddIcon />
                New
              </CoustomButton>
            </div>
          </Grid>
        </Grid>
      )}
      {!windowWidth && (
        <Grid container className={classes.searchContainer}>
          <Grid item className={classes.margin} style={{ display: "flex" }}>
            <ClickAwayListener
              mouseEvent="onMouseDown"
              touchEvent="onTouchStart"
              onClickAway={() => handleClose()}
            >
              <Box style={{ width: "85%" }}>
                <ChipInput
                  value={chipValue.length > 0 ? chipValue : []}
                  onClick={() => handleClickOpen()}
                  style={{ display: "flex" }}
                  classes={{ chipContainer: classes.chipContainer }}
                  InputLabelProps={{
                    classes: {
                      root: classes.activeInputLableColor,
                      focused: "focused"
                    }
                  }}
                  InputProps={{
                    classes: {
                      root: classes.activeInputColor,
                      input: classes.resize,
                      focused: "focused"
                    }
                  }}
                  label="Search"
                  onDelete={(chip, index) => handleDeleteChip(chip, index)}
                />
                <Collapse
                  in={open}
                  timeout="auto"
                  unmountOnExit
                  className={classes.overLayBox}
                >
                  <SimpleDialog
                    close={() => handleClose()}
                    searchValue={searchVlaues}
                    seacrhChipData={(data) => getProviderByFilterData(data)}
                  />
                </Collapse>
              </Box>
            </ClickAwayListener>
            <CoustomButton
              color="white"
              aria-label="edit"
              justIcon
              round
              onClick={() => {}}
            >
              <Search />
            </CoustomButton>
          </Grid>
          <Grid item className={classes.expirtItemGrid}>
            <CoustomButton
              variant="contained"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={exportListData}
              color="primary"
              className={classes.buttonStyle}
            >
              Export
            </CoustomButton>
            <CoustomButton
              variant="contained"
              onClick={providerregisterurl}
              aria-controls="simple-menu"
              color="primary"
              className={classes.buttonStyle}
            >
              <AddIcon />
              New
            </CoustomButton>
          </Grid>
        </Grid>
      )}
      <Table
        className={classes.table}
        aria-labelledby="tableTitle"
        size={dense ? "small" : "medium"}
        aria-label="enhanced table"
      >
        <EnhancedTableHead
          classes={classes}
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={rows.length}
        />
        <TableBody>
          {!isEmpty(rows) ? (
            rows.map((row, index) => (
              <TableRow>
                <TableCell
                  style={{ cursor: "pointer", color: "primary" }}
                  className={classes.patientNameColor}
                  align="center"
                  onClick={() => row.editable && handleNameRedirect(row.Id)}
                >
                  {row.Role_Name}
                </TableCell>
                <TableCell className={classes.cellText} align="center">
                  {row.Profile_Type}
                </TableCell>
                <TableCell className={classes.cellText} align="center">
                  {row.Landing_Page}
                </TableCell>
                <TableCell className={classes.cellText} align="center">
                  {row.Creation_Date}
                </TableCell>
                <TableCell className={classes.cellText} align="center">
                  {row.Update_Date}
                </TableCell>
                <TableCell className={classes.cellText} align="center">
                  {row.status}
                </TableCell>
                <TableCell>
                  <LongMenu
                    details={row.Id}
                    profileCode={row.profileCode}
                    editable={row.editable}
                    {...props}
                    openForm={() => setOpenModel(true)}
                    openassociateModel={() => setOpenassociateModel(true)}
                    setStatusPid={() => {
                      setStatusPid({
                        id: row.Id,
                        name: row.Role_Name,
                        status: row.Status,
                        associated: row.Associated_Users,
                        status: row.status,
                        clientId:
                          process.env.REACT_APP_ACCESS_SERVICE_PROVIDER_ID
                      });
                      setValues({
                        status: row.status,
                        id: row.Id
                      });
                    }}
                  />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell className="no-data" colSpan={12}>
                <img src={noDataImage} alt="" className={classes.noDataImage} />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        {rows.length ? (
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[
                  5,
                  10,
                  25,
                  { label: "All", value: props.tableData.roleList.totCount }
                ]}
                colSpan={12}
                count={props.tableData.roleList.totCount}
                rowsPerPage={rowsPerPage}
                page={page - 1}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        ) : (
          ""
        )}
      </Table>
    </>
  );
}

RoleDataList.propTypes = {
  tableData: PropTypes.object,
  history: PropTypes.any,
  changeRoleFormMode: PropTypes.func,
  rolePermisstionRequest: PropTypes.func,
  getRoleByFilterData: PropTypes.func,
  setRoleChips: PropTypes.func,
  chipValue: PropTypes.array,
  roleStatusUpdate: PropTypes.func,
  setRoleSearchData: PropTypes.func,
  getRoleExport: PropTypes.func,
  mainData: PropTypes.object,
  getUserDetailsById: PropTypes.func
};

const mapStateToProps = (app) => ({
  tableData: app.roles,
  mainData: app
});
export default connect(mapStateToProps, {
  rolePermisstionRequest,
  changeRoleFormMode,
  getRoleByFilterData,
  roleStatusUpdate,
  setRoleSearchData,
  getRoleExport,
  getUserDetailsById
})(RoleDataList);
