// payout calculation permission
export const viewCalculationPermission = (data) => {
  let allowPermission = true;
  const menuData = data.filter((menu) => menu.Cat_Code == 3);
  const submenuData =
    menuData && menuData[0].Page_Menu.filter((menu) => menu.Page_Code == 4);
  const pageData =
    submenuData && submenuData[0].Options.filter((view) => view.value == 1);
  if (pageData) {
    allowPermission = pageData[0].permission;
  }
  return allowPermission;
};

export const runCalculationPermission = (data) => {
  let allowPermission = true;
  const menuData = data.filter((menu) => menu.Cat_Code == 3);
  const submenuData =
    menuData && menuData[0].Page_Menu.filter((menu) => menu.Page_Code == 4);
  const pageData =
    submenuData && submenuData[0].Options.filter((view) => view.value == 2);
  if (pageData) {
    allowPermission = pageData[0].permission;
  }
  return allowPermission;
};

// payout summary permission
export const deleteSummaryPermission = (data) => {
  let allowPermission = true;
  const menuData = data.filter((menu) => menu.Cat_Code == 3);
  const submenuData =
    menuData && menuData[0].Page_Menu.filter((menu) => menu.Page_Code == 6);
  const pageData =
    submenuData && submenuData[0].Options.filter((view) => view.value == 3);
  if (pageData) {
    allowPermission = pageData[0].permission;
  }
  return allowPermission;
};
export const viewSummaryPermission = (data) => {
  let allowPermission = true;
  const menuData = data.filter((menu) => menu.Cat_Code == 3);
  const submenuData =
    menuData && menuData[0].Page_Menu.filter((menu) => menu.Page_Code == 6);
  const pageData =
    submenuData && submenuData[0].Options.filter((view) => view.value == 1);
  if (pageData) {
    allowPermission = pageData[0].permission;
  }
  return allowPermission;
};
export const freezeSummaryPermission = (data) => {
  let allowPermission = true;
  const menuData = data.filter((menu) => menu.Cat_Code == 3);
  const submenuData =
    menuData && menuData[0].Page_Menu.filter((menu) => menu.Page_Code == 6);
  const pageData =
    submenuData && submenuData[0].Options.filter((view) => view.value == 4);
  if (pageData) {
    allowPermission = pageData[0].permission;
  }
  return allowPermission;
};
export const exportSummaryPermission = (data) => {
  let allowPermission = true;
  const menuData = data.filter((menu) => menu.Cat_Code == 3);
  const submenuData =
    menuData && menuData[0].Page_Menu.filter((menu) => menu.Page_Code == 6);
  const pageData =
    submenuData && submenuData[0].Options.filter((view) => view.value == 2);
  if (pageData) {
    allowPermission = pageData[0].permission;
  }
  return allowPermission;
};
