/* eslint-disable import/extensions */
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import styles from "assets/jss/material-dashboard-react/components/businessForm";

const useStyles = makeStyles(styles);

export function SimpleDialog(props) {
  const [values, setValue] = React.useState({
    cpt: "",
    status: "",
    funded: "",
    fundedLess: "",
    offerDateFrom: "",
    offerDateTo: ""
  });
  const classes = useStyles();
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const onChange = (value, type) => {
    const allState = { ...values };
    allState[type] = value;
    setValue(allState);
  };

  return (
    <>
      <Grid container className={classes.margin} style={{ marginBottom: "5%" }}>
        <Grid item xs>
          <TextField
            className={classes.rootDialog}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                focused: "focused",
                input: classes.resize
              }
            }}
            label="CPT Code / Description"
            value={values.cpt}
            onChange={(e) => onChange(e.target.value, "cpt")}
            name="cpt"
            id="cpt"
            style={{ fontSize: "82%" }}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.margin} style={{ marginBottom: "5%" }}>
        <Grid item xs>
          <TextField
            className={classes.halfInput}
            InputLabelProps={{
              shrink: true,
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            type="date"
            label="Offer Date (From)"
            value={values.offerDateFrom}
            onChange={(e) => onChange(e.target.value, "offerDateFrom")}
            name="offerDateFrom"
            id="offerDateFrom"
            style={{ fontSize: "82%" }}
          />
        </Grid>
        <Grid item xs>
          <TextField
            className={classes.halfInput}
            InputLabelProps={{
              shrink: true,
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            type="date"
            label="Offer Date (To)"
            value={values.offerDateTo}
            onChange={(e) => onChange(e.target.value, "offerDateTo")}
            name="offerDateTo"
            id="offerDateTo"
            style={{ fontSize: "82%" }}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.margin} style={{ marginBottom: "5%" }}>
        <Grid item xs>
          <TextField
            className={classes.halfInput}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            label="Fund Greater than Equal ($)"
            value={values.funded}
            onChange={(e) => onChange(e.target.value, "funded")}
            name="funded"
            id="funded"
            style={{ fontSize: "82%" }}
          />
        </Grid>
        <Grid item xs>
          <TextField
            className={classes.halfInputSingle}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            label="Fund Less than Equal ($)"
            value={values.fundedLess}
            onChange={(e) => onChange(e.target.value, "fundedLess")}
            name="fundedLess"
            id="fundedLess"
            style={{ fontSize: "82%" }}
          />
        </Grid>
      </Grid>

      <Grid
        container
        className={classes.margin}
        style={{ justifyContent: "end" }}
      >
        <Button
          cancel
          variant="contained"
          className={classes.clearButton}
          onClick={() => props.close()}
        >
          clear
        </Button>
        <Button
          variant="contained"
          className={classes.buttonStyle}
          onClick={() => props.close()}
        >
          Search
        </Button>
      </Grid>
    </>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  close: PropTypes.func
};
