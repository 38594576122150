import React from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";

import PropTypes from "prop-types";

import Collapse from "@material-ui/core/Collapse";
import Search from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";
import CoustomButton from "../../../../components/CustomButtons/Button";
import tableStyle from "../../../../assets/jss/material-dashboard-react/components/reportTables";

const SearchFields = ({
  searchBar,
  Fields,
  handleSearchClose,
  handleSearchOpen,
  width
}) => {
  const useStyles = makeStyles(tableStyle);
  const classes = useStyles();
  return (
    <>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={() => handleSearchClose()}
      >
        <Box style={{ width: width || "35%" }}>
          <TextField
            onClick={() => handleSearchOpen()}
            style={{ display: "flex" }}
            onChange={() => {}}
            // classes={{ chipContainer: classes.chipContainer }}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            label="Search"
          />

          <Collapse
            in={searchBar}
            timeout="auto"
            unmountOnExit
            className={classes.overLayBox}
          >
            <Fields />
          </Collapse>
        </Box>
      </ClickAwayListener>
      <CoustomButton
        color="white"
        aria-label="edit"
        justIcon
        round
        onClick={() => {}}
      >
        <Search />
      </CoustomButton>
    </>
  );
};

SearchFields.propTypes = {
  searchBar: PropTypes.bool,
  Fields: PropTypes.any,
  handleSearchClose: PropTypes.func,
  handleSearchOpen: PropTypes.func,
  width: PropTypes.string
};

export default SearchFields;
