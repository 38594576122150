import { get, post } from "../api_interceptor/httpClient";
import toggleLoaderAction from "../../../Redux/Actions/loader";
import {
  GET_MERCHANT_MAPPING_LIST,
  GET_PROFILE_REPCODE_LIST,
  GET_MERCHANTS_BY_NAME,
  UPDATE_MAPPING_STATUS,
  GET_MERCHANT_PROFILES_HISTORY
} from "../api_interceptor/urls";

export const fetchMappedMerchantsList =
  (payload, successCallback, failureCallback) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await get(GET_MERCHANT_MAPPING_LIST, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };

export const fetchMappedMerchantsListAll =
  (successCallback, failureCallback) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await get(GET_MERCHANT_MAPPING_LIST);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };

export const fetchProfileRepCodeList =
  (payload, successCallback, failureCallback) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await get(GET_PROFILE_REPCODE_LIST, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };

export const fetchMerchantsByName =
  (payload, successCallback, failureCallback) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await get(GET_MERCHANTS_BY_NAME, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };

export const updateMappingStatusUpdate =
  (payload, successCallback, failureCallback) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await post(UPDATE_MAPPING_STATUS, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };


  export const fetchMerchantProfileHistory =
  (payload, successCallback, failureCallback) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await get(GET_MERCHANT_PROFILES_HISTORY, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };
