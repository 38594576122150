import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import { connect } from "react-redux";
import Radio from "@material-ui/core/Radio";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { Button, Grid, InputLabel, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import DateFnsUtils from "@date-io/date-fns";
import Select from "@material-ui/core/Select";
import { Field, Form, Formik, ErrorMessage } from "formik";
import {
  createTheme,
  ThemeProvider,
  makeStyles,
  withStyles,
  useTheme
} from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/components/userForm";
import tableStyle from "assets/jss/material-dashboard-react/components/reportTables";
import AppBar from "@material-ui/core/AppBar";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import isEmpty from "lodash/isEmpty";
import Checkbox from "@material-ui/core/Checkbox";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TableSortLabel from "@material-ui/core/TableSortLabel";

const headCells = [
  {
    id: "ProcessorID",
    label: "Processor",
    isSort: true,
    sortProperty: "ProcessorID",
    actionsCellStyle: "left"
  },
  {
    id: "AgentName",
    label: "Agent",
    isSort: true,
    sortProperty: "AgentName",
    actionsCellStyle: "left"
  },
  {
    id: "MID",
    label: "Merchants",
    isSort: true,
    sortProperty: "MID",
    actionsCellStyle: "left"
  },
  {
    id: "Adjustment_Name",
    label: "Adjustment Name",
    isSort: true,
    sortProperty: "Adjustment_Name",
    actionsCellStyle: "left"
  },
  {
    id: "StartDate",
    label: "Start Date",
    isSort: true,
    sortProperty: "StartDate",
    actionsCellStyle: "left"
  },
  {
    id: "EndDate",
    label: "End Date",
    isSort: true,
    sortProperty: "EndDate",
    actionsCellStyle: "left"
  },
  {
    id: "Amount",
    label: "Amount",
    isSort: true,
    sortProperty: "Amount",
    actionsCellStyle: "left"
  },
  {
    id: "Status",
    label: "Status",
    isSort: true,
    sortProperty: "Status",
    actionsCellStyle: "left"
  },
  {
    id: "action",
    label: "",
    isSort: false,
    sortProperty: "action",
    actionsCellStyle: "left"
  }
];

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#E6E4E4",
    color: theme.palette.common.black,
    height: "20px"
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const useStyles = makeStyles(tableStyle);
const Payout_Calculation_Listing = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      <TableContainer
        className={classes.TableContainer}
        component={Paper}
        style={{ marginTop: "8px" }}
      >
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <StyledTableCell align="left" padding="checkbox">
                <Checkbox
                  color="primary"
                  // indeterminate={
                  //   selected.length > 0 && selected.length < listData.length
                  // }
                  // checked={
                  //   listData.length > 0 && selected.length === listData.length
                  // }
                  // onChange={handleSelectAllClick}
                  inputProps={{ "aria-label": "select all desserts" }}
                />
              </StyledTableCell>
              <StyledTableCell align="left">
                <b>Agent (Repcode)</b>
              </StyledTableCell>
              <StyledTableCell align="center">
                <b>Processor</b>
              </StyledTableCell>
              <StyledTableCell align="center">
                <b>Start Date</b>
              </StyledTableCell>
              <StyledTableCell align="center">
                <b>Creation Date</b>
              </StyledTableCell>
              <StyledTableCell align="center">
                <b>Merchants</b>
              </StyledTableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    </>
  );
};

Payout_Calculation_Listing.propTypes = {};

Payout_Calculation_Listing.defaultProps = {};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(Payout_Calculation_Listing);
