/* eslint-disable import/extensions */
import React from "react";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Menu from "@material-ui/core/Menu";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import styles from "assets/jss/material-dashboard-react/components/businessForm";

const useStyles = makeStyles(styles);

function LongMenu(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { showHistory } = props;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {/* {roles && viewSummaryPermission(roles.Roles_Data.Master_Permission) && ( */}
        <MenuItem
          className={classes.dialogBoxDropDown}
          onClick={() => {
            showHistory();
            handleClose();
          }}
        >
          History
        </MenuItem>
        {/* )} */}
      </Menu>
    </div>
  );
}

LongMenu.propTypes = {
  showHistory: PropTypes.func
};

LongMenu.defaultProps = {
  showHistory: () => {}
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(LongMenu);
