import React from "react";
import Paper from "@material-ui/core/Paper";
import UserHierarchy from "../RoleManager/UserHierarchy";

const MyHierarchy = () => {
  const userId = localStorage.getItem("userId");
  return (
    <Paper elevation={3} style={{ background: "#ffffff" }}>
      <UserHierarchy userIdHierarchy={userId} />
    </Paper>
  );
};

export default MyHierarchy;
