/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Divider, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CardFooter from "components/Card/CardFooter";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import Card from "components/Card/Card";
import Modal from "@material-ui/core/Modal";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import ClearIcon from "@material-ui/icons/Clear";
import Accessibility from "@material-ui/icons/Accessibility";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import AttachMoneyOutlinedIcon from "@material-ui/icons/AttachMoneyOutlined";
import AllInclusiveOutlinedIcon from "@material-ui/icons/AllInclusiveOutlined";
import AttachmentOutlinedIcon from "@material-ui/icons/AttachmentOutlined";
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturned";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import MoreOutlinedIcon from "@material-ui/icons/MoreOutlined";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import Link from "@material-ui/core/Link";
import offerHeaderStyles from "assets/jss/material-dashboard-react/views/offerHeader";
import AWS from "aws-sdk";

const useStyles = makeStyles(offerHeaderStyles);

const HeaderOffer = (props) => {
  const classes = useStyles();
  const [documentData, setDocumentDataData] = React.useState(
    props.tableData.data
  );
  const [documentListModal, setDocumentListModal] = React.useState(false);

  React.useEffect(() => {
    if (documentData !== props.tableData.data) {
      setDocumentDataData(props.tableData.data);
    }
  }, [props]);

  const downloadFile = (fileName) => {
    // const data = {
    //   fileName,
    // };
    // props.offerDownloadFile(data);
    AWS.config.update({
      region: process.env.REACT_APP_AWS_REGION,
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
    });

    const bucketParams = {
      Bucket: process.env.REACT_APP_AWS_BUCKET,
      Key: "6151a642585f6d46e88c7f96.jpeg",
      ServerSideEncryption: "AES256",
      ACL: "public-read",
      ContentEncoding: "base64"
    };

    const s3 = new AWS.S3();

    s3.getObject(bucketParams, (err, data) => {
      if (err) {
      } else {
        s3.getObject(bucketParams).createReadStream();
      }
    });
  };
  // const titleRef = props.titleRef;
  // const handleScrollNewDues = () => {
  //   titleRef.current.scrollIntoView({
  //     top: "0",
  //     behavior: "smooth",
  //     block: "start",
  //   });
  // };

  // const titleActivePlan = props.titleActivePlan;
  // const handleScrollActivePlan = () => {
  //   titleActivePlan.current.scrollIntoView({
  //     top: "0",
  //     behavior: "smooth",
  //     block: "start",
  //   });
  // };

  return (
    <div className={classes.mainRoot}>
      <Grid spacing={2} container className={classes.mainContainerGrid}>
        <Grid item className={classes.duesGrid} lg={3} md={3} sm={6} xs={12}>
          <Card>
            <CardHeader color="info" stats icon className={classes.cardHeader}>
              <CardIcon color="info" className={classes.cardIcon}>
                <AllInclusiveOutlinedIcon />
                <Typography noWrap className={classes.cardTitle}>
                  Total Dues
                </Typography>
              </CardIcon>
            </CardHeader>
            <CardBody className={classes.cardBody}>
              <Typography className={classes.typographyAmount}>
                {props.showOfferDEtails.data.totalDuesAmount}
              </Typography>
              <Typography className={classes.typographyCount}>
                {props.showOfferDEtails.data.totalDues}
              </Typography>
            </CardBody>
            <CardFooter stats className={classes.cardFooter}>
              <div className={classes.viewDetail} align="left">
                &nbsp;&nbsp;
                {/* View detail <ArrowForwardIosIcon /> */}
              </div>
              <div className={classes.stats} />
            </CardFooter>
          </Card>
        </Grid>
        <Grid item className={classes.duesGrid} lg={3} md={3} sm={6} xs={12}>
          <Card>
            <CardHeader color="info" stats icon className={classes.cardHeader}>
              <CardIcon color="info" className={classes.cardIcon}>
                <AttachMoneyOutlinedIcon />
                <Typography noWrap className={classes.cardTitle}>
                  Active Plans
                </Typography>
              </CardIcon>
            </CardHeader>
            <CardBody className={classes.cardBody}>
              <Typography className={classes.typographyAmount}>
                {props.showOfferDEtails.data.activeAmount}
              </Typography>
              <Typography className={classes.typographyCount}>
                {props.showOfferDEtails.data.activePlans}
              </Typography>
            </CardBody>
            <CardFooter stats className={classes.cardFooter}>
              <div
                className={classes.viewDetail}
                align="left"
                onClick={(event) => props.handleChange(event, 2)}
              >
                View detail <ArrowForwardIosIcon />
              </div>
              <div className={classes.stats} />
            </CardFooter>
          </Card>
        </Grid>
        <Grid item className={classes.duesGrid} lg={3} md={3} sm={6} xs={12}>
          <Card>
            <CardHeader color="info" stats icon className={classes.cardHeader}>
              <CardIcon color="info" className={classes.cardIcon}>
                <Accessibility />
                <Typography noWrap className={classes.cardTitle}>
                  New Dues
                </Typography>
              </CardIcon>
            </CardHeader>
            <CardBody className={classes.cardBody}>
              <Typography className={classes.typographyAmount}>
                {props.showOfferDEtails.data.newDuesWithOfferAmount}
              </Typography>
              <Typography className={classes.typographyCount}>
                {props.showOfferDEtails.data.newDuesWithOffer}
              </Typography>
            </CardBody>
            <CardFooter stats className={classes.cardFooter}>
              <div
                className={classes.viewDetail}
                align="left"
                onClick={(event) => props.handleChange(event, 1)}
              >
                View detail <ArrowForwardIosIcon />
              </div>
              <div className={classes.stats} />
            </CardFooter>
          </Card>
        </Grid>
        <Grid
          item
          className={classes.documentGrid}
          lg={3}
          md={3}
          sm={6}
          xs={12}
        >
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info" className={classes.cardIcon}>
                <AttachmentOutlinedIcon
                  align="left"
                  className={classes.AttachmentOutlinedIcon}
                />
                <Typography className={classes.cardTitle}>Documents</Typography>
              </CardIcon>
            </CardHeader>
            <CardBody className={classes.cardDocumentBody}>
              <Grid container>
                {documentData &&
                  documentData.slice(0, 3).map((key) => (
                    <Grid item xs={12} className={classes.gridInnerDocument}>
                      <Typography className={classes.typographyDate}>
                        {key.Upload_Document_Date}
                      </Typography>
                      <Typography className={classes.typographyOffer}>
                        {key.offerNumber}
                      </Typography>
                      <Typography className={classes.typographyStatus}>
                        {key.Offer_Status}
                      </Typography>
                      <AssignmentReturnedIcon
                        className={classes.downloadBtn}
                        onClick={() =>
                          downloadFile(key.Offer_Acceptance_Document)
                        }
                      />
                    </Grid>
                  ))}
              </Grid>
            </CardBody>
            <CardFooter stats className={classes.cardFooter}>
              <div
                className={classes.viewMore}
                align="left"
                onClick={() => setDocumentListModal(true)}
              >
                <MoreOutlinedIcon />
                View More
              </div>
              <div className={classes.stats} />
            </CardFooter>
          </Card>
        </Grid>
        <Modal
          open={documentListModal}
          onClose={() => setDocumentListModal(false)}
        >
          <div className={classes.modalDiv} align="center">
            <Card className={classes.modalRoot}>
              <Grid container className={classes.modalGridContainer}>
                <Grid item xs={10}>
                  <div className={classes.modalHeaderTitle}>
                    {"Documents for: "}
                    <div
                      className={classes.modalHeaderName}
                      style={{ display: "flex" }}
                    >
                      &nbsp;&nbsp;{" "}
                      {`${props.showOfferDEtails.data.Patient_Name} -`}
                      <div style={{ fontSize: "20px" }}>TPO Level</div>
                    </div>
                    <div className={classes.tpoLevelMargin}>
                      <div
                        style={{
                          position: "relative",
                          display: "inline-flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "-30%"
                        }}
                      >
                        <FiberManualRecordIcon
                          fontSize="large"
                          style={{
                            color:
                              Math.round(
                                props.showOfferDEtails.data.tpoLevel
                              ) === 0
                                ? "#cfcfcf"
                                : Math.round(
                                    props.showOfferDEtails.data.tpoLevel
                                  ) === 1
                                ? "#FF0000"
                                : Math.round(
                                    props.showOfferDEtails.data.tpoLevel
                                  ) === 2
                                ? "#FFA500"
                                : Math.round(
                                    props.showOfferDEtails.data.tpoLevel
                                  ) === 3
                                ? "#FFFF00"
                                : Math.round(
                                    props.showOfferDEtails.data.tpoLevel
                                  ) === 4
                                ? "#00FF00"
                                : "#cfcfcf",
                            fontSize: "4rem"
                          }}
                        />
                        <Typography
                          align="center"
                          style={{
                            position: "absolute",
                            lineHeight: 1,
                            color: "#000000",
                            textAlign: "center",
                            width: "36px",
                            fontSize: "1.5rem",
                            fontWeight: 700
                          }}
                        >
                          {Math.round(props.showOfferDEtails.data.tpoLevel) ===
                          0
                            ? 0
                            : Math.round(
                                props.showOfferDEtails.data.tpoLevel
                              ) === 1
                            ? 1
                            : Math.round(
                                props.showOfferDEtails.data.tpoLevel
                              ) === 2
                            ? 2
                            : Math.round(
                                props.showOfferDEtails.data.tpoLevel
                              ) === 3
                            ? 3
                            : Math.round(
                                props.showOfferDEtails.data.tpoLevel
                              ) === 4
                            ? 4
                            : 0}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2}>
                  <ClearIcon
                    align="right"
                    style={{ cursor: "pointer" }}
                    onClick={() => setDocumentListModal(false)}
                  />
                </Grid>
              </Grid>
              <TableContainer className={classes.modalTableContainer}>
                <Table
                  className={classes.patientTable}
                  aria-labelledby="tableTitle"
                  aria-label="enhanced table"
                  stickyHeader
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={classes.cellTextHeader}
                        align="center"
                      >
                        Date
                      </TableCell>
                      <TableCell
                        className={classes.cellTextHeader}
                        align="Center"
                      >
                        Offer Number
                      </TableCell>
                      <TableCell
                        className={classes.cellTextHeader}
                        align="Center"
                      >
                        Status
                      </TableCell>
                      <TableCell align="Center">
                        <CloudDownloadIcon />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {documentData &&
                      documentData.map((key) => (
                        <TableRow>
                          <TableCell
                            className={classes.cellText}
                            align="center"
                          >
                            {key.Upload_Document_Date}
                          </TableCell>
                          <TableCell
                            className={classes.cellText}
                            align="center"
                          >
                            {key.offerNumber}
                          </TableCell>
                          <TableCell
                            className={classes.cellText}
                            align="center"
                          >
                            {key.Offer_Status}
                          </TableCell>
                          <TableCell align="center">
                            <AssignmentReturnedIcon
                              onClick={() =>
                                downloadFile(key.Offer_Acceptance_Document)
                              }
                              style={{ color: "#00acc1", cursor: "pointer" }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell colSpan="4">&nbsp;&nbsp;</TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Card>
          </div>
        </Modal>
      </Grid>
    </div>
  );
};

HeaderOffer.propTypes = {
  showOfferDEtails: PropTypes.any,
  tableData: PropTypes.object,
  // handleScrollNewDues: PropTypes.func,
  // handleScrollActivePlan: PropTypes.func,
  handleChange: PropTypes.func
};

export default HeaderOffer;
