/* eslint-disable no-nested-ternary */
/* eslint-disable radix */
/* eslint-disable no-loop-func */
/* eslint-disable no-restricted-syntax */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Box from "@material-ui/core/Box";
import moment from "moment";
// core components
import TextField from "@material-ui/core/TextField";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { Checkbox, Button } from "@material-ui/core";
import Card from "components/Card/Card";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import { useSnackbar } from "notistack";
import FormHelperText from "@material-ui/core/FormHelperText";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import Divider from "@material-ui/core/Divider";
import RoomIcon from "@material-ui/icons/Room";
import PresentToAllIcon from "@material-ui/icons/PresentToAll";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import SwapVerticalCircleIcon from "@material-ui/icons/SwapVerticalCircle";
import WebIcon from "@material-ui/icons/Web";
import SystemUpdateIcon from "@material-ui/icons/SystemUpdate";
import { getProviderDetailsById } from "../../Redux/Actions/ProvideRegistration";
import {
  getEquipmentCategory,
  postAddtionalInformation,
  updateAddtionalInformation
} from "../../actions/Operations";

const useStyles = makeStyles(styles);

function AddtionalInformation(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [equipmentCategoryData, setEquipmentCategoryData] =
    React.useState(false);
  const [data, setData] = React.useState(false);
  const [values, setValues] = React.useState({
    providerId:
      props.formData.providerDetailsById &&
      props.formData.providerDetailsById.Providers_Data[0]._id,
    merchantLocation: "",
    merchantLocationOther: "",
    merchantPremisesType: "",
    conductingBusinessApplication: "",
    adequatelyStaffedStocked: "",
    takenPicturesInsideOutside: "",
    identityPersonWhoSigned: "",
    pumpModel: "",
    siteServiceCompanyName: "",
    currentPOSSystem: "",
    pintedName: "",
    fuelPositions: "",
    phone: "",
    certifyDate: "",
    equipmentInformation: {
      data: [
        {
          rentalItem: "",
          qty: "",
          ip: "",
          equipmentCategory: "",
          equipmentName: "",
          authorizationNetwork: "",
          unitPriceWithoutTax: "",
          term: ""
        }
      ]
    }
  });
  const [validation, setValidation] = React.useState({
    providerId:
      props.formData.providerDetailsById &&
      props.formData.providerDetailsById.Providers_Data[0]._id,
    merchantLocation: false,
    merchantLocationOther: false,
    merchantPremisesType: false,
    conductingBusinessApplication: false,
    adequatelyStaffedStocked: false,
    takenPicturesInsideOutside: false,
    identityPersonWhoSigned: false,
    pumpModel: false,
    siteServiceCompanyName: false,
    currentPOSSystem: false,
    pintedName: false,
    fuelPositions: false,
    phone: false,
    certifyDate: false,
    equipmentInformation: {
      data: [
        {
          rentalItem: false,
          qty: false,
          ip: false,
          equipmentCategory: false,
          equipmentName: false,
          authorizationNetwork: false,
          unitPriceWithoutTax: false,
          term: false
        }
      ]
    }
  });

  if (!equipmentCategoryData) {
    props.getEquipmentCategory(
      "",
      (data) => {
        if (data.status) {
          setEquipmentCategoryData(data.data);
        }
      },
      (err) => {}
    );
  }

  const addOwner = () => {
    if (values.equipmentInformation.data.length < 3) {
      const newData = { ...values };
      const validations = { ...validation };
      newData.equipmentInformation.data.push({
        rentalItem: "",
        qty: "",
        ip: "",
        equipmentCategory: "",
        equipmentName: "",
        authorizationNetwork: "",
        unitPriceWithoutTax: "",
        term: ""
      });
      validations.equipmentInformation.data.push({
        rentalItem: false,
        qty: false,
        ip: false,
        equipmentCategory: false,
        equipmentName: false,
        authorizationNetwork: false,
        unitPriceWithoutTax: false,
        term: false
      });
      setValidation(validations);
      setValues(newData);
    }
  };

  const checkValidation = () => {
    let noError = true;
    const requestValues = { ...values };
    const allValueValidation = { ...validation };
    requestValues.equipmentInformation.data.forEach((key, i) => {
      if (key.rentalItem === "") {
        allValueValidation.equipmentInformation.data[i].rentalItem = true;
        noError = false;
      }
      if (key.qty === "") {
        allValueValidation.equipmentInformation.data[i].qty = true;
        noError = false;
      }
      if (key.equipmentCategory === "") {
        allValueValidation.equipmentInformation.data[
          i
        ].equipmentCategory = true;
        noError = false;
      }
      if (key.equipmentName === "") {
        allValueValidation.equipmentInformation.data[i].equipmentName = true;
        noError = false;
      }
    });
    setValidation(allValueValidation);
    return noError;
  };

  const handleSave = () => {
    const requestValues = { ...values };
    const EquimentInformation = values.equipmentInformation.data;
    requestValues.equipmentInformation = EquimentInformation;
    if (requestValues.conductingBusinessApplication === "") {
      requestValues.conductingBusinessApplication = false;
    }
    if (requestValues.adequatelyStaffedStocked === "") {
      requestValues.adequatelyStaffedStocked = false;
    }
    if (requestValues.takenPicturesInsideOutside === "") {
      requestValues.takenPicturesInsideOutside = false;
    }
    if (requestValues.identityPersonWhoSigned === "") {
      requestValues.identityPersonWhoSigned = false;
    }
    if (
      props.formData.providerDetailsById.Providers_Data[0].equipmentInformation
        .length === 0
    ) {
      if (checkValidation()) {
        props.postAddtionalInformation(
          requestValues,
          (data) => {
            enqueueSnackbar(data.message, {
              variant: "success"
            });
            handleBack();
          },
          (err) => {
            enqueueSnackbar(
              err.message ? err.message : "Failed to fetch Data",
              {
                variant: "error"
              }
            );
          }
        );
      } else {
        enqueueSnackbar("Please fill all Mandatory fields", {
          variant: "error"
        });
      }
    }
    if (
      props.formData.providerDetailsById.Providers_Data[0].equipmentInformation
        .length > 0
    ) {
      if (checkValidation()) {
        props.updateAddtionalInformation(
          requestValues,
          (data) => {
            enqueueSnackbar(data.message, {
              variant: "success"
            });
            handleBack();
          },
          (err) => {
            enqueueSnackbar(
              err.message ? err.message : "Failed to fetch Data",
              {
                variant: "error"
              }
            );
          }
        );
      } else {
        enqueueSnackbar("Please fill all Mandatory fields", {
          variant: "error"
        });
      }
    }
  };

  if (
    !data &&
    props.formData.providerDetailsById &&
    props.formData.providerDetailsById.Providers_Data &&
    props.formData.providerDetailsById.Providers_Data[0].equipmentInformation
      .length > 0
  ) {
    const preData =
      props.formData.providerDetailsById.Providers_Data[0].siteSurvey;
    const allValues = { ...values };
    allValues.adequatelyStaffedStocked =
      preData.adequatelyStaffedStocked !== undefined
        ? preData.adequatelyStaffedStocked
        : "";
    allValues.certifyDate =
      preData.certifyDate !== undefined ? preData.certifyDate : "";
    allValues.conductingBusinessApplication =
      preData.conductingBusinessApplication !== undefined
        ? preData.conductingBusinessApplication
        : "";
    allValues.currentPOSSystem =
      preData.currentPOSSystem !== undefined ? preData.currentPOSSystem : "";
    allValues.fuelPositions =
      preData.fuelPositions !== undefined ? preData.fuelPositions : "";
    allValues.identityPersonWhoSigned =
      preData.identityPersonWhoSigned !== undefined
        ? preData.identityPersonWhoSigned
        : "";
    allValues.merchantLocation =
      preData.merchantLocation !== undefined ? preData.merchantLocation : "";
    allValues.merchantLocationOther =
      preData.merchantLocationOther !== undefined
        ? preData.merchantLocationOther
        : "";
    allValues.merchantPremisesType =
      preData.merchantPremisesType !== undefined
        ? preData.merchantPremisesType
        : "";
    allValues.phone = preData.phone !== undefined ? preData.phone : "";
    allValues.pintedName =
      preData.pintedName !== undefined ? preData.pintedName : "";
    allValues.pumpModel =
      preData.pumpModel !== undefined ? preData.pumpModel : "";
    allValues.siteServiceCompanyName =
      preData.siteServiceCompanyName !== undefined
        ? preData.siteServiceCompanyName
        : "";
    allValues.takenPicturesInsideOutside =
      preData.takenPicturesInsideOutside !== undefined
        ? preData.takenPicturesInsideOutside
        : "";
    allValues.equipmentInformation.data =
      props.formData.providerDetailsById.Providers_Data[0].equipmentInformation
        .length > 0
        ? props.formData.providerDetailsById.Providers_Data[0]
            .equipmentInformation
        : [
            {
              rentalItem: "",
              qty: "",
              ip: "",
              equipmentCategory: "",
              equipmentName: "",
              authorizationNetwork: "",
              unitPriceWithoutTax: "",
              term: ""
            }
          ];
    setValues(allValues);
    setData(true);
  }

  const handleReset = () => {
    setValues({
      providerId:
        props.formData.providerDetailsById &&
        props.formData.providerDetailsById.Providers_Data[0]._id,
      merchantLocation: "",
      merchantLocationOther: "",
      merchantPremisesType: "",
      conductingBusinessApplication: "",
      adequatelyStaffedStocked: "",
      takenPicturesInsideOutside: "",
      identityPersonWhoSigned: "",
      pumpModel: "",
      siteServiceCompanyName: "",
      currentPOSSystem: "",
      pintedName: "",
      fuelPositions: "",
      phone: "",
      certifyDate: "",
      equipmentInformation: {
        data: [
          {
            rentalItem: "",
            qty: "",
            ip: "",
            equipmentCategory: "",
            equipmentName: "",
            authorizationNetwork: "",
            unitPriceWithoutTax: "",
            term: ""
          }
        ]
      }
    });
  };

  const deleteOwner = (index) => {
    const initialValue = {
      data: []
    };
    const initialValidation = {
      data: []
    };
    const value = { ...values };
    const validations = { ...validation };
    if (value.equipmentInformation.data.length > 1) {
      value.equipmentInformation.data.forEach((v, i) => {
        if (i !== index) {
          initialValue.data.push(v);
        }
      });
      value.equipmentInformation = initialValue;
      setValues(value);
    }
    if (validations.equipmentInformation.data.length > 1) {
      validations.equipmentInformation.data.forEach((v, i) => {
        if (i !== index) {
          initialValidation.data.push(v);
        }
      });
      validations.equipmentInformation = initialValidation;
      setValidation(value);
    }
  };

  const ownerOnchange = (index, data, type) => {
    const ownerData = { ...values };
    const validateError = { ...validation };
    ownerData.equipmentInformation.data[index][type] = data;
    validateError.equipmentInformation.data[index][type] = false;
    setValues(ownerData);
    setValidation(validateError);
  };

  const handleBack = () => {
    if (props.formData.providerDetailsById) {
      props.getProviderDetailsById(
        props.formData.providerDetailsById.Providers_Data[0]._id
      );
    }
    props.history.push(`/${props.mainData.app.layout}/user`);
  };

  const onChange = (value, type) => {
    const allState = { ...values };
    allState[type] = value;
    setValues(allState);
  };
  return (
    <div style={{ paddingBottom: "40px" }}>
      <Card
        style={{ borderTopLeftRadius: "16px", borderTopRightRadius: "16px" }}
      >
        <Box
          style={{
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
            alignItems: "center",
            padding: "10px"
          }}
          className={classes.tittleColor}
          height={40}
        >
          <Grid
            style={{
              display: "flex",
              position: "relative",
              flexWrap: "nowrap",
              boxSizing: "border-box",
              flexDirection: "row",
              alignItems: "center"
            }}
            container
          >
            <Grid style={{ position: "absolute" }} item>
              <IconButton
                color="white"
                aria-label="Back"
                onClick={() => handleBack()}
                component="span"
                style={{ background: "white", padding: "6px" }}
              >
                <KeyboardBackspaceIcon fontSize="small" />
              </IconButton>
            </Grid>
            <Grid style={{ margin: "auto" }} item>
              <Typography
                style={{ margin: "auto" }}
                className={classes.tittleTextColor}
              >
                Addtional Information
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box
          style={{
            alignItems: "center",
            marginTop: "10px"
          }}
          key={Math.random()}
          className={classes.tittleColor}
          height={40}
        >
          <Typography className={classes.tittleTextColor}>
            SITE SURVEY
          </Typography>
        </Box>
        <Box className={classes.marginBox}>
          <Grid container>
            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: {
                  xl: "center",
                  lg: "center",
                  md: "flex-start"
                },
                alignItems: "center",
                alignContent: "center"
              }}
              lg={12}
              sm={12}
              xs={12}
            >
              <PersonPinIcon className={classes.inputBox} fontSize="small" />
              <FormControl
                required
                className={classes.root}
                InputLabelProps={{
                  classes: {
                    root: classes.activeInputLableColor,
                    focused: "focused"
                  }
                }}
                // error={validationError.seasonalSales}
                // disabled={
                //   !(
                //     (kycCheckListFlag || ProviderStatus === "New Entry")
                //         && ProviderStatus !== "Live"
                //         && (editPermissionFlag || createPermissionFlag)
                //   )
                // }
                component="fieldset"
              >
                <FormGroup
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignContent: "center",
                    alignItems: "center",
                    flexWrap: "nowrap"
                  }}
                  row
                >
                  <FormLabel
                    style={{
                      marginTop: "10px",
                      fontSize: "14px",
                      minWidth: "15%"
                    }}
                    component="span"
                  >
                    Merchant Location
                  </FormLabel>
                  <FormControlLabel
                    style={{ marginTop: "10px", marginLeft: "10px" }}
                    control={
                      <Checkbox
                        color="primary"
                        checked={
                          values.merchantLocation === "Retail Store Front"
                        }
                        onChange={(e) =>
                          onChange("Retail Store Front", "merchantLocation")
                        }
                        name="Retail Store Front"
                      />
                    }
                    label="Retail Store Front"
                  />
                  <FormControlLabel
                    style={{ marginTop: "10px", marginLeft: "10px" }}
                    control={
                      <Checkbox
                        color="primary"
                        checked={values.merchantLocation === "Office Building"}
                        onChange={(e) =>
                          onChange("Office Building", "merchantLocation")
                        }
                        name="Office Building"
                      />
                    }
                    label="Office Building"
                  />
                  <FormControlLabel
                    style={{ marginTop: "10px", marginLeft: "10px" }}
                    control={
                      <Checkbox
                        color="primary"
                        checked={values.merchantLocation === "Warehouse"}
                        onChange={(e) =>
                          onChange("Warehouse", "merchantLocation")
                        }
                        name="Warehouse"
                      />
                    }
                    label="Warehouse"
                  />
                  <FormControlLabel
                    style={{ marginTop: "10px", marginLeft: "10px" }}
                    control={
                      <Checkbox
                        color="primary"
                        checked={values.merchantLocation === "Residence"}
                        onChange={(e) =>
                          onChange("Residence", "merchantLocation")
                        }
                        name="Residence"
                      />
                    }
                    label="Residence"
                  />
                  <FormControlLabel
                    style={{ marginTop: "10px", marginLeft: "10px" }}
                    control={
                      <Checkbox
                        color="primary"
                        checked={values.merchantLocation === "Others"}
                        onChange={(e) => onChange("Others", "merchantLocation")}
                        name="Others"
                      />
                    }
                    label="Others"
                  />
                </FormGroup>
                <FormHelperText>
                  {/* {validationError.seasonalSales} */}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: {
                  xl: "center",
                  lg: "center",
                  md: "flex-start"
                },
                alignItems: "center",
                alignContent: "center"
              }}
              lg={12}
              sm={12}
              xs={12}
            >
              <RoomIcon className={classes.inputBox} fontSize="small" />
              <FormControl
                required
                className={classes.root}
                InputLabelProps={{
                  classes: {
                    root: classes.activeInputLableColor,
                    focused: "focused"
                  }
                }}
                // error={validationError.seasonalSales}
                // disabled={
                //   !(
                //     (kycCheckListFlag || ProviderStatus === "New Entry")
                //         && ProviderStatus !== "Live"
                //         && (editPermissionFlag || createPermissionFlag)
                //   )
                // }
                component="fieldset"
              >
                <FormGroup
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignContent: "center",
                    alignItems: "center",
                    flexWrap: "nowrap"
                  }}
                  row
                >
                  <FormLabel
                    style={{
                      marginTop: "10px",
                      fontSize: "14px",
                      minWidth: "15%"
                    }}
                    component="span"
                  >
                    The Merchant
                  </FormLabel>
                  <FormControlLabel
                    style={{ marginTop: "10px", marginLeft: "10px" }}
                    control={
                      <Checkbox
                        color="primary"
                        cl
                        checked={values.merchantLocationOther === "Owns"}
                        onChange={(e) =>
                          onChange("Owns", "merchantLocationOther")
                        }
                        name="Owns"
                      />
                    }
                    label="Owns"
                  />
                  <FormControlLabel
                    style={{ marginTop: "10px", marginLeft: "10px" }}
                    control={
                      <Checkbox
                        color="primary"
                        checked={
                          values.merchantLocationOther ===
                          "Leases the business premises"
                        }
                        onChange={(e) =>
                          onChange(
                            "Leases the business premises",
                            "merchantLocationOther"
                          )
                        }
                        name="Leases the business premises"
                      />
                    }
                    label="Leases the business premises"
                  />
                </FormGroup>
                <FormHelperText>
                  {/* {validationError.seasonalSales} */}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Divider />
          <Grid container>
            <Grid
              item
              lg={6}
              sm={6}
              xs={6}
              style={{ paddingTop: "10px", paddingBottom: "10px" }}
            >
              <Grid container style={{ borderRightStyle: "groove" }}>
                <Grid
                  item
                  style={{ display: "flex", width: "100%" }}
                  sx={{
                    display: "flex",
                    justifyContent: {
                      xl: "center",
                      lg: "center",
                      md: "flex-start"
                    },
                    alignItems: "center",
                    alignContent: "center"
                  }}
                  lg={12}
                  sm={12}
                  xs={12}
                >
                  <PresentToAllIcon
                    className={classes.inputBox}
                    fontSize="small"
                  />
                  <FormControl
                    required
                    className={classes.root}
                    style={{ width: "100%" }}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    // error={validationError.seasonalSales}
                    // disabled={
                    //   !(
                    //     (kycCheckListFlag || ProviderStatus === "New Entry")
                    //         && ProviderStatus !== "Live"
                    //         && (editPermissionFlag || createPermissionFlag)
                    //   )
                    // }
                    component="fieldset"
                  >
                    <FormGroup
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignContent: "center",
                        alignItems: "center",
                        flexWrap: "nowrap"
                      }}
                      row
                    >
                      <FormLabel
                        style={{
                          marginTop: "10px",
                          fontSize: "14px",
                          width: "100%"
                        }}
                        component="span"
                      >
                        Merchant apears to be conducting business as represented
                        in application?
                      </FormLabel>
                      <div
                        style={{
                          marginTop: "10px",
                          marginLeft: "10px",
                          display: "flex",
                          justifyContent: "end"
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={
                                values.conductingBusinessApplication === true
                              }
                              onChange={(e) =>
                                onChange(true, "conductingBusinessApplication")
                              }
                              name="Yes"
                            />
                          }
                          label="Yes"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={
                                values.conductingBusinessApplication === false
                              }
                              onChange={(e) =>
                                onChange(false, "conductingBusinessApplication")
                              }
                              name="No"
                            />
                          }
                          label="No"
                        />
                      </div>
                    </FormGroup>
                    <FormHelperText>
                      {/* {validationError.seasonalSales} */}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  style={{ display: "flex", width: "100%" }}
                  sx={{
                    display: "flex",
                    justifyContent: {
                      xl: "center",
                      lg: "center",
                      md: "flex-start"
                    },
                    alignItems: "center",
                    alignContent: "center"
                  }}
                  lg={12}
                  sm={12}
                  xs={12}
                >
                  <TrendingUpIcon
                    className={classes.inputBox}
                    fontSize="small"
                  />
                  <FormControl
                    required
                    className={classes.root}
                    style={{ width: "100%" }}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    // error={validationError.seasonalSales}
                    // disabled={
                    //   !(
                    //     (kycCheckListFlag || ProviderStatus === "New Entry")
                    //         && ProviderStatus !== "Live"
                    //         && (editPermissionFlag || createPermissionFlag)
                    //   )
                    // }
                    component="fieldset"
                  >
                    <FormGroup
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignContent: "center",
                        alignItems: "center",
                        flexWrap: "nowrap"
                      }}
                      row
                    >
                      <FormLabel
                        style={{
                          marginTop: "10px",
                          fontSize: "14px",
                          width: "100%"
                        }}
                        component="span"
                      >
                        Merchant is adequately staffed and stocked to do
                        business?
                      </FormLabel>
                      <div
                        style={{
                          marginTop: "10px",
                          marginLeft: "10px",
                          display: "flex",
                          justifyContent: "end"
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={values.adequatelyStaffedStocked === true}
                              onChange={(e) =>
                                onChange(true, "adequatelyStaffedStocked")
                              }
                              name="Yes"
                            />
                          }
                          label="Yes"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={
                                values.adequatelyStaffedStocked === false
                              }
                              onChange={(e) =>
                                onChange(false, "adequatelyStaffedStocked")
                              }
                              name="No"
                            />
                          }
                          label="No"
                        />
                      </div>
                    </FormGroup>
                    <FormHelperText>
                      {/* {validationError.seasonalSales} */}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  style={{ display: "flex", width: "100%" }}
                  sx={{
                    display: "flex",
                    justifyContent: {
                      xl: "center",
                      lg: "center",
                      md: "flex-start"
                    },
                    alignItems: "center",
                    alignContent: "center"
                  }}
                  lg={12}
                  sm={12}
                  xs={12}
                >
                  <PhotoLibraryIcon
                    className={classes.inputBox}
                    fontSize="small"
                  />
                  <FormControl
                    required
                    className={classes.root}
                    style={{ width: "100%" }}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    // error={validationError.seasonalSales}
                    // disabled={
                    //   !(
                    //     (kycCheckListFlag || ProviderStatus === "New Entry")
                    //         && ProviderStatus !== "Live"
                    //         && (editPermissionFlag || createPermissionFlag)
                    //   )
                    // }
                    component="fieldset"
                  >
                    <FormGroup
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignContent: "center",
                        alignItems: "center",
                        flexWrap: "nowrap"
                      }}
                      row
                    >
                      <FormLabel
                        style={{
                          marginTop: "10px",
                          fontSize: "14px",
                          width: "100%"
                        }}
                        component="span"
                      >
                        Have you taken pictures of the inside and outside of the
                        premises?
                      </FormLabel>
                      <div
                        style={{
                          marginTop: "10px",
                          marginLeft: "10px",
                          display: "flex",
                          justifyContent: "end"
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={
                                values.takenPicturesInsideOutside === true
                              }
                              onChange={(e) =>
                                onChange(true, "takenPicturesInsideOutside")
                              }
                              name="Yes"
                            />
                          }
                          label="Yes"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={
                                values.takenPicturesInsideOutside === false
                              }
                              onChange={(e) =>
                                onChange(false, "takenPicturesInsideOutside")
                              }
                              name="No"
                            />
                          }
                          label="No"
                        />
                      </div>
                    </FormGroup>
                    <FormHelperText>
                      {/* {validationError.seasonalSales} */}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  style={{ display: "flex", width: "100%" }}
                  sx={{
                    display: "flex",
                    justifyContent: {
                      xl: "center",
                      lg: "center",
                      md: "flex-start"
                    },
                    alignItems: "center",
                    alignContent: "center"
                  }}
                  lg={12}
                  sm={12}
                  xs={12}
                >
                  <AssignmentLateIcon
                    className={classes.inputBox}
                    fontSize="small"
                  />
                  <FormControl
                    required
                    className={classes.root}
                    style={{ width: "100%" }}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    // error={validationError.seasonalSales}
                    // disabled={
                    //   !(
                    //     (kycCheckListFlag || ProviderStatus === "New Entry")
                    //         && ProviderStatus !== "Live"
                    //         && (editPermissionFlag || createPermissionFlag)
                    //   )
                    // }
                    component="fieldset"
                  >
                    <FormGroup
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignContent: "center",
                        alignItems: "center",
                        flexWrap: "nowrap"
                      }}
                      row
                    >
                      <FormLabel
                        style={{
                          marginTop: "10px",
                          fontSize: "14px",
                          width: "100%"
                        }}
                        component="span"
                      >
                        Have you confirmed the identity of the person who signed
                        the application?
                      </FormLabel>
                      <div
                        style={{
                          marginTop: "10px",
                          marginLeft: "10px",
                          display: "flex",
                          justifyContent: "end"
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={values.identityPersonWhoSigned === true}
                              onChange={(e) =>
                                onChange(true, "identityPersonWhoSigned")
                              }
                              name="Yes"
                            />
                          }
                          label="Yes"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={values.identityPersonWhoSigned === false}
                              onChange={(e) =>
                                onChange(false, "identityPersonWhoSigned")
                              }
                              name="No"
                            />
                          }
                          label="No"
                        />
                      </div>
                    </FormGroup>
                    <FormHelperText>
                      {/* {validationError.seasonalSales} */}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={6} lg={6}>
              <Grid
                container
                style={{ height: "100%" }}
                sx={{
                  display: "flex",
                  justifyContent: {
                    xl: "center",
                    lg: "center",
                    md: "flex-start"
                  },
                  alignItems: "center",
                  alignContent: "center"
                }}
              >
                <Grid item>
                  <div
                    style={{
                      margin: "auto",
                      display: "flex",
                      flexDirection: "column"
                    }}
                  >
                    <div style={{ marginTop: "20px", padding: "0px 10px" }}>
                      <Typography variant="caption">
                        I hereby Certify that I have physically inspected the
                        business premises of the Merchant at this address.
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    alignItems: "end",
                    width: "100%"
                  }}
                >
                  <Grid
                    container
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <WebIcon className={classes.inputBox} fontSize="small" />
                      <FormControl
                        required
                        className={classes.root}
                        style={{ width: "auto" }}
                        InputLabelProps={{
                          classes: {
                            root: classes.activeInputLableColor,
                            focused: "focused"
                          }
                        }}
                        // error={validationError.seasonalSales}
                        // disabled={
                        //   !(
                        //     (kycCheckListFlag || ProviderStatus === "New Entry")
                        //         && ProviderStatus !== "Live"
                        //         && (editPermissionFlag || createPermissionFlag)
                        //   )
                        // }
                        component="fieldset"
                      >
                        <FormGroup
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignContent: "center",
                            alignItems: "center",
                            flexWrap: "nowrap"
                          }}
                          row
                        >
                          <FormLabel
                            style={{ marginTop: "10px", fontSize: "14px" }}
                            component="span"
                          >
                            Printed Name
                          </FormLabel>
                          <FormControlLabel
                            style={{
                              marginTop: "10px",
                              marginLeft: "10px",
                              marginRight: "0px"
                            }}
                            control={
                              <TextField
                                className={classes.fullInput}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.activeInputLableColor,
                                    focused: "focused"
                                  }
                                }}
                                InputProps={{
                                  inputProps: { min: 0 },
                                  classes: {
                                    root: classes.activeInputColor,
                                    input: classes.resizeAddtional,
                                    focused: "focused"
                                  }
                                }}
                                value={values.pintedName}
                                onChange={(e) =>
                                  onChange(e.target.value, "pintedName")
                                }
                                name="pintedName"
                                variant="outlined"
                                id="pintedName"
                                type="text"
                              />
                            }
                          />
                        </FormGroup>
                        <FormHelperText>
                          {/* {validationError.seasonalSales} */}
                        </FormHelperText>
                      </FormControl>
                    </div>
                    <div style={{ display: "flex", justifyContent: "end" }}>
                      <FormControl
                        className={classes.root}
                        InputLabelProps={{
                          classes: {
                            root: classes.activeInputLableColor,
                            focused: "focused"
                          }
                        }}
                        // error={validationError.seasonalSales}
                        // disabled={
                        //   !(
                        //     (kycCheckListFlag || ProviderStatus === "New Entry")
                        //         && ProviderStatus !== "Live"
                        //         && (editPermissionFlag || createPermissionFlag)
                        //   )
                        // }
                        component="fieldset"
                      >
                        <FormGroup
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                            flexWrap: "nowrap"
                          }}
                          row
                        >
                          <FormLabel
                            style={{ marginTop: "10px", fontSize: "14px" }}
                            component="span"
                          >
                            Date
                          </FormLabel>
                          <FormControlLabel
                            style={{ marginTop: "10px", marginLeft: "10px" }}
                            control={
                              <TextField
                                className={classes.quaterInput}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.activeInputLableColor,
                                    focused: "focused"
                                  }
                                }}
                                disabled
                                InputProps={{
                                  inputProps: { min: 0 },
                                  classes: {
                                    root: classes.activeInputColor,
                                    input: classes.resizeAddtional,
                                    focused: "focused"
                                  }
                                }}
                                value={moment().format("MM/DD/YYYY")}
                                name="date"
                                id="pintedName"
                                type="text"
                              />
                            }
                          />
                        </FormGroup>
                        <FormHelperText>
                          {/* {validationError.seasonalSales} */}
                        </FormHelperText>
                      </FormControl>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider />
          <Grid container>
            <Grid
              item
              lg={6}
              sm={6}
              xs={6}
              style={{ paddingTop: "10px", paddingBottom: "10px" }}
            >
              <Grid container style={{ borderRightStyle: "groove" }}>
                <Grid
                  item
                  style={{ display: "flex" }}
                  sx={{
                    display: "flex",
                    justifyContent: {
                      xl: "center",
                      lg: "center",
                      md: "flex-start"
                    },
                    alignItems: "center",
                    alignContent: "center"
                  }}
                  lg={12}
                  sm={12}
                  xs={12}
                >
                  <SwapVerticalCircleIcon
                    className={classes.inputBox}
                    fontSize="small"
                  />
                  <FormControl
                    required
                    className={classes.root}
                    style={{ width: "100%" }}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    // error={validationError.seasonalSales}
                    // disabled={
                    //   !(
                    //     (kycCheckListFlag || ProviderStatus === "New Entry")
                    //         && ProviderStatus !== "Live"
                    //         && (editPermissionFlag || createPermissionFlag)
                    //   )
                    // }
                    component="fieldset"
                  >
                    <FormGroup
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexWrap: "nowrap"
                      }}
                      row
                    >
                      <FormLabel
                        style={{
                          marginTop: "10px",
                          fontSize: "14px",
                          width: "100%"
                        }}
                        component="span"
                      >
                        What is(are) the pump model(s) (if applicable)?
                      </FormLabel>
                      <FormControlLabel
                        style={{
                          marginTop: "10px",
                          display: "flex",
                          justifyContent: "end"
                        }}
                        control={
                          <TextField
                            className={classes.quaterInput}
                            style={{ width: "100%" }}
                            InputLabelProps={{
                              classes: {
                                root: classes.activeInputLableColor,
                                focused: "focused"
                              }
                            }}
                            InputProps={{
                              inputProps: { min: 0 },
                              classes: {
                                root: classes.activeInputColor,
                                input: classes.resizeAddtional,
                                focused: "focused"
                              }
                            }}
                            value={values.pumpModel}
                            onChange={(e) =>
                              onChange(e.target.value, "pumpModel")
                            }
                            name="pumpModel"
                            variant="outlined"
                            id="pumpModel"
                            type="text"
                          />
                        }
                      />
                    </FormGroup>
                    <FormHelperText>
                      {/* {validationError.seasonalSales} */}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  style={{ display: "flex" }}
                  sx={{
                    display: "flex",
                    justifyContent: {
                      xl: "center",
                      lg: "center",
                      md: "flex-start"
                    },
                    alignItems: "center",
                    alignContent: "center"
                  }}
                  lg={12}
                  sm={12}
                  xs={12}
                >
                  <WebIcon className={classes.inputBox} fontSize="small" />
                  <FormControl
                    required
                    className={classes.root}
                    style={{ width: "100%" }}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    // error={validationError.seasonalSales}
                    // disabled={
                    //   !(
                    //     (kycCheckListFlag || ProviderStatus === "New Entry")
                    //         && ProviderStatus !== "Live"
                    //         && (editPermissionFlag || createPermissionFlag)
                    //   )
                    // }
                    component="fieldset"
                  >
                    <FormGroup
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignContent: "center",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexWrap: "nowrap"
                      }}
                      row
                    >
                      <FormLabel
                        style={{
                          marginTop: "10px",
                          fontSize: "14px",
                          width: "100%"
                        }}
                        component="span"
                      >
                        What is the site service company name?
                      </FormLabel>
                      <FormControlLabel
                        style={{
                          marginTop: "10px",
                          display: "flex",
                          justifyContent: "end"
                        }}
                        control={
                          <TextField
                            className={classes.quaterInput}
                            style={{ width: "100%" }}
                            InputLabelProps={{
                              classes: {
                                root: classes.activeInputLableColor,
                                focused: "focused"
                              }
                            }}
                            InputProps={{
                              inputProps: { min: 0 },
                              classes: {
                                root: classes.activeInputColor,
                                input: classes.resizeAddtional,
                                focused: "focused"
                              }
                            }}
                            value={values.siteServiceCompanyName}
                            onChange={(e) =>
                              onChange(e.target.value, "siteServiceCompanyName")
                            }
                            name="siteServiceCompanyName"
                            variant="outlined"
                            id="siteServiceCompanyName"
                            type="text"
                          />
                        }
                      />
                    </FormGroup>
                    <FormHelperText>
                      {/* {validationError.seasonalSales} */}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  style={{ display: "flex" }}
                  sx={{
                    display: "flex",
                    justifyContent: {
                      xl: "center",
                      lg: "center",
                      md: "flex-start"
                    },
                    alignItems: "center",
                    alignContent: "center"
                  }}
                  lg={12}
                  sm={12}
                  xs={12}
                >
                  <SystemUpdateIcon
                    className={classes.inputBox}
                    fontSize="small"
                  />
                  <FormControl
                    required
                    className={classes.root}
                    style={{ width: "100%" }}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    // error={validationError.seasonalSales}
                    // disabled={
                    //   !(
                    //     (kycCheckListFlag || ProviderStatus === "New Entry")
                    //         && ProviderStatus !== "Live"
                    //         && (editPermissionFlag || createPermissionFlag)
                    //   )
                    // }
                    component="fieldset"
                  >
                    <FormGroup
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignContent: "center",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexWrap: "nowrap"
                      }}
                      row
                    >
                      <FormLabel
                        style={{
                          marginTop: "10px",
                          fontSize: "14px",
                          width: "100%"
                        }}
                        component="span"
                      >
                        What is the current POS system they are using?
                      </FormLabel>
                      <FormControlLabel
                        style={{
                          marginTop: "10px",
                          display: "flex",
                          justifyContent: "end"
                        }}
                        control={
                          <TextField
                            className={classes.quaterInput}
                            style={{ width: "100%" }}
                            InputLabelProps={{
                              classes: {
                                root: classes.activeInputLableColor,
                                focused: "focused"
                              }
                            }}
                            InputProps={{
                              inputProps: { min: 0 },
                              classes: {
                                root: classes.activeInputColor,
                                input: classes.resizeAddtional,
                                focused: "focused"
                              }
                            }}
                            value={values.currentPOSSystem}
                            onChange={(e) =>
                              onChange(e.target.value, "currentPOSSystem")
                            }
                            name="siteServiceCompanyName"
                            variant="outlined"
                            id="siteServiceCompanyName"
                            type="text"
                          />
                        }
                      />
                    </FormGroup>
                    <FormHelperText>
                      {/* {validationError.seasonalSales} */}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={6} lg={6}>
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: {
                    xl: "center",
                    lg: "center",
                    md: "flex-start"
                  },
                  alignItems: "center",
                  alignContent: "center"
                }}
              >
                <Grid
                  item
                  style={{ display: "flex" }}
                  sx={{
                    display: "flex",
                    justifyContent: {
                      xl: "center",
                      lg: "center",
                      md: "flex-start"
                    },
                    alignItems: "center",
                    alignContent: "center"
                  }}
                  lg={12}
                  sm={12}
                  xs={12}
                >
                  <WebIcon className={classes.inputBox} fontSize="small" />
                  <FormControl
                    required
                    className={classes.root}
                    style={{ display: "flex", width: "100%" }}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    // error={validationError.seasonalSales}
                    // disabled={
                    //   !(
                    //     (kycCheckListFlag || ProviderStatus === "New Entry")
                    //         && ProviderStatus !== "Live"
                    //         && (editPermissionFlag || createPermissionFlag)
                    //   )
                    // }
                    component="fieldset"
                  >
                    <FormGroup
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignContent: "center",
                        alignItems: "center",
                        flexWrap: "nowrap",
                        justifyContent: "space-between"
                      }}
                      row
                    >
                      <FormLabel
                        style={{
                          marginTop: "10px",
                          fontSize: "14px"
                        }}
                        component="span"
                      >
                        Phone
                      </FormLabel>
                      <FormControlLabel
                        style={{
                          marginTop: "10px"
                        }}
                        control={
                          <TextField
                            className={classes.fullInput}
                            InputLabelProps={{
                              classes: {
                                root: classes.activeInputLableColor,
                                focused: "focused"
                              }
                            }}
                            InputProps={{
                              inputProps: { min: 0 },
                              classes: {
                                root: classes.activeInputColor,
                                input: classes.resizeAddtional,
                                focused: "focused"
                              }
                            }}
                            value={values.phone}
                            onChange={(e) => onChange(e.target.value, "phone")}
                            name="phone"
                            variant="outlined"
                            id="fuelPositions"
                            type="text"
                          />
                        }
                      />
                    </FormGroup>
                    <FormHelperText>
                      {/* {validationError.seasonalSales} */}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "end"
                  }}
                  xs={12}
                  sm={12}
                  lg={12}
                >
                  <Grid container>
                    <Grid
                      item
                      style={{ display: "flex" }}
                      sx={{
                        display: "flex",
                        justifyContent: {
                          xl: "center",
                          lg: "center",
                          md: "flex-start"
                        },
                        alignItems: "center",
                        alignContent: "center"
                      }}
                      lg={12}
                      sm={12}
                      xs={12}
                    >
                      <WebIcon className={classes.inputBox} fontSize="small" />
                      <FormControl
                        required
                        className={classes.root}
                        style={{ display: "flex", width: "100%" }}
                        InputLabelProps={{
                          classes: {
                            root: classes.activeInputLableColor,
                            focused: "focused"
                          }
                        }}
                        // error={validationError.seasonalSales}
                        // disabled={
                        //   !(
                        //     (kycCheckListFlag || ProviderStatus === "New Entry")
                        //         && ProviderStatus !== "Live"
                        //         && (editPermissionFlag || createPermissionFlag)
                        //   )
                        // }
                        component="fieldset"
                      >
                        <FormGroup
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignContent: "center",
                            alignItems: "center",
                            flexWrap: "nowrap",
                            justifyContent: "space-between"
                          }}
                          row
                        >
                          <FormLabel
                            style={{
                              marginTop: "10px",
                              fontSize: "14px"
                            }}
                            component="span"
                          >
                            Fuel Positions
                          </FormLabel>
                          <FormControlLabel
                            style={{
                              marginTop: "10px"
                            }}
                            control={
                              <TextField
                                className={classes.fullInput}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.activeInputLableColor,
                                    focused: "focused"
                                  }
                                }}
                                InputProps={{
                                  inputProps: { min: 0 },
                                  classes: {
                                    root: classes.activeInputColor,
                                    input: classes.resizeAddtional,
                                    focused: "focused"
                                  }
                                }}
                                value={values.fuelPositions}
                                onChange={(e) =>
                                  onChange(e.target.value, "fuelPositions")
                                }
                                name="fuelPositions"
                                variant="outlined"
                                id="fuelPositions"
                                type="text"
                              />
                            }
                          />
                        </FormGroup>
                        <FormHelperText>
                          {/* {validationError.seasonalSales} */}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            spacing={2}
            style={{
              textAlign: "center",
              padding: "10px",
              justifyContent: "flex-end"
            }}
          >
            <Grid item sm={1} xs={1}>
              <Button
                variant="outlined"
                fullWidth
                color="#696969"
                onClick={() => handleReset()}
              >
                Reset
              </Button>
            </Grid>
            <Grid item sm={1} xs={1}>
              <Button
                className={classes.buttonStyle}
                variant="contained"
                fullWidth
                color="#696969"
                onClick={() => handleSave()}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </div>
  );
}

AddtionalInformation.propTypes = {
  mainData: PropTypes.object,
  history: PropTypes.object,
  getEquipmentCategory: PropTypes.func,
  postAddtionalInformation: PropTypes.func,
  formData: PropTypes.object,
  getProviderDetailsById: PropTypes.func,
  updateAddtionalInformation: PropTypes.func
};

const mapStateToProps = (app) => ({
  formData: app.provideRegistration,
  mainData: app
});

export default connect(mapStateToProps, {
  getEquipmentCategory,
  postAddtionalInformation,
  updateAddtionalInformation,
  getProviderDetailsById
})(AddtionalInformation);
