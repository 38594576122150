import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import { connect } from "react-redux";
import Radio from "@material-ui/core/Radio";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { Button, Grid, InputLabel, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import DateFnsUtils from "@date-io/date-fns";
import Select from "@material-ui/core/Select";
import { Field, Form, Formik, ErrorMessage } from "formik";
import {
  createTheme,
  ThemeProvider,
  makeStyles
} from "@material-ui/core/styles";
import isEmpty from "lodash/isEmpty";
import ItemTable from "./itemTable";
import { getQueryParams } from "../../utils/common";
import {
  fetchRateList,
  fetchProcessorList,
  fetchPricingScheduleDetailsByID,
  newUpdatePricingSchedule
} from "../../actions/pricingSchedule.action";
import Validations from "../../utils/validations";
import styles from "assets/jss/material-dashboard-react/components/userForm";
import tableStyle from "../../components/CustomizeTable/style";

const priceTypeDisabledValidation = [1, 2, 8];

const initialPricingDetails = {
  ProcessorID: "",
  PS_Name: "",
  St_Date: null,
  Description: "",
  Split_Type: "I",
  rateType: "",
  rate: "",
  multiProfit: "",
  multiLoss: "",
  Profit_Percent: "",
  Loss_Percent: "",
  Groups: []
};

const theme = createTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        background: "#F9FAFF 0% 0% no-repeat",
        borderRadius: "6px",
        height: "100%"
      },
      input: {
        padding: "14px 14px",
        "&.Mui-disabled": {
          color: "rgba(0, 0, 0, 0.26) !important",
          boxShadow: "none !important",
          backgroundColor: "#f1f1f199 !important"
        }
      }
    },
    MuiFormControl: {
      root: {
        // background: "#F9FAFF 0% 0% no-repeat",
        borderRadius: "6px"
        // maxWidth: "220px",
      }
    },
    MuiPickersDatePickerRoot: {
      toolbar: {
        backgroundColor: "#2ba992 !important"
      }
    }
  }
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={2} pb={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const useStyles = makeStyles(styles);
const useBtnStyles = makeStyles(tableStyle);

const PricingSch = ({
  fetchRateList,
  fetchProcessorList,
  fetchPricingScheduleDetailsByID,
  newUpdatePricingSchedule
}) => {
  const classes = useStyles();
  const btnClasses = useBtnStyles();
  const PRICING_SCHEDULE_ID = getQueryParams("psId");
  const PROCESSOR_ID = getQueryParams("processorId");
  const EditFlag = PRICING_SCHEDULE_ID && PROCESSOR_ID;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const formikRef = React.useRef(null);
  const [initialValues, setInitialValues] = React.useState({
    ...initialPricingDetails
  });
  const [selectedItem, setSelectedItem] = React.useState([]);
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [rateList, setRateList] = React.useState([]);
  const [processorList, setProcessorList] = React.useState([]);
  const [fieldsDetails, setFieldsDetails] = React.useState({});
  const [selectedProcessor, setSelectedProcessor] = React.useState(
    EditFlag ? PROCESSOR_ID : ""
  );
  const [selectedRateType, setSelectedRateType] = React.useState("");

  const handleTabChange = (event, newValue) => {
    if (!isEmpty(selectedItem)) {
      setSelectedItem([]);
    }
    setSelectedTab(newValue);
  };

  const getRateList = () => {
    fetchRateList(
      (data) => {
        if (data.status) {
          setRateList(data.data);
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
      }
    );
  };

  const getProcessorList = () => {
    fetchProcessorList(
      (data) => {
        if (data.status) {
          setProcessorList(data.data);
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
      }
    );
  };

  const handleBack = () => {
    history.push("/admin/PricingSchedule");
  };

  const getFieldsDetails = () => {
    let payload = { processorId: selectedProcessor };
    if (EditFlag) {
      payload = {
        processorId: selectedProcessor,
        pricingScheduleId: PRICING_SCHEDULE_ID
      };
    }
    fetchPricingScheduleDetailsByID(
      { ...payload },
      (data) => {
        if (data.status) {
          setFieldsDetails(data.data);
          setInitialValues({
            ...formikRef.current.values,
            ...data.data.Pricing_Detail,
            Groups: [...data.data.Pricing_Items.Groups]
          });
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
      }
    );
  };

  const addEditPricingScheduleTemplate = (values) => {
    const payload = {
      Pricing_Detail: {
        PS_ID: null,
        PS_Name: values.PS_Name,
        ProcessorID: values.ProcessorID,
        St_Date: formatDate(values.St_Date),
        Description: values.Description,
        Split_Type: values.Split_Type,
        Profit_Percent: values.Profit_Percent,
        Loss_Percent: values.Loss_Percent
      },
      Pricing_Items: { Groups: [...values.Groups] }
    };

    if (EditFlag) {
      payload.Pricing_Detail.PS_ID = values.PS_ID;
    }
    console.log("payload", payload);
    debugger;
    newUpdatePricingSchedule(
      { pricing_data: { ...payload } },
      (data) => {
        if (data.status && data.status !== "error") {
          enqueueSnackbar(`${data.message}`, {
            variant: "success"
          });
          history.push("/admin/PricingSchedule");
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
      }
    );
  };

  function formatDate(date) {
    return moment(date).startOf("month").format("YYYY-MM-DD");
  }

  const applyMultipleChanges = () => {
    const tabListData = [
      ...formikRef.current.values.Groups[selectedTab].Items_List
    ];
    const newValues = [];
    tabListData.forEach((data) => {
      const tempData = { ...data };

      if (selectedItem.find((itemName) => itemName === data.ItemName)) {
        tempData.IncludeID = formikRef.current.values.IncludeID || 1;
        tempData.RateType_ID = formikRef.current.values.rateType
          ? formikRef.current.values.rateType
          : tempData.RateType_ID;
        tempData.Rate = formikRef.current.values.rate
          ? formikRef.current.values.rate
          : tempData.Rate;
        tempData.Profit = formikRef.current.values.multiProfit.toString()
          ? formikRef.current.values.multiProfit
          : tempData.Profit;
        tempData.Loss = formikRef.current.values.multiLoss.toString()
          ? formikRef.current.values.multiLoss
          : tempData.Loss;
      }
      newValues.push({ ...tempData });
    });
    formikRef.current.values.Groups[selectedTab].Items_List = newValues;
    setInitialValues({ ...formikRef.current.values });
  };

  const checkRateDisableFun = (value) => {
    const flag = priceTypeDisabledValidation.some((a) => a === value);
    return flag;
  };

  const rateDisabledFlag = React.useMemo(
    () => checkRateDisableFun(selectedRateType),
    [selectedRateType]
  );

  React.useEffect(() => {
    getProcessorList();
    getRateList();
  }, []);

  React.useEffect(() => {
    if (selectedProcessor) {
      getFieldsDetails();
    }
  }, [selectedProcessor]);

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Box className="agent-payout-container">
          <Box mb={4} className="agent-payout-addNewProfile">
            <Grid className="custflex-dc" container>
              <Formik
                enableReinitialize
                innerRef={(r) => {
                  formikRef.current = r;
                }}
                onSubmit={addEditPricingScheduleTemplate}
                initialValues={{ ...initialValues, EditFlag }}
                validationSchema={Validations.addEditPricingSchedule}
              >
                {({ values, setFieldValue, resetForm, errors, touched }) => (
                  <>
                    <Form>
                      <Grid
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginBottom: "40px"
                        }}
                        container
                        item
                      >
                        <Grid item>
                          <Typography variant="h5">
                            <b>
                              {EditFlag
                                ? `Pricing Schedule - (${initialValues.PS_Name})`
                                : "New Pricing Schedule"}
                            </b>
                          </Typography>
                        </Grid>
                        <Grid
                          style={{ display: "flex", flexWrap: "nowrap" }}
                          spacing={4}
                          item
                        >
                          <Box mr={2}>
                            <Button
                              variant="outlined"
                              onClick={handleBack}
                              className={btnClasses.clearButton}
                            >
                              Back
                            </Button>
                          </Box>
                          <Box mr={2}>
                            <Button
                              onClick={() => {
                                if (EditFlag) {
                                  if (PROCESSOR_ID !== selectedProcessor) {
                                    setSelectedProcessor(PROCESSOR_ID);
                                  }
                                  setInitialValues({
                                    ...formikRef.current.values,
                                    ...fieldsDetails.Pricing_Detail,
                                    Groups: [
                                      ...fieldsDetails.Pricing_Items.Groups
                                    ]
                                  });
                                } else {
                                  setInitialValues({
                                    ...initialPricingDetails
                                  });
                                  setSelectedProcessor("");
                                }
                                resetForm();
                                setSelectedItem([]);
                              }}
                              variant="contained"
                              className={btnClasses.clearButton}
                            >
                              Reset
                            </Button>
                          </Box>
                          <Box>
                            <Button
                              type="submit"
                              className={btnClasses.ExportButtonStyle}
                              variant="contained"
                            >
                              {EditFlag ? "Update" : "Create"}
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid
                        spacing={4}
                        container
                        style={{
                          marginBottom: "14px"
                        }}
                        className="custflex-dr-aic"
                        justifyContent="start"
                        item
                      >
                        <Grid
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          className="custflex-dr-aic"
                          container
                          item
                        >
                          <Grid
                            className="fieldWithLabel"
                            style={{
                              justifyContent: "start"
                            }}
                            xs={4}
                            item
                          >
                            <InputLabel id="label-PS_Name">
                              Name
                              <span className="spn-required">*</span>
                            </InputLabel>
                          </Grid>
                          <Grid xs={8} item>
                            <FormControl fullWidth>
                              <Field
                                as={TextField}
                                fullWidth
                                name="PS_Name"
                                error={errors.PS_Name && touched.PS_Name}
                                // helperText={touched.PS_Name && errors.PS_Name}
                                labelId="label-PS_Name"
                                variant="outlined"
                                placeholder="Name"
                              />
                            </FormControl>
                            <ErrorMessage name="PS_Name">
                              {(msg) => (
                                <Typography
                                  component="span"
                                  className="errorAbs"
                                  variant="colorError"
                                >
                                  {msg}
                                </Typography>
                              )}
                            </ErrorMessage>
                          </Grid>
                        </Grid>
                        <Grid
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          className="custflex-dr-aic"
                          container
                          item
                        >
                          <Grid className="fieldWithLabel" xs={4} item>
                            <InputLabel id="label-ProcessorID">
                              Processor
                              <span className="spn-required">*</span>
                            </InputLabel>
                          </Grid>
                          <Grid xs={8} item>
                            <FormControl fullWidth>
                              <Field
                                as={Select}
                                fullWidth
                                name="ProcessorID"
                                labelId="label-ProcessorID"
                                variant="outlined"
                                displayEmpty
                                disabled={EditFlag}
                                error={
                                  errors.ProcessorID && touched.ProcessorID
                                }
                                onChange={(e) => {
                                  setFieldValue("ProcessorID", e.target.value);
                                  setSelectedProcessor(e.target.value);
                                }}
                                placeholder="Processor"
                              >
                                <MenuItem disabled value="">
                                  Select Processor
                                </MenuItem>
                                {processorList.map((processor) => (
                                  <MenuItem value={processor.ProcessorID}>
                                    {processor.ProcessorName}
                                  </MenuItem>
                                ))}
                              </Field>
                            </FormControl>
                            <ErrorMessage name="ProcessorID">
                              {(msg) => (
                                <Typography
                                  component="span"
                                  className="errorAbs"
                                  variant="colorError"
                                >
                                  {msg}
                                </Typography>
                              )}
                            </ErrorMessage>
                          </Grid>
                        </Grid>
                        <Grid
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          className="custflex-dr-aic"
                          container
                          item
                        >
                          <Grid className="fieldWithLabel" xs={4} item>
                            <InputLabel id="label-St_Date">
                              Start Month
                              <span className="spn-required">*</span>
                            </InputLabel>
                          </Grid>
                          <Grid xs={8} item>
                            <FormControl fullWidth>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Field
                                  as={KeyboardDatePicker}
                                  fullWidth
                                  views={["month", "year"]}
                                  id="St_Date"
                                  name="St_Date"
                                  labelId="label-St_Date"
                                  error={errors.St_Date && touched.St_Date}
                                  minDateMessage=""
                                  invalidDateMessage=""
                                  maxDateMessage=""
                                  disabled={EditFlag}
                                  inputVariant="outlined"
                                  format="MM/yyyy"
                                  placeholder="MM/YYYY"
                                  value={moment(values.St_Date).format(
                                    "MM-DD-YYYY"
                                  )}
                                  onChange={(value) => {
                                    let dateObj = value;
                                    if (dateObj && dateObj.getMonth()) {
                                      const month = dateObj.getMonth() + 1; // months from 1-12
                                      const day = dateObj.getDate();
                                      const year = dateObj.getFullYear();
                                      dateObj = `${month}/${day}/${year}`;
                                    }
                                    setFieldValue("St_Date", dateObj);
                                  }}
                                  KeyboardButtonProps={{
                                    "aria-label": "start month"
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            </FormControl>
                            <ErrorMessage name="St_Date">
                              {(msg) => (
                                <Typography
                                  component="span"
                                  className="errorAbs"
                                  variant="colorError"
                                >
                                  {msg}
                                </Typography>
                              )}
                            </ErrorMessage>
                          </Grid>
                        </Grid>
                        <Grid
                          xs={1}
                          item
                          className="fieldWithLabel"
                          style={{
                            justifyContent: "start"
                          }}
                        >
                          <InputLabel id="label-Description">
                            Description
                            {/* <span className="spn-required">*</span> */}
                          </InputLabel>
                        </Grid>

                        <Grid
                          xs={11}
                          item
                          style={{
                            paddingLeft: "35px"
                          }}
                        >
                          <FormControl fullWidth>
                            <Field
                              as={TextField}
                              fullWidth
                              variant="outlined"
                              name="Description"
                              error={errors.Description && touched.Description}
                              rows={3}
                              multiline
                              labelId="label-Description"
                              placeholder="Description"
                            />
                          </FormControl>
                          <ErrorMessage name="Description">
                            {(msg) => (
                              <Typography
                                component="span"
                                className="errorAbs"
                                variant="colorError"
                              >
                                {msg}
                              </Typography>
                            )}
                          </ErrorMessage>
                        </Grid>
                      </Grid>

                      <Grid
                        spacing={4}
                        container
                        className="custflex-dr-aic"
                        item
                      >
                        <Grid
                          xs={12}
                          sm={6}
                          md={4}
                          lg={4}
                          className="custflex-dr-aic"
                          container
                          item
                        >
                          <Grid
                            xs={4}
                            className="fieldWithLabel"
                            item
                            style={{
                              justifyContent: "start"
                            }}
                          >
                            <InputLabel id="label-Split_Type">
                              Split Type
                              {/* <span className="spn-required">*</span> */}
                            </InputLabel>
                          </Grid>
                          <Grid
                            style={{
                              display: "flex",
                              alignItems: "center"
                              // justifyContent: "center"
                            }}
                            xs={8}
                            container
                            item
                          >
                            <FormControl>
                              <RadioGroup
                                aria-labelledby="label-Split_Type"
                                row
                                error={errors.Split_Type && touched.Split_Type}
                                style={{ flexWrap: "nowrap" }}
                                name="Split_Type"
                                color="primary"
                                value={values.Split_Type}
                                onChange={(e) => {
                                  setFieldValue(
                                    "Split_Type",
                                    e.target.defaultValue
                                  );
                                  if (e.target.defaultValue === "P") {
                                    setFieldValue("multiProfit", "");
                                    setFieldValue("multiLoss", "");
                                  }
                                  if (e.target.defaultValue === "I") {
                                    setFieldValue("Profit_Percent", "");
                                    setFieldValue("Loss_Percent", "");
                                  }
                                }}
                              >
                                <FormControlLabel
                                  value="P"
                                  control={<Radio color="primary" />}
                                  label="Program"
                                  color="primary"
                                  disabled={EditFlag}
                                />
                                <FormControlLabel
                                  value="I"
                                  color="primary"
                                  control={<Radio color="primary" />}
                                  label="Item"
                                  disabled={EditFlag}
                                />
                              </RadioGroup>
                            </FormControl>
                            <ErrorMessage name="Split_Type">
                              {(msg) => (
                                <Typography
                                  component="span"
                                  className="errorAbs"
                                  variant="colorError"
                                >
                                  {msg}
                                </Typography>
                              )}
                            </ErrorMessage>
                          </Grid>
                        </Grid>
                        {values.Split_Type === "P" && (
                          <Grid
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            className="custflex-dr-aic"
                            container
                            item
                          >
                            <Grid className="fieldWithLabel" xs={4} item>
                              <InputLabel id="label-Profit_%">
                                Profit&nbsp;%
                                {/* <span className="spn-required">*</span> */}
                              </InputLabel>
                            </Grid>
                            <Grid xs={8} item>
                              <FormControl fullWidth>
                                <Field
                                  as={TextField}
                                  error={
                                    errors.Profit_Percent &&
                                    touched.Profit_Percent &&
                                    values.Split_Type == "P"
                                  }
                                  aria-labelledby="label-Profit_%"
                                  fullWidth
                                  name="Profit_Percent"
                                  variant="outlined"
                                  type="number"
                                  InputProps={{
                                    inputProps: { min: 0, max: 100 }
                                  }}
                                  displayEmpty
                                  placeholder="Profit %"
                                />
                              </FormControl>
                              <ErrorMessage name="Profit_Percent">
                                {(msg) => (
                                  <Typography
                                    component="span"
                                    className="errorAbs"
                                    variant="colorError"
                                  >
                                    {msg}
                                  </Typography>
                                )}
                              </ErrorMessage>
                            </Grid>
                          </Grid>
                        )}
                        {values.Split_Type == "P" && (
                          <Grid
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            className="custflex-dr-aic"
                            container
                            item
                          >
                            <Grid className="fieldWithLabel" xs={4} item>
                              <InputLabel id="label-Loss_%">
                                Loss&nbsp;%
                                {/* <span className="spn-required">*</span> */}
                              </InputLabel>
                            </Grid>
                            <Grid xs={8} item>
                              <FormControl fullWidth>
                                <Field
                                  as={TextField}
                                  error={
                                    errors.Loss_Percent &&
                                    touched.Loss_Percent &&
                                    values.Split_Type === "P"
                                  }
                                  aria-labelledby="label-Loss_%"
                                  fullWidth
                                  name="Loss_Percent"
                                  variant="outlined"
                                  type="number"
                                  InputProps={{
                                    inputProps: { min: 0, max: 100 }
                                  }}
                                  displayEmpty
                                  placeholder="Loss %"
                                />
                              </FormControl>
                              <ErrorMessage name="Loss_Percent">
                                {(msg) => (
                                  <Typography
                                    component="span"
                                    className="errorAbs"
                                    variant="colorError"
                                  >
                                    {msg}
                                  </Typography>
                                )}
                              </ErrorMessage>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>

                      {!isEmpty(values.Groups) && (
                        <Box mt={6}>
                          <Box component="p" variant="body1">
                            <Typography variant="h6">
                              Multiple Update
                            </Typography>
                          </Box>
                          <Grid
                            spacing={3}
                            container
                            className="custflex-dr-aic"
                            item
                          >
                            <Grid
                              xs={12}
                              sm={6}
                              md={4}
                              lg={3}
                              // className="custflex-dr-aic"
                              item
                            >
                              <FormControl fullWidth>
                                <Field
                                  as={Select}
                                  fullWidth
                                  name="rateType"
                                  error={errors.rateType && touched.rateType}
                                  onChange={(e) => {
                                    setFieldValue("rateType", e.target.value);
                                    setSelectedRateType(e.target.value);
                                  }}
                                  variant="outlined"
                                  displayEmpty
                                  placeholder="Rate Type"
                                >
                                  <MenuItem disabled value="">
                                    Rate Type
                                  </MenuItem>
                                  {rateList.map((rates) => (
                                    <MenuItem value={rates.RateType_ID}>
                                      {rates.RateType}
                                    </MenuItem>
                                  ))}
                                </Field>
                              </FormControl>
                              <ErrorMessage name="rateType">
                                {(msg) => (
                                  <Typography
                                    component="span"
                                    className="errorAbs"
                                    variant="colorError"
                                  >
                                    {msg}
                                  </Typography>
                                )}
                              </ErrorMessage>
                            </Grid>
                            <Grid
                              xs={12}
                              sm={6}
                              md={4}
                              lg={2}
                              // className="custflex-dr-aic"
                              item
                            >
                              <FormControl fullWidth>
                                <Field
                                  as={TextField}
                                  error={errors.rate && touched.rate}
                                  fullWidth
                                  disabled={rateDisabledFlag}
                                  name="rate"
                                  variant="outlined"
                                  displayEmpty
                                  placeholder="Rate"
                                />
                              </FormControl>
                              <ErrorMessage name="rate">
                                {(msg) => (
                                  <Typography
                                    component="span"
                                    className="errorAbs"
                                    variant="colorError"
                                  >
                                    {msg}
                                  </Typography>
                                )}
                              </ErrorMessage>
                            </Grid>
                            {values.Split_Type === "I" && (
                              <Grid
                                xs={12}
                                sm={6}
                                md={4}
                                lg={2}
                                // className="custflex-dr-aic"
                                item
                              >
                                <FormControl fullWidth>
                                  <Field
                                    as={TextField}
                                    fullWidth
                                    error={
                                      errors.multiProfit &&
                                      touched.multiProfit &&
                                      values.Split_Type === "I"
                                    }
                                    name="multiProfit"
                                    variant="outlined"
                                    type="number"
                                    InputProps={{
                                      inputProps: { min: 0, max: 100 }
                                    }}
                                    displayEmpty
                                    placeholder="Profit %"
                                  />
                                </FormControl>
                                <ErrorMessage name="multiProfit">
                                  {(msg) => (
                                    <Typography
                                      component="span"
                                      className="errorAbs"
                                      variant="colorError"
                                    >
                                      {msg}
                                    </Typography>
                                  )}
                                </ErrorMessage>
                              </Grid>
                            )}
                            {values.Split_Type === "I" && (
                              <Grid
                                xs={12}
                                sm={6}
                                md={4}
                                lg={2}
                                // className="custflex-dr-aic"
                                item
                              >
                                <FormControl fullWidth>
                                  <Field
                                    as={TextField}
                                    fullWidth
                                    error={
                                      errors.multiLoss &&
                                      touched.multiLoss &&
                                      values.Split_Type === "I"
                                    }
                                    name="multiLoss"
                                    type="number"
                                    InputProps={{
                                      inputProps: { min: 0, max: 100 }
                                    }}
                                    variant="outlined"
                                    displayEmpty
                                    placeholder="Loss %"
                                  />
                                </FormControl>
                                <ErrorMessage name="multiLoss">
                                  {(msg) => (
                                    <Typography
                                      component="span"
                                      className="errorAbs"
                                      variant="colorError"
                                    >
                                      {msg}
                                    </Typography>
                                  )}
                                </ErrorMessage>
                              </Grid>
                            )}
                            <Grid
                              xs={12}
                              sm={12}
                              md={values.Split_Type === "I" ? 8 : 12}
                              lg={values.Split_Type === "I" ? 3 : 7}
                              className="custflex-dr-aic"
                              item
                              style={{
                                justifyContent: "flex-end"
                              }}
                            >
                              <Button
                                disabled={isEmpty(selectedItem)}
                                className={btnClasses.ExportButtonStyle}
                                variant="contained"
                                onClick={applyMultipleChanges}
                              >
                                Apply To Selected Item
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                      <Box mt={4}>
                        <Tabs
                          value={selectedTab}
                          onChange={handleTabChange}
                          indicatorColor="primary"
                          scrollButtons="auto"
                          variant="scrollable"
                          textColor="primary"
                        >
                          {values.Groups.map((tab, index) => (
                            <Tab
                              key={tab.GroupID}
                              index={index}
                              label={`${tab.GroupName} (${tab.Included}/${tab.TotalCount})`}
                            />
                          ))}
                        </Tabs>
                        {values.Groups.map((tab, index) => (
                          <TabPanel
                            key={tab.GroupID}
                            value={selectedTab}
                            index={index}
                          >
                            <ItemTable
                              tabIndex={index}
                              values={values}
                              setFieldValue={setFieldValue}
                              listData={tab.Items_List}
                              rateList={rateList}
                              selected={selectedItem}
                              setSelected={setSelectedItem}
                              checkRateDisableFun={checkRateDisableFun}
                              disabledFlag={values.Split_Type === "P"}
                            />
                          </TabPanel>
                        ))}
                      </Box>
                    </Form>
                  </>
                )}
              </Formik>
            </Grid>
          </Box>
        </Box>
      </ThemeProvider>
    </div>
  );
};

PricingSch.propTypes = {
  fetchRateList: PropTypes.func,
  fetchProcessorList: PropTypes.func,
  fetchPricingScheduleDetailsByID: PropTypes.func,
  newUpdatePricingSchedule: PropTypes.func
};

PricingSch.defaultProps = {
  fetchRateList: () => {},
  fetchProcessorList: () => {},
  fetchPricingScheduleDetailsByID: () => {},
  newUpdatePricingSchedule: () => {}
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
  fetchRateList,
  fetchProcessorList,
  fetchPricingScheduleDetailsByID,
  newUpdatePricingSchedule
})(PricingSch);
