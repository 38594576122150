import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import { payout_calculation_history } from "../../../actions/PayoutCalculation.action";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import isEmpty from "lodash/isEmpty";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { TablePageData } from "../../../utils/constants";
import tableStyle from "../../../components/CustomizeTable/style";
import CustomTable from "../../../components/CustomizeTable/CustomTable";
import CoustomButton from "../../../../../components/CustomButtons/Button";
import { exportToCSV, getQueryParams } from "../../../utils/common";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import noDataImage from "../../../../../assets/img/noDataImage.png";

const headCellsItems = [
  {
    id: "ProcessorName",
    label: "Processor",
    isSort: true,
    sortProperty: "ProcessorName",
    actionsCellStyle: "center"
  },
  {
    id: "ResidualMonth",
    label: "Residual Month",
    isSort: true,
    sortProperty: "ResidualMonth",
    actionsCellStyle: "center"
  },
  {
    id: "Type",
    label: "Calculation Type",
    isSort: true,
    sortProperty: "Type",
    actionsCellStyle: "center"
  },
  {
    id: "StartDate",
    label: "Start Date",
    isSort: true,
    sortProperty: "StartDate",
    actionsCellStyle: "center"
  },
  {
    id: "EndDate",
    label: "End Date",
    isSort: true,
    sortProperty: "EndDate",
    actionsCellStyle: "center"
  },
  {
    id: "Status",
    label: "Status",
    isSort: true,
    sortProperty: "Status",
    actionsCellStyle: "center"
  },
  {
    id: "No_Of_Merch",
    label: "Merchants",
    isSort: false,
    sortProperty: "No_Of_Merch",
    actionsCellStyle: "center"
  },
  {
    id: "StartedBy_Name",
    label: "Initiated By",
    isSort: false,
    sortProperty: "StartedBy_Name",
    actionsCellStyle: "center"
  }
];

const CalculationHistory = ({ payout_calculation_history }) => {
  const CALCULATION_ID = getQueryParams("calcId");
  const URL_FLAG = CALCULATION_ID;
  const useStyles = makeStyles(tableStyle);
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = React.useState(1);
  const [initialCall, setInitialCall] = React.useState(true);
  const [order, setOrder] = React.useState("desc");
  const [pageDetails, setPageDetails] = React.useState({ ...TablePageData });
  const [orderBy, setOrderBy] = React.useState("UpdatedOn");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [calculationHistoryList, setCalculationHistoryList] = React.useState(
    []
  );

  const handleChangeRowsPerPage = (event) => {
    setPage(1);
    setRowsPerPage(event.target.value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const calculationHistoryListCall = function () {
    const payload = { logID: URL_FLAG };
    payout_calculation_history(
      payload,
      (res) => {
        if (res.status == "success") {
          setCalculationHistoryList(res);
          setPageDetails({
            ...pageDetails,
            lastPage: Math.ceil(res.totalCount / rowsPerPage),
            from: page === 1 ? 1 : (page - 1) * rowsPerPage + 1,
            to:
              page * rowsPerPage < res.totalCount
                ? page * rowsPerPage
                : res.totalCount,
            total: res.totalCount
          });
        }
      },
      (err) => {
        enqueueSnackbar("An error occurred.", {
          variant: "error"
        });
      }
    );
  };

  React.useState(() => {
    calculationHistoryListCall();
  }, []);

  const exportListData = () => {
    if (!isEmpty(calculationHistoryList.data)) {
      const mappedLogs = calculationHistoryList.data.map((row) => ({
        "Processor Name": row.ProcessorName,
        "Residual Month": row.ResidualMonth,
        "Calculation Type": row.Type,
        "Start Date": moment.utc(row.StartDate).format("MM/DD/YYYY HH:mm:ss"),
        "End Date": moment.utc(row.EndDate).format("MM/DD/YYYY HH:mm:ss"),
        Status: row.Status,
        Merchants: row.No_Of_Merch,
        "Stated By": row.StartedBy_Name
      }));
      exportToCSV(mappedLogs, "Calculation History List");
    }
  };

  return (
    <TableContainer
      className={classes.TableContainer}
      component={Paper}
      style={{ borderRadius: "unset" }}
    >
      <Grid
        item
        style={{
          margin: "1rem",
          justifyContent: "space-between",
          display: "flex"
        }}
      >
        <CoustomButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          className={classes.clearButton}
          onClick={() => history.goBack()}
        >
          Back
        </CoustomButton>
        <CoustomButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={exportListData}
          variant="contained"
          color="primary"
          className={classes.buttonStyle}
        >
          Export
        </CoustomButton>
      </Grid>
      <CustomTable
        page={page}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        data={!isEmpty(calculationHistoryList) && calculationHistoryList.data}
        pageDetails={pageDetails}
        headCells={headCellsItems}
        isCallInitialization={initialCall}
        selected={[]}
        setSelected={() => {}}
        isSelection={false}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        totalCount={
          !isEmpty(calculationHistoryList) && calculationHistoryList.totalCount
        }
      >
        {!isEmpty(calculationHistoryList) ? (
          calculationHistoryList.data.map((row) => {
            return (
              <TableRow className={classes.cellHeadSign} key={row.ID}>
                <TableCell className={classes.cellText} align="center">
                  {row.ProcessorName}
                </TableCell>
                <TableCell className={classes.cellText} align="center">
                  {row.ResidualMonth}
                </TableCell>
                <TableCell className={classes.cellText} align="center">
                  {row.Type}
                </TableCell>
                <TableCell className={classes.cellText} align="center">
                  {moment.utc(row.StartDate).format("MM/DD/YYYY HH:mm:ss")}
                </TableCell>
                <TableCell className={classes.cellText} align="center">
                  {moment.utc(row.EndDate).format("MM/DD/YYYY HH:mm:ss")}
                </TableCell>
                <TableCell className={classes.cellText} align="center">
                  {row.Status}
                </TableCell>
                <TableCell className={classes.cellText} align="center">
                  {row.No_Of_Merch}
                </TableCell>
                <TableCell className={classes.cellText} align="center">
                  {row.StartedBy_Name}
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <TableCell className="no-data" colSpan={12}>
              <img src={noDataImage} alt="" className={classes.noDataImage} />
            </TableCell>
          </TableRow>
        )}
      </CustomTable>
    </TableContainer>
  );
};

CalculationHistory.propTypes = {
  payout_calculation_history: PropTypes.func
};

CalculationHistory.defaultProps = {
  payout_calculation_history: () => {}
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
  payout_calculation_history
})(CalculationHistory);
