import React from "react";
import PropTypes from "prop-types";
import SvgIcon from "@material-ui/core/SvgIcon";
import { alpha, makeStyles, withStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import { connect } from "react-redux";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import isEmpty from "lodash/isEmpty";
import TreeItem from "@material-ui/lab/TreeItem";
import PersonIcon from "@material-ui/icons/Person";
import { Button, Typography } from "@material-ui/core";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import { CheckBoxOutlineBlank } from "@material-ui/icons";
import { getUserHierarchyTreeApi } from "../../actions/TableData";
import { getQueryParams } from "../../Utils/commonUtils";

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

function TransitionComponent(props) {
  //   const style = useSpring({
  //     from: {
  //       opacity: 0,
  //       transform: 'translate3d(20px,0,0)',
  //     },
  //     to: {
  //       opacity: props.in ? 1 : 0,
  //       transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
  //     },
  //   });

  return (
    // <animated.div
    // style={""}>
    <Collapse {...props} />
    // </animated.div>
  );
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
  in: PropTypes.bool,
};

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    "&:hover > $content": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:focus > $content, &$selected > $content": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: "var(--tree-view-color)",
    },
    "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label":
      {
        backgroundColor: "transparent",
      },
  },
  labelName: {
    marginLeft: "10px",
    fontSize: "16px !important",
  },
}));

const StyledTreeItem = withStyles((theme) => ({
  iconContainer: {
    "& .close": {
      opacity: 0.3,
    },
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,

    marginBottom: 5,
    fontSize: "16px !important",
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}))((props) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
));

const ViewHierarchy = ({ getUserHierarchyTreeApi, userIdHierarchy }) => {
  const classes = useTreeItemStyles();
  // const userID = getQueryParams("userId");
  const [hierarchyTree, setHierarchyTree] = React.useState({});
  const [expanded, setExpanded] = React.useState([]);
  const [selected, setSelected] = React.useState([]);

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
  };

  const expendAll = () => {
    if (isEmpty(hierarchyTree)) {
      return;
    }
    if (isEmpty(hierarchyTree.children)) {
      setSelected([hierarchyTree.id]);
      setExpanded([hierarchyTree.id]);
    } else {
      const idList = [hierarchyTree.id];
      const updatedIdList = getExpandIdRecursive(
        idList,
        hierarchyTree.children
      );
      setSelected([updatedIdList[0]]);
      setExpanded([...updatedIdList]);
    }
  };

  const getExpandIdRecursive = (list, array = []) => {
    if (isEmpty(array)) {
      return list;
    }
    array.map((item) => {
      if (item.children) {
        list.unshift(item.id);
        getExpandIdRecursive(list, item.children);
      }
    });
    return list;
  };

  const collapseAll = () => {
    if (isEmpty(hierarchyTree)) {
      return;
    }
    setExpanded([]);
    setSelected([]);
  };

  const renderTree = (nodes) => (
    <>
      <StyledTreeItem
        id={nodes.id}
        key={nodes.id}
        nodeId={nodes.id}
        style={{ marginTop: "10px", marginBottom: "10px" }}
        label={
          <div
            className={classes.labelRoot}
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "20px",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "20px",
              }}
            >
              {nodes.id === hierarchyTree.id ? (
                <SupervisorAccountIcon color="inherit" />
              ) : (
                <PersonIcon color="inherit" />
              )}
              <Typography
                className={classes.labelName}
                style={{
                  color: nodes.status === "Active" ? "#000000" : "#ff0000",
                }}
                // variant="subtitle2"
              >
                {nodes.name}
              </Typography>
            </div>
          </div>
        }
      >
        {Array.isArray(nodes.children)
          ? nodes.children.map((node) => renderTree(node))
          : null}
      </StyledTreeItem>
    </>
  );

  const getUserTreeList = () => {
    getUserHierarchyTreeApi({ userID: userIdHierarchy }, (data) => {
      setHierarchyTree(data.data[0] ? data.data[0] : data.data);
    });
  };

  React.useEffect(() => {
    if (userIdHierarchy) {
      getUserTreeList();
    }
  }, [userIdHierarchy]);

  return (
    <Box
      style={{
        padding: "45px",
        minHeight: "800px",
        flexGrow: 1,
        maxWidth: "90%",
        overflowY: "auto",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <TreeView
        aria-label="customized"
        id={hierarchyTree.id}
        defaultExpanded={[hierarchyTree.id || ""]}
        // expanded={expandList}
        defaultCollapseIcon={<MinusSquare />}
        defaultExpandIcon={<PlusSquare />}
        expanded={expanded}
        selected={selected}
        onNodeToggle={handleToggle}
        onNodeSelect={handleSelect}
        // defaultEndIcon={
        //   hierarchyTree.id === 1 ? <SupervisorAccountIcon /> : <></>
        // }
      >
        {renderTree(hierarchyTree)}
      </TreeView>

      <div style={{ marginTop: "6px" }}>
        <Button
          onClick={() => {
            if (!isEmpty(expanded)) {
              collapseAll();
            } else {
              expendAll();
            }
          }}
          variant="contained"
          style={{ textTransform: "none" }}
        >
          <Typography variant="caption" color="inherit">
            {!isEmpty(expanded) ? "Collapes All" : "Expand All"}
          </Typography>
        </Button>
      </div>
    </Box>
  );
};

ViewHierarchy.propTypes = {
  getUserHierarchyTreeApi: PropTypes.func,
  userIdHierarchy: PropTypes.string,
};
const mapStateToProps = () => ({});
export default connect(mapStateToProps, { getUserHierarchyTreeApi })(
  ViewHierarchy
);
