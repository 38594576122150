/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { alpha, makeStyles, withStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import IndeterminateCheckBoxOutlinedIcon from "@material-ui/icons/IndeterminateCheckBoxOutlined";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import {
  Checkbox,
  TextField,
  TableBody,
  TableCell,
  Box,
  Grid,
} from "@material-ui/core";
import { NumericFormat } from "react-number-format";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import tableStyle from "../../assets/jss/material-dashboard-react/components/reportTables";

const useTableStyles = makeStyles(tableStyle);
const useStyles = makeStyles(styles);

const StyledTreeItem = withStyles(theme => ({
  iconContainer: {
    "& svg": {
      height: 20,
      width: 20,
    },
    height: 32,
    width: 32,
    display: "flex",
    alignItems: "center",
  },
  label: {
    fontSize: "1.1rem !important",
  },
  group: {
    marginLeft: 15,
    paddingLeft: 20,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}))(props => <TreeItem {...props} />);

const SubTreeItem = withStyles(theme => ({
  iconContainer: {
    "& svg": {
      height: 18,
      width: 18,
    },
    height: 30,
    width: 30,
    display: "flex",
    alignItems: "center",
  },
  label: {
    fontSize: "1rem !important",
    width: "20%",
  },
  group: {
    marginTop: 8,
    marginLeft: 20,
    paddingLeft: 20,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}))(props => <TreeItem {...props} />);

export default function PricingEquimentInfo(props) {
  const tableClasses = useTableStyles();
  const classes = useStyles();
  return (
    <>
      <TreeView
        defaultCollapseIcon={<IndeterminateCheckBoxOutlinedIcon />}
        defaultExpandIcon={<AddBoxOutlinedIcon />}
        style={{ width: "100%" }}
      >
        {props?.equipmentData.map((data, mainIndex) => (
          <StyledTreeItem nodeId={data?.categoryId} label={data?.categoryName}>
            <Table
              className={tableClasses.table}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
              style={{ marginBottom: "20px" }}
            >
              <TableBody>
                <TableRow>
                  <Box style={{ marginLeft: "20px", paddingLeft: "20px" }}>
                    <Grid
                      container
                      // item
                      // spacing={2}
                      style={{
                        textAlign: "center",
                        justifyContent: "inherit",
                      }}
                    >
                      <Grid item sm={3} xs={3} />
                      <Grid align="left" item sm={3} xs={3}>
                        Type
                      </Grid>
                      <Grid align="left" item sm={2} xs={2}>
                        Unit Price($)
                      </Grid>
                    </Grid>
                  </Box>
                </TableRow>
                {data?.equipmentItem.map((item, index) => (
                  <TableRow>
                    <TableCell style={{ padding: "4px" }}>
                      <SubTreeItem
                        nodeId={item?.equipId}
                        label={item?.equipmentName}
                      >
                        {item.equipmentType.map((subItem, i) => (
                          <Grid
                            key={subItem.equipmentID}
                            container
                            // item
                            // spacing={2}
                            style={{
                              textAlign: "center",
                              justifyContent: "inherit",
                              alignItems: "center",
                            }}
                          >
                            <Grid align="center" item sm={3} xs={3}>
                              <Checkbox
                                style={{ padding: "4px" }}
                                checked={subItem.activeCheckbox}
                                onChange={e => props.onChange(
                                  e.target.checked,
                                  "check",
                                  mainIndex,
                                  index,
                                  i
                                )}
                                inputProps={{
                                  "aria-label": "select all desserts",
                                }}
                                color="primary"
                              />
                            </Grid>
                            <Grid sm={3} xs={3} align="left">
                              <TextField
                                className={classes.quaterInputPricing}
                                style={{
                                  width: "80%",
                                  fontSize: "14px",
                                }}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.activeInputLableColor,
                                    focused: "focused",
                                  },
                                }}
                                InputProps={{
                                  classes: {
                                    root: classes.activeInputColor,
                                    input: classes.resizePrice,
                                    focused: "focused",
                                  },
                                }}
                                disabled={
                                  !!(subItem.name || !subItem.activeCheckbox)
                                }
                                value={subItem.name}
                                name="type"
                                id="type"
                              />
                            </Grid>
                            <Grid sm={2} xs={2} align="left">
                              <TextField
                                className={classes.quaterInputPricing}
                                style={{
                                  width: "60%",
                                  fontSize: "14px",
                                }}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.activeInputLableColor,
                                    focused: "focused",
                                  },
                                }}
                                InputProps={{
                                  classes: {
                                    root: classes.activeInputColor,
                                    input: classes.resizePrice,
                                    focused: "focused",
                                  },
                                  inputComponent: NumericFormat,
                                }}
                                onChange={e => props.onChange(
                                  e.target.value,
                                  item,
                                  mainIndex,
                                  index,
                                  i
                                )}
                                disabled={!subItem.activeCheckbox}
                                value={subItem.unitPrice}
                                name="unitPrice"
                                id="unitPrice"
                              />
                            </Grid>
                            <Grid sm={2} xs={2} align="left">
                              {
                                subItem.unitPrice === "" && (
                                  <span style={{
                                    color: "red",
                                    fontWeight: 600,
                                    fontStyle: "italic",
                                  }}>
                                    Invalid value
                                  </span>
                                )
                              }
                              {
                                parseFloat(subItem.Min_Unit_Price.replace(/,/g, "")) > parseFloat(subItem.unitPrice.replace(/,/g, "")) && (
                                  <span style={{
                                    color: "red",
                                    fontWeight: 600,
                                    fontStyle: "italic",
                                  }}>
                                    The value is too low
                                  </span>
                                )
                              }
                              {
                                parseFloat(subItem.Max_Unit_Price.replace(/,/g, "")) < parseFloat(subItem.unitPrice.replace(/,/g, "")) && (
                                  <span style={{
                                    color: "red",
                                    fontWeight: 600,
                                    fontStyle: "italic",
                                  }}>
                                    The value is too high
                                  </span>
                                )
                              }
                            </Grid>
                          </Grid>
                        ))}
                      </SubTreeItem>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTreeItem>
        ))}
      </TreeView>
    </>
  );
}
