import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import isEmpty from "lodash/isEmpty";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import FormControl from "@material-ui/core/FormControl";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import MenuItem from "@material-ui/core/MenuItem";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import styles from "../../../../assets/jss/material-dashboard-react/components/businessForm";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

const theme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      backgroundColor: "#2ba992",
      toolbar: {
        backgroundColor: "#3ab499"
      }
    }
  }
});

function SimpleDialog({
  setSearchValues,
  handleSubmitSearch,
  handleClearSearch,
  searchValues,
  agentsList,
  processorList,
  adjustmentNameList,
  getAdjustmentListFun,
  merchantList,
  getFilteredAdjustments
}) {
  const classes = useStyles();

  const onChange = (value, type) => {
    const allState = { ...searchValues };
    if (type == "FromMonth") {
      allState[type] = moment(value).startOf("month").format("YYYY-MM-DD");
    } else if (type == "ToMonth") {
      allState[type] = moment(value).endOf("month").format("YYYY-MM-DD");
    } else {
      allState[type] = value;
    }
    setSearchValues({ ...allState });
  };

  const defaultProps = {
    options: agentsList && agentsList
  };
  const merchantProps = {
    options: merchantList && merchantList
  };
  const adjustmentProps = {
    options: adjustmentNameList && adjustmentNameList
  };
  return (
    <>
      <ThemeProvider theme={theme}>
        <Grid
          container
          className={classes.margin}
          style={{ marginBottom: "1%" }}
        >
          <Grid item xs>
            <TextField
              className={classes.rootDialog}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused"
                }
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused"
                }
              }}
              SelectProps={{
                MenuProps: { disablePortal: false }
              }}
              label="Processor"
              value={searchValues.ProcessorID}
              onChange={(e) => onChange(e.target.value, "ProcessorID")}
              name="ProcessorID"
              id="ProcessorID"
              select
              fullWidth
            >
              {processorList.map((processor) => (
                <MenuItem
                  className={classes.dialogBoxDropDown}
                  value={processor.ProcessorID}
                >
                  {processor.ProcessorName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid
          container
          className={classes.margin}
          style={{ marginBottom: "1%" }}
        >
          <Grid item xs>
            <Autocomplete
              {...defaultProps}
              name="ProfileID"
              id="ProfileID"
              style={{
                fontSize: "14px",
                "&.focused": {
                  // color: theme.palette.primary.main,
                  fontSize: "14px",
                  fontStyle: "Helvetica, Arial,sans-serif"
                }
              }}
              value={
                agentsList &&
                agentsList.find((op) =>
                  op.UserID == searchValues.ProfileID
                    ? searchValues.ProfileID
                    : ""
                )
              }
              fullWidth
              onChange={(e, value) => {
                if (value) {
                  onChange(value.UserID, "ProfileID");
                } else {
                  onChange("", "ProfileID");
                }
              }}
              clearOnBlur
              clearOnEscape
              options={
                agentsList &&
                agentsList?.sort((a, b) =>
                  a.AgentName.localeCompare(b.AgentName)
                )
              }
              getOptionLabel={(option) => option.AgentName}
              getOptionValue={(option) => option.UserID}
              renderInput={(params) => (
                <TextField
                  InputLabelProps={{
                    classes: {
                      root: classes.activeInputLableColor,
                      focused: "focused"
                    }
                  }}
                  required
                  name="ProfileID"
                  id="ProfileID"
                  InputProps={{
                    classes: {
                      root: classes.activeInputColor,
                      input: classes.resize,
                      focused: "focused"
                    },
                    required: true
                  }}
                  {...params}
                  label="Agent Name"
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid
          container
          className={classes.margin}
          style={{ marginBottom: "1%" }}
        >
          <Grid item xs>
            <Autocomplete
              {...adjustmentProps}
              value={
                adjustmentNameList &&
                adjustmentNameList.find((op) =>
                  op.Adj_ID == searchValues.Adj_ID ? searchValues.Adj_ID : ""
                )
              }
              onChange={(e, value) => {
                if (value) {
                  onChange(value.Adj_ID, "Adj_ID");
                } else {
                  onChange("", "Adj_ID");
                }
              }}
              clearOnBlur
              clearOnEscape
              name="Adj_ID"
              id="Adj_ID"
              fullWidth
              options={
                adjustmentNameList &&
                adjustmentNameList?.sort((a, b) =>
                  a.Adjustment_Name.localeCompare(b.Adjustment_Name)
                )
              }
              getOptionLabel={(option) => option.Adjustment_Name}
              getOptionValue={(option) => option.Adj_ID}
              renderInput={(params) => (
                <TextField
                  InputLabelProps={{
                    classes: {
                      root: classes.activeInputLableColor,
                      focused: "focused"
                    }
                  }}
                  required
                  name="Adj_ID"
                  id="Adj_ID"
                  InputProps={{
                    classes: {
                      root: classes.activeInputColor,
                      input: classes.resize,
                      focused: "focused"
                    },
                    required: true
                  }}
                  {...params}
                  label="Adjustment Name"
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid
          container
          className={classes.margin}
          style={{ marginBottom: "1%" }}
        >
          <Grid style={{ width: "100%" }} item xs={12}>
            <TextField
              className={classes.rootDialog}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused"
                }
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused"
                }
              }}
              SelectProps={{
                MenuProps: { disablePortal: false }
              }}
              label="DBA Name or MID"
              value={searchValues.MID}
              autoFocus={
                searchValues.MID != "" && searchValues.MID != null && true
              }
              onChange={(e) => onChange(e.target.value, "MID")}
              name="MID"
              style={{ width: "100%" }}
              fullWidth
              id="MID"
            />
          </Grid>
        </Grid>
        <Grid
          container
          className={classes.margin}
          style={{ marginBottom: "1%" }}
        >
          <Grid style={{ width: "100%" }} item xs={12}>
            <TextField
              className={classes.rootDialog}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused"
                }
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused"
                }
              }}
              SelectProps={{
                MenuProps: { disablePortal: false }
              }}
              label="Status"
              value={searchValues.Status}
              onChange={(e) => onChange(e.target.value, "Status")}
              name="Status"
              style={{ width: "100%" }}
              fullWidth
              id="Status"
              select
            >
              <MenuItem className={classes.dialogBoxDropDown} value="1">
                Active
              </MenuItem>
              <MenuItem className={classes.dialogBoxDropDown} value="0">
                Inactive
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <Grid spacing={2} container>
          <Grid item xs={6} lg={6}>
            <FormControl fullWidth>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  views={["month", "year"]}
                  id="FromMonth"
                  name="FromMonth"
                  maxDate={new Date()}
                  minDateMessage=""
                  invalidDateMessage=""
                  maxDateMessage=""
                  inputVariant="standard"
                  format="MM/yyyy"
                  placeholder="MM/YYYY"
                  label="Start Month"
                  value={moment(searchValues.FromMonth).format("YYYY-MM-DD")}
                  InputProps={{
                    classes: {
                      root: classes.activeInputColor,
                      input: classes.resize,
                      focused: "focused"
                    }
                  }}
                  onChange={(value) => {
                    onChange(value, "FromMonth");
                  }}
                  KeyboardButtonProps={{
                    size: "small"
                  }}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Grid>
          <Grid item xs={6} lg={6}>
            <FormControl fullWidth>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  views={["month", "year"]}
                  id="ToMonth"
                  name="ToMonth"
                  maxDate={moment(new Date()).endOf("month")}
                  minDate={searchValues.FromMonth}
                  minDateMessage=""
                  invalidDateMessage=""
                  maxDateMessage=""
                  label="End Month"
                  inputVariant="standard"
                  format="MM/yyyy"
                  placeholder="MM/YYYY"
                  InputProps={{
                    classes: {
                      root: classes.activeInputColor,
                      input: classes.resize,
                      focused: "focused"
                    }
                  }}
                  value={moment(searchValues.ToMonth).format("YYYY-MM-DD")}
                  onChange={(value) => {
                    onChange(value, "ToMonth");
                  }}
                  KeyboardButtonProps={{
                    size: "small"
                  }}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          container
          className={classes.margin}
          style={{ justifyContent: "end", marginTop: "5%" }}
        >
          <Button
            variant="contained"
            className={classes.clearButton}
            onClick={() => handleClearSearch()}
          >
            clear
          </Button>
          <Button
            variant="contained"
            className={classes.buttonStyle}
            onClick={() => handleSubmitSearch()}
          >
            Search
          </Button>
        </Grid>
      </ThemeProvider>
    </>
  );
}

SimpleDialog.propTypes = {
  setSearchValues: PropTypes.func,
  merchantList: PropTypes.array,
  searchValues: PropTypes.object,
  agentsList: PropTypes.array,
  processorList: PropTypes.array,
  adjustmentNameList: PropTypes.array,
  handleSubmitSearch: PropTypes.func,
  handleClearSearch: PropTypes.func,
  getAdjustmentListFun: PropTypes.func,
  getFilteredAdjustments: PropTypes.func
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(SimpleDialog);
