/* eslint-disable no-nested-ternary */
import React, { useMemo } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import qs from "qs";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  useParams,
  useLocation,
  useHistory,
  useRouteMatch
} from "react-router-dom";
import ListIcon from "@material-ui/icons/List";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Button from "components/CustomButtons/Button";
import { useTheme } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { setBackToSearchFromDetails } from "Redux/Actions/genrateOfferPatient";

import styles from "assets/jss/material-dashboard-react/components/headerStyle";
import AdminNavbarLinks from "./AdminNavbarLinks";
import { logout } from "../../Redux/Actions/LoginActions";

const useStyles = makeStyles(styles);

function useRouter() {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  // Return our custom router object
  // Memoize so that a new object is only returned if something changes
  return useMemo(
    () => ({
      // For convenience add push(), replace(), pathname at top level
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,
      // Merge params and parsed query string into single "query" object
      // so that they can be used interchangeably.
      // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
      query: {
        ...qs.parse(location.search), // Convert string to object
        ...params
      },
      // Include match, location, history objects so we have
      // access to extra React Router functionality if needed.
      match,
      location,
      history
    }),
    [params, match, location, history]
  );
}

function Header(props) {
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const router = useRouter();
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const [pathName, prePathName] = React.useState(window.location.pathname);
  const [temp, setTemp] = React.useState(false);
  const classes = useStyles();
  const { color } = props;
  const appBarClasses = classNames({
    [` ${classes[color]}`]: color
  });

  const handleBackMobile = () => {
    history.goBack();
    props.setBackToSearchFromDetails();
    setTemp(!temp);
  };
  const handleClose = () => {
    props.setopen();
    if (props.openDrawer) {
      props.setOpenDrawerHover(false);
    }
  };

  let modalHead = "";
  const urlName = history.location.pathname.split("/")[2].toLowerCase();
  const pageName = props.app.split("/")[1].toLowerCase();
  const pageUrl = urlName.includes("-") && urlName.replace(/-/g, " ");

  if (urlName == pageName) {
    modalHead = props.app;
  } else if (urlName.includes("-")) {
    modalHead = `${props.app.split("/")[0]}/${" "}${pageUrl}`;
  } else {
    modalHead = `${props.app.split("/")[0]}/${" "}${
      history.location.pathname.split("/")[2]
    }`;
  }
  modalHead = modalHead.replace(/([a-z])([A-Z])/g, "$1 $2");

  const includeHistory = history.location.pathname
    .split("/")[2]
    .toLowerCase()
    .includes(modalHead.replace(/\s+/g, "").split("/")[1].toLowerCase());

  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar
        className={classes.container}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div>
          {includeHistory || windowWidth ? (
            <Button
              onClick={() => handleClose()}
              color="white"
              aria-label="edit"
              justIcon
              round
            >
              {props.openDrawer ? (
                <MoreVertIcon style={{ color: "#000" }} fontSize="lg" />
              ) : (
                <ListIcon style={{ color: "#000" }} fontSize="lg" />
              )}
            </Button>
          ) : includeHistory ? (
            <Button
              onClick={() => handleClose()}
              color="white"
              aria-label="edit"
              justIcon
              round
            >
              {props.openDrawer ? (
                <MoreVertIcon style={{ color: "#000" }} fontSize="lg" />
              ) : (
                <ListIcon style={{ color: "#000" }} fontSize="lg" />
              )}
            </Button>
          ) : (
            <Button
              onClick={() => handleBackMobile()}
              color="white"
              aria-label="edit"
              justIcon
              round
            >
              <ArrowBackIcon style={{ color: "#000" }} fontSize="lg" />
            </Button>
          )}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {modalHead}
        </div>
        <div className={classes.hideHeader}>
          <AdminNavbarLinks {...props} />
        </div>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  setopen: PropTypes.func,
  openDrawer: PropTypes.any,
  setState: PropTypes.func,
  menuData: PropTypes.object,
  logout: PropTypes.func,
  displayName: PropTypes.string,
  app: PropTypes.object,
  setBackToSearchFromDetails: PropTypes.func,
  setCallRolePermission: PropTypes.func,
  setOpenDrawerHover: PropTypes.func
};

const mapStateToProps = ({ userRole, app }) => ({
  menuData: userRole,
  app: app.appBarName
});

export default connect(mapStateToProps, { logout, setBackToSearchFromDetails })(
  Header
);
