/* eslint-disable import/extensions */
import React from "react";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Menu from "@material-ui/core/Menu";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import styles from "assets/jss/material-dashboard-react/components/businessForm";

const useStyles = makeStyles(styles);

function LongMenu(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownload = () => {
    props.downloadFile();
    handleClose();
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      {props.name == "User" && (
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
        >
          <MenuItem
            className={classes.dialogBoxDropDown}
            onClick={() => {
              handleDownload();
            }}
          >
            Download
          </MenuItem>
        </Menu>
      )}
    </div>
  );
}

LongMenu.propTypes = {
  downloadFile: PropTypes.func,
  name: PropTypes.string
};

LongMenu.defaultProps = {
  downloadFile: () => {},
  name: ""
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(LongMenu);
