import * as types from "./ActionTypes";
import { get } from "../Helper/index";
import * as urls from "../../Uintls/Urls";

export const activePatient = (data) => (dispatch) => {
  dispatch({ type: types.ACTIVE_PATIENT_TABLE_DATA_CALL });
  get({
    url: urls.ACTIVE_PATIENT_URL,
    success: types.ACTIVE_PATIENT_TABLE_DATA_SUCCESS,
    failure: types.ACTIVE_PATIENT_TABLE_DATA_ERROR,
    dispatch,
    body: data
  });
};

export const openFundingList = () => (dispatch) => {
  const data = {
    userId: localStorage.getItem("userId")
  };
  dispatch({ type: types.OPEN_FUNDING_TABLE_DATA_CALL });
  get({
    url: urls.OPEN_FUNDING_LISTS_URL,
    success: types.OPEN_FUNDING_TABLE_DATA_SUCCESS,
    failure: types.OPEN_FUNDING_TABLE_DATA_ERROR,
    dispatch,
    body: data
  });
};

export const pendingOffersList = () => (dispatch) => {
  const data = {
    userId: localStorage.getItem("userId")
  };
  dispatch({ type: types.PENDING_OFFERS_TABLE_DATA_CALL });
  get({
    url: urls.PENDING_OFFER_LISTS_URL,
    success: types.PENDING_OFFERS_TABLE_DATA_SUCCESS,
    failure: types.PENDING_OFFERS_TABLE_DATA_ERROR,
    dispatch,
    body: data
  });
};

export const dailyDeposite = () => (dispatch) => {
  const data = {
    loginEmail: localStorage.getItem("email")
  };
  dispatch({ type: types.DAILY_DEPOSIT_TABLE_DATA_CALL });
  get({
    url: urls.DAILY_DEPOSIT_URL,
    success: types.DAILY_DEPOSIT_TABLE_DATA_SUCCESS,
    failure: types.DAILY_DEPOSIT_TABLE_DATA_ERROR,
    dispatch,
    body: data
  });
};

export const totalOutStanding = () => (dispatch) => {
  const data = {
    ClientId: JSON.stringify(localStorage.getItem("sendApiClientId")),
    fromdate: "01-11-2020",
    todate: "02-03-2021"
  };
  dispatch({ type: types.TOTAL_OUTSTANDING_TABLE_DATA_CALL });
  get({
    url: urls.TOTAL_OUTSTANDING_URL,
    success: types.TOTAL_OUTSTANDING_TABLE_DATA_SUCCESS,
    failure: types.TOTAL_OUTSTANDING_TABLE_DATA_ERROR,
    dispatch,
    body: data
  });
};

export const defaults = () => (dispatch) => {
  const data = {
    ClientId: JSON.stringify(localStorage.getItem("sendApiClientId"))
  };
  dispatch({ type: types.DEFAULTS_TABLE_DATA_CALL });
  get({
    url: urls.DEFAULTS_URL,
    success: types.DEFAULTS_TABLE_DATA_SUCCESS,
    failure: types.DEFAULTS_TABLE_DATA_ERROR,
    dispatch,
    body: data
  });
};

export const moveToCollection = () => (dispatch) => {
  const data = {
    ClientId: JSON.stringify(localStorage.getItem("sendApiClientId"))
  };
  dispatch({ type: types.MOVE_TO_COLLECTION_TABLE_DATA_CALL });
  get({
    url: urls.MOVE_TO_COLLECTION_URL,
    success: types.MOVE_TO_COLLECTION_TABLE_DATA_SUCCESS,
    failure: types.MOVE_TO_COLLECTION_TABLE_DATA_ERROR,
    dispatch,
    body: data
  });
};

export const badDebts = () => (dispatch) => {
  const data = {
    ClientId: JSON.stringify(localStorage.getItem("sendApiClientId"))
  };
  dispatch({ type: types.BED_DEBTS_TABLE_DATA_CALL });
  get({
    url: urls.BED_DEBTS_URL,
    success: types.BED_DEBTS_TABLE_DATA_SUCCESS,
    failure: types.BED_DEBTS_TABLE_DATA_ERROR,
    dispatch,
    body: data
  });
};

export const newMemberRegister = () => (dispatch) => {
  const data = {
    ClientId: JSON.stringify(localStorage.getItem("sendApiClientId"))
  };
  dispatch({ type: types.NEW_MEMBER_TABLE_DATA_CALL });
  get({
    url: urls.NEW_MEMBER_URL,
    success: types.NEW_MEMBER_TABLE_DATA_SUCCESS,
    failure: types.NEW_MEMBER_TABLE_DATA_ERROR,
    dispatch,
    body: data
  });
};

export const accountStatementList = () => (dispatch) => {
  const data = {
    userId: localStorage.getItem("userId")
  };
  dispatch({ type: types.ACCOUNT_STATEMENT_TABLE_DATA_CALL });
  get({
    url: urls.ACCOUNT_STATEMENT_LISTS_URL,
    success: types.ACCOUNT_STATEMENT_TABLE_DATA_SUCCESS,
    failure: types.ACCOUNT_STATEMENT_TABLE_DATA_ERROR,
    dispatch,
    body: data
  });
};
