/* eslint-disable no-tabs */
/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useRef, useEffect } from "react";
import * as am4charts from "@amcharts/amcharts4/charts";
import PropTypes from "prop-types";
import * as am4core from "@amcharts/amcharts4/core";
// eslint-disable-next-line camelcase
import { defaults_colors_graph } from "../../assets/GloableColor";

function Daliydeposits(props) {
  const chartRef = useRef(null);
  useEffect(() => {
    if (document.getElementById("default") !== null) {
      const chart = am4core.create("default", am4charts.XYChart);
      chart.logo.disabled = true;
      chart.colors.step = 2;
      chart.maskBullets = false;
      const title = chart.titles.create();
      title.text = "Customers defaulting";
      title.fontSize = 13;
      title.fontFamily = "Calibri";
      title.paddingBottom = 10;
      title.align = "left";
      title.fontWeight = "bold";
      if (
        props.dashboardData.defaultData &&
        props.dashboardData.defaultData.data
      ) {
        // Add data
        chart.data = props.dashboardData.defaultData.data;
      }
      // Add data
      //   chart.data = [{
      //     date: "Jan-2020",
      //     count: 198,
      //     volume: 560796.811,
      //   }, {
      //     date: "Feb-2020",
      //     count: 219,
      //     volume: 628475.85,
      //   }, {
      //     date: "Mar-2020",
      //     count: 261,
      //     volume: 709346.42,
      //   }, {
      //     date: "Apr-2020",
      //     count: 218,
      //     volume: 538842.32,
      //   }, {
      //     date: "May-2020",
      //     count: 194,
      //     volume: 421183.32,
      //   }, {
      //     date: "Jun-2020",
      //     count: 180,
      //     volume: 303933.17,
      //   }, {
      //     date: "Jul-2020",
      //     count: 162,
      //     volume: 213920.78,
      //   }, {
      //     date: "Aug-2020",
      //     count: 103,
      //     volume: 136785.12,
      //   }, {
      //     date: "Sep-2020",
      //     count: 93,
      //     volume: 75005.94,
      //   }, {
      //     date: "Oct-2020",
      //     count: 51,
      //     volume: 29474.38,
      //   }, {
      //     date: "Nov-2020",
      //     count: 11,
      //     volume: 3465.6,
      //   }, {
      //     date: "Dec-2020",
      //     count: 0,
      //     volume: 0,
      //   }];

      const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      // dateAxis.renderer.labels.template.location = 20;
      dateAxis.renderer.minGridDistance = 0;
      dateAxis.renderer.grid.template.disabled = true;
      dateAxis.renderer.fullWidthTooltip = true;
      dateAxis.renderer.labels.template.rotation = 315;
      dateAxis.renderer.labels.template.verticalCenter = "right";
      dateAxis.renderer.labels.template.fontSize = am4core.percent(70);
      dateAxis.tooltip.disabled = true;
      dateAxis.renderer.labels.template.url = "/admin/defaults";
      dateAxis.renderer.labels.template.urlTarget = "_self";

      const distanceAxis = chart.yAxes.push(new am4charts.ValueAxis());
      distanceAxis.title.text = "Volume ($)";
      distanceAxis.title.fontSize = am4core.percent(70);
      distanceAxis.renderer.grid.template.disabled = true;
      distanceAxis.renderer.labels.template.fontSize = am4core.percent(65);
      distanceAxis.tooltip.disabled = true;

      const durationAxis = chart.yAxes.push(new am4charts.ValueAxis());
      durationAxis.title.text = "Count";
      durationAxis.title.fontSize = am4core.percent(70);
      durationAxis.baseUnit = "count";
      durationAxis.renderer.labels.template.fontSize = am4core.percent(65);
      durationAxis.renderer.grid.template.disabled = true;
      durationAxis.renderer.opposite = true;
      durationAxis.syncWithAxis = distanceAxis;
      durationAxis.tooltip.disabled = true;

      // durationAxis.durationFormatter.durationFormat = "hh'h' mm'min'";

      // Create series
      const distanceSeries = chart.series.push(new am4charts.ColumnSeries());
      distanceSeries.dataFields.valueY = "totalAmount";
      distanceSeries.dataFields.dateX = "monthYear";
      distanceSeries.yAxis = distanceAxis;

      //    distanceSeries.tooltipText = "{valueY}";
      distanceSeries.name = "Volume";
      distanceSeries.fontSize = am4core.percent(70);
      distanceSeries.columns.template.width = am4core.percent(50);
      distanceSeries.fill = defaults_colors_graph.volume;
      distanceSeries.stroke = defaults_colors_graph.volume;
      distanceSeries.columns.template.fillOpacity = 8;
      distanceSeries.columns.template.propertyFields.strokeDasharray =
        "dashLength";
      distanceSeries.columns.template.propertyFields.fillOpacity = "alpha";
      distanceSeries.showOnInit = true;

      const distanceState =
        distanceSeries.columns.template.states.create("hover");
      distanceState.properties.fillOpacity = 0.9;

      const durationSeries = chart.series.push(new am4charts.LineSeries());
      durationSeries.dataFields.valueY = "count";
      durationSeries.dataFields.dateX = "monthYear";
      durationSeries.name = "Count";
      durationSeries.yAxis = durationAxis;
      durationSeries.strokeWidth = 1;
      durationSeries.propertyFields.strokeDasharray = "dashLength";
      //   durationSeries.tooltipText = "{valueY}";
      // durationSeries.tooltipText = "[bold]{date}[\]\n[font-size:13px]Count: [/][font-size:11px]{count}\n[font-size:13px]{name} = [/][font-size:11px]{valueY}";
      durationSeries.tooltipHTML = `<table style="font-size:10px">
		  <tr>
		  <center style="font-size:10px"><strong>{monthYear}</strong></center>
		  <hr />
		</tr>
	   
		  <tr>
			<th align="left">Count:</th>
			<td>{count}</td>
		  </tr>
		  <tr>
			<th align="left">Volume($):</th>
			<td>{totalAmount}</td>
		  </tr>
		  
		  </table>`;
      durationSeries.showOnInit = true;
      durationSeries.stroke = defaults_colors_graph.line;
      durationSeries.fill = defaults_colors_graph.line;

      const durationBullet = durationSeries.bullets.push(
        new am4charts.Bullet()
      );
      const durationRectangle = durationBullet.createChild(am4core.Rectangle);
      durationBullet.horizontalCenter = "middle";
      durationBullet.verticalCenter = "middle";
      const circleBullet = durationSeries.bullets.push(
        new am4charts.CircleBullet()
      );
      circleBullet.circle.radius = 2;

      const durationState = durationBullet.states.create("hover");
      durationState.properties.scale = 1.2;

      // Add legend
      chart.legend = new am4charts.Legend();
      chart.legend.position = "top";
      chart.legend.fontSize = 10;
      chart.legend.useDefaultMarker = true;
      const marker = chart.legend.markers.template.children.getIndex(0);
      marker.cornerRadius(12, 12, 12, 12);
      marker.strokeWidth = 0;
      marker.strokeOpacity = 0;

      const markerTemplate = chart.legend.markers.template;
      markerTemplate.width = 10;
      markerTemplate.height = 10;

      durationSeries.tooltip.dy = -8;
      // Add cursor
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.fullWidthLineX = true;
      chart.cursor.xAxis = dateAxis;

      chart.cursor.lineX.strokeOpacity = 0;
      chart.cursor.lineX.fill = defaults_colors_graph.volume;
      chart.cursor.lineX.fillOpacity = 0.1;
      chart.cursor.lineY.disabled = true;

      const hoverState = distanceSeries.columns.template.states.create("hover");
      hoverState.properties.fill = am4core.color(
        defaults_colors_graph.columnHover
      );
      hoverState.properties.fillOpacity = 0.5;

      const buttonContainer = chart.plotContainer.createChild(
        am4core.Container
      );
      buttonContainer.shouldClone = false;
      buttonContainer.align = "right";
      buttonContainer.zIndex = Number.MAX_SAFE_INTEGER;
      buttonContainer.layout = "horizontal";

      // var zoomInButton = buttonContainer.createChild(am4core.Button);
      // zoomInButton.label.text = "+";
      // zoomInButton.events.on("hit", function(ev) {
      // var diff = dateAxis.maxZoomed - dateAxis.minZoomed;
      // var delta = diff * 0.2;
      // dateAxis.zoomToDates(new Date(dateAxis.minZoomed + delta), new Date(dateAxis.maxZoomed - delta));
      // });

      // var zoomOutButton = buttonContainer.createChild(am4core.Button);
      // zoomOutButton.label.text = "-";
      // zoomOutButton.align = "left";
      // zoomOutButton.events.on("hit", function(ev) {
      // var diff = dateAxis.maxZoomed - dateAxis.minZoomed;
      // var delta = diff * 0.2;
      // dateAxis.zoomToDates(new Date(dateAxis.minZoomed - delta), new Date(dateAxis.maxZoomed + delta));
      // });
    }
  }, [props]);
  return <div id="default" style={{ width: "100%", height: "20rem" }} />;
}

Daliydeposits.propTypes = {
  dashboardData: PropTypes.object
};

export default Daliydeposits;
