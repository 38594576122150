import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";

export const exportToCSV = (
  mappedLogs,
  fileName,
  chipValue,
  exportedBy,
  exportedByFname,
  exportedByLname
) => {
  var myCurrentDate = new Date();
  var date =
    myCurrentDate.getDate() +
    "/" +
    (myCurrentDate.getMonth() + 1) +
    "/" +
    myCurrentDate.getFullYear() +
    " " +
    myCurrentDate.getHours() +
    ":" +
    myCurrentDate.getMinutes() +
    ":" +
    myCurrentDate.getSeconds();
  const newCurrentDate = date;
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const ws = XLSX.utils.json_to_sheet(mappedLogs, { origin: "A6" });
  XLSX.utils.sheet_add_aoa(ws, [[`Module:`]], { origin: "A1" });
  XLSX.utils.sheet_add_aoa(ws, [[`${fileName}`]], { origin: "B1" });
  XLSX.utils.sheet_add_aoa(ws, [[`Exported on:`]], { origin: "A2" });
  XLSX.utils.sheet_add_aoa(ws, [[`${newCurrentDate}`]], { origin: "B2" });
  XLSX.utils.sheet_add_aoa(ws, [[`Exported by:`]], { origin: "A3" });
  XLSX.utils.sheet_add_aoa(
    ws,
    [[`${exportedByFname} ${exportedByLname} <${exportedBy}>`]],
    { origin: "B3" }
  );
  XLSX.utils.sheet_add_aoa(ws, [[`Filters:`]], { origin: "A4" });
  XLSX.utils.sheet_add_aoa(ws, [[`${chipValue}`]], { origin: "B4" });
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

export const exportToCSVProvider = (
  mappedLogs,
  fileName,
  chipValue,
  exportedBy,
  exportedByFname,
  exportedByLname
) => {
  var myCurrentDate = new Date();
  var date =
    myCurrentDate.getDate() +
    "/" +
    (myCurrentDate.getMonth() + 1) +
    "/" +
    myCurrentDate.getFullYear() +
    " " +
    myCurrentDate.getHours() +
    ":" +
    myCurrentDate.getMinutes() +
    ":" +
    myCurrentDate.getSeconds();
  const newCurrentDate = date;
  let Heading = [[`Module: Pricing Scheduled Details`, "Last Name", "Email"]];
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const ws = XLSX.utils.json_to_sheet(mappedLogs, { origin: "A6" });
  XLSX.utils.sheet_add_aoa(ws, [[`Module:`]], { origin: "A1" });
  XLSX.utils.sheet_add_aoa(ws, [[`${fileName}`]], { origin: "B1" });
  XLSX.utils.sheet_add_aoa(ws, [[`Exported on:`]], { origin: "A2" });
  XLSX.utils.sheet_add_aoa(ws, [[`${newCurrentDate}`]], { origin: "B2" });
  XLSX.utils.sheet_add_aoa(ws, [[`Exported by:`]], { origin: "A3" });
  XLSX.utils.sheet_add_aoa(
    ws,
    [[`${exportedByFname} ${exportedByLname} <${exportedBy}>`]],
    { origin: "B3" }
  );
  XLSX.utils.sheet_add_aoa(ws, [[`Filters:`]], { origin: "A4" });
  XLSX.utils.sheet_add_aoa(ws, [[`${chipValue}`]], { origin: "B4" });
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  ws["B4"].s = {
    font: {
      name: "arial",
      sz: 24,
      bold: true,
      color: "#F2F2F2"
    }
  };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

export const exportToXLS = (mappedLogs, fileName) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const ws = XLSX.utils.json_to_sheet(mappedLogs, { origin: "A1" });
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const actionPermission = (roleData, catId, pageId, type) => {
  const roles = get(roleData, "roleTab.Roles_Data", false);
  if (isEmpty(roles) || !catId || !pageId || !type) {
    return roles;
  }
  if (!isEmpty(roles.Master_Permission)) {
    const mainCat = roles.Master_Permission.filter(
      (cat) => cat.catCode === catId
    );
    let permission = !!mainCat;
    if (!permission) {
      return false;
    }
    const pageDetails = mainCat[0].Page_Menu.filter(
      (page) => page.Page_Code === pageId
    );
    permission = !!pageDetails;
    if (!permission) {
      return false;
    }
    const permissionData = pageDetails[0].Options.find(
      (per) => per.value === type
    );
    if (permissionData) {
      return permissionData.permission;
    }
    return false;
  }
};

export function formatPhoneNumber(v) {
  var r = v.replace(/\D/g, "");
  if (r.length > 11) {
    r = r.replace(/^(\d\d\d)(\d{3})(\d{0,4}).*/, "$1-$2-$3");
    return r;
  } else if (r.length > 6) {
    r = r.replace(/^(\d\d\d)(\d{3})(\d{0,4}).*/, "$1-$2-$3");
  } else if (r.length > 3) {
    r = r.replace(/^(\d\d\d)(\d{0,3})/, "$1-$2");
  } else {
    r = r.replace(/^(\d*)/, "$1");
  }
  return r;
  // // let val;
  // val = val.replace(/\D/g, "");
  // val = val.replace(/^(\d{3})/, "$1-");
  // val = val.replace(/-(\d{2})/, "-$1-");
  // val = val.replace(/(\d)-(\d{4}).*/, "$1-$2");
  // return val;
}

export const getQueryParams = (data) => {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get(data);
};
