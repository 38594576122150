import "../../assets/css/CountryCode.scss";

import "date-fns";
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  MenuItem,
  Select
} from "@material-ui/core";
// import Card from "components/Card/Card";
import { useSnackbar } from "notistack";
import CustomButton from "components/CustomButtons/Button";
import ButtonOriginal from "@material-ui/core/Button";
import CardIcon from "components/Card/CardIcon";
import Avatar from "@material-ui/core/Avatar";
import CardHeader from "components/Card/CardHeader";
import { ErrorMessage, Field, Form, Formik } from "formik";
import isEmpty from "lodash";
import Divider from "@material-ui/core/Divider";
import Autocomplete, {
  createFilterOptions
} from "@material-ui/lab/Autocomplete";
// import CancelIcon from "@material-ui/icons/Cancel";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import InputAdornment from "@material-ui/core/InputAdornment";
import { addNewPatientCall } from "../../actions/Patient.action";
import PatientStyle from "./PatientStyle";
import { getCountryList } from "../../actions/login.action";
import { getCountryTypeList } from "../../Redux/Actions/UserRegistration";
import { locationCity } from "../../constant/locationConstant";
import Validation from "../../Utils/Validations";

const useStyles = makeStyles(PatientStyle);
const AddPatientForm = (props) => {
  const formikRef = React.useRef(null);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [countryList, setCountryList] = React.useState([]);
  const filter = createFilterOptions();
  const [initialSubmit, setInitialSubmit] = React.useState(false);

  const checkValidation = (error) => {
    const tempData = {};
    error.map((err) => {
      tempData[err.param] = err.msg;
    });
    formikRef.current.setErrors(tempData);
  };
  const initialValues = {
    firstName: "",
    lastName: "",
    ssn: "",
    mobile: "",
    confirmSsn: "",
    email: "",
    dob: null,
    address1: "",
    address2: "",
    country: "US",
    state: "",
    city: "",
    zip: "",
    responsibilty: "",
    provider: "",
    insuranceProvider: "",
    otherservicename: "",
    service: "",
    countryCode: 1
  };

  const onFormSubmit = (values) => {
    const data = {
      firstName: values.firstName,
      lastName: values.lastName,
      ssn: values.ssn,
      mobile: values.mobile,
      email: values.email,
      dob: values.dob,
      address1: values.address1,
      address2: values.address2,
      country: values.country,
      state: values.state,
      city: values.city,
      zip: values.zip,
      responsibilty: values.responsibilty,
      provider: values.provider,
      insuranceProvider: values.insuranceProvider,
      otherservicename: "",
      service: "",
      countryCode: values.countryCode
    };
    if (values.otherservicename !== "") {
      data.service = "other";
      data.otherservicename = values.otherservicename;
    } else {
      data.service = values.service;
      data.otherservicename = "";
    }
    props.addNewPatientCall(
      data,
      () => {
        enqueueSnackbar("Patient registered successfully.", {
          variant: "success"
        });
      },
      (errors) => {
        if (errors.message === "Patient already registered") {
          enqueueSnackbar(errors.message, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          });
        } else {
          checkValidation(errors.errors);
        }
      }
    );
  };

  React.useEffect(() => {
    if (!props.formData.countryTypeList) {
      props.getCountryTypeList();
    }
    if (isEmpty(countryList)) {
      props.getCountryList((data) => {
        setCountryList(data.Country_Data);
      });
    }
  }, []);

  const defaultProps = {
    options: props.formData.cptCodeData.State_Data
  };

  return (
    <>
      <CardHeader className={classes.headerCardIcon}>
        <CardIcon className={classes.avatarIcon}>
          <Avatar alt="Remy Sharp" />
        </CardIcon>
      </CardHeader>
      <Paper className={classes.loginFormPaper}>
        <Grid
          container
          sm={12}
          xs={12}
          alignItems="center"
          justify="flex-start"
          className={classes.addPatientBackButton}
        >
          <Grid item>
            <Button
              variant="contained"
              size="small"
              className={classes.clearButton}
              onClick={() => props.history.goBack()}
            >
              <Typography component="p">Back</Typography>
            </Button>
          </Grid>
        </Grid>
        <Formik
          innerRef={(p) => {
            formikRef.current = p;
          }}
          onSubmit={onFormSubmit}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={Validation.addPatient}
        >
          {({
            errors,
            setFieldValue,
            values,
            resetForm,
            touched,
            handleSubmit
          }) => (
            <Form>
              <Grid className={classes.addPatientForm} spacing={1} container>
                <Grid item xs={12} sm={3}>
                  <Field
                    as={TextField}
                    fullWidth
                    size="small"
                    error={
                      initialSubmit && errors.firstName && touched.firstName
                    }
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        focused: "focused"
                      }
                    }}
                    label="First Name"
                    name="firstName"
                    id="firstName"
                  />
                  <ErrorMessage name="firstName">
                    {(msg) => (
                      <Typography
                        component="span"
                        color="error"
                        variant="body2"
                      >
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <Field
                    as={TextField}
                    fullWidth
                    size="small"
                    error={initialSubmit && errors.lastName && touched.lastName}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        focused: "focused"
                      }
                    }}
                    label="Last Name"
                    name="lastName"
                    id="lastName"
                  />
                  <ErrorMessage name="lastName">
                    {(msg) => (
                      <Typography
                        component="span"
                        color="error"
                        variant="body2"
                      >
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Field
                    as={TextField}
                    error={initialSubmit && errors.ssn && touched.ssn}
                    fullWidth
                    size="small"
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,

                        focused: "focused"
                      }
                    }}
                    label="SSN"
                    name="ssn"
                    id="ssn"
                  />
                  <ErrorMessage name="ssn">
                    {(msg) => (
                      <Typography
                        component="span"
                        color="error"
                        variant="body2"
                      >
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Field
                    as={TextField}
                    error={
                      initialSubmit && errors.confirmSsn && touched.confirmSsn
                    }
                    fullWidth
                    size="small"
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        focused: "focused"
                      }
                    }}
                    label="Confirm SSN"
                    name="confirmSsn"
                    id="confirmSsn"
                  />
                  <ErrorMessage name="confirmSsn">
                    {(msg) => (
                      <Typography
                        component="span"
                        color="error"
                        variant="body2"
                      >
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <Field
                    as={TextField}
                    error={initialSubmit && errors.mobile && touched.mobile}
                    fullWidth
                    size="small"
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    label="Mobile"
                    name="mobile"
                    id="mobile"
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,

                        focused: "focused"
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <Select
                            id="country-menu"
                            value={values.countryCode}
                            onChange={(event) => {
                              setFieldValue("countryCode", event.target.value);
                            }}
                            classes={{ root: "country-list" }}
                            className="no-underline"
                            inputProps={{
                              "aria-label": "Without label"
                            }}
                          >
                            {countryList.map((country) => (
                              <MenuItem
                                value={country.Dial_Code}
                                option={countryList}
                                key={country.Country_Code}
                                className="country"
                              >
                                <span
                                  className={`country-flag ${country.Country_Code.toLowerCase().substring(
                                    0,
                                    2
                                  )}`}
                                />
                                &nbsp;
                                <span className="dial-code">{`+${country.Dial_Code}`}</span>
                                &nbsp;
                                <span className="country-name">
                                  ({country.Country_Name})
                                </span>
                              </MenuItem>
                            ))}
                          </Select>
                        </InputAdornment>
                      )
                    }}
                  />
                  <ErrorMessage name="mobile">
                    {(msg) => (
                      <Typography
                        component="span"
                        color="error"
                        variant="body2"
                      >
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Field
                    as={TextField}
                    error={initialSubmit && errors.email && touched.email}
                    fullWidth
                    size="small"
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        focused: "focused"
                      }
                    }}
                    label="Email"
                    type="email"
                    name="email"
                    id="email"
                  />
                  <ErrorMessage name="email">
                    {(msg) => (
                      <Typography
                        component="span"
                        color="error"
                        variant="body2"
                      >
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>

                <Grid item xs={12} sm={3}>
                  {/* <Field
                      as={TextField}
                      fullWidth
                      size="small"
                      error={initialSubmit && errors.dob && touched.dob}
                      InputLabelProps={{
                        shrink: true,
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,

                          focused: "focused",
                        },
                      }}
                      label="Date Of Birth"
                      type="date"
                      name="dob"
                      id="dob"
                    /> */}
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      as={TextField}
                      autoOk
                      fullWidth
                      value={values.dob}
                      onChange={(value) => setFieldValue("dob", value)}
                      views={["year", "month", "date"]}
                      label="Date Of Birth"
                      format="MM/dd/yyyy"
                      name="dob"
                      id="dob"
                    />
                  </MuiPickersUtilsProvider>
                  <ErrorMessage name="dob">
                    {(msg) => (
                      <Typography
                        component="span"
                        color="error"
                        variant="body2"
                      >
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    as={TextField}
                    error={initialSubmit && errors.address1 && touched.address1}
                    fullWidth
                    size="small"
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,

                        focused: "focused"
                      }
                    }}
                    label="Address1"
                    name="address1"
                    id="address1"
                  />
                  <ErrorMessage name="address1">
                    {(msg) => (
                      <Typography
                        component="span"
                        color="error"
                        variant="body2"
                      >
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Field
                    as={TextField}
                    // error={
                    //   initialSubmit && errors.address2 && touched.address2
                    // }
                    fullWidth
                    size="small"
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,

                        focused: "focused"
                      }
                    }}
                    label="Address2"
                    name="address2"
                    id="address2"
                  />
                </Grid>

                <Grid item xs={6} sm={3}>
                  <Autocomplete
                    fullWidth
                    size="small"
                    error={initialSubmit && errors.country && touched.country}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      color: "secondary",
                      classes: {
                        root: classes.activeInputColor,

                        focused: "focused"
                      }
                    }}
                    label="Country"
                    name="country"
                    id="country"
                    value={
                      countryList.find(
                        (option) => option.Country_Code === values.country
                      ) || {}
                    }
                    onChange={(_, value) => {
                      value && setFieldValue("country", value.Country_Code);
                    }}
                    options={countryList}
                    classes={{
                      option: classes.option
                    }}
                    autoHighlight
                    getOptionLabel={(option) => option.Country_Name}
                    renderOption={(option) => (
                      <>
                        <span
                          className={`country-flag ${option.Country_Code.toLowerCase().substring(
                            0,
                            2
                          )}`}
                        />
                        &nbsp;
                        <span className="country-name">
                          {option.Country_Name}
                        </span>
                        &nbsp;
                        <span className="dial-code">{`(${option.Country_Code})`}</span>
                      </>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose a country"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password" // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />

                  <ErrorMessage name="country">
                    {(msg) => (
                      <Typography
                        component="span"
                        color="error"
                        variant="body2"
                      >
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Field
                    as={TextField}
                    fullWidth
                    size="small"
                    select={values.country === "US"}
                    error={initialSubmit && errors.state && touched.state}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    label="State"
                    name="state"
                    id="state"
                  >
                    {locationCity.map((key) => (
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value={key.value}
                      >
                        {key.name}
                      </MenuItem>
                    ))}
                  </Field>
                  <ErrorMessage name="state">
                    {(msg) => (
                      <Typography
                        component="span"
                        color="error"
                        variant="body2"
                      >
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Field
                    as={TextField}
                    error={initialSubmit && errors.city && touched.city}
                    fullWidth
                    size="small"
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,

                        focused: "focused"
                      }
                    }}
                    label="City"
                    name="city"
                    id="city"
                  />
                  <ErrorMessage name="city">
                    {(msg) => (
                      <Typography
                        component="span"
                        color="error"
                        variant="body2"
                      >
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>

                <Grid item xs={6} sm={3}>
                  <Field
                    as={TextField}
                    error={initialSubmit && errors.zip && touched.zip}
                    fullWidth
                    size="small"
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,

                        focused: "focused"
                      }
                    }}
                    label="Zip"
                    name="zip"
                    id="zip"
                  />
                  <ErrorMessage name="zip">
                    {(msg) => (
                      <Typography
                        component="span"
                        color="error"
                        variant="body2"
                      >
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={12} sm={12} />
                <Grid item xs={12} sm={12} />
                <Grid item xs={12} sm={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    as={TextField}
                    fullWidth
                    size="small"
                    error={
                      initialSubmit &&
                      errors.responsibilty &&
                      touched.responsibilty
                    }
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,

                        focused: "focused"
                      }
                    }}
                    label="Responsibilty ($)"
                    name="responsibilty"
                    id="responsibilty"
                  />
                  <ErrorMessage name="responsibilty">
                    {(msg) => (
                      <Typography
                        component="span"
                        color="error"
                        variant="body2"
                      >
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Field
                    fullWidth
                    size="small"
                    as={TextField}
                    error={initialSubmit && errors.provider && touched.provider}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,

                        focused: "focused"
                      }
                    }}
                    select
                    label="Provider"
                    name="provider"
                    id="provider"
                  >
                    {props.userProvider.userProvider &&
                      props.userProvider.userProvider.data.map((key) => (
                        <MenuItem value={key.Porvider_Id}>
                          {key.ProviderData}
                        </MenuItem>
                      ))}
                  </Field>
                  <ErrorMessage name="provider">
                    {(msg) => (
                      <Typography
                        component="span"
                        color="error"
                        variant="body2"
                      >
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Field
                    as={TextField}
                    fullWidth
                    size="small"
                    error={
                      initialSubmit &&
                      errors.insuranceProvider &&
                      touched.insuranceProvider
                    }
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,

                        focused: "focused"
                      }
                    }}
                    label="Insurance Carrier"
                    name="insuranceProvider"
                    id="insuranceProvider"
                  />
                  <ErrorMessage name="insuranceProvider">
                    {(msg) => (
                      <Typography
                        component="span"
                        color="error"
                        variant="body2"
                      >
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    value={
                      defaultProps.options.find(
                        (option) => option.cptCode === values.service
                      ) || values.service
                    }
                    label="Service Description"
                    options={defaultProps.options}
                    onChange={(event, newValue) => {
                      if (typeof newValue === "string") {
                        setFieldValue("service", newValue);
                        setFieldValue("otherservicename", newValue);
                      } else if (newValue && newValue.cptCode) {
                        setFieldValue("service", newValue.cptCode);
                        setFieldValue("otherservicename", "");
                      } else {
                        setFieldValue("service", newValue);
                        setFieldValue("otherservicename", newValue);
                      }
                    }}
                    selectOnFocus
                    clearOnBlur
                    fullWidth
                    size="small"
                    handleHomeEndKeys
                    id="service"
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      // Suggest the creation of a new value
                      if (params.inputValue !== "") {
                        filtered.unshift(params.inputValue);
                      }

                      return filtered;
                    }}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === "string") {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.cptDescription) {
                        return option.cptDescription;
                      }
                      // Regular option
                      return option.cptDescription;
                    }}
                    renderOption={(option) =>
                      typeof option === "string" ? (
                        <div>
                          <span style={{ fontWeight: 600 }}>
                            {`Add ${option}`}
                          </span>
                        </div>
                      ) : (
                        option.cptDescription
                      )
                    }
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        size="small"
                        {...params}
                        error={
                          initialSubmit && errors.service && touched.service
                        }
                        label="Service Description"
                      />
                    )}
                  />
                  <ErrorMessage name="service">
                    {(msg) => (
                      <Typography
                        component="span"
                        color="error"
                        variant="body2"
                      >
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>
              </Grid>
              <Box className={classes.buttonBox}>
                {/* <CustomButton
                  variant="outlined"
                  className={classes.clearButton}
                  size="large"
                  onClick={() => props.history.goBack()}
                >
                  Back
                </CustomButton> */}
                <ButtonOriginal
                  variant="outlined"
                  size="large"
                  style={{ height: "2.1em", marginTop: "5px" }}
                  onClick={() => {
                    resetForm();
                    // props.history.goBack();
                  }}
                >
                  Reset
                </ButtonOriginal>
                <CustomButton
                  variant="contained"
                  className={classes.buttonStyle}
                  color="#696969"
                  type="submit"
                  onClick={() => {
                    setInitialSubmit(true);
                    handleSubmit();
                  }}
                  size="large"
                >
                  Register
                </CustomButton>
              </Box>
              <Grid item xs={12} sm={12} />
            </Form>
          )}
        </Formik>
      </Paper>
    </>
  );
};

AddPatientForm.propTypes = {
  formData: PropTypes.object,
  history: PropTypes.object,
  setPatiemtModal: PropTypes.func,
  userProvider: PropTypes.object,
  getCountryTypeList: PropTypes.func,
  addNewPatientCall: PropTypes.func,
  getCountryList: PropTypes.func
};

const mapStateToProps = (app) => ({
  formData: app.genrateOfferPatient,
  userProvider: app.users
});

export default connect(mapStateToProps, {
  getCountryTypeList,
  addNewPatientCall,
  getCountryList
})(AddPatientForm);
