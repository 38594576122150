import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Grid, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory } from "react-router-dom";
import Select from "@material-ui/core/Select";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import FormGroup from "@material-ui/core/FormGroup";
import CancelIcon from "@material-ui/icons/Cancel";
import Switch from "@material-ui/core/Switch";
import {
  withStyles,
  makeStyles,
  useTheme,
  ThemeProvider,
  createTheme
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import isEmpty from "lodash/isEmpty";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import { Field, Form, Formik } from "formik";
import Input from "@material-ui/core/Input";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Validation from "../../utils/validations";
import Tooltip from "@material-ui/core/Tooltip";
import { updateAgentPS } from "modules/payout/actions/agentPayout.action";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import EditIcon from "@material-ui/icons/Edit";
import Modal from "@material-ui/core/Modal";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import Fade from "@material-ui/core/Fade";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#E6E4E4",
    color: theme.palette.common.black,
    height: "20px"
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const useStyles = makeStyles({
  table: {
    minWidth: 700
  }
});

const modalStyles = makeStyles(styles);

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#000",
    fontSize: "12px"
  },
  tooltipPlacementTop: {
    margin: "2px 0"
  }
}))(Tooltip);

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1)
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: "#2ba992",
      "& + $track": {
        backgroundColor: "#CDCDCD",
        opacity: 1,
        border: "none"
      }
    },
    "&$focusVisible $thumb": {
      color: "#2ba992",
      border: "6px solid #fff"
    }
  },
  thumb: {
    width: 24,
    height: 24
  },
  track: {
    borderRadius: 26 / 2,
    border: `0px solid ${theme.palette.grey[400]}`,
    backgroundColor: "#CDCDCD",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"])
  },
  checked: {},
  focusVisible: {}
}))(({ classes, ...props }) => (
  <Switch
    focusVisibleClassName={classes.focusVisible}
    disableRipple
    classes={{
      root: classes.root,
      switchBase: classes.switchBase,
      thumb: classes.thumb,
      track: classes.track,
      checked: classes.checked
    }}
    {...props}
  />
));

const ProcessorTable = ({
  selectedUser,
  addSplitFlag,
  setAddSplitFlag,
  splitList,
  shareType,
  payeeList,
  getPayerList,
  payerList,
  getPayeeList,
  psList,
  getPricingScheduleList,
  onAddSplitProfile,
  onChangeAgentPayoutStatus,
  onRemoveAgentMultiSplit,
  handleProcessorEdit,
  handleProcessorVersion,
  getAgentPayoutMultiSplitList,
  setSelected,
  select
}) => {
  const classes = useStyles();
  const modalClasses = modalStyles();
  const dispatch = useDispatch();
  const oldTheme = useTheme();
  const theme = createTheme({
    ...oldTheme,
    overrides: {
      MuiAutocomplete: {
        inputRoot: {
          padding: "0px"
        },
        input: {
          padding: "0px"
        }
      },
      MuiOutlinedInput: {
        root: {
          background: "#F9FAFF 0% 0% no-repeat",
          borderRadius: "6px",
          height: "44px"
        }
      }
    }
  });
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const editPS = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const addSplit = () => {
    setAddSplitFlag(true);
    getPayeeList(splitList.ProfileID, splitList.ProcessorID);
    getPayerList(splitList.ProfileID);
    getPricingScheduleList(splitList.ProcessorID);
  };

  const updateAgentProfilePS = (values, actions) => {
    const payload = {
      profileID: values.profileId,
      pricingScheduleId: values.pricingScheduleId
    };
    dispatch(
      updateAgentPS(
        { ...payload },
        (data) => {
          if (data.status === "success") {
            getAgentPayoutMultiSplitList();
            actions.resetForm();
            onClose();
          }
        },
        (err) => {
          enqueueSnackbar(`${err.message}`, {
            variant: "error"
          });
        }
      )
    );
  };

  return (
    <div style={{ marginBottom: "40px" }}>
      <Modal
        open={open}
        onClose={onClose}
        align="center"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Fade in={open}>
          <ThemeProvider theme={theme}>
            <Paper
              style={{
                minWidth: "350px",
                minHeight: "260px"
              }}
            >
              <div
                className={classes.statusHeadingText}
                style={{
                  padding: "8px",
                  backgroundColor: "#2ba992",
                  color: "white"
                }}
              >
                Update Pricing Schedule
              </div>
              <Formik
                enableReinitialize
                // validationSchema={}
                onSubmit={updateAgentProfilePS}
                initialValues={{
                  pricingScheduleId: splitList.PS_ID,
                  profileId: splitList.ProfileID
                }}
              >
                {({ values, setFieldValue, resetForm }) => (
                  <Form>
                    <div style={{ padding: "2rem" }}>
                      <div style={{ marginBottom: "2rem" }}>
                        <Typography
                          style={{ textAlign: "left", fontSize: "14px" }}
                        >
                          Select Pricing Schedule
                        </Typography>
                        <Field
                          fullWidth
                          as={Autocomplete}
                          options={psList}
                          name="pricingScheduleId"
                          value={
                            values?.pricingScheduleId &&
                            psList?.find((option) =>
                              option?.PS_ID === values?.pricingScheduleId
                                ? values?.pricingScheduleId
                                : ""
                            )
                          }
                          onChange={(_, value, action) => {
                            if (action === "clear") {
                              setFieldValue("pricingScheduleId", "");
                            } else if (value) {
                              setFieldValue("pricingScheduleId", value.PS_ID);
                            }
                          }}
                          labelId="label-Pricing-Schedule"
                          variant="outlined"
                          placeholder="Pricing Schedule"
                          getOptionLabel={(option) => option.PS_Name || ""}
                          getOptionValue={(option) => option.PS_ID || ""}
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" />
                          )}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignContent: "center",
                          alignItems: "center",
                          gap: "1rem"
                        }}
                      >
                        <Button
                          onClick={() => {
                            resetForm();
                            onClose();
                          }}
                          variant="outlined"
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          style={{
                            backgroundColor: "#2ba992",
                            color: "whitesmoke"
                          }}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Paper>
          </ThemeProvider>
        </Fade>
      </Modal>
      <Formik
        enableReinitialize
        // validationSchema={}
        onSubmit={onAddSplitProfile}
        initialValues={{
          pricingScheduleId: "",
          profileId: splitList.ProfileID,
          splitLevel: JSON.parse(splitList.MultiSplitData || "[]").length + 1,
          profileIDRec: "",
          shareTypeID: 1,
          splitPer: "",
          profileIDPayer: ""
        }}
      >
        {({ values, setFieldValue, resetForm }) => (
          <>
            <Form>
              <Box style={{ marginTop: "20px", marginLeft: "20px" }}>
                <Grid
                  sp
                  container
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <Grid item>
                      <Typography
                        style={{
                          font: "normal normal 600 15px/41px Segoe UI",
                          textDecoration: "underline",
                          color: "#010101",
                          cursor: "pointer"
                        }}
                        onClick={() => {
                          history.push(
                            `/admin/MerchantMapping?processorId=${splitList.ProcessorID}&profileId=${splitList.ProfileID}&userId=${selectedUser.UserID}`
                          );
                        }}
                      >
                        Assigned Merchants: {splitList.Assigned_Merchants}
                      </Typography>
                    </Grid>
                    <Grid style={{ marginLeft: "20px" }} item>
                      <CustomTooltip
                        title="Click to view profile versions"
                        placement="top"
                      >
                        <Typography
                          style={{
                            font: "normal normal 600 15px/41px Segoe UI",
                            textDecoration: "underline",
                            color: "#010101",
                            cursor: "pointer"
                          }}
                          onClick={() =>
                            handleProcessorVersion(
                              splitList.ProfileID,
                              splitList.Versions
                            )
                          }
                        >
                          Versions: {splitList.Versions}
                        </Typography>
                      </CustomTooltip>
                    </Grid>
                  </div>
                  <div>
                    <Grid style={{ marginRight: "1.5rem" }} item>
                      {!isEmpty(splitList.LastCalculation) && (
                        <CustomTooltip
                          title="Click here to edit profile"
                          placement="top"
                        >
                          <Typography
                            style={{
                              font: "normal normal 600 15px/41px Segoe UI",
                              textDecoration: "underline",
                              color: "#2ba992",
                              marginRight: "1rem",
                              cursor: "pointer"
                            }}
                            onClick={() =>
                              handleProcessorEdit(splitList.ProfileID)
                            }
                          >
                            Edit
                          </Typography>
                        </CustomTooltip>
                      )}
                    </Grid>
                  </div>
                </Grid>
              </Box>
              <Box>
                <TableContainer
                  style={{
                    borderTopLeftRadius: "22px",
                    borderTopRightRadius: "22px"
                  }}
                  component={Paper}
                >
                  <Table
                    className={classes.table}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center">
                          <b>Processor</b>
                          <br />
                          {splitList.ProcessorName}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <b>RepCode</b>
                          <br />
                          {splitList.RepCode}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <b>Period</b>
                          <br />
                          {splitList.Period}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <b>Pricing Schedule</b>
                          <br />
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "1rem"
                            }}
                          >
                            <span>{splitList.PricingSchedule}</span>
                            {isEmpty(splitList.LastCalculation) && (
                              <CustomTooltip
                                title="Update pricing schedule"
                                placement="top"
                              >
                                <EditIcon
                                  onClick={editPS}
                                  style={{ cursor: "pointer" }}
                                />
                              </CustomTooltip>
                            )}
                          </span>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <b>Last Calculation</b>
                          <br />
                          {splitList.LastCalculation}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <FormGroup style={{ alignItems: "center" }}>
                            <IOSSwitch
                              checked={splitList.Status === "A"}
                              onChange={() => {
                                onChangeAgentPayoutStatus(splitList.ProfileID);
                              }}
                            />
                          </FormGroup>
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          style={{ color: "#7D7D7D", fontWeight: "bold" }}
                          align="center"
                        >
                          Split Level
                        </TableCell>
                        <TableCell
                          style={{ color: "#7D7D7D", fontWeight: "bold" }}
                          align="center"
                        >
                          Agent (RepCode)
                        </TableCell>
                        <TableCell
                          style={{ color: "#7D7D7D", fontWeight: "bold" }}
                          align="center"
                        >
                          Share Type
                        </TableCell>
                        <TableCell
                          style={{ color: "#7D7D7D", fontWeight: "bold" }}
                          align="center"
                        >
                          Pricing Schedule
                        </TableCell>
                        <TableCell
                          style={{ color: "#7D7D7D", fontWeight: "bold" }}
                          align="center"
                        >
                          Paid by
                        </TableCell>
                        <TableCell
                          style={{ color: "#7D7D7D", fontWeight: "bold" }}
                          align="center"
                        />
                      </TableRow>
                      {!isEmpty(splitList.MultiSplitData) &&
                        JSON.parse(splitList.MultiSplitData).map((row) => (
                          <TableRow
                            onClick={() => {
                              if (!isEmpty(splitList.LastCalculation)) {
                                setSelected("");
                              } else {
                                setSelected(row.SplitLevel);
                              }
                            }}
                            style={{
                              boxShadow:
                                select === row.SplitLevel
                                  ? "inset 4px 0px 0px 0px #2ba992"
                                  : "",
                              background:
                                select === row.SplitLevel ? "#2ba99217" : "",
                              cursor: !isEmpty(splitList.LastCalculation)
                                ? ""
                                : "pointer"
                            }}
                            key={row.SplitLevel}
                          >
                            <StyledTableCell
                              style={{
                                padding: "0px",
                                textAlign: "-webkit-center"
                              }}
                              align="center"
                              component="th"
                              scope="row"
                            >
                              <span
                                style={{
                                  background: "#2ba992",
                                  borderRadius: "25px",
                                  width: "35px",
                                  height: "35px",
                                  display: "flex",
                                  alignItems: "center",
                                  color: "#fff",
                                  justifyContent: "center"
                                }}
                              >
                                {row.SplitLevel}
                              </span>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.Agent}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.ShareType}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.PricingSchedule}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.PaidBy}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {select === row.SplitLevel &&
                              select ===
                                JSON.parse(splitList.MultiSplitData || "[]")
                                  .length ? (
                                <IconButton
                                  onClick={() => {
                                    onRemoveAgentMultiSplit(row.APSplitID);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              ) : (
                                ""
                              )}
                            </StyledTableCell>
                          </TableRow>
                        ))}
                      {addSplitFlag && select ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              padding: "0px",
                              textAlign: "-webkit-center"
                            }}
                            align="center"
                            component="th"
                            scope="row"
                          >
                            <span
                              style={{
                                background: "#2ba992",
                                borderRadius: "25px",
                                width: "35px",
                                height: "35px",
                                display: "flex",
                                alignItems: "center",
                                color: "#fff",
                                justifyContent: "center"
                              }}
                            >
                              {values.splitLevel}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <Field
                              fullWidth
                              as={Autocomplete}
                              name="profileIDRec"
                              id="profileIDRec"
                              variant="outlined"
                              style={{
                                fontSize: "14px",
                                "&.focused": {
                                  // color: theme.palette.primary.main,
                                  fontSize: "14px",
                                  fontStyle: "Helvetica, Arial,sans-serif",
                                  background: "#f9faff00 0% 0% no-repeat"
                                },
                                minWidth: "250px"
                              }}
                              InputProps={{
                                style: {
                                  background: "#f9faff00 0% 0% no-repeat"
                                }
                              }}
                              autoHighlight
                              // onInputChange={(e, newInputValue) => {
                              //   if (newInputValue.length >= 3) {
                              //     getAgentList(newInputValue);
                              //   }
                              // }}
                              getOptionDisabled={(option) =>
                                option.UserID === 919863231596669598
                              }
                              onChange={(_, value, action) => {
                                if (action === "clear") {
                                  setFieldValue("profileIDRec", "");
                                } else {
                                  value && setFieldValue("profileIDRec", value);
                                  setFieldValue(
                                    "pricingScheduleId",
                                    value.PS_ID
                                  );
                                }
                              }}
                              selectOnFocus
                              options={payeeList || []}
                              getOptionLabel={(option) =>
                                option
                                  ? `${option.FName} ${option.LName} (${option.RepCode})`
                                  : ""
                              }
                              renderInput={(params) => (
                                <TextField
                                  InputLabelProps={{
                                    classes: {
                                      // root: classes.activeInputLableColor,
                                      focused: "focused"
                                    },
                                    style: { background: "#000000" }
                                  }}
                                  required
                                  variant="outlined"
                                  InputProps={{
                                    classes: {
                                      // root: classes.activeInputColor,
                                      // input: classes.resize,
                                      focused: "focused"
                                    },
                                    style: { background: "#000000" },
                                    required: true
                                  }}
                                  {...params}
                                  // label="Agent Name"
                                />
                              )}
                              renderOption={(option, { inputValue }) => {
                                const matches = match(
                                  `${option.FName} ${option.LName} (${option.RepCode})`,
                                  inputValue,
                                  {
                                    insideWords: true
                                  }
                                );
                                const parts = parse(
                                  `${option.FName} ${option.LName} (${option.RepCode})`,
                                  matches
                                );

                                return (
                                  <div>
                                    {parts.map((part, index) => (
                                      <span
                                        style={{
                                          background: part.highlight
                                            ? "#f3ff0070"
                                            : "",
                                          fontWeight: part.highlight ? 400 : "",
                                          fontSize: "14px"
                                        }}
                                      >
                                        {part.text}
                                      </span>
                                    ))}
                                  </div>
                                );
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <Field
                              as={Select}
                              name="shareTypeID"
                              id="shareTypeID"
                              sx={{ minWidth: "150px" }}
                              onChange={(e) => {
                                const value = e.target.value;
                                setFieldValue("shareTypeID", value);
                                if (value === 1 || value === 2) {
                                  setFieldValue("splitPer", "");
                                } else {
                                  setFieldValue("pricingScheduleId", "");
                                }
                              }}
                              classes={{ root: "select-min-width" }}
                              variant="outlined"
                              placeholder="Share type*"
                            >
                              {shareType.map((type) => (
                                <MenuItem value={type.ShareType_ID}>
                                  {type.ShareType}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {(values.shareTypeID === 1 ||
                              values.shareTypeID === 2) &&
                            !isEmpty(psList) ? (
                              <Field
                                as={Autocomplete}
                                options={psList}
                                disabled={!values.shareTypeID}
                                name="pricingScheduleId"
                                value={
                                  psList.find(
                                    (option) =>
                                      option.PS_ID === values.pricingScheduleId
                                  ) || {}
                                }
                                onChange={(_, value, action) => {
                                  if (action === "clear") {
                                    setFieldValue("pricingScheduleId", "");
                                  } else if (value) {
                                    setFieldValue(
                                      "pricingScheduleId",
                                      value.PS_ID
                                    );
                                  }
                                }}
                                style={{ minWidth: "250px" }}
                                labelId="label-Pricing-Schedule"
                                variant="outlined"
                                placeholder="Pricing Schedule"
                                getOptionLabel={(option) =>
                                  option.PS_Name || ""
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    // label="Combo box"
                                    variant="outlined"
                                  />
                                )}
                              />
                            ) : (
                              <Field
                                as={TextField}
                                id="splitPer"
                                name="splitPer"
                                variant="outlined"
                                sx={{ minWidth: "250px" }}
                                placeholder="Pricing Schedule*"
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <Field
                              as={Autocomplete}
                              name="profileIDPayer"
                              id="profileIDPayer"
                              variant="outlined"
                              style={{
                                fontSize: "14px",
                                "&.focused": {
                                  // color: theme.palette.primary.main,
                                  fontSize: "14px",
                                  fontStyle: "Helvetica, Arial,sans-serif",
                                  background: "#f9faff00 0% 0% no-repeat"
                                },
                                minWidth: "250px"
                              }}
                              InputProps={{
                                style: {
                                  background: "#f9faff00 0% 0% no-repeat"
                                }
                              }}
                              autoHighlight
                              fullWidth
                              // onInputChange={(e, newInputValue) => {
                              //   if (newInputValue.length >= 3) {
                              //     getAgentList(newInputValue);
                              //   }
                              // }}
                              getOptionDisabled={(option) =>
                                option.UserID === 919863231596669598
                              }
                              onChange={(_, value, action) => {
                                if (action === "clear") {
                                  setFieldValue("profileIDPayer", "");
                                } else {
                                  value &&
                                    setFieldValue("profileIDPayer", value);
                                }
                              }}
                              selectOnFocus
                              options={payerList || []}
                              getOptionLabel={(option) =>
                                option
                                  ? `${option.FName} ${option.LName} (${option.RepCode})`
                                  : ""
                              }
                              renderInput={(params) => (
                                <TextField
                                  InputLabelProps={{
                                    classes: {
                                      // root: classes.activeInputLableColor,
                                      focused: "focused"
                                    },
                                    style: { background: "#000000" }
                                  }}
                                  required
                                  variant="outlined"
                                  InputProps={{
                                    classes: {
                                      // root: classes.activeInputColor,
                                      // input: classes.resize,
                                      focused: "focused"
                                    },
                                    style: { background: "#000000" },
                                    required: true
                                  }}
                                  {...params}
                                  // label="Agent Name"
                                />
                              )}
                              renderOption={(option, { inputValue }) => {
                                const matches = match(
                                  `${option.FName} ${option.LName} (${option.RepCode})`,
                                  inputValue,
                                  {
                                    insideWords: true
                                  }
                                );
                                const parts = parse(
                                  `${option.FName} ${option.LName} (${option.RepCode})`,
                                  matches
                                );

                                return (
                                  <div>
                                    {parts.map((part, index) => (
                                      <span
                                        style={{
                                          background: part.highlight
                                            ? "#f3ff0070"
                                            : "",
                                          fontWeight: part.highlight ? 400 : "",
                                          fontSize: "14px"
                                        }}
                                      >
                                        {part.text}
                                      </span>
                                    ))}
                                  </div>
                                );
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <div>
                              <Button
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                  alignContent: "center",
                                  alignItems: "center"
                                }}
                                type="submit"
                              >
                                Save
                              </Button>
                              <IconButton
                                onClick={() => {
                                  setAddSplitFlag(false);
                                  setSelected(false);
                                  resetForm();
                                }}
                              >
                                <CancelIcon />
                              </IconButton>
                            </div>
                          </StyledTableCell>
                        </TableRow>
                      ) : (
                        <>
                          {isEmpty(splitList.LastCalculation) && (
                            <TableRow
                              style={{
                                textAlign: "center",
                                position: "relative",
                                height: "53.0125px"
                              }}
                            >
                              <div
                                style={{
                                  fontWeight: "bold",
                                  margin: "10px",
                                  textAlign: "center",
                                  position: "absolute",
                                  right: 0,
                                  left: 0
                                }}
                              >
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    textDecoration: "underline",
                                    color: "#EA1616",
                                    cursor: "pointer"
                                  }}
                                  onClick={() => {
                                    setSelected(true);
                                    addSplit();
                                  }}
                                >
                                  Click here
                                </span>{" "}
                                to add Splits
                              </div>
                            </TableRow>
                          )}
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

ProcessorTable.propTypes = {
  selectedUser: PropTypes.object,
  splitList: PropTypes.object,
  addSplitFlag: PropTypes.bool,
  setAddSplitFlag: PropTypes.bool,
  shareType: PropTypes.array,
  payeeList: PropTypes.array,
  getPayerList: PropTypes.func,
  getPayeeList: PropTypes.func,
  payerList: PropTypes.array,
  psList: PropTypes.array,
  getPricingScheduleList: PropTypes.func,
  onAddSplitProfile: PropTypes.func,
  onChangeAgentPayoutStatus: PropTypes.func,
  onRemoveAgentMultiSplit: PropTypes.func,
  handleProcessorEdit: PropTypes.func,
  handleProcessorVersion: PropTypes.func,
  getAgentPayoutMultiSplitList: PropTypes.func,
  setSelected: PropTypes.func,
  select: PropTypes.any
};

export default ProcessorTable;
