const BASE_URL = process.env.REACT_APP_PAYOUT_API_BASE_PATH;
const url = process.env.REACT_APP_API_BASE_PATH;

export const GET_PAYOUT_PROCESSOR_LIST = `${BASE_URL}/processor-list`;
export const GET_AGENT_LIST = `${BASE_URL}/agent-list`;
export const GET_REP_CODE_LIST = `${BASE_URL}/repcode-list`;
export const GET_PAYOUT_LIST = `${BASE_URL}/payout-list-v1`;
export const GET_PAYOUT_DETAILS_LIST = `${BASE_URL}/payout-details-v1`;
export const GET_PAYOUT_LIST_NEW = `${BASE_URL}/payout-list`;
export const GET_PAYOUT_DETAILS_LIST_NEW = `${BASE_URL}/payout-details`;
export const GET_INCOME_EXPENSE_DETAIL = `${BASE_URL}/income-expense-detail`;
export const GET_ADJUSTMENT_DETAILS_LIST = `${BASE_URL}/adjustment-report`;
export const GET_IMPORT_LOGS_LIST = `${BASE_URL}/import-logs`;
export const GET_IMPORT_LOG_MODULE_LIST = `${BASE_URL}/module-master`;
export const GET_IMPORT_LOG_DOWNLOAD_FILE = `${url}/export-data-file`;
