/* eslint-disable import/extensions */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Button from "components/CustomButtons/Button";
import PropTypes from "prop-types";

const styles = (theme) => ({
  tittleColor: {
    backgroundColor: theme.palette.primary.dark,
    display: "flex",
    alighItem: "center",
    justifyContent: "center",
    paddingTop: ".7%",
    height: "2rem"
  },
  tittleTextColor: {
    color: theme.palette.primary.contrastText,
    fontSize: "120%",
    fontStyle: "Helvetica, Arial,sans-serif"
  },
  searchGrid: {
    display: "flex",
    flexDirection: "row"
  },
  autoFill: {
    width: "100%"
  },
  margin: {
    margin: "1%",
    marginTop: "2%"
  },
  textColor: {
    color: "#595c5c",
    fontSize: "90%",
    fontStyle: "Helvetica, Arial,sans-serif"
  },
  input: {
    width: "80%"
  },
  cardWidth: {
    width: "100%"
  },
  iconBox: {
    backgroundColor: theme.palette.info.contrastText
  },
  tncBox: {
    height: "40px",
    display: "flex",
    alighItem: "center",
    justifyContent: "center",
    borderColor: "black",
    backgroundColor: "#fff4cc",
    margin: "1rem"
  },
  buttonBox: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    alighItem: "center",
    margin: "2rem"
  },
  buttonStyle: {
    backgroundColor: theme.palette.primary.main
  },
  inputBox: {
    marginTop: "9%",
    color: "#bfbdbd"
  },
  searchBox: {
    marginTop: "2.5%",
    color: "#bfbdbd"
  },
  gridBox: {
    display: "flex",
    flexDirection: "row"
  },
  activeInputLableColor: {
    fontSize: "90%",
    fontStyle: "Helvetica, Arial,sans-serif",
    "&.focused": {
      color: theme.palette.primary.main
    }
  },
  root: {
    width: "30%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      fontStyle: "Helvetica, Arial,sans-serif"
    }
  },
  rootSelectInput: {
    width: "80%",
    marginBottom: "10%",
    fontSize: "90%",
    fontStyle: "Helvetica, Arial,sans-serif"
  },
  resize: {
    fontSize: "80%",
    fontStyle: "Helvetica, Arial,sans-serif",
    color: theme.palette.primary.dark
  },
  checkBox: {
    padding: "0px"
  },
  fullInput: {
    width: "80%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      fontStyle: "Helvetica, Arial,sans-serif"
    }
  },
  halfInput: {
    width: "87%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      fontStyle: "Helvetica, Arial,sans-serif"
    }
  },
  quaterInput: {
    width: "73%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      fontStyle: "Helvetica, Arial,sans-serif"
    }
  },
  readOnlyInput: {
    backgroundColor: "#f5f5f5",
    fontStyle: "italic"
  }
});

const useStyles = makeStyles(styles);
const ShowOffer = (props) => {
  const [values, setValues] = React.useState(props.preValues);
  const onChange = () => {};
  const classes = useStyles();
  return (
    <div>
      <Box height={40}>
        <Typography>Your Best Available Offer is:</Typography>
      </Box>
      <Box className={classes.margin}>
        <Grid container className={classes.margin}>
          <Grid item xs>
            <TextField
              className={classes.fullInput}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused"
                }
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused"
                }
              }}
              label="Down Payment"
              value={values.downPayment}
              onChange={(e) => onChange(e.target.value, "businessDiscrition")}
              name="businessDiscrition"
              id="businessDiscrition"
            />
          </Grid>
          <Grid item xs>
            <TextField
              className={classes.fullInput}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused"
                }
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused"
                }
              }}
              label="Monthly Payments"
              value={values.totalEmi}
              onChange={(e) => onChange(e.target.value, "businessDiscrition")}
              name="businessDiscrition"
              id="businessDiscrition"
            />
          </Grid>
        </Grid>

        <Grid container className={classes.margin}>
          <Grid item xs>
            <TextField
              className={classes.fullInput}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused"
                }
              }}
              InputProps={{
                readOnly: true,
                classes: {
                  root: classes.readOnlyInput,
                  input: classes.resize,
                  focused: "focused"
                }
              }}
              disabled
              label="Balance"
              value={values.perEmiAmt}
              onChange={(e) => onChange(e.target.value, "perEmiAmt")}
              name="businessDiscrition"
              id="businessDiscrition"
            />
          </Grid>
          <Grid item xs>
            <TextField
              className={classes.fullInput}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused"
                }
              }}
              InputProps={{
                readOnly: true,
                classes: {
                  root: classes.readOnlyInput,
                  input: classes.resize,
                  focused: "focused"
                }
              }}
              disabled
              label="APR(%)"
              value={values.apr}
              onChange={(e) => onChange(e.target.value, "businessDiscrition")}
              name="businessDiscrition"
              id="businessDiscrition"
            />
          </Grid>
        </Grid>
      </Box>
      <Grid container className={classes.margin}>
        <Box className={classes.buttonBox}>
          <Button
            variant="contained"
            className={classes.buttonStyle}
            color="#696969"
            size="large"
            onClick={() => props.nextStep()}
          >
            Accept Offer
          </Button>
        </Box>
      </Grid>
    </div>
  );
};

ShowOffer.prototype = {
  preValues: PropTypes.any,
  nextStep: PropTypes.any
};

export default ShowOffer;
