import { makeStyles } from "@material-ui/core/styles";

const commonStyle = makeStyles((theme) => ({
  borderInput: {
    "& .MuiInputBase-root": {
      "& .MuiOutlinedInput-inputMarginDense": {
        paddingTop: theme.spacing(1.8125),
        paddingBottom: theme.spacing(1.8125),
      },
      "& .MuiAutocomplete-input": {
        paddingTop: `${theme.spacing(1.125)}px !important`,
        paddingBottom: `${theme.spacing(1.125)}px !important`,
      },
      "&.Mui-disabled": {
        backgroundColor: theme.palette.action.hover,
        "& .MuiInputBase-input": {
          cursor: "no-drop",
        },
      },
    },
  },
  multilineOutlinedInput: {
    "&.MuiOutlinedInput-root-740": {
      height: "90px",
    },
  },
}));

export { commonStyle };
