import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Grid, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import {
  withStyles,
  makeStyles,
  createTheme,
  ThemeProvider
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import isEmpty from "lodash/isEmpty";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import { Field, Form, Formik } from "formik";
import Input from "@material-ui/core/Input";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { Divider } from "@material-ui/core";
import { getAgentProfileVersionList } from "../../actions/agentPayout.action";
import tableStyle from "../../components/CustomizeTable/style";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#E6E4E4",
    color: theme.palette.common.black,
    height: "20px"
  },
  body: {
    fontSize: 14
  }
}))(TableCell);
const useBtnStyles = makeStyles(tableStyle);
const useStyles = makeStyles({
  table: {
    minWidth: 700
  }
});

const theme = createTheme({
  overrides: {
    MuiTypography: {
      body: {
        fontSize: "14px"
      }
    }
  },
  palette: {
    neutral: {
      main: "#64748B",
      contrastText: "#fff"
    }
  }
});

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1)
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: "#2ba992",
      "& + $track": {
        backgroundColor: "#CDCDCD",
        opacity: 1,
        border: "none"
      }
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff"
    }
  },
  thumb: {
    width: 24,
    height: 24
  },
  track: {
    borderRadius: 26 / 2,
    border: `0px solid ${theme.palette.grey[400]}`,
    backgroundColor: "#CDCDCD",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"])
  },
  checked: {},
  focusVisible: {}
}))(({ classes, ...props }) => (
  <Switch
    focusVisibleClassName={classes.focusVisible}
    disableRipple
    classes={{
      root: classes.root,
      switchBase: classes.switchBase,
      thumb: classes.thumb,
      track: classes.track,
      checked: classes.checked
    }}
    {...props}
  />
));

const ProfileVersion = ({
  selectedUser,
  addSplitFlag,
  setAddSplitFlag,
  listData,
  shareType,
  payeeList,
  getPayerList,
  payerList,
  getPayeeList,
  psList,
  getPricingScheduleList,
  onAddSplitProfile,
  onChangeAgentPayoutStatus,
  onRemoveAgentMultiSplit,
  editProfileID,
  handleBack
}) => {
  const classes = useStyles();
  const btnClasses = useBtnStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [select, setSelected] = useState("");
  const [versionsList, setVersionsList] = useState(false);
  const history = useHistory();
  const splitList = listData.filter((id) => id.ProfileID == editProfileID)[0];

  const addSplit = () => {
    if (splitList) {
      setAddSplitFlag(true);
      getPayeeList(splitList.ProfileID, splitList.ProcessorID);
      getPayerList(splitList.ProfileID);
      getPricingScheduleList(splitList.ProcessorID);
    }
  };

  const getProfileVersions = () => {
    const data = {
      profileId: editProfileID
    };
    dispatch(
      getAgentProfileVersionList(
        data,
        (res) => {
          if (res.status == "success") {
            setVersionsList(res.data);
          }
        },
        (err) => {
          enqueueSnackbar(`${err.message}`, {
            variant: "error"
          });
        }
      )
    );
  };

  React.useEffect(() => {
    if (!versionsList) {
      getProfileVersions();
    }
  }, []);

  return (
    <Paper style={{ padding: "2rem" }}>
      <ThemeProvider theme={theme}>
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0px 1rem",
            marginTop: "1rem"
          }}
        >
          <Grid item>
            <Box>
              <Box
                style={{ display: "flex", alignItems: "center", gap: "1rem" }}
              >
                <Typography>
                  <b style={{ fontWeight: "bold" }}> Agent Name / Repcode: </b>
                </Typography>
                <Typography>
                  {selectedUser.AgentName} / [{splitList.RepCode}]
                </Typography>
              </Box>
              <Box
                style={{ display: "flex", alignItems: "center", gap: "1rem" }}
              >
                <Typography>
                  <b style={{ fontWeight: "bold" }}>Processor: </b>
                </Typography>
                <Typography>{splitList.ProcessorName}</Typography>
              </Box>
              <Box
                style={{ display: "flex", alignItems: "center", gap: "1rem" }}
              >
                <Typography>
                  <b style={{ fontWeight: "bold" }}>
                    Last Residual Calculated For:{" "}
                  </b>
                </Typography>
                <Typography>{splitList.Period}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              className={btnClasses.clearButton}
              onClick={handleBack}
            >
              Back
            </Button>
          </Grid>
        </Grid>
        <Grid item style={{ margin: "1.5rem 0px" }}>
          <Typography
            style={{
              display: "flex",
              justifyContent: "end",
              marginRight: "50px",
              color: "#2ba992"
            }}
          >
            # of Records: {versionsList && versionsList.length}
          </Typography>
          <Divider />
        </Grid>
        <Formik
          enableReinitialize
          // validationSchema={}
          onSubmit={onAddSplitProfile}
          initialValues={{
            pricingScheduleId: "",
            profileId: splitList.ProfileID,
            splitLevel: JSON.parse(splitList.MultiSplitData || "[]").length + 1,
            profileIDRec: "",
            shareTypeID: 1,
            splitPer: "",
            profileIDPayer: ""
          }}
        >
          {({ values, setFieldValue, resetForm }) => (
            <>
              <Form>
                {versionsList &&
                  versionsList.map((version) => {
                    return (
                      <Box style={{ marginBottom: "2.5rem" }}>
                        <TableContainer
                          style={{
                            borderTopLeftRadius: "22px",
                            borderTopRightRadius: "22px"
                          }}
                          component={Paper}
                        >
                          <Table
                            className={classes.table}
                            aria-label="customized table"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell align="center">
                                  <b>Processor</b>
                                  <br />
                                  {splitList.ProcessorName}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <b>RepCode</b>
                                  <br />
                                  {splitList.RepCode}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <b>Period</b>
                                  <br />
                                  {version.Period}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <b>Pricing Schedule</b>
                                  <br />
                                  {version.PricingSchedule}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <b>Last Calculation</b>
                                  <br />
                                  {splitList.LastCalculation}
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell
                                  style={{
                                    color: "#7D7D7D",
                                    fontWeight: "bold"
                                  }}
                                  align="center"
                                >
                                  Split Level
                                </TableCell>
                                <TableCell
                                  style={{
                                    color: "#7D7D7D",
                                    fontWeight: "bold"
                                  }}
                                  align="center"
                                >
                                  Agent (RepCode)
                                </TableCell>
                                <TableCell
                                  style={{
                                    color: "#7D7D7D",
                                    fontWeight: "bold"
                                  }}
                                  align="center"
                                >
                                  Share Type
                                </TableCell>
                                <TableCell
                                  style={{
                                    color: "#7D7D7D",
                                    fontWeight: "bold"
                                  }}
                                  align="center"
                                >
                                  Pricing Schedule
                                </TableCell>
                                <TableCell
                                  style={{
                                    color: "#7D7D7D",
                                    fontWeight: "bold"
                                  }}
                                  align="center"
                                >
                                  Paid by
                                </TableCell>
                              </TableRow>
                              {JSON.parse(version.MultiSplitData || "[]").map(
                                (row) => (
                                  <>
                                    <TableRow
                                      style={{
                                        boxShadow:
                                          select === row.SplitLevel
                                            ? "inset 4px 0px 0px 0px #2ba992"
                                            : "",
                                        background:
                                          select === row.SplitLevel
                                            ? "#2ba99217"
                                            : "",
                                        cursor: "pointer"
                                      }}
                                      key={row.SplitLevel}
                                    >
                                      <StyledTableCell
                                        style={{
                                          padding: "0px",
                                          textAlign: "-webkit-center"
                                        }}
                                        align="center"
                                        component="th"
                                        scope="row"
                                      >
                                        <span
                                          style={{
                                            background: "#2ba992",
                                            borderRadius: "25px",
                                            width: "35px",
                                            height: "35px",
                                            display: "flex",
                                            alignItems: "center",
                                            color: "#fff",
                                            justifyContent: "center"
                                          }}
                                        >
                                          {row.SplitLevel}
                                        </span>
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        {row.Agent}
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        {row.ShareType}
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        {row.PricingSchedule}
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        {row.PaidBy}
                                      </StyledTableCell>
                                    </TableRow>
                                  </>
                                )
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    );
                  })}
              </Form>
            </>
          )}
        </Formik>
      </ThemeProvider>
    </Paper>
  );
};

ProfileVersion.propTypes = {
  selectedUser: PropTypes.object,
  listData: PropTypes.object,
  addSplitFlag: PropTypes.bool,
  setAddSplitFlag: PropTypes.bool,
  shareType: PropTypes.array,
  payeeList: PropTypes.array,
  getPayerList: PropTypes.func,
  getPayeeList: PropTypes.func,
  payerList: PropTypes.array,
  psList: PropTypes.array,
  getPricingScheduleList: PropTypes.func,
  onAddSplitProfile: PropTypes.func,
  onChangeAgentPayoutStatus: PropTypes.func,
  onRemoveAgentMultiSplit: PropTypes.func,
  editProfileID: PropTypes.any,
  handleBack: PropTypes.func
};

export default ProfileVersion;
