import { get } from "../api_interceptor/httpClient";
import toggleLoaderAction from "../../../Redux/Actions/loader";
import {
  GET_PAYOUT_LIST,
  GET_PAYOUT_PROCESSOR_LIST,
  GET_AGENT_LIST,
  GET_REP_CODE_LIST,
  GET_PAYOUT_DETAILS_LIST,
  GET_PAYOUT_LIST_NEW,
  GET_PAYOUT_DETAILS_LIST_NEW,
  GET_INCOME_EXPENSE_DETAIL,
  GET_ADJUSTMENT_DETAILS_LIST,
  GET_IMPORT_LOGS_LIST,
  GET_IMPORT_LOG_MODULE_LIST,
  GET_IMPORT_LOG_DOWNLOAD_FILE
} from "../api_interceptor/urls";

export const fetchPayoutList =
  (payload, successCallback, failureCallback) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await get(GET_PAYOUT_LIST, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };

export const fetchPayoutListNew =
  (payload, successCallback, failureCallback) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await get(GET_PAYOUT_LIST_NEW, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };

export const fetchProcessorList =
  (successCallback, failureCallback, payload) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await get(GET_PAYOUT_PROCESSOR_LIST, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };

export const fetchAgentList =
  (successCallback, failureCallback, payload) => async () => {
    const userId = localStorage.getItem("userId") || "";
    try {
      const data = await get(GET_AGENT_LIST, { userId, ...payload });

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const fetchRepCodeList =
  (successCallback, failureCallback, payload) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await get(GET_REP_CODE_LIST, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };

export const fetchPayoutDetailsList =
  (payload, successCallback, failureCallback) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await get(GET_PAYOUT_DETAILS_LIST, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };

export const fetchPayoutDetailsListNew =
  (payload, successCallback, failureCallback) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await get(GET_PAYOUT_DETAILS_LIST_NEW, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };

export const fetchAdjustmentDetailsList =
  (payload, successCallback, failureCallback) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await get(GET_ADJUSTMENT_DETAILS_LIST, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };

export const fetchIncomeExpenseDetailList =
  (payload, successCallback, failureCallback) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await get(GET_INCOME_EXPENSE_DETAIL, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };

export const getImportLogsList =
  (payload, successCallback, failureCallback) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await get(GET_IMPORT_LOGS_LIST, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };

export const getImportLogsModuleList =
  (successCallback, failureCallback) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await get(GET_IMPORT_LOG_MODULE_LIST);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };

export const fetchImportLogDownloadFile =
  (payload, successCallback, failureCallback) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await get(GET_IMPORT_LOG_DOWNLOAD_FILE, payload);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };
