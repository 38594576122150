/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/extensions */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import closeImage from "assets/img/Close_Image.png";
import success from "assets/img/success.gif";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Card from "components/Card/Card.js";
import setupStyles from "assets/jss/material-dashboard-react/components/setUp";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";

import {
  saveProviderParameter,
  changeProviderParameterFormMode,
  getProviderParameterCall
} from "../../Redux/Actions/ProvideRegistration";

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}
function rand() {
  return Math.round(Math.random() * 20) - 10;
}
const useStyles = makeStyles(setupStyles);
// const Setup = props => {
function Setup(props) {
  const [validationModal, setValidationModal] = React.useState(false);
  const [creatParameterModal, setCreatParameterModal] = React.useState(false);
  const [prePerameterData, setPrePerameterData] = React.useState(
    props.formData.saveProviderParameter
  );
  const [errorMessage, setErrorMessage] = React.useState(false);

  const [validationError, setVlidationError] = React.useState({
    clientId:
      props.formData.providerParameterData &&
      props.formData.providerParameterData.clientId !== ""
        ? false
        : props.formData.saveProviderParameter !== "View",
    Down_Payment: props.formData.saveProviderParameter !== "View",
    Minimum_Down_Payment: props.formData.saveProviderParameter !== "View",
    Minimum_Monthly_Payment: props.formData.saveProviderParameter !== "View",
    Maximum_Monthly_Payment: props.formData.saveProviderParameter !== "View",
    Allow_Overriding_Monthly_Payment_Date:
      props.formData.saveProviderParameter !== "View",
    Review_Offers_above: props.formData.saveProviderParameter !== "View",
    interestRateTier1: props.formData.saveProviderParameter !== "View",
    interestRateTier2: props.formData.saveProviderParameter !== "View",
    interestRateTier3: props.formData.saveProviderParameter !== "View",
    interestRateTier4: props.formData.saveProviderParameter !== "View",
    discountRate: props.formData.saveProviderParameter !== "View"
  });

  const [values, setValues] = React.useState({
    clientId:
      props.formData.providerParameterData &&
      props.formData.providerParameterData.clientId
        ? props.formData.providerParameterData.clientId
        : "",
    Start_Date: moment().format("MM-DD-YYYY"),
    Down_Payment: "",
    Minimum_Down_Payment: "",
    Minimum_Monthly_Payment: "",
    Maximum_Monthly_Payment: "",
    Allow_Overriding_Monthly_Payment_Date: "",
    Review_Offers_above: "",
    interestRateTier1: "",
    interestRateTier2: "",
    interestRateTier3: "",
    interestRateTier4: "",
    discountRate: ""
  });

  // const [values, setValues] = React.useState({
  //   Start_Date: moment().format("MM-DD-YYYY"),
  //   Down_Payment: props.data.Down_Payment,
  //   Minimum_Down_Payment: props.data.Minimum_Down_Payment,
  //   Minimum_Monthly_Payment: props.data.Minimum_Monthly_Payment,
  //   Maximum_Monthly_Payment: props.data.Maximum_Monthly_Payment,
  //   Minimum_Interest_Rate: props.data.Minimum_Interest_Rate,
  //   Maximum_Interest_Rate: props.data.Maximum_Interest_Rate,
  //   Allow_Overriding_Monthly_Payment_Date: props.data.Allow_Overriding_Monthly_Payment_Date,
  //   Review_Offers_above: props.data.Review_Offers_above,
  // });

  const checkValidation = () => {
    const error = { ...validationError };
    let validate = true;
    for (const key in values) {
      if (
        values[key] === "" &&
        (key === "clientId" ||
          key === "Down_Payment" ||
          key === "Minimum_Down_Payment" ||
          key === "Minimum_Monthly_Payment" ||
          key === "Maximum_Monthly_Payment" ||
          key === "Allow_Overriding_Monthly_Payment_Date" ||
          key === "Review_Offers_above" ||
          key === "discountRate" ||
          key === "interestRateTier1" ||
          key === "interestRateTier2" ||
          key === "interestRateTier3" ||
          key === "interestRateTier4")
      ) {
        error[key] = true;
        validate = false;
      } else {
        error[key] = false;
      }
    }
    setVlidationError(error);
    return validate;
  };

  const [open, setOpen] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const onChange = (value, type) => {
    const allState = { ...values };
    allState[type] = value;
    setValues(allState);
  };

  function handleSubmit() {
    if (
      props.formData.formMode === "Create" ||
      props.formData.formMode === "Edit"
    ) {
      props.changeProviderParameterFormMode("init");
      setOpen(false);
      setValidationModal(false);

      const request = { ...values };
      request.pid = props.data.id;
      handleClose();
      props.saveProviderParameter(request);
    }
    setOpen(false);
  }

  React.useEffect(() => {
    if (prePerameterData !== props.formData.saveProviderParameter) {
      setValidationModal(false);
      if (props.formData.saveProviderParameter.status) {
        if (props.formData.saveProviderParameter.useremail) {
          setCreatParameterModal(true);
        } else {
          const data = {
            providerId: props.data.id
          };
          props.setShowSetUp();
          props.getProviderParameterCall(data);
        }
      } else if (
        props.formData.saveProviderParameter &&
        !props.formData.saveProviderParameter.status &&
        props.formData.saveProviderParameter.errors
      ) {
        setErrorMessage(props.formData.saveProviderParameter.errors);
        setPrePerameterData(props.formData.saveProviderParameter);
        setValidationModal(true);
      }
      setPrePerameterData(props.formData.saveProviderParameter);
    }
  }, [prePerameterData, props]);

  const handleResteButton = () => {
    const value = {
      clientId: props.data.value.clientId,
      Start_Date: moment().format("DD-MM-YYYY"),
      Down_Payment: props.data.value.Down_Payment,
      Minimum_Down_Payment: props.data.value.Minimum_Down_Payment,
      Minimum_Monthly_Payment: props.data.value.Minimum_Monthly_Payment,
      Maximum_Monthly_Payment: props.data.value.Maximum_Monthly_Payment,
      Allow_Overriding_Monthly_Payment_Date:
        props.data.value.Allow_Overriding_Monthly_Payment_Date,
      Review_Offers_above: props.data.value.Review_Offers_above,
      interestRateTier1: props.data.value.interestRateTier1,
      interestRateTier2: props.data.value.interestRateTier2,
      interestRateTier3: props.data.value.interestRateTier3,
      interestRateTier4: props.data.value.interestRateTier4,
      discountRate: props.data.value.discountRate
    };
    setValues(value);
  };

  const handleOpen = () => {
    if (checkValidation()) {
      props.changeProviderParameterFormMode("init");
      setOpen(true);
      setValidationModal(false);
    } else {
      setValidationModal(true);
    }
  };

  const handleErrorMessage = () => {
    setValidationModal(false);
    setErrorMessage(false);
  };

  const handleOkSuccessSubmit = () => {
    const data = {
      providerId: props.data.id
    };
    props.setShowSetUp();
    props.getProviderParameterCall(data);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [data, setData] = React.useState(false);
  if (!data && props.data.value) {
    const preData = props.data.value;
    const allState = { ...values };

    allState.clientId = preData.clientId;
    allState.Start_Date = preData.Start_Date;
    allState.Down_Payment = preData.Down_Payment;
    allState.Minimum_Down_Payment = preData.Minimum_Down_Payment;
    allState.Minimum_Monthly_Payment = preData.Minimum_Monthly_Payment;
    allState.Maximum_Monthly_Payment = preData.Maximum_Monthly_Payment;
    allState.Allow_Overriding_Monthly_Payment_Date =
      preData.Allow_Overriding_Monthly_Payment_Date;
    allState.Review_Offers_above = preData.Review_Offers_above;
    allState.interestRateTier1 = preData.interestRateTier1;
    allState.interestRateTier2 = preData.interestRateTier2;
    allState.interestRateTier3 = preData.interestRateTier3;
    allState.interestRateTier4 = preData.interestRateTier4;
    allState.discountRate = preData.discountRate;

    setValues(allState);
    setData(true);
  }

  const classes = useStyles();
  const disabled = "";
  // const disabled = props.formData.saveProviderParameter === "View";
  return (
    <div>
      <Modal open={validationModal} onClose={() => setValidationModal(false)}>
        <div className={classes.modalDiv}>
          <Card className={classes.modalCard} style={{ height: "auto" }}>
            <Grid constainer className={classes.margin}>
              <Grid item style={{ alignItems: "center" }}>
                <div className={classes.cardDiv}>
                  <div align="center">
                    <img src={closeImage} width="30%" />
                  </div>
                  <div className={classes.successText}>
                    {`${
                      validationError.clientId ||
                      validationError.Down_Payment ||
                      validationError.Minimum_Down_Payment ||
                      validationError.Minimum_Monthly_Payment ||
                      validationError.Maximum_Monthly_Payment ||
                      validationError.Allow_Overriding_Monthly_Payment_Date ||
                      validationError.Review_Offers_above ||
                      validationError.discountRate ||
                      validationError.interestRateTier1 ||
                      validationError.interestRateTier2 ||
                      validationError.interestRateTier3 ||
                      validationError.interestRateTier4
                        ? "- Fields marked with an asterisk (*) are mandatory"
                        : ""
                    }`}
                    <br />
                  </div>
                  {errorMessage && (
                    <div
                      className={classes.successText}
                      style={{ overflowY: "scroll", Maxheight: "200px" }}
                    >
                      {/* {errorMessage} */}
                      {errorMessage &&
                        errorMessage.map((key) => (
                          <div className={classes.successText}>-{key.msg} </div>
                        ))}
                    </div>
                  )}
                  <div align="center" style={{ paddingTop: "10px" }}>
                    <Button
                      variant="contained"
                      className={classes.buttonStyle}
                      size="sm"
                      onClick={() => handleErrorMessage()}
                      align="center"
                    >
                      Ok
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Card>
        </div>
      </Modal>
      <Modal
        open={creatParameterModal}
        onClose={() => setCreatParameterModal(false)}
      >
        <div className={classes.modalDiv}>
          <Card className={classes.modalCard} style={{ height: "auto" }}>
            <Grid constainer className={classes.margin}>
              <Grid item style={{ alignItems: "center" }}>
                <div className={classes.cardDiv}>
                  <div align="center">
                    <img src={success} width="40%" />
                  </div>
                  <div
                    className={classes.successText}
                    style={{ whiteSpace: "initial" }}
                  >
                    A password set message was sent to your email address.
                    Please click the link in that message to set your password.
                    <br />
                  </div>
                  <div align="center" style={{ paddingTop: "10px" }}>
                    <Button
                      variant="contained"
                      className={classes.buttonStyle}
                      size="sm"
                      onClick={() => handleOkSuccessSubmit()}
                      align="center"
                    >
                      Ok
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Card>
        </div>
      </Modal>
      <Card>
        <Box className={classes.marginBox}>
          <Grid container className={classes.margin}>
            <Grid item xs={6} sm={6} md={6}>
              <Typography>Parameters</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} align="right">
              <Button
                className={classes.clearButton}
                onClick={() =>
                  props.history.push(`/${props.userDetails.layout}/Providers`)
                }
              >
                Back To Provider List {props.data.clientid}
              </Button>
            </Grid>
          </Grid>
          <Grid container className={classes.margin} align="center">
            <Grid item xs={12}>
              <Typography>
                {`Provider (DBA Name): ${props.data.name}`}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.margin}
            style={{ paddingTop: "0.8%" }}
          >
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                required
                error={validationError.clientId}
                className={classes.root}
                InputLabelProps={{
                  shrink: true,
                  classes: {
                    root: classes.root,
                    focused: "focused"
                  }
                }}
                disabled={!validationError.clientId}
                InputProps={{
                  classes: {
                    root: classes.activeInputColor,
                    input: classes.resize,
                    focused: "focused"
                  }
                }}
                label="Provider ID (Unique 3-10 Alphanumeric digit)"
                value={values.clientId}
                onChange={(e) => onChange(e.target.value, "clientId")}
                name="clientId"
                id="clientId"
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} />
          </Grid>
          <Grid
            container
            className={classes.margin}
            style={{ paddingTop: "2%" }}
            xs={12}
            sm={6}
            md={6}
          >
            <Grid item xs>
              <TextField
                fullWidth
                className={classes.root}
                InputLabelProps={{
                  shrink: true,
                  classes: {
                    root: classes.root,
                    focused: "focused"
                  }
                }}
                disabled={disabled}
                InputProps={{
                  classes: {
                    root: classes.activeInputColor,
                    input: classes.resize,
                    focused: "focused"
                  }
                }}
                type="date"
                label="Start Date"
                value={values.Start_Date}
                onChange={(e) => onChange(e.target.value, "Start_Date")}
                name="Start_Date"
                id="Start_Date"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} />
          </Grid>
          <Grid
            container
            className={classes.margin}
            style={{ paddingTop: "0.8%" }}
          >
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                required
                error={validationError.Down_Payment}
                className={classes.halfInput}
                InputLabelProps={{
                  classes: {
                    root: classes.activeInputLableColor,
                    focused: "focused"
                  }
                }}
                disabled={disabled}
                InputProps={{
                  classes: {
                    root: classes.activeInputColor,
                    input: classes.resize,
                    focused: "focused"
                  },
                  required: true
                }}
                label="Min Down Payment"
                value={values.Down_Payment}
                onChange={(e) => onChange(e.target.value, "Down_Payment")}
                name="Down_Payment"
                id="Down_Payment"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                required
                error={validationError.Minimum_Down_Payment}
                className={classes.halfInput}
                InputLabelProps={{
                  classes: {
                    root: classes.activeInputLableColor,
                    focused: "focused"
                  }
                }}
                disabled={disabled}
                InputProps={{
                  classes: {
                    root: classes.activeInputColor,
                    input: classes.resize,
                    focused: "focused"
                  }
                }}
                label="Max Down Payment $"
                value={values.Minimum_Down_Payment}
                onChange={(e) =>
                  onChange(e.target.value, "Minimum_Down_Payment")
                }
                name="Minimum_Down_Payment"
                id="Minimum_Down_Payment"
              />
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.margin}
            style={{ paddingTop: "0.8%" }}
          >
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                required
                error={validationError.Minimum_Monthly_Payment}
                className={classes.halfInput}
                InputLabelProps={{
                  classes: {
                    root: classes.activeInputLableColor,
                    focused: "focused"
                  }
                }}
                disabled={disabled}
                InputProps={{
                  classes: {
                    root: classes.activeInputColor,
                    input: classes.resize,
                    focused: "focused"
                  }
                }}
                label="Min Number of Monthly Payments"
                value={values.Minimum_Monthly_Payment}
                onChange={(e) =>
                  onChange(e.target.value, "Minimum_Monthly_Payment")
                }
                name="Minimum_Monthly_Payment"
                id="Minimum_Monthly_Payment"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <TextField
                required
                error={validationError.Maximum_Monthly_Payment}
                className={classes.halfInput}
                InputLabelProps={{
                  classes: {
                    root: classes.activeInputLableColor,
                    focused: "focused"
                  }
                }}
                disabled={disabled}
                InputProps={{
                  classes: {
                    root: classes.activeInputColor,
                    input: classes.resize,
                    focused: "focused"
                  }
                }}
                label="Max Number of  Monthly Payments"
                value={values.Maximum_Monthly_Payment}
                onChange={(e) =>
                  onChange(e.target.value, "Maximum_Monthly_Payment")
                }
                name="Maximum_Monthly_Payment"
                id="Maximum_Monthly_Payment"
              />
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.margin}
            style={{ paddingTop: "0.8%" }}
          >
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                required
                error={validationError.Allow_Overriding_Monthly_Payment_Date}
                className={classes.halfInput}
                InputLabelProps={{
                  classes: {
                    root: classes.activeInputLableColor,
                    focused: "focused"
                  }
                }}
                disabled={disabled}
                InputProps={{
                  classes: {
                    root: classes.activeInputColor,
                    input: classes.resize,
                    focused: "focused"
                  }
                }}
                label="Allow overriding monthly payment date"
                value={values.Allow_Overriding_Monthly_Payment_Date}
                onChange={(e) =>
                  onChange(
                    e.target.value,
                    "Allow_Overriding_Monthly_Payment_Date"
                  )
                }
                name="Allow_Overriding_Monthly_Payment_Date"
                id="Allow_Overriding_Monthly_Payment_Date"
                select
              >
                <MenuItem className={classes.dialogBoxDropDown} value="Yes">
                  Yes
                </MenuItem>
                <MenuItem className={classes.dialogBoxDropDown} value="No">
                  No
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                required
                error={validationError.Review_Offers_above}
                className={classes.halfInput}
                InputLabelProps={{
                  classes: {
                    root: classes.activeInputLableColor,
                    focused: "focused"
                  }
                }}
                InputProps={{
                  classes: {
                    root: classes.activeInputColor,
                    input: classes.resize,
                    focused: "focused"
                  }
                }}
                disabled={disabled}
                label="Review offers above $"
                value={values.Review_Offers_above}
                onChange={(e) =>
                  onChange(e.target.value, "Review_Offers_above")
                }
                name="Review_Offers_above"
                id="Review_Offers_above"
              />
            </Grid>
          </Grid>
          <Box
            className={classes.tittleColor}
            height={40}
            style={{ marginTop: "3%" }}
          >
            <Typography
              className={classes.tittleTextColor}
              style={{ fontSize: "110%" }}
            >
              Interest Rate (2 decimal 0-100)
            </Typography>
          </Box>
          <Grid
            container
            className={classes.margin}
            style={{ paddingTop: "0.8%" }}
          >
            <Grid item xs={6} sm={3}>
              <TextField
                required
                error={validationError.interestRateTier1}
                className={classes.halfInput}
                InputLabelProps={{
                  classes: {
                    root: classes.activeInputLableColor,
                    focused: "focused"
                  }
                }}
                disabled={disabled}
                InputProps={{
                  classes: {
                    root: classes.activeInputColor,
                    input: classes.resize,
                    focused: "focused"
                  }
                }}
                label="Tier-1"
                value={values.interestRateTier1}
                onChange={(e) => onChange(e.target.value, "interestRateTier1")}
                name="interestRateTier1"
                id="interestRateTier1"
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                required
                error={validationError.interestRateTier2}
                className={classes.halfInput}
                InputLabelProps={{
                  classes: {
                    root: classes.activeInputLableColor,
                    focused: "focused"
                  }
                }}
                disabled={disabled}
                InputProps={{
                  classes: {
                    root: classes.activeInputColor,
                    input: classes.resize,
                    focused: "focused"
                  }
                }}
                label="Tier-2"
                value={values.interestRateTier2}
                onChange={(e) => onChange(e.target.value, "interestRateTier2")}
                name="interestRateTier2"
                id="interestRateTier2"
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                required
                error={validationError.interestRateTier3}
                className={classes.halfInput}
                InputLabelProps={{
                  classes: {
                    root: classes.activeInputLableColor,
                    focused: "focused"
                  }
                }}
                disabled={disabled}
                InputProps={{
                  classes: {
                    root: classes.activeInputColor,
                    input: classes.resize,
                    focused: "focused"
                  }
                }}
                label="Tier-3"
                value={values.interestRateTier3}
                onChange={(e) => onChange(e.target.value, "interestRateTier3")}
                name="interestRateTier3"
                id="interestRateTier3"
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                required
                error={validationError.interestRateTier4}
                className={classes.halfInput}
                InputLabelProps={{
                  classes: {
                    root: classes.activeInputLableColor,
                    focused: "focused"
                  }
                }}
                InputProps={{
                  classes: {
                    root: classes.activeInputColor,
                    input: classes.resize,
                    focused: "focused"
                  }
                }}
                disabled={disabled}
                label="Tier-4"
                value={values.interestRateTier4}
                onChange={(e) => onChange(e.target.value, "interestRateTier4")}
                name="interestRateTier4"
                id="interestRateTier4"
              />
            </Grid>
          </Grid>
          <Box
            className={classes.tittleColor}
            height={40}
            style={{ marginTop: "3%" }}
          >
            <Typography
              className={classes.tittleTextColor}
              style={{ fontSize: "110%" }}
            >
              Full Payment Discount Rate (2 decimal 0-100)
            </Typography>
          </Box>
          <Grid
            container
            className={classes.margin}
            style={{ paddingTop: "0.8%" }}
          >
            <Grid item xs={6} sm={12} md={6}>
              <TextField
                required
                fullWidth
                error={validationError.discountRate}
                className={classes.halfInput}
                InputLabelProps={{
                  classes: {
                    root: classes.activeInputLableColor,
                    focused: "focused"
                  }
                }}
                disabled={disabled}
                InputProps={{
                  classes: {
                    root: classes.activeInputColor,
                    input: classes.resize,
                    focused: "focused"
                  }
                }}
                label="Discount rate"
                value={values.discountRate}
                onChange={(e) => onChange(e.target.value, "discountRate")}
                name="discountRate"
                id="discountRate"
              />
            </Grid>
          </Grid>
          <Grid container className={classes.margin}>
            <Box className={classes.buttonBox}>
              <Button
                variant="contained"
                className={classes.clearButton}
                color="#696969"
                disabled={disabled}
                size="large"
                onClick={() => handleResteButton()}
              >
                Reset
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button
                variant="contained"
                className={classes.buttonStyle}
                color="#696969"
                size="large"
                disabled={disabled}
                onClick={() => handleOpen()}
              >
                submit
              </Button>
            </Box>
          </Grid>
        </Box>
      </Card>
      <Modal open={open} onClose={handleClose}>
        <div className={classes.modalDiv}>
          <Card
            style={{
              height: "auto",
              width: "35%",
              display: "flex",
              flexDirection: "column",
              paddingTop: "25px",
              paddingBottom: "25px",
              paddingRight: "25px",
              paddingLeft: "25px"
            }}
          >
            <Grid constainer className={classes.margin}>
              <Grid item xs={1} />
              <Grid
                item
                xs={12}
                style={{
                  alignItems: "center",
                  textAlign: "-webkit-center",
                  display: "flex",
                  flexDirection: "column",
                  margin: "25px"
                }}
              >
                <ContactSupportIcon
                  style={{ marginBottom: "5%", width: "8%" }}
                />
                Are you sure you want to update configuration setting from given
                data?
              </Grid>
              <Grid item xs={1} />
            </Grid>
            <Grid container className={classes.margin}>
              <Grid item xs={2} />
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  className={classes.buttonStyle}
                  style={{
                    width: "70%",
                    marginLeft: "25%",
                    margin: "10%",
                    marginRight: "2%"
                  }}
                  size="sm"
                  onClick={() => handleSubmit()}
                >
                  Ok
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  className={classes.clearButton}
                  style={{ width: "70%", margin: "10%", marginLeft: "0px" }}
                  size="sm"
                  onClick={() => handleClose()}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Card>
        </div>
      </Modal>
    </div>
  );
}

Setup.propTypes = {
  saveProviderParameter: PropTypes.func,
  formData: PropTypes.object,
  data: PropTypes.object,
  history: PropTypes.object,
  userDetails: PropTypes.object,
  // tableData: PropTypes.object,
  setShowSetUp: PropTypes.func,
  getProviderParameterCall: PropTypes.func,
  changeProviderParameterFormMode: PropTypes.func
};

const mapStateToProps = (app) => ({
  formData: app.provideRegistration,
  mainData: app
});
export default connect(mapStateToProps, {
  saveProviderParameter,
  changeProviderParameterFormMode,
  getProviderParameterCall
})(Setup);
