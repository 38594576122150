import * as types from "./ActionTypes";
import * as urls from "../../Uintls/Urls";
import { post, put, get } from "../Helper/index";

export const callOnBoarding = (data) => (dispatch) => {
  dispatch({ type: types.ONBORDING_PROVIDER_CALL });
  post({
    url: `${urls.ONBORDING_PROVIDER_URL}`,
    success: types.ONBORDING_PROVIDER_SUCCESS,
    failure: types.ONBORDING_PROVIDER_ERROR,
    dispatch,
    body: data,
  });
};
