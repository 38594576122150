import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, Grid } from "@material-ui/core";
import { createTheme, ThemeProvider, useTheme } from "@material-ui/core/styles";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import AgentPayoutHeader from "./header";
import PayoutActions from "./payoutActions";
import AddNewProfile from "./addNewProfile";
import ProcessorTable from "./processorTable";
import EditProcessor from "./editProcessor";
import ProfileVersion from "./profileVersions";
import { getQueryParams } from "../../utils/common";
import {
  fetchAgentList,
  addNewAgentPayout,
  fetctShareTypeList,
  fetctAgentPayeeList,
  fetctAgentPayerList,
  fetctAgentPayoutList,
  deleteAgentMultiSplit,
  addNewAgentMultiSplit,
  changeAgentPayoutSplitStatus,
  updateAgentPS
} from "../../actions/agentPayout.action";
import { useHistory } from "react-router-dom";
import {
  fetchPricingScheduleList,
  fetchProcessorList
} from "../../actions/pricingSchedule.action";

const AgentPayout = () => {
  const oldTheme = useTheme();
  const theme = createTheme({
    ...oldTheme,
    overrides: {
      MuiOutlinedInput: {
        root: {
          background: "#F9FAFF 0% 0% no-repeat",
          borderRadius: "6px"
        },
        input: {
          padding: "12px"
        }
      },
      // MuiFormControl: {
      //   root: {
      //     background: "#F9FAFF 0% 0% no-repeat",
      //     borderRadius: "6px",
      //     height: "44px",
      //     // maxWidth: "220px",
      //   },
      // },
      MuiAutocomplete: {
        inputRoot: {
          padding: "0px"
        },
        input: {
          padding: "0px"
        }
      }
    }
  });

  const dispatch = useDispatch();
  const history = useHistory();
  const USER_ID = getQueryParams("userId");
  const { enqueueSnackbar } = useSnackbar();
  const [addPayout, setAddPayout] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState({});
  const [processorList, setProcessorList] = React.useState([]);
  // const [repCodeList, setRepCodeList] = React.useState([]);
  const [splitList, setSplitList] = React.useState([]);
  const [otherDetails, setOtherDetails] = React.useState({});
  const [shareType, setShareType] = React.useState([]);
  const [agentsList, setAgentsList] = React.useState([]);
  const [psList, setPsList] = React.useState({});
  const [payeeList, setPayeeList] = React.useState([]);
  const [payerList, setPayerList] = React.useState([]);
  const [addSplitFlag, setAddSplitFlag] = React.useState(false);
  const [showEditTable, setShowEditTable] = React.useState(false);
  const [showProfileVersion, setShowProfileVersion] = React.useState(false);
  const [editProfileID, setEditProfileID] = React.useState(false);
  const [isAgentDataError, setIsAgentDataError] = React.useState(false);
  const [select, setSelected] = React.useState("");
  const [selectDetails, setSelectDetails] = React.useState({
    processor: {}
  });

  const getProcessorList = () => {
    dispatch(
      fetchProcessorList(
        (data) => {
          if (data.status) {
            setProcessorList(data.data);
          } else {
            enqueueSnackbar(`${data.message}`, {
              variant: "error"
            });
          }
        },
        (err) => {
          enqueueSnackbar(`${err.message}`, {
            variant: "error"
          });
        }
      )
    );
  };

  const getAgentList = (agentNaame = "") => {
    // if (searchAgentName === agentName) {
    //   return;
    // }
    // searchAgentName = agentName;
    dispatch(
      fetchAgentList(
        (data) => {
          if (data.status) {
            if (
              (data.message === "No record found" && !isEmpty(agentsList)) ||
              isEmpty(data.data)
            ) {
              setAgentsList([
                {
                  UserID: 919863231596669598,
                  AgentName: "No record found"
                }
              ]);
            }
            if (data.data) {
              setAgentsList(() => [...data.data]);
            }
          } else {
            enqueueSnackbar(`${data.message}`, {
              variant: "error"
            });
          }
        },
        (err) => {
          enqueueSnackbar(`${err.message}`, {
            variant: "error"
          });
        },
        { search: agentNaame }
      )
    );
  };

  const getShareTypeList = () => {
    dispatch(
      fetctShareTypeList(
        (data) => {
          if (data.status) {
            setShareType(data.data[0]);
          } else {
            enqueueSnackbar(`${data.message}`, {
              variant: "error"
            });
          }
        },
        (err) => {
          enqueueSnackbar(`${err.message}`, {
            variant: "error"
          });
        },
        { userId: selectedUser.UserID }
      )
    );
  };

  const getPayeeList = (profileId, processorId) => {
    dispatch(
      fetctAgentPayeeList(
        (data) => {
          if (data.status) {
            setPayeeList(data.data);
          } else {
            enqueueSnackbar(`${data.message}`, {
              variant: "error"
            });
          }
        },
        (err) => {
          enqueueSnackbar(`${err.message}`, {
            variant: "error"
          });
        },
        { profileId, processorId }
      )
    );
  };

  const getPayerList = (profileId) => {
    dispatch(
      fetctAgentPayerList(
        (data) => {
          if (data.status === "success") {
            setPayerList(data.data);
          } else {
            enqueueSnackbar(`${data.message}`, {
              variant: "error"
            });
          }
        },
        (err) => {
          enqueueSnackbar(`${err.message}`, {
            variant: "error"
          });
        },
        { profileId }
      )
    );
  };

  const getPricingScheduleList = (psId = "") => {
    dispatch(
      fetchPricingScheduleList(
        {
          sortColumn: "PS_Name",
          sortOrder: "desc",
          recordPerPage: 9999,
          pageNo: 1,
          processorId: psId || selectDetails.processor.ProcessorID
        },
        (data) => {
          if (data.status === "success") {
            setPsList(data.processorData);
          } else {
            enqueueSnackbar(`${data.message}`, {
              variant: "error"
            });
          }
        },
        (err) => {
          enqueueSnackbar(`${err.message}`, {
            variant: "error"
          });
        }
      )
    );
  };

  const onAddEditProfile = (values, actions) => {
    const payload = {};
    payload.createdBy = localStorage.getItem("userId") || "";
    payload.userId = selectedUser.UserID;
    payload.processorId = values.processorId.ProcessorID;
    payload.pricingScheduleId = values.pricingScheduleId.PS_ID;
    payload.repCode = values.repCode;
    payload.forever = values.forever ? "Y" : "N";
    payload.dateFrom = values.dateFrom ? values.dateFrom : "";
    payload.dateTo = values.dateTo ? values.dateTo : "";

    dispatch(
      addNewAgentPayout(
        { ...payload },
        (data) => {
          if (data.status === "success") {
            enqueueSnackbar(`${data.message}`, {
              variant: "success"
            });
            actions.resetForm();
            setAddPayout(false);
            getAgentPayoutMultiSplitList();
          } else {
            enqueueSnackbar(`${data.message}`, {
              variant: "error"
            });
          }
        },
        (err) => {
          enqueueSnackbar(`${err.message}`, {
            variant: "error"
          });
        }
      )
    );
  };

  const getAgentPayoutMultiSplitList = () => {
    dispatch(
      fetctAgentPayoutList(
        (data) => {
          if (data.status == "success") {
            setSplitList(data.data);
            setIsAgentDataError(false);
            setOtherDetails({
              active_merchants: data.active_merchants,
              active_processors: data.active_processors,
              active_profiles: data.active_profiles
            });
          } else {
            setIsAgentDataError(data.message);
            setSplitList([]);
          }
        },
        (err) => {
          setIsAgentDataError(err.message);
          setSplitList([]);
        },
        { userId: selectedUser.UserID }
      )
    );
  };

  const onAddSplitProfile = (values, { ...rest }) => {
    const payload = { ...values };
    payload.profileIDPayer = values.profileIDPayer.ProfileID;
    payload.profileIDRec = values.profileIDRec.ProfileID;

    dispatch(
      addNewAgentMultiSplit(
        payload,
        (data) => {
          if (data.status === "success") {
            enqueueSnackbar(`${data.message}`, {
              variant: "success"
            });
            rest.resetForm();
            setAddSplitFlag(false);
            getAgentPayoutMultiSplitList();
          } else {
            enqueueSnackbar(`${data.message}`, {
              variant: "error"
            });
          }
        },
        (err) => {
          enqueueSnackbar(`${err.message}`, {
            variant: "error"
          });
        }
      )
    );
  };

  const onChangeAgentPayoutStatus = (profileId) => {
    dispatch(
      changeAgentPayoutSplitStatus(
        (data) => {
          if (data.status === "success") {
            getAgentPayoutMultiSplitList();
          } else {
            enqueueSnackbar(`${data.message}`, {
              variant: "error"
            });
          }
        },
        (err) => {
          enqueueSnackbar(`${err.message}`, {
            variant: "error"
          });
        },
        { profileId }
      )
    );
  };

  const onRemoveAgentMultiSplit = (splitId) => {
    dispatch(
      deleteAgentMultiSplit(
        (data) => {
          if (data.status === "success") {
            getAgentPayoutMultiSplitList();
          } else {
            enqueueSnackbar(`${data.message}`, {
              variant: "error"
            });
          }
        },
        (err) => {
          enqueueSnackbar(`${err.message}`, {
            variant: "error"
          });
        },
        { splitId }
      )
    );
  };

  React.useEffect(() => {
    getAgentList();
    getShareTypeList();
  }, []);

  React.useEffect(() => {
    if (USER_ID && !isEmpty(agentsList) && isEmpty(selectedUser)) {
      const user = agentsList.find((x) => x.UserID == USER_ID);
      if (user) setSelectedUser(user);
    }
  }, [USER_ID, agentsList]);

  React.useEffect(() => {
    if (!isEmpty(processorList)) setProcessorList([]);
    if (!isEmpty(otherDetails)) setOtherDetails([]);
    if (selectedUser.UserID) {
      getProcessorList();
      getAgentPayoutMultiSplitList();
    }
  }, [selectedUser]);

  React.useEffect(() => {
    getPricingScheduleList();
  }, []);

  const handleProcessorEdit = (id) => {
    setShowProfileVersion(false);
    setShowEditTable(true);
    setEditProfileID(id);
  };

  const handleProcessorVersion = (id, version) => {
    if (version == "00") {
      enqueueSnackbar("No Versions found for this User.", {
        variant: "error"
      });
    } else {
      setShowEditTable(false);
      setShowProfileVersion(true);
      setEditProfileID(id);
    }
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Box className="agent-payout-container">
          {!showEditTable && !showProfileVersion && (
            <>
              <AgentPayoutHeader
                agentsList={agentsList}
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
                otherDetails={otherDetails}
                setIsAgentDataError={setIsAgentDataError}
                setSelected={setSelected}
              />

              {!isEmpty(selectedUser) && (
                <>
                  <PayoutActions
                    addPayout={addPayout}
                    setAddPayout={setAddPayout}
                  />
                  {addPayout && (
                    <AddNewProfile
                      setAddPayout={setAddPayout}
                      selectedUser={selectedUser}
                      agentsList={agentsList}
                      processorList={processorList}
                      // repCodeList={repCodeList}
                      setSelectDetails={setSelectDetails}
                      psList={psList}
                      selectDetails={selectDetails}
                      onSubmit={onAddEditProfile}
                    />
                  )}
                  {splitList.map((split, i) => (
                    <ProcessorTable
                      key={i}
                      selectedUser={selectedUser}
                      addSplitFlag={addSplitFlag}
                      setAddSplitFlag={setAddSplitFlag}
                      shareType={shareType}
                      splitList={split}
                      payeeList={payeeList}
                      payerList={payerList}
                      getPayerList={getPayerList}
                      getPayeeList={getPayeeList}
                      getPricingScheduleList={getPricingScheduleList}
                      psList={psList}
                      onAddSplitProfile={onAddSplitProfile}
                      getAgentPayoutMultiSplitList={
                        getAgentPayoutMultiSplitList
                      }
                      setSelected={setSelected}
                      select={select}
                      onChangeAgentPayoutStatus={onChangeAgentPayoutStatus}
                      onRemoveAgentMultiSplit={onRemoveAgentMultiSplit}
                      handleProcessorEdit={(id) => handleProcessorEdit(id)}
                      handleProcessorVersion={(id, version) =>
                        handleProcessorVersion(id, version)
                      }
                    />
                  ))}
                </>
              )}
            </>
          )}
          {showEditTable && !showProfileVersion && (
            <EditProcessor
              editProfileID={editProfileID}
              listData={splitList}
              selectedUser={selectedUser}
              addSplitFlag={addSplitFlag}
              setAddSplitFlag={setAddSplitFlag}
              shareType={shareType}
              payeeList={payeeList}
              payerList={payerList}
              getPayerList={getPayerList}
              getPayeeList={getPayeeList}
              getPricingScheduleList={getPricingScheduleList}
              psList={psList}
              onAddSplitProfile={onAddSplitProfile}
              onChangeAgentPayoutStatus={onChangeAgentPayoutStatus}
              onRemoveAgentMultiSplit={onRemoveAgentMultiSplit}
              getAgentPayoutMultiSplitList={getAgentPayoutMultiSplitList}
              handleBack={() => setShowEditTable(false)}
              handleProcessorEdit={(id) => handleProcessorEdit(id)}
              setSelected={setSelected}
              select={select}
            />
          )}
          {showProfileVersion && !showEditTable && (
            <ProfileVersion
              editProfileID={editProfileID}
              listData={splitList}
              selectedUser={selectedUser}
              addSplitFlag={addSplitFlag}
              setAddSplitFlag={setAddSplitFlag}
              shareType={shareType}
              payeeList={payeeList}
              payerList={payerList}
              getPayerList={getPayerList}
              getPayeeList={getPayeeList}
              getPricingScheduleList={getPricingScheduleList}
              psList={psList}
              onAddSplitProfile={onAddSplitProfile}
              onChangeAgentPayoutStatus={onChangeAgentPayoutStatus}
              onRemoveAgentMultiSplit={onRemoveAgentMultiSplit}
              handleBack={() => setShowProfileVersion(false)}
            />
          )}
        </Box>

        {isAgentDataError && (
          <Typography style={{ color: "red", textAlign: "center" }}>
            {isAgentDataError}
          </Typography>
        )}
      </ThemeProvider>
    </div>
  );
};

AgentPayout.propTypes = {};

export default AgentPayout;
