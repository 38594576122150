import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import styles from "assets/jss/material-dashboard-react/components/businessForm";

const useStyles = makeStyles(styles);

export function SimpleDialog(props) {
  const [values, setValue] = React.useState({
    Id: "",
    amount: "",
    cpt: "",
    amountLess: "",
    paymentDateFrom: "",
    paymentDateTo: ""
  });
  const classes = useStyles();
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const onChange = (value, type) => {
    const allState = { ...values };
    allState[type] = value;
    setValue(allState);
  };

  return (
    <>
      <Grid container className={classes.margin} style={{ marginBottom: "5%" }}>
        <Grid item xs>
          <TextField
            className={classes.rootDialog}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                focused: "focused",
                input: classes.resize
              }
            }}
            label="MID / DBA Name"
            value={values.id}
            onChange={(e) => onChange(e.target.value, "id")}
            name="EHR"
            id="EHR"
            style={{ fontSize: "82%" }}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.margin} style={{ marginBottom: "3%" }}>
        <Grid item xs>
          <TextField
            className={classes.rootDialog}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                focused: "focused",
                input: classes.resize
              }
            }}
            label="Crypto Name"
            value={values.cpt}
            onChange={(e) => onChange(e.target.value, "cpt")}
            name="cpt"
            id="cpt"
            style={{ fontSize: "82%" }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.margin}
        style={{ marginTop: "2%", marginBottom: "5%" }}
      >
        <Grid item xs>
          <TextField
            className={classes.halfInput}
            InputLabelProps={{
              shrink: true,
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            label="Deposit Date (From)"
            type="date"
            value={values.paymentDateFrom}
            onChange={(e) => onChange(e.target.value, "paymentDateFrom")}
            name="paymentDateFrom"
            id="paymentDateFrom"
            style={{ fontSize: "82%" }}
          />
        </Grid>
        <Grid item xs>
          <TextField
            className={classes.halfInput}
            InputLabelProps={{
              shrink: true,
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            label="Deposit Date (To)"
            type="date"
            value={values.paymentDateTo}
            onChange={(e) => onChange(e.target.value, "paymentDateTo")}
            name="paymentDateTo"
            id="paymentDateTo"
            style={{ fontSize: "82%" }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.margin}
        style={{ marginTop: "2%", marginBottom: "5%" }}
      >
        <Grid item xs>
          <TextField
            className={classes.halfInput}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            label="Amount Greater than Equal ($)"
            value={values.amount}
            onChange={(e) => onChange(e.target.value, "amount")}
            name="amount"
            id="amount"
            style={{ fontSize: "82%" }}
          />
        </Grid>
        <Grid item xs>
          <TextField
            className={classes.halfInputSingle}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            label="Amount Less than Equal ($)"
            value={values.amountLess}
            onChange={(e) => onChange(e.target.value, "amountLess")}
            name="amountLess"
            id="amountLess"
            style={{ fontSize: "82%" }}
          />
        </Grid>
      </Grid>

      <Grid
        container
        className={classes.margin}
        style={{ justifyContent: "end" }}
      >
        <Button
          variant="contained"
          className={classes.clearButton}
          onClick={() => props.close()}
        >
          clear
        </Button>
        <Button
          variant="contained"
          className={classes.buttonStyle}
          onClick={() => props.close()}
        >
          Search
        </Button>
      </Grid>
    </>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  close: PropTypes.func
};
